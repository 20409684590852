import React from "react";
import useSWR from "swr";
import { ENDPOINTS } from "../endpoints";

const convertMsToDays = (ms: number) => {
  const msInOneSecond = 1000;
  const secondsInOneMinute = 60;
  const minutesInOneHour = 60;
  const hoursInOneDay = 24;

  const minutesInOneDay = hoursInOneDay * minutesInOneHour;
  const secondsInOneDay = secondsInOneMinute * minutesInOneDay;
  const msInOneDay = msInOneSecond * secondsInOneDay;

  return Math.ceil(ms / msInOneDay);
};

export const getDaysBetweenDates = (dateOne: Date, dateTwo: Date) => {
  let differenceInMs = dateTwo.getTime() - dateOne.getTime();

  if (differenceInMs < 0) {
    differenceInMs = dateOne.getTime() - dateTwo.getTime();
  }

  return convertMsToDays(differenceInMs);
};

export const durationEnum = {
  week: "week",
  month: "month",
  day: "day",
};
const MONTH_DAYS = 30;
const WEEK_DAYS = 7;
export const DISCOUNT_TYPE = "Fixed";

export const usePriceData = (dateOne: Date, dateTwo: Date, service: any) => {
  const [price, setPrice] = React.useState({});
  React.useEffect(() => {
    let totalAmount;
    let discountedAmount;
    let disc;
    const duration = service?.rate?.duration;
    const amount = service?.rate?.amount;
    const discount = service?.discount;
    if (!dateOne || !dateTwo) {
      return;
    }
    const totalDays =
      dateOne && dateTwo && getDaysBetweenDates(dateOne, dateTwo);
    if (duration === durationEnum.month) {
      totalAmount = amount * Math.ceil(totalDays / MONTH_DAYS);
    }
    if (duration === durationEnum.week) {
      totalAmount = amount * Math.ceil(totalDays / WEEK_DAYS);
    }
    if (duration === durationEnum.day) {
      totalAmount = amount * totalDays;
    }
    if (discount?.discountType === "%" && totalAmount) {
      disc = (totalAmount * discount.value) / 100;
      discountedAmount = (totalAmount * (100 - discount.value)) / 100;
    }
    if (discount?.discountType === DISCOUNT_TYPE && totalAmount) {
      disc = discount.value;
      discountedAmount = totalAmount - discount.value;
    }
    setPrice({
      price: {
        currency: service?.rate?.currency,
        value: totalAmount || 0,
      },
      discount: {
        currency: service?.rate?.currency,
        value: disc || 0,
      },
      total: {
        currency: service?.rate?.currency,
        value: discountedAmount || 0,
      },
      rate: {
        type: discount?.discountType,
        value: discount?.value,
      },
      discountedAmount,
      days: totalDays,
    });
  }, [dateOne, dateTwo, service]);
  return price;
};

export const useRentalBooking = (id: string) => {
  const url = `${ENDPOINTS.GET_RENTAL_BOOKING}/${id}`;
  const { data, error, isValidating } = useSWR(url);
  return { data, error, isValidating };
};
