import useSWR from "swr";
import { stringify } from "query-string";
import { SearchSuggestResult } from "modules/entities";
import { ENDPOINTS } from "../endpoints";

export const useSearchSuggest = (textValue: string) => {
  const resultText = stringify({ text: textValue });
  const { data, error, isValidating } = useSWR<SearchSuggestResult>(
    textValue ? `${ENDPOINTS.SEARCH_SUGGESTS}?${resultText}` : null
  );
  return { data, error, isValidating };
};
