import React from "react";
import { differenceInSeconds, isValid } from "date-fns";
import { normalizePhone } from "modules/ui";
import { getVerificationData } from "./usePhoneAuthentication";
import { OTP_VERIFICATION_SECS_TO_WAIT } from "../constants";

const getDateState = (phone: string) => {
  const data = getVerificationData(normalizePhone(phone));
  return data ? data.timeSent : null;
};

const getSecondsLeft = (date: Date | null) => {
  if (!date || !isValid(date)) {
    return 0;
  }
  const seconds = differenceInSeconds(new Date(), date);

  return Math.max(OTP_VERIFICATION_SECS_TO_WAIT - seconds, 0);
};

export const useOtpResend = (phone: string) => {
  const [date] = React.useState(() => getDateState(phone));
  const [secondsLeft, setSecondsLeft] = React.useState(() =>
    getSecondsLeft(date)
  );

  React.useEffect(() => {
    const id = setInterval(() => {
      const date = getDateState(phone);
      setSecondsLeft(getSecondsLeft(date));
    }, 1000);
    return () => clearInterval(id);
  }, [phone]);

  return {
    secondsLeft,
    canResend: secondsLeft === 0,
  };
};
