import React from "react";
import { useFormContext } from "react-hook-form";
import { useTheme } from "styled-components";
import { makeStyles } from "@material-ui/core/styles";
import { TextField } from "modules/ui";

interface Props {
  name: string;
  label: string;
  value?: string;
  requiredErrorMessage?: string;
  validationPattern?: RegExp;
  validationRules?: {
    root: string;
    index: number;
    key: string;
  };
  defaultValue?: string | null;
}

interface Validation {
  required?: string | boolean;
  pattern?: { value: RegExp; message: string };
}

const useClasses = makeStyles((theme) => ({
  root: {
    width: "100%",
    "& label.Mui-focused": {
      color: theme.custom.primary.main,
    },
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: theme.custom.primary.main,
      },
    },
  },
}));

export const FormInput = (props: Props) => {
  const {
    name,
    label,
    requiredErrorMessage,
    validationPattern,
    validationRules,
    value,
    defaultValue,
  } = props;
  const { register, errors } = useFormContext();
  const theme: any = useTheme();
  const validation: Validation = {
    required: requiredErrorMessage,
  };
  if (validationPattern) {
    validation.pattern = {
      message: "Please, enter valid value",
      value: validationPattern,
    };
  }
  const classes = useClasses();

  const getError = () => {
    if (!validationRules || !errors[validationRules.root]) {
      return !!errors[name];
    } else {
      const { root, index, key } = validationRules;
      return !!errors[root][index] && !!errors[root][index][key];
    }
  };

  return (
    <TextField
      label={label}
      error={getError()}
      inputRef={register(validation)}
      name={name}
      value={value}
      variant="outlined"
      color={theme.custom.primary.main}
      classes={classes}
      helperText={getError() && requiredErrorMessage}
      defaultValue={defaultValue}
    />
  );
};

export default {
  title: "Booking",
};
