import React from "react";
import { useRouteMatch, Redirect } from "react-router-dom";
import { routes } from "modules/routing";

const isLanguageValid = (language: string) => {
  return ["en", "id"].includes(language);
};

const getDefaultLanguage = (): string => {
  return "en";
};

export const LanguageRedirect = () => {
  const match = useRouteMatch<{ language?: string }>(
    routes.languagePrefix.pattern
  );
  const language = match?.params?.language;
  const valid = language && isLanguageValid(language);

  React.useEffect(() => {
    if (valid && language) {
      document.documentElement.lang = language;
    }
  }, [valid, language]);

  if (!valid) {
    const nextLanguage = getDefaultLanguage();
    const nextLink = `/${nextLanguage}`;
    return <Redirect to={nextLink} />;
  }
  return null;
};
