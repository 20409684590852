import * as React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import CircularProgress from "@material-ui/core/CircularProgress";
import { SearchResult } from "modules/entities";
import { TripCard } from "../TripCard/TripCard";
import { getOperatorCode } from "../../../utils/getOperatorCode";
import GoogleAnalyticsService from "../../../utils/GoogleAnalyticsService";
import { LOG_EVENTS } from "../../../utils/LogEvents";


type SearchResultListProps = {
  results: Array<SearchResult>;
  selectedResult: SearchResult | null | undefined;
  isLoading: boolean;
  toggle: (next: SearchResult) => void;
  selectedTab: string;
};

// const isSame = (a: SearchResult, b: SearchResult) => {
//   const sameStops =
//     a.dropOutStopId === b.dropOutStopId && a.pickUpStopId === b.pickUpStopId;
//   const sameRides = a.id === b.id;
//   return sameStops && sameRides;
// };

const LoadingContainer = styled.div`
  background-color: #ffffff;
  opacity: 0.8;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ResultNotFound = styled.div`
  display: flex;
  height: 60px;
  align-items: center;
  justify-content: center;
`;

export const SearchResultList: React.FC<SearchResultListProps> = ({
  selectedResult,
  isLoading,
  toggle,
  results,
  selectedTab,
}) => {
  const { t } = useTranslation('searchResult');
  if (isLoading) {
    return (
      <LoadingContainer>
        <CircularProgress color="secondary" />
      </LoadingContainer>
    );
  }
  if (results && results.length === 0) {
    GoogleAnalyticsService.createEvent(
      LOG_EVENTS.LOG_EVENTS_NAME.SEARCH_RESULT_EMPTY,
      {
        search_result_empty: LOG_EVENTS.LOG_METHOD_VALUE.EMPTY_SEARCH_RESULT,
        operator_code: getOperatorCode(),
        source: "web",
      }
    );
  }
  return (
    <>
      {results && results.length === 0 ? (
        <ResultNotFound>
          <p>{t("tripNotAvailable")}</p>
        </ResultNotFound>
      ) : (
        results && results.map((searchResult, index) => {
          const {
            ride: { id },
            dropOutStopId,
            pickUpStopId,
            timezone,
            emission,
          } = searchResult;
          const key = `${id}.${pickUpStopId}.${dropOutStopId}-${index}`;
          const selected = selectedResult;
          if (!timezone) {
            console.error(`No timezone for this ride: ${id}`);
          }
          return (
            <TripCard
              key={key}
              selected={selected}
              onSelect={() => toggle(searchResult)}
              ride={searchResult.ride}
              pickUpStopId={searchResult.pickUpStopId}
              dropOutStopId={searchResult.dropOutStopId}
              timezone={timezone}
              selectedTab={selectedTab}
              emission={searchResult.emission}
            />
          );
        })
      )}
    </>
  );
};
