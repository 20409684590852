import React from "react";
import { useParams } from "react-router";
import { useTranslation } from "react-i18next";
import { format } from "date-fns";
import styled from "styled-components";
import {
  getDaysBetweenDates,
  useRentalBooking,
} from "modules/api/hooks/rental";
import { getRentalService } from "modules/api/methods"; 
import { useLanguage } from "modules/localization";
import { getOperatorCode } from "modules/utils/getOperatorCode";
// import VehicleImage from "../images/vehicle.png";
import { PriceBlock } from "./RentalForm";
import { updateOperatorCode } from "modules/api/client";

// const ButtonBook = styled.button`
//   background: #5a5b6a;
//   box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.15);
//   border-radius: 3px;
//   color: #fff;
//   border: none;
//   width: 20%;
//   margin: 20px auto;
//   height: 40px;
//   font-weight: bold;
//   font-size: 12px;
//   line-height: 14px;
//   display: block;
//   @media (max-width: ${(props) =>
//       props.theme.custom.breakpoints["xs-breakpoint"]}) {
//     width: 92%;
//   }
// `;

const Wrapper = styled.div`
  background: #f6f6f6;
  padding-top: 50px;
  .wrapper {
    background: #fff;
    padding: 20px 14px;
    width: 35%;
    margin: auto;
    @media (max-width: ${(props) =>
        props.theme.custom.breakpoints["md-breakpoint"]}) {
      width: 90%;
    }
  }
  h6 {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 18px;
    color: #4f4f4f;
    margin: 0;
  }
  p {
    font-size: 14px;
    margin: 5px 0 20px;
    line-height: 18px;
    color: #4f4f4f;
  }
  .seprator {
    width: 100%;
    border-top: 0.5px solid #d8d8d8;
    margin: 10px 0;
    height: 5px;
  }
  .vehicle-section {
    display: flex;
    justify-content: space-between;
  }
  .vehicle-section {
    .vehicle-image {
      width: 45%;
      @media (max-width: ${(props) =>
          props.theme.custom.breakpoints["md-breakpoint"]}) {
        width: 50%;
      }
      img {
        width: 100%;
      }
    }
    .about-vechile {
      display: flex;
      flex-wrap: nowrap;
      flex-direction: column;
      justify-content: space-between;
      .content {
        margin-bottom: 10px;
        p {
          font-style: normal;
          font-weight: normal;
          font-size: 12px;
          line-height: 17px;
          color: #4f4f4f;
          margin: 0;
        }
        h6 {
          font-style: normal;
          font-weight: bold;
          font-size: 12px;
          color: #4f4f4f;
          line-height: 17px;
          margin: 0;
        }
      }
    }
  }
  .pricing h6 {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    color: #4f4f4f;
  }
  .pricing div {
    padding: 0;
    margin-top: 0;
  }
  .price-breakout {
    display: flex;
    justify-content: space-between;
    margin: 10px 0;
    p {
      font-weight: normal;
      font-size: 12px;
      line-height: 18px;
      color: #5a5b6a;
      margin: 0;
    }
    h6 {
      font-weight: 600;
      font-size: 13px;
      line-height: 18px;
      color: #5a5b6a;
      margin: 0;
    }
    &.total {
      p {
        font-weight: 600;
        font-size: 14px;
        line-height: 18px;
        color: #19191d;
        margin: 0;
      }
      h6 {
        font-weight: 600;
        font-size: 14px;
        line-height: 18px;
        color: #19191d;
        margin: 0;
      }
    }
  }
  .end-section p {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    color: #4f4f4f;
  }
  .end-section small {
    font-weight: normal;
    font-size: 12px;
    line-height: 18px;
    color: #4f4f4f;
  }
`;
interface ParamTypes {
  id: string;
}

export const durationEnum = {
  week: "week",
  month: "month",
  day: "day",
};

const MONTH_DAYS = 30;
const WEEK_DAYS = 7;
export const DISCOUNT_TYPE = "Fixed";

export const usePriceData = (dateOne: Date, dateTwo: Date, service: any) => {
  let totalAmount;
  let discountedAmount;
  let disc;
  const duration = service?.rate?.duration;
  const amount = service?.rate?.amount;
  const discount = service?.discount;
  if (!dateOne || !dateTwo) {
    return;
  }
  const totalDays = dateOne && dateTwo && getDaysBetweenDates(dateOne, dateTwo);
  if (duration === durationEnum.month) {
    totalAmount = amount * Math.ceil(totalDays / MONTH_DAYS);
  }
  if (duration === durationEnum.week) {
    totalAmount = amount * Math.ceil(totalDays / WEEK_DAYS);
  }
  if (duration === durationEnum.day) {
    totalAmount = amount * totalDays;
  }
  if (discount?.discountType === "%" && totalAmount) {
    disc = (totalAmount * discount.value) / 100;
    discountedAmount = (totalAmount * (100 - discount.value)) / 100;
  }
  if (discount?.discountType === DISCOUNT_TYPE && totalAmount) {
    disc = discount.value;
    discountedAmount = totalAmount - discount.value;
  }
  const price = {
    price: {
      currency: service?.rate?.currency,
      value: totalAmount || 0,
    },
    discount: {
      currency: service?.rate?.currency,
      value: disc || 0,
    },
    total: {
      currency: service?.rate?.currency,
      value: discountedAmount || 0,
    },
    rate: {
      type: discount?.discountType,
      value: discount?.value,
    },
    discountedAmount,
    days: totalDays,
  };
  return price;
};

export const ThankYouPage = () => {
  let operator = localStorage.getItem("operatorCode");
  if (operator) {
    updateOperatorCode(operator);
  }
  const { id } = useParams<ParamTypes>();
  const { t } = useTranslation("rental");
  const language = useLanguage();
  const { data: booking }: any = useRentalBooking(id);
  const [service, setService] = React.useState<any>(null);
  const price = service && {
    currency: service?.rate?.currency,
    value: service?.rate?.amount,
  };
  const baseUrl = `${window.location.origin}/${
    window.location.pathname.split("/")[1]
  }/${language}`;

  React.useEffect(() => {
    const fetchService = async () => {
      if (booking) {
        const response = await getRentalService(booking?.rentalServiceId);
        setService(response ? response[0] : {});
      }
    };
    fetchService();
  }, [booking]);

  const priceObj: any = usePriceData(
    new Date(booking?.departureDate),
    new Date(booking?.returnDate),
    service,
  );
  const days =
    booking &&
    getDaysBetweenDates(
      new Date(booking.departureDate),
      new Date(booking.returnDate),
    );
  const priceData = {
    price: priceObj?.price,
    discount: {
      currency: booking?.discount.currency,
      value: booking?.discount.amount,
    },
    total: {
      currency: booking?.price.currency,
      value: booking?.price.value,
    },
    days: days,
    rate: {
      value: service?.discount?.value,
      type: service?.discount?.discountType,
    },
  };

  return (
    <Wrapper>
      {booking && (
        <>
          <div className="wrapper">
            <h6>
              {t("dear")} {booking?.name},
            </h6>
            <p>
              {t("thanks")} {getOperatorCode()}
              <br />
              <br />
              {t("referenceText")} <b>{booking?.bookingCode}</b> on{" "}
              {format(new Date(booking?.createdAt), "E, dd MMMM yyyy")}{" "}
              {t("details")}
            </p>
            <div className="seprator"></div>
            {service && (
              <div className="vehicle-section">
                <div className="about-vechile">
                  <div className="content">
                    <p>{t("serviceName")}</p>
                    <h6>{service?.name}</h6>
                  </div>
                  <div className="content">
                    <p>{t("duration")}</p>
                    <h6>
                      {format(new Date(booking?.departureDate), "d MMM") +
                        "-" +
                        format(new Date(booking?.returnDate), "d MMM")}
                      ({priceData.days} days)
                    </h6>
                  </div>
                  <div className="content">
                    <p>{t("fare")}</p>
                    <h6>
                      {t("price", { price })} per{" "}
                      {service?.rate?.duration.toLowerCase()}
                    </h6>
                  </div>
                </div>
                <div className="vehicle-image">
                  <img src={service?.imageUrl} alt="" />
                </div>
              </div>
            )}
            <div className="seprator"></div>
            <div className="pricing">
              <PriceBlock
                price={priceData?.price}
                discount={priceData?.discount}
                total={priceData?.total}
                days={priceData?.days}
                rate={priceData?.rate}
              />
              <div className="end-section">
                <p>{t("appreciate")}</p>
                <small>
                  {t("regards")} <br />
                  {getOperatorCode()} <br />
                  {baseUrl} <br />
                </small>
              </div> 
            </div>
          </div>
          {/* <ButtonBook>Download PDF</ButtonBook> */}
        </>
      )}
    </Wrapper>
  );
};
