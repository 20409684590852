import * as React from "react";
import isObject from "lodash/isObject";
import { useLocation, useParams } from "react-router-dom";
import { LinearProgress } from "@material-ui/core";
import { useTicket } from "modules/api";
import { Redirect, routes } from "modules/routing";
import { CheckoutSuccessTemplate } from "../templates/CheckoutSuccessTemplate";
import {IsDefaultOperator} from "modules/utils/IsDefaultOperator"
import {getOperatorCode} from "modules/utils/getOperatorCode"
import { useHistory } from "react-router-dom";

export const CheckoutSuccess = () => {
  const { state } = useLocation();
  const { ticketId } = useParams();
  const history = useHistory(); 
  
  const { data: ticket } = useTicket(ticketId);  
  if (IsDefaultOperator()){
    let url = `/${getOperatorCode()}/checkout/thankyou/${ticketId}`;
    
  }
  else if ((!isObject(state) || typeof (state as any).ticketId !== "string") && !IsDefaultOperator()) {
    return <Redirect to={routes.home.url()} />;
  }
  if (!ticket) {
    return <LinearProgress />;
  }
  return (
    <CheckoutSuccessTemplate
      bookingNumber={ticket.bookCode || ""}
      ticketId={ticket.id}
      isSuccess
      userMail={ticket.email || "-"}
      externalOperatorId={ticket.externalOperatorId || ""}
    />
  );
};
