import React from "react";
// import clsx from "clsx";
// import classes from "./HeaderMenuItem.module.css";
import styled from "styled-components";

interface Props {
  value: string;
  parentClass?: string;
  onClick?: () => void;
  className?: string;
}

const HeaderMenu = styled.div`
  font-family: ${(props) => props.theme.custom["roboto-font"]};
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: ${(props) => props.theme.custom["white-color"]};
  padding: 5px;
  &:hover {
    cursor: pointer;
  }
  &.marginLeft {
    margin-left: 50px;
  }
`;

export function HeaderMenuItem(props: Props) {
  const { value, onClick, className } = props;
  return (
    <HeaderMenu className={className} onClick={onClick}>
      {value}
    </HeaderMenu>
  );
}
