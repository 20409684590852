import { axios } from "../client";
import { ENDPOINTS } from "../endpoints";

export const getCampaigns = (rideId: string | null) => {
  return axios.get(`${ENDPOINTS.CAMPAIGNS}/${rideId}`);
};

export const validateCoupon = (
  coupon: string,
  rideId: any,
  noPassenger: any,
) => {
  return axios.get(
    `${ENDPOINTS.VALIDATE_COUPON}/${coupon}/${rideId}?countOfPassengers=${noPassenger}`,
  );
};
