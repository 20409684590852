import * as React from "react";
import firebase from "firebase/app";
import "firebase/auth";
import { differenceInSeconds, isValid } from "date-fns";
import { useLanguage } from "modules/localization";
import { normalizePhone } from "modules/ui";
import { safeParse } from "modules/utils/safePars";
import { auth } from "../firebaseAuth";
import { OTP_VERIFICATION_SECS_TO_WAIT } from "../constants";

const CACHE_KEY = "phone-verifications";

const getVerifications = () => {
  const str = localStorage.getItem(CACHE_KEY) || "{}";
  const value = safeParse(str);
  if (typeof value === "object" && value) {
    return value;
  }
  return {};
};

const saveVerification = (
  phone: string,
  verificationId: string,
  timeSent: Date
) => {
  const values = {
    ...getVerifications(),
    [phone]: {
      verificationId,
      timeSent,
    },
  };
  localStorage.setItem(CACHE_KEY, JSON.stringify(values));
};

type OtpData = {
  verificationId: string;
  timeSent: Date;
};
export const getVerificationData = (phone: string): null | OtpData => {
  const result = getVerifications()[phone];
  if (result && typeof result.verificationId === "string" && result.timeSent) {
    const dateObj = new Date(result.timeSent);
    const isExpired =
      differenceInSeconds(new Date(), dateObj) > OTP_VERIFICATION_SECS_TO_WAIT;
    if (!isExpired && isValid(dateObj)) {
      return {
        verificationId: result.verificationId,
        timeSent: dateObj,
      };
    }
  }
  return null;
};

export const usePhoneAuthentication = (verifyButtonId: string) => {
  const recaptchaVerifierRef = React.useRef<firebase.auth.RecaptchaVerifier>();
  const language = useLanguage();

  React.useEffect(() => {
    auth.languageCode = language;
  }, [language]);

  React.useEffect(() => {
    const recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
      verifyButtonId,
      {
        size: "invisible",
      }
    );
    recaptchaVerifierRef.current = recaptchaVerifier;
  }, [verifyButtonId]);

  const logIn = React.useCallback((phoneNumber: string): Promise<string> => {
    const normalizedPhone = normalizePhone(phoneNumber);
    const res = getVerificationData(normalizedPhone);
    if (res) {
      return Promise.resolve(res.verificationId);
    }
    const appVerifier = recaptchaVerifierRef.current;
    if (!recaptchaVerifierRef.current) {
      return Promise.reject("recaptcha not initialized");
    }
    return recaptchaVerifierRef.current?.render().then((widgetId) => {
      return firebase
        .auth()
        .signInWithPhoneNumber(
          phoneNumber,
          appVerifier as firebase.auth.ApplicationVerifier
        )
        .then(({ verificationId }) => {
          const now = new Date();
          saveVerification(normalizedPhone, verificationId, now);
          // @ts-ignore
          window.grecaptcha.reset(widgetId);
          return verificationId;
        })
        .catch((err) => {
          // @ts-ignore
          window.grecaptcha.reset(widgetId);
          throw err;
        });
    });
  }, []);

  return logIn;
};
