import { shouldRenderFeature } from "@bussr-io/feature-toggling";

export const featureToggle = async (
  featureName: any,
  firebaseApp: any,
  criteria: any,
) => {
  const toggleValue = await shouldRenderFeature(
    firebaseApp,
    featureName,
    criteria,
  );
  return toggleValue;
};
