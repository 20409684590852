import * as React from "react";
import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
// import clsx from "clsx";
import { isValidNumber } from "libphonenumber-js";
import styled from "styled-components";
import { CircularProgress, Grid, Typography } from "@material-ui/core";
import {
  arePhonesEqual,
  usePhoneAuthentication,
  usePhoneAuthErrorMessage,
  useUserData,
} from "modules/auth";
import { Button, TEST_ID, PhoneInput } from "modules/ui";
import { BookingFormFieldsName } from "modules/ui/variables";
import { PhoneVerified } from "../../molecules/PhoneVerified";
import { OtpModal } from "../OtpModal/OtpModal";
import { usePhoneVerify } from "./PhoheVerifyContext";
// import classes from "../Block.module.css";

const validationRules = {
  required: "required",
  validate: (phone: string) => {
    if (!isValidNumber(phone)) {
      return "incorrectPhone";
    }
  },
};

const CustomButton = styled(({ ...rest }) => <Button {...rest} />)`
  background-color: ${(props) => props.theme.custom.secondary.main};
  &:hover {
    background-color: ${(props) => props.theme.custom.primary.main};
  }
`;

const InfoDescriptionText = styled.p`
  font-family: ${(props) => props.theme.custom["roboto-font"]};
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 18px;
  color: ${(props) => props.theme.custom["gray-color"]};
  // margin: 6px 0 0 0;
  margin-top: 16px;
  margin-bottom: 16px;
`;

const FieldWrapper = styled.div`
  display: grid;
  // gap: 17px;
  &.fieldwrapperError {
    // gap: 22px;
  }
`;

const authButtonId = "phone-verification-button";

const useOnSubmit = (authorizedUserPhone: string | null) => {
  const { triggerValidation, setError, getValues, clearError } =
    useFormContext();

  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [verificationId, setVerificationId] = React.useState<null | string>(
    null
  );
  const [otpModalOpen, setOtpModalOpen] = React.useState(false);
  const [phoneToShowInModal, setPhone] = React.useState("");
  const logIn = usePhoneAuthentication(authButtonId);

  const onSubmit = async () => {
    const { phone } = getValues();
    if (authorizedUserPhone && arePhonesEqual(phone, authorizedUserPhone)) {
      return;
    }
    const valid = await triggerValidation("phone");
    if (!valid) {
      return;
    }
    try {
      setIsSubmitting(true);
      clearError("commonPhone");
      const res = await logIn(phone);
      setPhone(phone);
      setVerificationId(res);
      setOtpModalOpen(true);
    } catch (e) {
      const errorType = e?.code || "generic";
      setError("commonPhone", "submit", errorType);
    } finally {
      setIsSubmitting(false);
    }
  };
  const value = usePhoneVerify();

  React.useEffect(() => {
    if (value) {
      value.current = onSubmit;
    }
  });

  return {
    onSubmit,
    isSubmitting,
    verificationId,
    phoneToShowInModal,
    otpModalOpen,
    setOtpModalOpen,
  };
};
export type Props = {
  label?: string;
};

export const PhoneField: React.FC<Props> = ({ label }) => {
  const { value: userValue } = useUserData();
  const { errors, control, watch, setValue } = useFormContext();
  const { t } = useTranslation("reservation");

  const authorizedUserPhone = userValue?.phoneNumber ?? "";
  const currentPhone = watch(BookingFormFieldsName.phone, authorizedUserPhone);

  const errorMessage = errors.phone?.message;
  const errorLabel = errorMessage ? t(errorMessage) : undefined;

  React.useEffect(() => {
    if (authorizedUserPhone) {
      setValue(BookingFormFieldsName.phone, authorizedUserPhone);
    }
  }, [authorizedUserPhone, setValue]);

  const isPhoneVerified =
    arePhonesEqual(currentPhone, authorizedUserPhone) &&
    isValidNumber(currentPhone);

  const submitError = usePhoneAuthErrorMessage(errors.commonPhone?.message);

  const {
    onSubmit,
    isSubmitting,
    verificationId,
    phoneToShowInModal,
    otpModalOpen,
    setOtpModalOpen,
  } = useOnSubmit(authorizedUserPhone);

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === "Enter") {
      event.preventDefault();
      if (!isSubmitting) {
        onSubmit();
      }
    }
  };

  return (
    <FieldWrapper
      className={
        (errors[BookingFormFieldsName.email] ||
          errors[BookingFormFieldsName.phone]) &&
        "fieldwrapperError"
      }
    >
      <Controller
        name={BookingFormFieldsName.phone}
        rules={validationRules}
        control={control}
        error={Boolean(errorMessage)}
        helperText={errorLabel}
        variant="outlined"
        label={label === "" ? label : t("phoneLabel")}
        onKeyDown={handleKeyDown}
        color="primary"
        as={PhoneInput}
        data-testid={TEST_ID.VERIFY_PHONE}
      />
      {!isPhoneVerified && (
        <InfoDescriptionText>{t("phoneDescription")}</InfoDescriptionText>
      )}
      {submitError && (
        <Typography color="error" variant="caption">
          {submitError}
        </Typography>
      )}
      <Grid container justify="flex-end">
        {isPhoneVerified && <PhoneVerified />}
        <CustomButton
          id={authButtonId}
          data-testid={TEST_ID.VERIFY_PHONE}
          type="button"
          disableElevation={false}
          onClick={onSubmit}
          // should be in the document for google captcha
          style={{ display: isPhoneVerified ? "none" : undefined }}
          startIcon={
            isSubmitting && <CircularProgress size={16} color="inherit" />
          }
          disabled={isSubmitting}
        >
          {t("verify")}
        </CustomButton>
        {verificationId && (
          <OtpModal
            onClose={() => setOtpModalOpen(false)}
            open={otpModalOpen}
            phone={phoneToShowInModal}
            verificationId={verificationId}
          />
        )}
      </Grid>
    </FieldWrapper>
  );
};
