import React from "react";
import { useTranslation } from "react-i18next";
import { Title } from "modules/legal/atoms/Title";
import { TextContainer } from "modules/legal/atoms/TextContainer";
import { Subtitle } from "modules/legal/atoms/Subtitle";
import { SecondaryTitle } from "modules/legal/atoms/SecondaryTitle";
import { Row } from "modules/legal/atoms/Row";
import { PrivacyGroups } from "../types";

export const PrivacyPolicy = () => {
  const { t } = useTranslation("privacy");
  const groups = t("groups", { returnObjects: true }) as Array<PrivacyGroups>;

  return (
    <TextContainer>
      <Title>{t("title")}</Title>
      {groups.map((group) => {
        return (
          <div id={group.id} key={group.id}>
            <Subtitle>{group.subtitle}</Subtitle>
            {group.items.map((item, index) => {
              return (
                <div key={index}>
                  {item.subtitle && (
                    <SecondaryTitle>{item.subtitle}</SecondaryTitle>
                  )}
                  {item.items.map((text, index2) => {
                    return <Row key={index2}>{text}</Row>;
                  })}
                </div>
              );
            })}
          </div>
        );
      })}
    </TextContainer>
  );
};
