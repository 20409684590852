import React from "react";
import { Redirect as BaseRedirect, RedirectProps } from "react-router-dom";
import { useLanguage } from "modules/localization";
import { trimSlash } from "../utils/trimSlash";

export const Redirect: React.FC<RedirectProps> = (props) => {
  const language = useLanguage();

  let to: RedirectProps["to"] = "";

  if (typeof props.to === "string") {
    to = `/${language}/${trimSlash(props.to)}`;
  } else {
    to = {
      ...props.to,
      pathname:
        props.to.pathname !== undefined
          ? `/${language}/${trimSlash(props.to.pathname)}`
          : undefined,
    };
  }
  return <BaseRedirect {...props} to={to} />;
};
