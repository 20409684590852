import TooltipDefault from "@material-ui/core/Tooltip";
import { withStyles } from "@material-ui/core/styles";

export const Tooltip = withStyles({
  tooltip: {
    backgroundColor: "#C4C4C4",
    fontSize: "11px",
    fontFamily: "Roboto",
  },
  arrow: {
    color: "#C4C4C4",
  },
})(TooltipDefault);
