import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { Divider, Typography } from "@material-ui/core";
import { getBusStop } from "modules/entities/helpers";
import { Ride, Timezone } from "modules/entities";
import { getDuration } from "modules/utils/getDuration";
import { getRefundCategoryText } from "../../../utils/rideUtils";
import { TripInfo } from "../../molecules/TripInfo/TripInfo";
import { TripReserve } from "../../atoms/TripReserve/TripReserve";
import { IsDefaultOperator } from 'modules/utils/IsDefaultOperator'
import { CarbonEmissionCounter } from '../../molecules/CarbonEmissionCounter/CarbonEmissionCounter';
// import { TripAdditional } from "../../atoms/TripAdditional/TripAdditional";
// import classes from "./TripCard.module.css";

const CardWrapper = styled.div`
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  margin-top: 24px;

  @media (max-width: ${(props) =>
    props.theme.custom.breakpoints["xs-breakpoint"]}) {
    flex-wrap: wrap;
    justify-content: center;
  }
`;

const TripDetailsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const TripInfoWrapper = styled.div`
  margin-bottom: 13px;
  display: flex;
  height: 100%;
`;

const TripReserveDesktop = styled.div`
  @media (max-width: ${(props) =>
    props.theme.custom.breakpoints["xs-breakpoint"]}) {
    display: none;
  }
`;
const TripReserveMobile = styled.div`
  display: none;
  @media (max-width: ${(props) =>
    props.theme.custom.breakpoints["xs-breakpoint"]}) {
    display: flex;
    height: 100%;
    align-items: center;
  }
`;

const OperatorHeading = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  h6 {
    display: inline-block;
  }
`;

const RefunableText = styled(Typography)`
  font-size: 10px;
  line-height: 16px;
  color: ${(props) => props.theme.custom["gray-text-color"]};
  border: 1px solid ${(props) => props.theme.custom["gray-text-color"]};
  border-radius: 4px;
  text-transform: uppercase;
  padding: 3px 7px;
  font-weight: bold;
`;

const OperatorWrapper = styled.p`
padding-bottom: 8px;
font-size: 10px;
.op-name{
  font-weight: 600 !important;
  padding-left:4px;
  font-size: 12px;
  color: #4A4B57;
}
`;

type TripCardProps = {
  ride: Ride;
  pickUpStopId: string;
  dropOutStopId: string;
  selected: any;
  onSelect: () => void;
  timezone?: Timezone;
  selectedTab: string;
  emission: any;
};

export const TripCard: React.FC<TripCardProps> = ({
  selected,
  onSelect,
  ride,
  pickUpStopId,
  dropOutStopId,
  timezone,
  selectedTab,
  emission,
}) => {
  const { t } = useTranslation("searchResult");
  // prettier-ignore
  const pickUpStop = React.useMemo(() => getBusStop(ride, pickUpStopId), [pickUpStopId, ride])
  // prettier-ignore
  const dropOutStop = React.useMemo(() => getBusStop(ride, dropOutStopId), [dropOutStopId, ride])

  const duration = React.useMemo(() => {
    const from = new Date(pickUpStop?.timeOfArrival ?? "");
    const to = new Date(dropOutStop?.timeOfArrival ?? "");
    return getDuration(from, to);
  }, [dropOutStop, pickUpStop]);

  if (!pickUpStop || !dropOutStop) {
    return null;
  }

  return (
    <>
      <CardWrapper>
        <TripDetailsWrapper>
          <OperatorHeading>
            <Typography variant="subtitle2" gutterBottom color="textPrimary">
              {ride.externalOperator?.name || ""}
            </Typography>
            <RefunableText variant="subtitle2" gutterBottom color="textPrimary">
              {getRefundCategoryText(ride.refundRule, t)}
            </RefunableText>
          </OperatorHeading>
          {IsDefaultOperator() ?
            <OperatorWrapper>{t('operatedBy')}
              <span className="op-name">{ride.externalOperator?.id || ""}</span>
            </OperatorWrapper>
            : ''}

          <TripInfoWrapper>
            <TripInfo
              pickUpStop={pickUpStop}
              dropOutStop={dropOutStop}
              timezone={timezone}
              stops={ride?.stops}
              duration={duration}
              showDuration={true}
              wasManuallyEdited={ride?.wasManuallyEdited}
              disableConversion={ride?.disableConversion}
              ridePatternId={ride?.ridePatternId}
            >
              <TripReserveMobile>
                <TripReserve
                  price={ride.price}
                  selected={ride?.id === selected?.ride?.id}
                  onSelect={onSelect}
                  seatsLeft={ride.bus.availableSeats}
                  selectedTab={selectedTab}
                  discountDetails={
                    ride?.bestCampaignOffer &&
                    ride?.bestCampaignOffer?.discountDetails
                  }
                />
              </TripReserveMobile>
            </TripInfo>
          </TripInfoWrapper>
          {/* <TripAdditional duration={duration} facilities={undefined} /> */}
          <CarbonEmissionCounter emission={emission} />
        </TripDetailsWrapper>
        <TripReserveDesktop>
          <TripReserve
            price={ride.price}
            selected={ride?.id === selected?.ride?.id}
            onSelect={onSelect}
            seatsLeft={ride.bus.availableSeats}
            selectedTab={selectedTab}
            discountDetails={
              ride?.bestCampaignOffer &&
              ride?.bestCampaignOffer?.discountDetails
            }
          />
        </TripReserveDesktop>
      </CardWrapper>
      <Divider />
    </>
  );
};
