import { Price } from "./price";
import { PaymentMethodCodes, PaymentTypes } from "./paymentMethod";
import { Ride } from "./searchResult";

export enum TicketStatus {
  pre_draft = "pre_draft",
  draft = "draft",
  reserved = "reserved",
  paid = "paid",
  removed = "removed",
  payment_cancelled = "payment_cancelled",
  on_the_way = "on_the_way",
  used = "used",
  cancelled = "cancelled",
  refund_initiated = "refund_initiated",
  refund_pending_for_approval = "refund_pending_for_approval",
  refund_credited = "refund_credited",
  refunded_and_ticket_cancelled = "refunded_and_ticket_cancelled",
  refund_reject_and_ticket_cancelled = "refund_reject_and_ticket_cancelled",
  refund_failed_and_ticket_cancelled = "refund_failed_and_ticket_cancelled",
}

export const TicketCancelStatus = [
  "payment_cancelled",
  "cancelled",
  "refund_initiated",
  "refund_pending_for_approval",
  "refund_credited",
  "refunded_and_ticket_cancelled",
  "refund_reject_and_ticket_cancelled",
  "refund_failed_and_ticket_cancelled",
];

export interface Ticket {
  discount: TicketDiscount;
  basePrice: Price;
  flags: {
    watched: boolean;
  };
  status: TicketStatus;
  passenger: TicketPassenger;
  paymentMethod: PaymentTypes;
  // bank name
  paymentMethodCode: PaymentMethodCodes;
  paymentResponse: string;
  usedVoucherCode: null | string;
  statusHistory: TicketStatusHistory[];
  payLimit: null | string;
  bookCode: null | string;
  numCode: null | number;
  seatCode: null | string;
  vendor: null | string;
  // email which was used to send email when the ticket was booked
  email?: null | string;
  externalOperatorId: string | null;
  id: string;
  rideId: string;
  /*
   * ride comes always from backend but the structure is not correct.
   */
  ride?: Ride;
  pickUpStopId: string;
  dropOutStopId: string;
  userId: string;
  price: Price;
  accessCode: string;
  createdAt: string;
  updatedAt: string;
  qrCode: string;
  facilities: Array<Facility>;
  timezone: { from: string; to: string };
  offset: { from: string; to: string };
}

export interface TicketDiscount {
  type: string;
  percent_off: number;
  amount_off: number;
  value: number;
  currency: string;
}

export interface TicketPassenger {
  firstName: string;
  lastName: string;
}

export interface TicketStatusHistory {
  status: TicketStatus;
  timestamp: string;
}

export interface Facility {
  type: string;
  label: string;
  image: string;
}

export interface TicketGroup {
  price: Price;
  basePrice: Price;
  flags: {
    watched: boolean;
  };
  ticketsId: string[];
  paymentMethod: string;
  usedVoucherCode: null | string;
  status: TicketStatus;
  vendor: null | string;
  externalOperatorId: null | string;
  id: string;
  userId: string;
  dropOutStopId: string;
  pickUpStopId: string;
  rideId: string;
  createdAt: string;
  updatedAt: string;
}
