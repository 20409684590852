import React from "react";
import { useTranslation } from "react-i18next";
import styled, { useTheme } from "styled-components";
import firebase from "firebase";
import { useSelector } from "react-redux";
import { FormContext, useForm } from "react-hook-form";
import { isValidNumber } from "libphonenumber-js";
import { Button, CircularProgress, Grid } from "@material-ui/core";
import { updateMe, useFetchMe } from "modules/api/hooks/profile";
import { arePhonesEqual, useUserData } from "modules/auth";
import { usePhoneVerify } from "modules/booking/organisms/PhoneField/PhoheVerifyContext";
import { Notifications } from "modules/notifications";
import { PhoneFieldV2 } from "modules/booking/organisms/PhoneField/PhoneFieldV2";
import { Label } from "../atoms/label/label";
import { FormInput } from "../molecules/FormInput/FormInput";
import GoogleAnalyticsService from "../../../modules/utils/GoogleAnalyticsService";
import { LOG_EVENTS } from "../../../modules/utils/LogEvents";
import { getOperatorCode } from "../../../modules/utils/getOperatorCode";
import { PROFILE } from "modules/auth/constants";
import { featureToggle } from "modules/utils/featureToggle";
import { routes } from "modules/routing";
import { useHistory } from "react-router-dom";

const EMAIL_PATTERN =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const LoaderWrapper = styled.div`
  display: flex;
  position: fixed;
  top: 0;
  flex-direction: column;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9;
  align-items: center;
  justify-content: center;
  background: rgba(255, 255, 255, 0.5);
`;

const ProfileWrapper = styled.div`
  max-width: 990px;
  margin: 0 auto;
  width: 100%;
  @media (max-width: ${(props) =>
      props.theme.custom.breakpoints["xs-breakpoint"]}) {
    padding: 0 20px;
    max-width: fit-content;
  }
`;

const GridCol = styled(Grid)`
  margin-bottom: 20px;
  @media (max-width: ${(props) =>
      props.theme.custom.breakpoints["xs-breakpoint"]}) {
    margin-bottom: 15px;
  }
  .MuiFormHelperText-contained {
    margin: 4px 0;
  }
`;

const TitleWrapper = styled.h3`
  margin: 35px 0;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 18px;
  font-feature-settings: "cpsp" on;
  color: #4f4f4f;
`;

const ButtonWrapper = styled(Button)`
  color: #fff;
  background: ${(props) => props.theme.custom.secondary["main"]};
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
  border-radius: 3px;
  max-width: 200px;
  width: 100%;
  height: 44px;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  border: 1px solid ${(props) => props.theme.custom.secondary["main"]};
  @media (max-width: ${(props) =>
      props.theme.custom.breakpoints["xs-breakpoint"]}) {
    max-width: inherit;
    margin-bottom: 20px;
  }
  &:hover {
    background: ${(props) => props.theme.custom.primary["main"]};
  }
  &:disabled {
    background-color: ${(props) => props.theme.custom.secondaryInactive};
    color: ${(props) => props.theme.custom["white-color"]};
  }
`;

export const Profile = () => {
  const history = useHistory();
  const methods = useForm();
  const { data } = useFetchMe();
  const theme: any = useTheme();
  const verifyPhone = usePhoneVerify();
  const { t } = useTranslation("profile");
  const { value: userData } = useUserData();
  const [loading, setLoading] = React.useState(false);
  const [btnDisabled, setBtnDisabled] = React.useState(true);
  const [oldData, setOldData] = React.useState({ name: "", email: "" });
  const [profileInfo, setProfileInfo] = React.useState({ name: "", email: "" });
  const firebase_app = useSelector(
    (state: any) => state.remoteConfig.firebaseApp,
  );
  const criteria = {
    country: useSelector((state: any) => state.remoteConfig.countryName),
    operator: useSelector((state: any) => state.remoteConfig.operatorId),
  };

  React.useEffect(() => {
    async function getResult() {
      const toggleProfile = await featureToggle(
        PROFILE,
        firebase_app,
        criteria,
      );
      if (!toggleProfile) {
        history.push(routes.home.url());
      }
    }

    getResult();
  }, [criteria, firebase_app, history]);

  const handleChange = (type: string, event: any) => {
    const { target } = event;
    setProfileInfo({
      ...profileInfo,
      [type]: target.value,
    });
  };

  React.useEffect(() => {
    if (
      profileInfo?.name?.trim() !== oldData.name ||
      profileInfo?.email?.trim() !== oldData.email
    ) {
      setBtnDisabled(false);
    } else {
      setBtnDisabled(true);
    }
  }, [profileInfo, oldData]);

  React.useEffect(() => {
    GoogleAnalyticsService.createEvent(LOG_EVENTS.LOG_EVENTS_NAME.SCREEN_VIEW, {
      firebase_screen: LOG_EVENTS.SCREEN_NAME.PROFILE_SCREEN,
      firebase_screen_class: LOG_EVENTS.SCREEN_NAME.PROFILE_SCREEN,
      operator_code: getOperatorCode(),
      source: "web",
    });
    const nextResults = data ?? {};
    setOldData({ name: nextResults.name, email: nextResults.email });
    setProfileInfo({ name: nextResults.name, email: nextResults.email });
  }, [data]);

  const updateProfile = async (values: any) => {
    const { phone, countryCode } = values;
    const tempNumber = `+${countryCode}${phone}`;

    if (
      !isValidNumber(userData?.phoneNumber ?? "") ||
      !arePhonesEqual(userData?.phoneNumber, tempNumber)
    ) {
      verifyPhone?.current?.();
      return;
    }
    setLoading(true);
    try {
      GoogleAnalyticsService.createEvent(LOG_EVENTS.LOG_EVENTS_NAME.ADD_EMAIL, {
        registration: LOG_EVENTS.LOG_METHOD_VALUE.EMAIL_ADDED,
        operator_code: getOperatorCode(),
        source: "web",
      });
      GoogleAnalyticsService.createEvent(
        LOG_EVENTS.LOG_EVENTS_NAME.ADD_FULL_NAME,
        {
          registration: LOG_EVENTS.LOG_METHOD_VALUE.FULL_NAME_ADDED,
          operator_code: getOperatorCode(),
          source: "web",
        },
      );
      const { currentUser } = firebase.auth();
      if (currentUser) {
        await currentUser.updateProfile({ displayName: profileInfo.name });
        updateMe({
          email: values.Email,
          phone: tempNumber.replace(/ +/g, ""),
        }).then((r) => {
          Notifications.enqueueSnackbar(
            t(r?.response?.data?.message || "updateMesg"),
            {
              preventDuplicate: true,
            },
          );
        });
      } else {
        throw new Error("User must be loggedIn");
      }
    } catch (exception) {
      // BugsnagService.notify(exception);
    }
    setLoading(false);
  };

  return (
    <>
      {loading && (
        <LoaderWrapper>
          <CircularProgress color={theme?.custom?.primary?.main} />
        </LoaderWrapper>
      )}
      <ProfileWrapper>
        <TitleWrapper>{t("myProfile")}</TitleWrapper>
        <form onSubmit={methods.handleSubmit(updateProfile)}>
          <FormContext {...methods}>
            <Grid container spacing={2}>
              <GridCol item xs={12} md={4} lg={4}>
                <Label label={t("fullName")} />
                <FormInput
                  name="Name"
                  requiredErrorMessage={t("requiredName")}
                  onChange={(e: any) => handleChange("name", e)}
                  value={profileInfo?.name}
                  smallText={t("fullnameInfo")}
                />
              </GridCol>
              <GridCol item xs={12} md={4} lg={4}>
                <Label label={t("emailAddress")} />
                <FormInput
                  name="Email"
                  requiredErrorMessage={t("requiredEmail")}
                  validationPattern={EMAIL_PATTERN}
                  onChange={(e: any) => handleChange("email", e)}
                  value={profileInfo?.email}
                  smallText={t("mustBe")}
                />
              </GridCol>
              <GridCol item xs={12} md={4} lg={4}>
                <Label label={t("phoneNumber")} />
                <PhoneFieldV2 label="" />
              </GridCol>
            </Grid>
            <Grid container>
              <ButtonWrapper
                disabled={btnDisabled}
                fullWidth
                type="submit"
                onClick={updateProfile}
                data-testid="reservation-proceed-btn"
              >
                {t("save")}
              </ButtonWrapper>
            </Grid>
          </FormContext>
        </form>
      </ProfileWrapper>
    </>
  );
};
