import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import styled, { useTheme } from "styled-components";
import { withStyles } from "@material-ui/core";
import { Button } from "modules/ui";

export const ArrowUpSVG = ({ color }: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="8"
    height="6"
    viewBox="0 0 8 6"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.646447 4.85355C0.451184 4.65829 0.451184 4.34171 0.646447 4.14645L3.64645 1.14645C3.84171 0.951185 4.15829 0.951185 4.35355 1.14645L7.35355 4.14645C7.54882 4.34171 7.54882 4.65829 7.35355 4.85355C7.15829 5.04882 6.84171 5.04882 6.64645 4.85355L4 2.20711L1.35355 4.85355C1.15829 5.04882 0.841709 5.04882 0.646447 4.85355Z"
      fill={color}
      stroke={color}
      stroke-width="0.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

export const ArrowDownSVG = ({ color }: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="8"
    height="6"
    viewBox="0 0 8 6"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.35355 1.14645C7.54882 1.34171 7.54882 1.65829 7.35355 1.85355L4.35355 4.85355C4.15829 5.04881 3.84171 5.04881 3.64645 4.85355L0.646447 1.85355C0.451185 1.65829 0.451185 1.34171 0.646447 1.14645C0.841709 0.951184 1.15829 0.951184 1.35355 1.14645L4 3.79289L6.64645 1.14645C6.84171 0.951184 7.15829 0.951184 7.35355 1.14645Z"
      fill={color}
      stroke={color}
      stroke-width="0.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

export interface TripStopsProps {
  stops?: any; //TODO
}

const Flex = styled.div`
  display: flex;
  .flexWrap {
    flex-wrap: wrap;
    justify-items: center;
    width: 181px;
    display: flex;
  }
`;

const ArrowWrapper = styled.div`
  ul {
    padding: 0;
    margin: 0 0 10px;
    list-style: none;

    li {
      color: #454545;
      font-weight: 400;
      text-align: left;
      font-size: 12px;
      line-height: 16px;
      margin-top: 8px;
    }
  }
`;

const TextButton = withStyles((theme) => ({
  root: {
    padding: "0px",
    color: theme.custom.secondary.main,
  },
  label: {
    fontSize: "12px",
    margin: "0px",
    fontWeight: "normal",
    alignItems: "center",
  },
}))(Button);

export const TripStops: React.FC<TripStopsProps> = ({ stops }) => {
  const { t } = useTranslation("searchResult");
  const theme: any = useTheme();
  const [showMore, setShowMore] = useState(false);
  return (
    <Flex>
      {stops && stops.length > 1 && (
        <div>
          <TextButton onClick={() => setShowMore(!showMore)} variant="text">
            {stops.length} {t("stops")} &nbsp;&nbsp;
            {showMore ? (
              <ArrowUpSVG color={theme.custom.secondary["main"]} />
            ) : (
              <ArrowDownSVG color={theme.custom.secondary["main"]} />
            )}
          </TextButton>
          <ArrowWrapper>
            {showMore && (
              <ul className="">
                {stops.map((stop: any, index: number) => {
                  return <li key={index}>{stop?.stop?.name}</li>;
                })}
              </ul>
            )}
          </ArrowWrapper>
        </div>
      )}
    </Flex>
  );
};
