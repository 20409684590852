import { createTheme } from "@material-ui/core/styles";
import { breakpoints } from "../variables";

declare module "@material-ui/core/styles" {
  interface Theme {
    custom: {
      primary: any;
      secondary: any;
      secondaryInactive: any;
      breakpoints: any;
      "white-color": string;
      "silver-color": string;
      "chartreuse-color": string;
      "emperor-color": string;
      "mercury-color": string;
      "gray-color": string;
      "hover-select": string;
      "alabaster-color": string;
      "alto-color": string;
      "alto-darker-color": string;
      "mine-shaft-color": string;
      "dove-gray-color": string;
      "snow-flurry-color": string;
      "limed-spruce": string;
      "concrete-color": string;
      "gray-text-color": string;
      "button-chartreuse-color-disabled": string;
      "light-grey": string;
      "redish-color": string;
      "roboto-font": string;
      logo: string;
      logoText: string;
      isLogoText: boolean;
    };
  }

  interface ThemeOptions {
    custom?: {
      primary?: any;
      secondary?: any;
      breakpoints?: any;
      "white-color": string;
      "silver-color": string;
      "chartreuse-color": string;
      "emperor-color": string;
      "mercury-color": string;
      "gray-color": string;
      "hover-select": string;
      "alabaster-color": string;
      "alto-color": string;
      "alto-darker-color": string;
      "mine-shaft-color": string;
      "dove-gray-color": string;
      "snow-flurry-color": string;
      "limed-spruce": string;
      "concrete-color": string;
      "gray-text-color": string;
      "button-chartreuse-color-disabled": string;
      "light-grey": string;
      "redish-color": string;
      "roboto-font": string;
      logo: string;
      logoText: string;
      isLogoText: boolean;
    };
  }
}

export const muiTheme = createTheme({
  palette: {
    primary: {
      main: "#FAA61A",
      contrastText: "#fff",
    },
    secondary: {
      main: "#03AA00",
      contrastText: "#fff",
    },
    text: {
      primary: "#4F4F4F",
    },
  },
  custom: {
    secondary: {
      main: "rgba(3,170,0,.5)",
      contrastText: "#fff",
    },
    primary: {
      main: "rgba(3,170,0,1)",
      contrastText: "#fff",
    },
    "white-color": "#fff",
    "silver-color": "#C4C4C4",
    "chartreuse-color": "#03AA00",
    "emperor-color": "#4F4F4F",
    "mercury-color": "#E8E8E8",
    "gray-color": "#828282",
    "hover-select": "#f7f7f7",
    "alabaster-color": "#eeeeee",
    "alto-color": "#E0E0E0",
    "alto-darker-color": "#DADADA",
    "mine-shaft-color": "rgba(0, 0, 0, 0.87)",
    "dove-gray-color": "rgba(0, 0, 0, 0.6)",
    "snow-flurry-color": "#E3FFC4",
    "limed-spruce": "#353F47",
    "concrete-color": "#f2f2f2",
    "gray-text-color": "#979797",
    "button-chartreuse-color-disabled": "rgba(3, 170, 0, 0.3)",
    "light-grey": "#D8D8D8",
    "redish-color": "#ff5757",
    "roboto-font": '"Roboto", "Helvetica", "Arial", sans-serif',
    logo: "",
    logoText: "",
    isLogoText: true,
    breakpoints: {
      "lg-breakpoint": "1279px",
      "md-breakpoint": "768px",
      "xs-breakpoint": "575px",
    },
  },
  typography: {
    overline: {
      textTransform: "capitalize",
      fontWeight: "bold",
      fontSize: "0.875rem",
      letterSpacing: "0.02857em",
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: breakpoints.xs,
      md: breakpoints.xs,
      lg: breakpoints.lg,
      xl: 1920,
    },
  },
  overrides: {
    MuiDialog: {
      paper: {
        boxShadow: "0px 20px 30px rgba(0, 0, 0, 0.25)",
        borderRadius: 10,
      },
      paperFullScreen: {
        borderRadius: 0,
      },
    },
    MuiTextField: {
      root: {
        height: "40px",
      },
    },
    MuiInputBase: {
      input: {
        padding: "10px 12px",
        fontSize: "16px",
        color: "#4F4F4F",
      },
    },
    MuiOutlinedInput: {
      input: {
        padding: "10px 12px",
        borderRadius: "inherit",
      },
    },
    MuiInputLabel: {
      root: {
        height: "37px",
        color: "#BDBDBD",
      },
      shrink: {
        fontStyle: "normal",
        color: "#828282",
      },
      outlined: {
        transform: "translate(14px, 11px) scale(1)",
      },
    },
  },
});
