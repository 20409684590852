import React from "react";
import { SWRConfig } from "swr";
import { BrowserRouter, Switch, Route, useParams } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import { Provider } from "react-redux";
import { MuiThemeProvider, StylesProvider } from "@material-ui/core/styles";
import { config } from "modules/api";
import { operatorConfig } from "modules/api/methods";
import { updateOperatorCode } from "modules/api/client";
import { initializefirebaseApp } from "modules/auth/firebaseAuth";
import { UPDATE_COUNTRY, UPDATE_OPERATOR,SET_OPERATOR_CODE } from "modules/auth/constants";
import SetParameter from "modules/auth/store-middleware";
import { AppRouting } from "modules/routing";
import { I18nProvider } from "modules/localization";
import { Layout, muiTheme } from "modules/ui";
import { Loading } from "modules/ui/atoms/Loading";
import NotFound from "modules/ui/molecules/NotFound";
import { NotificationsProvider } from "modules/notifications";
import { store } from "./store";

interface ParamTypes {
  operatorCode: string;
}

export const useApiTheme = (operatorCode: string) => {
  const [theme, setTheme] = React.useState(muiTheme);
  const [apiConfig, setApiConfig] = React.useState<any>({});

  initializefirebaseApp();

  React.useEffect(() => {
    updateOperatorCode(operatorCode.toUpperCase());

    const fetchTheme = async () => {
      const fetchedData = await operatorConfig();

      if (fetchedData.isAxiosError) {
        // setTimeout(() => {
        //   window.location.href = window.location.origin;
        // }, 0);
      }
      const data = {
        theme: fetchedData?.theme,
        region: fetchedData?.region,
        assets: {
          web: fetchedData?.web
        }
      };
      if (data.hasOwnProperty("theme")) {
        theme.custom.primary.main = data?.theme?.color?.primary;
        theme.custom.secondary.main = data?.theme?.color?.secondary;
        theme.custom.secondaryInactive = data?.theme?.color?.secondary_inactive;
        theme.palette.primary.main = data?.theme?.color?.primary;
        theme.palette.secondary.main = data?.theme?.color?.secondary;
        theme.palette.primary.main = data?.theme?.color?.primary;
        theme.custom.logo = data?.assets?.web?.logo;
        theme.custom.isLogoText = data?.assets?.web?.isLogoText;
        theme.custom.logoText = data?.assets?.web?.logoText;
        setTheme(theme);
        setApiConfig(data);
      }
    };
    fetchTheme();
    SetParameter(UPDATE_OPERATOR, operatorCode.toUpperCase());
  }, [operatorCode, theme]);

  return { newTheme: theme, apiConfig: apiConfig };
};

function AppContainer() {
  const [text, setText] = React.useState("");
  const { operatorCode } = useParams<ParamTypes>();
  const [loading, setLoading] = React.useState(true);
  const { newTheme, apiConfig } = useApiTheme(operatorCode);
  React.useEffect(() => {
    if (apiConfig?.region) {
      setTimeout(() => {
        setLoading(false);
      }, 1000);
      document.title = operatorCode.toUpperCase();

      if (apiConfig?.assets?.web?.favicon["favicon-ico"]) {
        const head = document.getElementsByTagName("head")[0];
        const favicon = document.createElement("link");
        favicon.rel = "icon";
        favicon.href = apiConfig?.assets?.web?.favicon["favicon-ico"];
        head.append(favicon);
      }

      sessionStorage.setItem("opRegion", JSON.stringify(apiConfig?.region));
      store.dispatch({ type: "GOT_REGION", payload: apiConfig?.region });
      SetParameter(UPDATE_COUNTRY, apiConfig?.region?.countryCode);
    }

    if (!apiConfig) {
      setText("title");
      setTimeout(() => {
        window.location.href = window.location.origin;
      }, 5000);
    }
  }, [operatorCode, apiConfig]);

  return (
    <StylesProvider injectFirst>
      <MuiThemeProvider theme={{ ...newTheme }}>
        <ThemeProvider theme={{ ...newTheme }}>
          <NotificationsProvider>
            {loading ? (
              <I18nProvider>
                <Loading title={text} />
              </I18nProvider>
            ) : (
              <BrowserRouter basename={operatorCode}>
                <I18nProvider>
                  <SWRConfig value={config}>
                    <Provider store={store}>
                      <Layout>
                        <AppRouting />
                      </Layout>
                    </Provider>
                  </SWRConfig>
                </I18nProvider>
              </BrowserRouter>
            )}
          </NotificationsProvider>
        </ThemeProvider>
      </MuiThemeProvider>
    </StylesProvider>
  );
}

function App() {
  return (
    <BrowserRouter>
      <Switch>
        <Route
          exact
          path="/"
          render={() => (
            <I18nProvider>
              <NotFound />  
            </I18nProvider>
          )}
        />
        <Route path="/:operatorCode?" component={AppContainer} />
      </Switch>
    </BrowserRouter>
  );
}

export default App;
