export const SearchFormConstants = {
  FROM: "from",
  TO: "to",
  PASSENGERS: "passengers",
  DATE: "date",
  DATE_TO: "returnDate",
};

export const defaultOperatorIN = 'MAAS-IN';
export const defaultOperatorID = 'MAAS-ID';
export const defaultOperatorPH = 'MAAS-PH';
export const defaultOperatorSG = 'MAAS-SG';
export const defaultOperatorMY = 'MAAS-MY';
export const defaultOperatorAE = 'MAAS-AE';
export const defaultOperator = 'MAAS';