import React from "react";
import { useTranslation } from "react-i18next";
import { enUS, id } from "date-fns/locale";
import { format } from "date-fns";
import { useTimer } from "react-timer-hook";
// import clsx from "clsx";
import styled from "styled-components";
import { useLanguage } from "modules/localization";
import { device } from "modules/ui/variables";
// import classes from "./TimeLeft.module.css";

type Props = {
  expiryTimestamp: Date;
  onExpire?: () => void;
};

interface TimerSettings {
  expiryTimestamp: number;
  onExpire?: () => void;
}

const TimeLeftWrapper = styled.div`
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => props.theme.custom.primary.main};
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  max-width: 650px;
`;

const TimeLeftTitle = styled.p`
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 27px;
  text-transform: uppercase;
  margin: 15px 0 5px 0;
  color: ${(props) => props.theme.custom.primary.contrastText};
  letter-spacing: 1px;
  @media ${device.mobile} {
    margin: 10px 0 5px 0;
  }
`;

const TimeWrapper = styled.div`
  display: flex;
`;

const UntilText = styled.div`
  font-size: 14px;
  color: ${(props) => props.theme.custom.primary.contrastText};
  margin: 10px 0 15px 0;
  @media ${device.mobile} {
    margin: 10px 0 10px 0;
  }
`;

const TimeText = styled.div`
  margin: 0;
  font-weight: 600;
  font-size: 24px;
  color: ${(props) => props.theme.custom["white-color"]};
  @media (max-width: ${(props) =>
      props.theme.custom.breakpoints["xs-breakpoint"]}) {
    font-size: 18px;
  }
  &.paddingLeft {
    padding-left: 5px;
  }
`;

export const TimeLeft: React.FC<Props> = ({ expiryTimestamp, onExpire }) => {
  const { t } = useTranslation("paymentsGuide");
  const language = useLanguage();
  const currentLocale = language === "en" ? enUS : id;
  const currentDate = format(expiryTimestamp, "EEE, d MMM, yyyy", {
    locale: currentLocale,
  });
  const settings: TimerSettings = {
    expiryTimestamp: Number(expiryTimestamp),
    onExpire,
  };
  const { seconds, minutes, hours } = useTimer(settings);
  const checkTime = (time: number) => {
    return time < 10 ? `0${time}` : time;
  };
  return (
    <TimeLeftWrapper>
      <TimeLeftTitle>{t("tileLeftTitle")}</TimeLeftTitle>
      <TimeWrapper>
        {hours > 0 && (
          <TimeText>
            {checkTime(hours)} {t("hours")} :
          </TimeText>
        )}
        {minutes > 0 && (
          <TimeText className="paddingLeft">
            {checkTime(minutes)} {t("minutes")} :
          </TimeText>
        )}
        <TimeText className="paddingLeft">
          {checkTime(seconds)} {t("seconds")}
        </TimeText>
      </TimeWrapper>
      <UntilText>
        ({t("until")} {currentDate})
      </UntilText>
    </TimeLeftWrapper>
  );
};
