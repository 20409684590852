import React from "react";
import range from "lodash/range";
import styled from "styled-components";
import { makeStyles, useMediaQuery, useTheme } from "@material-ui/core";
import { TEST_ID, TextField } from "modules/ui";
import { useOtpInput } from "./useCodeInput";
import { OTP_LENGTH } from "../constants";
// import classes from "./CodeInput.module.css";

const Container = styled.div`
  display: grid;
  column-gap: 28px;
  padding-top: 10px;
`;

type OtpProps = {
  length?: number;
  onChange: (value: string) => void;
  value: string;
  error?: boolean;
};

const useClasses = makeStyles({
  textField: {
    textAlign: "center",
    fontWeight: "bold",
    fontSize: "31px",
    lineHeight: 18,
    color: "#4f4f4f",
    paddingLeft: 0,
    paddingRight: 0,
    paddingTop: 0,
    paddingBottom: 14,
  },
});

export const CodeInput: React.FC<OtpProps> = ({
  length = OTP_LENGTH,
  onChange: handleChange,
  value,
  error,
}) => {
  const { onChange, onFocus, onKeyUp, register, values } = useOtpInput({
    length,
    onChange: handleChange,
    value,
  });

  const muiClasses = useClasses();

  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down("sm"));
  const size = mobile ? 25 : 50;

  return (
    <Container
      style={{ gridTemplateColumns: `repeat(${length}, ${size}px)` }}
      // className={classes.container}
      data-testid={TEST_ID.OTP_INPUTS_CONTAINER}
    >
      {range(length).map((index) => {
        return (
          <TextField
            key={index}
            inputRef={register(index)}
            type="text"
            value={values[index]}
            onChange={onChange(index)}
            onKeyUp={onKeyUp(index)}
            onFocus={onFocus}
            error={error}
            autoFocus={index === 0}
            InputProps={{
              classes: { input: muiClasses.textField },
            }}
          />
        );
      })}
    </Container>
  );
};
