import React from "react";
import clsx from "clsx";
import format from "date-fns/format";
import { parseISO } from "date-fns";
import { utcToZonedTime } from "date-fns-tz";
import styled from "styled-components";
import Grid from "@material-ui/core/Grid";
import { getBusStopLabel, getBusStopName } from "modules/entities/helpers";
import { StopElement, Timezone } from "modules/entities";
import { Redirect, routes } from "modules/routing";
import convertToUtc from "modules/utils/convertUtc";
import classes from "modules/search-result-list/organisms/TripCard/TripCard.module.css";

export interface TripInfoProps {
  smallFont?: boolean;
  pickUpStop: StopElement;
  dropOutStop: StopElement;
  timezone?: Timezone;
  children?: JSX.Element;
  stops?: any;
  wasManuallyEdited?: boolean;
  disableConversion?: boolean;
  ridePatternId?: string | null;
}

const getTimeValue = (
  time: string,
  timezone: string,
  wasManuallyEdited?: boolean,
  disableConversion?: boolean,
  ridePatternId?: string | null,
) => {
  try {
    if (!timezone) {
      return null;
    }
    let newTime = convertToUtc(time).toISOString();
    if (
      wasManuallyEdited === true ||
      disableConversion === true ||
      ridePatternId === null
    ) {
      newTime = time;
    }
    return format(utcToZonedTime(parseISO(newTime), timezone), "HH:mm");
  } catch (error) {
    console.error(error);
    return <Redirect to={routes.home.url()} />;
  }
};

const TripTime = styled(Grid)`
  height: 100%;
  justify-content: space-between;
  align-items: flex-start;
`;
const ReserveButtonColumn = styled(Grid)`
  height: 100%;
  align-items: flex-end;
  align-content: flex-end;
`;
const HrLine = styled.div`
  width: 7px;
  height: 100%;
  border-right: 1px solid ${(props) => props.theme.custom["gray-color"]};
  position: relative;
  &::before,
  &::after {
    position: absolute;
    top: 0;
    content: " ";
    border-radius: 100px;
    width: 10px;
    height: 10px;
    border: 1px solid ${(props) => props.theme.custom["light-grey"]};
    background: ${(props) => props.theme.custom["alabaster-color"]};
    left: 2px;
  }
  &::after {
    bottom: 0;
    top: unset;
  }
`;
const TextCommon = styled.p`
  font-family: ${(props) => props.theme.custom["roboto-font"]};
  font-style: normal;
  color: ${(props) => props.theme.custom["emperor-color"]};
  font-size: 12px;
  font-weight: bold;
  line-height: 14px;
  margin: 0;
  &.light {
    font-weight: 400;
  }
`;

export const MyRideInfo: React.FC<TripInfoProps> = ({
  pickUpStop,
  dropOutStop,
  smallFont,
  timezone,
  children,
  stops,
  wasManuallyEdited,
  disableConversion,
  ridePatternId,
}) => {
  return (
    <Grid item container wrap="nowrap" alignItems="center">
      <TripTime item container direction="column" lg={1} xs={2}>
        <Grid
          item
          className={clsx(
            classes.textBold,
            classes.paddingBottom,
            smallFont && classes.textSmall,
          )}
        >
          <TextCommon>
            {timezone &&
              timezone.from &&
              getTimeValue(
                pickUpStop.timeOfArrival,
                timezone.from,
                wasManuallyEdited,
                disableConversion,
                ridePatternId,
              )}
          </TextCommon>
        </Grid>
        <Grid
          item
          className={clsx(
            classes.textBold,
            classes.paddingTop,
            smallFont && classes.textSmall,
          )}
        >
          <TextCommon>
            {timezone &&
              timezone.to &&
              getTimeValue(
                dropOutStop.timeOfArrival,
                timezone.to,
                wasManuallyEdited,
                disableConversion,
                ridePatternId,
              )}
          </TextCommon>
        </Grid>
      </TripTime>
      <TripTime item xs={1}>
        {/* <RouteIcon className={classes.icon} /> */}
        <HrLine />
      </TripTime>
      <TripTime item xs={4} lg={8} container direction="column" className="">
        <Grid
          item
          className={clsx(
            classes.paddingBottom,
            smallFont && classes.textSmall,
          )}
        >
          <TextCommon>{getBusStopName(pickUpStop.stop)}</TextCommon>
          <TextCommon className="light">
            {getBusStopLabel(pickUpStop.stop)}
          </TextCommon>
        </Grid>
        <Grid
          item
          className={clsx(classes.paddingTop, smallFont && classes.textSmall)}
        >
          <TextCommon>{getBusStopName(dropOutStop.stop)}</TextCommon>
          <TextCommon className="light">
            {getBusStopLabel(dropOutStop.stop)}
          </TextCommon>
        </Grid>
      </TripTime>
      <ReserveButtonColumn item xs={5} container direction="column">
        {children}
      </ReserveButtonColumn>
    </Grid>
  );
};
