/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from "react";
import styled from "styled-components";
import { Link } from "modules/routing";

const SideNavigationPanel = styled.nav`
  border-bottom: 1px solid ${(props) => props.theme.custom["silver-color"]};

  ul {
    list-style: none;
    margin: 0;
    li.side-navigation-panel-select-option {
      height: 60px;
      padding: 0px 20px 0;
      display: flex;
      align-items: center;
      .side-navigation-panel-select-option-icon {
        margin-right: 25px;
        width: 30px;
      }
      .side-navigation-panel-select-option-text {
        font-size: 18px;
        font-weight: 500;
        line-height: 16px;
        color: ${(props) => props.theme.custom["limed-spruce"]};
      }
      &.selected {
        background: ${(props) => props.theme.custom["alabaster-color"]};
        .side-navigation-panel-select-option-text {
          color: ${(props) => props.theme.custom.primary.main};
        }
      }
    }
  }
`;

declare global {
  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace JSX {
    interface IntrinsicElements {
      ElemBefore: React.ReactNode;
    }
  }
}

export type NavItemProps = {
  title: string;
  itemId: string;
  // disabled?: boolean;
  elemBefore?: React.FC<unknown>;
  subNav?: NavItemProps[];
};

export type SideNavProps = {
  items: NavItemProps[];
  activeItemId: string;
  onSelect?: ({ itemId }: { itemId: string }) => void;
};

const Navigation: React.FC<SideNavProps> = ({
  activeItemId,
  onSelect,
  items,
}) => {
  const [activeSubNav, setActiveSubNav] = useState({
    expanded: true,
    selectedId: activeItemId,
  });

  // Listen for parent prop changes and update state
  React.useEffect(() => {
    setActiveSubNav((originalSubNav) => ({
      expanded: originalSubNav.expanded,
      selectedId: activeItemId,
    }));
  }, [activeItemId]);

  function handleClick(itemId: string): void {
    // call the callback if supplied
    onSelect?.({ itemId });
  }

  return (
    <>
      {items.length > 0 && (
        <SideNavigationPanel
          role="navigation"
          aria-label="side-navigation"
          //   className="side-navigation-panel"
        >
          <ul className="side-navigation-panel-select">
            {items.map((item: NavItemProps) => {
              const ElemBefore: any = item.elemBefore;
              const isItemSelected: boolean =
                item.itemId === activeSubNav.selectedId;

              return (
                <React.Fragment key={item.itemId}>
                  <Link to={item.itemId}>
                    <li
                      onClick={(): void => {
                        // handleSubNavExpand(item);
                        handleClick(item.itemId);
                      }}
                      className={`side-navigation-panel-select-option ${
                        isItemSelected ? "selected" : ""
                      }`}
                    >
                      <span className="side-navigation-panel-select-option-icon">
                        {/** Prefix Icon Component */}
                        {ElemBefore && <ElemBefore />}
                      </span>
                      <span className="side-navigation-panel-select-option-text">
                        {item.title}
                      </span>

                      {/* {item.subNav &&
                      item.subNav.length > 0 &&
                      (isActiveTab ? <ChevronUpIcon /> : <ChevronDownIcon />)} */}
                    </li>
                  </Link>
                </React.Fragment>
              );
            })}
          </ul>
        </SideNavigationPanel>
      )}
    </>
  );
};

export default Navigation;
