import React from "react";
import { useLocation, Prompt } from "react-router-dom";
// import { useSelector, RootStateOrAny } from "react-redux";
import { createHash, transactionInitiate } from "modules/api/methods/payment";
// import { createBookingWithXendit } from "modules/api/methods/booking";
import { useTickets } from "modules/api";
import { TicketStatus } from "modules/entities";
import { Redirect, routes } from "modules/routing";
import { Notifications } from "modules/notifications";
import { CancellationDialog } from "../../checkout-va/organisms/CancellationDialog/CancellationDialog";
import { useHandleLeave } from "../../checkout-va/hooks/useHandleLeave";
import PayUForm from "./payUForm";

type CheckoutResponse = {
  url: string;
};

const TICKET_REFRESH_INTERVAL = 5000;
// const XENDIT_PH = "XENDIT_PH";

const PaymentCheckout = () => {
  const { state }: any = useLocation();
  const [checkout, setCheckout] = React.useState<CheckoutResponse>();
  const [data, setData] = React.useState<any>(null);
  // const region = useSelector((state: RootStateOrAny) => state.region);
  const {
    handleLeave,
    closeDialog,
    confirmationOpen,
    isBlockingNavigation,
    unblockNavigation,
  } = useHandleLeave();
  const redirectUrl = "";
  let backUrl = "";
  const { data: ticketData, error } = useTickets(
    state?.paymentData?.serviceType !== "rental_catalog_booking" &&
      state.gatewayName !== "payu" &&
      state?.ticketId,
    {
      refreshInterval: TICKET_REFRESH_INTERVAL,
    },
  );
  // const showXendit = region?.paymentGateway === XENDIT_PH;

  React.useEffect(() => {
    if (checkout) {
      window.location.href = checkout.url;
      const xenditIframe = document.createElement("iframe");
      xenditIframe.src = checkout.url;
      xenditIframe.id = "iframe";
      document.getElementById("main-container")?.appendChild(xenditIframe);
      xenditIframe.style.height = "100vh";
    }

    // returned function will be called on component unmount
    return () => {
      const xenditIframe = document.getElementById("iframe");
      if (xenditIframe) {
        document.getElementById("main-container")?.removeChild(xenditIframe);
      }
    };
  }, [checkout]);

  React.useEffect(() => {
    if (state) {
      const { paymentData, gatewayName } = state;

      if (gatewayName === "payu") {
        createHash(paymentData)
          .then((r) => {
            setData(r.data);
          })
          .catch((e) => {
            Notifications.enqueueSnackbar(e?.response?.data?.message, {
              preventDuplicate: true,
            });
          });
      } else {
        transactionInitiate(paymentData)
          .then((r) => {
            setCheckout(r);
          })
          .catch((e) => {
            Notifications.enqueueSnackbar(e?.response?.data?.message, {
              preventDuplicate: true,
            });
          });
      }
    }
  }, [state]);

  if (ticketData) {
    backUrl = routes.newReservation.url({
      dropOutStopId: ticketData.ticket.dropOutStopId,
      pickUpStopId: ticketData.ticket.pickUpStopId,
      passengers: state.passengerCount,
      rideId: ticketData.ticket.rideId,
    });
  }
  // else {
  //   backUrl = ticketData
  //     ? routes.selectPayment.url({
  //         dropOutStopId: ticketData.ticket.dropOutStopId,
  //         pickUpStopId: ticketData.ticket.pickUpStopId,
  //         passengers: state.passengerCount,
  //         rideId: ticketData.ticket.rideId,
  //         paymentCode: undefined,
  //       })
  //     : "";
  // }

  const isTicketCancelled =
    ticketData?.ticket?.status === TicketStatus.payment_cancelled ||
    ticketData?.ticket?.status === TicketStatus.cancelled;

  if (isTicketCancelled && backUrl) {
    return <Redirect to={backUrl} />;
  } else if ([404, 403].includes(error?.status as number)) {
    return <Redirect to={redirectUrl} />;
  } else if (ticketData?.ticket?.status === "paid") {
    return (
      <Redirect
        to={{
          pathname: routes.checkoutSuccess.url({
            ticketId: ticketData?.ticket?.id,
          }),
          state: {
            ticketId: ticketData?.ticket?.id,
          },
        }}
      />
    );
  }

  if (data !== null) {
    return <PayUForm data={data} />;
  }

  if (!state) {
    return <Redirect to={routes.home.url()} />;
  }

  if (state) {
    return (
      <div>
        {state?.paymentData?.serviceType !== "rental_catalog_booking" && (
          <>
            <Prompt when={isBlockingNavigation} message={handleLeave} />
            <CancellationDialog
              ticketId={state?.ticketId}
              open={confirmationOpen}
              onClose={closeDialog}
              backUrl={backUrl}
              unblockNavigation={unblockNavigation}
            />
          </>
        )}
      </div>
    );
  }

  return <></>;
};

export default PaymentCheckout;
