import * as React from "react";
import { Location } from "history";

export const useHandleLeave = () => {
  const [confirmationOpen, setConfirmationOpen] = React.useState(false);
  const [isBlockingNavigation, setBlockingNavigation] = React.useState(true);

  React.useEffect(() => {
    if (isBlockingNavigation) {
      return;
    }
    const { href } = window.location;
    window.history.pushState(null, "", href);
    const handlePop = () => {
      window.history.pushState(null, "", href);
      setConfirmationOpen(true);
    };
    window.addEventListener("popstate", handlePop);
    return () => {
      window.removeEventListener("popstate", handlePop);
      window.history.back();
    };
  }, [isBlockingNavigation]);

  const closeDialog = React.useCallback(() => setConfirmationOpen(false), []);

  const handleLeave = (location: Location) => {
    setConfirmationOpen(true);
    return false;
  };

  return {
    confirmationOpen,
    handleLeave,
    closeDialog,
    isBlockingNavigation,
    unblockNavigation: () => setBlockingNavigation(false),
  };
};
