import React from "react";
import clsx from "clsx";
import styled from "styled-components";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import { Popper } from "@material-ui/core";
import Grow from "@material-ui/core/Grow";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import { TEST_ID } from "modules/ui";
import arrowIcon from "./arrow-icon.svg";
import {getOperatorCode}  from "../../../utils/getOperatorCode"
import GoogleAnalyticsService from "../../../utils/GoogleAnalyticsService";
import {LOG_EVENTS} from "../../../utils/LogEvents";
// import classes from "./PassengerAmountSelect.module.css";

interface Option {
  value: number;
}

const PopperWrapper = styled(({ ...rest }) => <Popper {...rest} />)`
  z-index: 99;
  @media (max-width: ${(props) =>
      props.theme.custom.breakpoints["lg-breakpoint"]}) {
    width: 100%;
  }
`;

const Label = styled.p`
  margin: 0px 0px 5px 5px;
  font-family: ${(props) => props.theme.custom["roboto-font"]};
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: ${(props) => props.theme.custom["gray-color"]};
  @media (max-width: ${(props) =>
      props.theme.custom.breakpoints["lg-breakpoint"]}) {
    display: none;
  }
`;

const InputWrapper = styled.div`
  margin: 0px 0px 5px 5px;
  font-family: ${(props) => props.theme.custom["roboto-font"]};
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: ${(props) => props.theme.custom["gray-color"]};
  @media (max-width: ${(props) =>
    props.theme.custom.breakpoints["md-breakpoint"]}) {
  margin: 0px;
}
  @media (max-width: ${(props) =>
      props.theme.custom.breakpoints["xs-breakpoint"]}) {
    margin: 0px 0px 5px 0px;
  }
`;

const SelectWrapper = styled(({ ...rest }) => <MenuList {...rest} />)`
  min-width: 200px;
  margin-top: 10px;
  background: ${(props) => props.theme.custom["white-color"]};
  border: 1px solid ${(props) => props.theme.custom["silver-color"]};
  box-sizing: border-box;
  box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.05);
  border-radius: 3px;
  list-style: none;
  padding-inline-start: 0;
  @media (max-width: ${(props) =>
      props.theme.custom.breakpoints["xs-breakpoint"]}) {
    width: 90%;
    margin: 10px auto;
    left: 10px;
  }
  .select {
    padding: 10px;
    font-family: ${(props) => props.theme.custom["roboto-font"]};
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: ${(props) => props.theme.custom["emperor-color"]};
    &:hover {
      cursor: pointer;
      background: ${(props) => props.theme.custom["hover-select"]};
    }
    &.selectActive {
      background: ${(props) => props.theme.custom["alabaster-color"]};
      &:hover {
        background: ${(props) => props.theme.custom["alabaster-color"]};
      }
    }
  }
`;

const SelectInputWrapper = styled.div`
  min-width: 200px;
  background: ${(props) => props.theme.custom["white-color"]};
  border: 1px solid ${(props) => props.theme.custom["silver-color"]};
  box-sizing: border-box;
  border-radius: 3px;
  list-style: none;
  padding-inline-start: 0;
`;

const SelectInput = styled.button`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: ${(props) => props.theme.custom["roboto-font"]};
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 19px;
  padding: 10px;
  color: ${(props) => props.theme.custom["emperor-color"]};
  min-width: 197px;
  border: none;
  background: transparent;
  @media (max-width: ${(props) =>
      props.theme.custom.breakpoints["lg-breakpoint"]}) {
    min-width: 100%;
  }
`;

interface Props {
  label: string;
  options: Array<Option>;
  value?: number;
  onChange?: React.Dispatch<React.SetStateAction<number>>;
}

export function SearchSelect(props: Props) {
  const { label, value, onChange, options } = props;

  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef<HTMLDivElement>(null);

  const handleClick = (
    e: React.MouseEvent<HTMLButtonElement> | React.FocusEvent<HTMLButtonElement>
  ) => {
    e.preventDefault();
    if (!open) {
      setOpen(true);
    }
  };

  const handleClose = (event: React.MouseEvent<EventTarget>) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }

    setOpen(false);
  };

  const handleChanges = (value: number) => {
    GoogleAnalyticsService.createEvent(LOG_EVENTS.LOG_EVENTS_NAME.SELECT_SEATS, {
      home: LOG_EVENTS.LOG_METHOD_VALUE.SEATS_SELECTED,
      count:value,
      operator_code: getOperatorCode(),
      source:"web"
    });
    onChange && onChange(value);
    setOpen(false);
  };

  function handleListKeyDown(event: React.KeyboardEvent) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    }
  }

  const selectedPassenger = options.find((option) => option.value === value);
  return (
    <div>
      <Label>{label}</Label>
      <InputWrapper>
        <SelectInputWrapper ref={anchorRef}>
          <SelectInput
            data-testid={TEST_ID.PASSENGERS_COUNT_SELECT}
            onClick={handleClick}
            onFocus={handleClick}
            value={selectedPassenger && selectedPassenger.value}
          >
            <span>
              {selectedPassenger && `${selectedPassenger.value} ${label}`}
            </span>
            <img src={arrowIcon} alt="arrow" />
          </SelectInput>
          <PopperWrapper
            open={open}
            anchorEl={anchorRef.current}
            role="listbox"
            transition
          >
            {({ TransitionProps, placement }: any) => (
              <Grow
                {...TransitionProps}
                style={{
                  transformOrigin:
                    placement === "bottom" ? "center top" : "center bottom",
                }}
              >
                <div>
                  <ClickAwayListener onClickAway={handleClose}>
                    <SelectWrapper
                      autoFocusItem={open}
                      onKeyDown={handleListKeyDown}
                      data-testid={TEST_ID.PASSENGERS_COUNT_DROPDOWN}
                    >
                      {options.map((item) => (
                        <MenuItem
                          key={item.value}
                          className={clsx(
                            "select",
                            (selectedPassenger && selectedPassenger.value) ===
                              item.value && "selectActive"
                          )}
                          onClick={() => handleChanges(item.value)}
                        >
                          {`${item.value} ${label}`}
                        </MenuItem>
                      ))}
                    </SelectWrapper>
                  </ClickAwayListener>
                </div>
              </Grow>
            )}
          </PopperWrapper>
        </SelectInputWrapper>
      </InputWrapper>
    </div>
  );
}
