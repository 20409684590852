import React from "react";
import { useLocation, Prompt } from "react-router-dom";
import { useSelector, RootStateOrAny } from "react-redux";
import { createBookingWithXendit } from "modules/api/methods/booking";
import { useTickets } from "modules/api";
import { TicketStatus } from "modules/entities";
import { Redirect, routes } from "modules/routing";
import { Notifications } from "modules/notifications";
import { CancellationDialog } from "../../checkout-va/organisms/CancellationDialog/CancellationDialog";
import { useHandleLeave } from "../../checkout-va/hooks/useHandleLeave";

type CheckoutResponse = {
  checkoutId: string;
  redirectUrl: string;
  ticketGroupId: string;
};

// type paymentType = {
//   BOOKING: "booking",
//   RENTAL_SERVICE: "rental_Service"
// };

type XenditParams = {
  su: string;
  eu: string;
  type: string;
  id: string;
};

const TICKET_REFRESH_INTERVAL = 5000;
const XENDIT_PH = "XENDIT_PH";

const XenditCheckout = () => {
  const { state }: any = useLocation();
  const [checkout, setCheckout] = React.useState<CheckoutResponse>();
  const region = useSelector((state: RootStateOrAny) => state.region);
  const {
    handleLeave,
    closeDialog,
    confirmationOpen,
    isBlockingNavigation,
    unblockNavigation,
  } = useHandleLeave();
  const redirectUrl = "";
  let backUrl = "";
  const { data: ticketData, error } = useTickets(
    state?.type !== "rental_catalog_booking" && state?.id,
    {
      refreshInterval: TICKET_REFRESH_INTERVAL,
    }
  );
  const showXendit = region?.paymentGateway === XENDIT_PH;

  React.useEffect(() => {
    if (checkout) {
      const xenditIframe = document.createElement("iframe");
      xenditIframe.src = checkout.redirectUrl;
      xenditIframe.id = "iframe";
      document.getElementById("main-container")?.appendChild(xenditIframe);
      xenditIframe.style.height = "100vh";
    }
    // returned function will be called on component unmount
    return () => {
      const xenditIframe = document.getElementById("iframe");
      if (xenditIframe)
        document.getElementById("main-container")?.removeChild(xenditIframe);
    };
  }, [checkout]);

  React.useEffect(() => {
    if (state) {
      const { id, su, eu, type } = state as XenditParams;
      if (id) {
        const params: any = {
          id: id,
          completePaymentUrl: su,
          errorPaymentUrl: eu,
          type: type,
        };

        createBookingWithXendit(params)
          .then((r) => {
            setCheckout(r);
          })
          .catch((e) => {
            Notifications.enqueueSnackbar(e?.response?.data?.message, {
              preventDuplicate: true,
            });
          });
      }
    }
  }, [state]);

  if (ticketData && showXendit) {
    backUrl = routes.newReservation.url({
      dropOutStopId: ticketData.ticket.dropOutStopId,
      pickUpStopId: ticketData.ticket.pickUpStopId,
      passengers: state.passengerCount,
      rideId: ticketData.ticket.rideId,
    });
  } else {
    backUrl = ticketData
      ? routes.selectPayment.url({
          dropOutStopId: ticketData.ticket.dropOutStopId,
          pickUpStopId: ticketData.ticket.pickUpStopId,
          passengers: state.passengerCount,
          rideId: ticketData.ticket.rideId,
          paymentCode: undefined,
        })
      : "";
  }

  const isTicketCancelled =
    ticketData?.ticket?.status === TicketStatus.payment_cancelled ||
    ticketData?.ticket?.status === TicketStatus.cancelled;

  if (isTicketCancelled && backUrl) {
    return <Redirect to={backUrl} />;
  } else if ([404, 403].includes(error?.status as number)) {
    return <Redirect to={redirectUrl} />;
  } else if (ticketData?.ticket?.status === "paid") {
    return (
      <Redirect
        to={{
          pathname: routes.checkoutSuccess.url({
            ticketId: ticketData?.ticket?.id,
          }),
          state: {
            ticketId: ticketData?.ticket?.id,
          },
        }}
      />
    );
  }

  if (!state) {
    return <Redirect to={routes.home.url()} />;
  }

  if (state) {
    return (
      <div>
        {state?.type !== "rental_catalog_booking" && (
          <>
            <Prompt when={isBlockingNavigation} message={handleLeave} />
            <CancellationDialog
              ticketId={state?.id}
              open={confirmationOpen}
              onClose={closeDialog}
              backUrl={backUrl}
              unblockNavigation={unblockNavigation}
            />
          </>
        )}
      </div>
    );
  }

  return <></>;
};

export default XenditCheckout;
