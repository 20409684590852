import { auth, firebase } from "../../auth/firebaseAuth";
import { Notifications } from "../../notifications";

const googleProvider = new firebase.auth.GoogleAuthProvider();

export default function loginWithGoogle() {
  return auth
    .signInWithPopup(googleProvider)
    .then((res) => {
      Notifications.enqueueSnackbar("Logged in successfully", {
        preventDuplicate: true,
      });
      return [true, false, res];
    })
    .catch((error) => {
      Notifications.enqueueSnackbar(error?.message, {
        preventDuplicate: true,
      });
      return [true, false, error];
    });
}
