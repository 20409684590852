import * as React from "react";
import { useTranslation } from "react-i18next";
import { format } from "libphonenumber-js";
import { Controller, useForm } from "react-hook-form";
import styled from "styled-components";
import { CircularProgress, Typography } from "@material-ui/core";
import {
  arePhonesEqual,
  useOtpResend,
  usePhoneAuthentication,
  usePhoneAuthErrorMessage,
} from "modules/auth";
import { Button, TEST_ID } from "modules/ui";
import { auth, firebase } from "../../firebaseAuth";
import { ReactComponent as SmartphoneIcon } from "../../icons/smartphone.svg";
import { CodeInput } from "../../molecules/CodeInput";
import { OTP_LENGTH } from "../../constants";
import { useUserData } from "../AuthProvider";
import { OtpVerificationSuccess } from "../../molecules/OtpVerificationSuccess";
import GoogleAnalyticsService from "../../../utils/GoogleAnalyticsService"
import {LOG_EVENTS} from "../../../utils/LogEvents"
import {getOperatorCode}  from "../../../utils/getOperatorCode";
// import classes from "./OtpVerification.module.css";

type VerificationProps = {
  phone: string;
  verificationId: string;
  onContinue: () => void;
};

const isVerified = (phone: string, currentPhone: any) => {
  return currentPhone && arePhonesEqual(phone, currentPhone);
};

const formatSeconds = (seconds: number) => {
  const secs = seconds > 9 ? seconds : `0${seconds}`;
  return `00:${secs}`;
};

const Container = styled(({ ...rest }) => <form {...rest} />)`
  display: grid;
  grid-auto-rows: max-content;
  grid-template-columns: 100%;
  row-gap: 10px;
  place-items: center;
`;

const SecondaryText = styled.p`
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;
  margin: 0;
  text-align: center;
`;
const SecondaryText2 = styled.p`
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  margin: 0;
  text-align: center;
`;

const Phone = styled.span`
  font-weight: 700;
  white-space: nowrap;
`;

const ResendLabel = styled.p`
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  letter-spacing: -0.06px;
  color: #979797;
  display: inline;
`;

const ResendTimeLeft = styled.p`
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  letter-spacing: -0.06px;
  color: #979797;
  display: inline;
  font-weight: bold;
`;

const Title = styled(({ ...rest }) => <Typography {...rest} />)`
  text-align: center;
  margin-bottom: 1rem;
  @media (max-width: ${(props) =>
      props.theme.custom.breakpoints["xs-breakpoint"]}) {
    font-size: 22px;
    font-weight: 500;
    margin: 0;
    color: ${(props) => props.theme.custom["emperor-color"]};
  }
`;

const ContinueButton = styled(({ ...rest }) => <Button {...rest} />)`
  background-color: ${(props) => props.theme.custom.secondary.main};
  &:disabled {
    background-color: ${(props) => props.theme.custom.secondaryInactive};
    opacity: 0.5;
    color: ${(props) => props.theme.custom["white-color"]};
  }
  margin-top: 40px;
  height: 60px;
  font-size: 16px;
  font-weight: 500;
`;

const ErrorText = styled(({ ...rest }) => <Typography {...rest} />)`
  margin-top: 10px;
  font-size: 14px;
`;

const ResendTime = styled.p`
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: ${(props) => props.theme.custom["gray-text-color"]};
  margin-top: 24px;
`;

export const OtpVerification: React.FC<VerificationProps> = ({
  phone,
  verificationId,
  onContinue,
}) => {
  const { t } = useTranslation("otp");
  const { value: currentUser } = useUserData();
  const [isResending, setIsResending] = React.useState(false);
  const {
    watch,
    control,
    handleSubmit,
    errors,
    setError,
    clearError,
    formState,
  } = useForm({
    defaultValues: {
      otp: "",
    },
  });

  React.useEffect(() => {
    GoogleAnalyticsService.createEvent(LOG_EVENTS.LOG_EVENTS_NAME.SCREEN_VIEW, {
      firebase_screen: LOG_EVENTS.SCREEN_NAME.OTP_VERIFICATION_SCREEN, 
      firebase_screen_class: LOG_EVENTS.SCREEN_NAME.OTP_VERIFICATION_SCREEN,
      operator_code: getOperatorCode(),
      source:"web"
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const logIn = usePhoneAuthentication("otp-resend");
  const resendOtp = () => {
    clearError("common");
    setIsResending(true);
    logIn(phone)
      .catch((e) => {
        const errorType = e?.code || "generic";
        setError("common", "submit", errorType);
      })
      .finally(() => {
        setIsResending(false);
      });
  };
  const { secondsLeft, canResend } = useOtpResend(phone);
  const verify = async (
    { otp }: { otp: string },
    event: React.SyntheticEvent
  ) => {
    event.stopPropagation();
    clearError("common");
    GoogleAnalyticsService.createEvent(LOG_EVENTS.LOG_EVENTS_NAME.ADD_OTP, {
      registration: LOG_EVENTS.LOG_METHOD_VALUE.OTP_ADDED,
      operator_code: getOperatorCode(),
      source:"web"
    });
    try {
      const credential = await firebase.auth.PhoneAuthProvider.credential(
        verificationId,
        otp
      );
      GoogleAnalyticsService.createEvent(LOG_EVENTS.LOG_EVENTS_NAME.PRESS_BUTTON_CONTINUE, {
        login: LOG_EVENTS.LOG_METHOD_VALUE.CONTINUE_BUTTON_PRESSED,
        operator_code: getOperatorCode(),
        source:"web"
      });
      GoogleAnalyticsService.createEvent(LOG_EVENTS.LOG_EVENTS_NAME.GET_OTP_SUCCESS, {
        registration: LOG_EVENTS.LOG_METHOD_VALUE.OTP_SUCCESS,
        operator_code: getOperatorCode(),
        source:"web"
      });
      GoogleAnalyticsService.createEvent(LOG_EVENTS.LOG_EVENTS_NAME.LOGIN, {
        login: LOG_EVENTS.LOG_METHOD_VALUE.LOGIN_CLICKED,
        method: LOG_EVENTS.LOG_METHOD_VALUE.PHONE_NUMBER,
        operator_code: getOperatorCode(),
        source:"web"
      });

      GoogleAnalyticsService.createEvent(LOG_EVENTS.LOG_EVENTS_NAME.SCREEN_VIEW, {
        firebase_screen: LOG_EVENTS.SCREEN_NAME.PHONE_LOGIN_SCREEN, 
        firebase_screen_class: LOG_EVENTS.SCREEN_NAME.PHONE_LOGIN_SCREEN,
        operator_code: getOperatorCode(),
        source:"web"
      });
      
      await auth.signInWithCredential(credential);
    } catch (e) {
      GoogleAnalyticsService.createEvent(LOG_EVENTS.LOG_EVENTS_NAME.RECEIVE_OTP_ERROR, {
        registration: LOG_EVENTS.LOG_METHOD_VALUE.OTP_ERROR_RECEIVED,
        operator_code: getOperatorCode(),
        source:"web"
      });
      const errorType = e?.code || "generic";
      setError("common", "submit", errorType);
    }
  };

  const errorType = (errors as any).common?.message;
  const error = usePhoneAuthErrorMessage(errorType);
  const otp = watch("otp", "");

  return (
    <Container onSubmit={handleSubmit(verify as any)}>
      <SmartphoneIcon />
      {/* <Typography variant="h5">{t("title")}</Typography> */}
      <Title variant="h6">{t("title")}</Title>
      <SecondaryText>
        {t("codeSent")}
        <br />
        {t("sms")} <Phone>{format(phone, "INTERNATIONAL")}</Phone>
      </SecondaryText>
      <SecondaryText2>{t("verificationCode")}</SecondaryText2>
      {!isVerified(phone, currentUser?.phoneNumber) ? (
        <>
          <Controller
            control={control}
            name="otp"
            error={Boolean(errorType)}
            as={CodeInput as any}
          />
          {errorType && <ErrorText color="error">{error}</ErrorText>}
          <ContinueButton
            type="submit"
            fullWidth
            color="secondary"
            size="large"
            date-testid={TEST_ID.VERIFY_OTP}
            disabled={otp.length !== OTP_LENGTH || formState.isSubmitting}
            startIcon={
              formState.isSubmitting && (
                <CircularProgress size={18} color="inherit" />
              )
            }
          >
            {t("verify")}
          </ContinueButton>
          <div style={{ display: !canResend ? "none" : undefined }}>
            <Button
              type="button"
              fullWidth
              size="large"
              date-testid={TEST_ID.OTP_RESEND}
              id="otp-resend"
              variant="text"
              disabled={isResending}
              onClick={resendOtp}
              startIcon={
                isResending && <CircularProgress size={18} color="inherit" />
              }
            >
              <Typography variant="button">{t("resend")}</Typography>
            </Button>
          </div>
          {!canResend && (
            <ResendTime>
              <ResendLabel>{t("resendTimeLeft")}</ResendLabel>
              <ResendTimeLeft>{formatSeconds(secondsLeft)}</ResendTimeLeft>
            </ResendTime>
          )}
        </>
      ) : (
        <OtpVerificationSuccess onContinue={onContinue} />
      )}
    </Container>
  );
};
