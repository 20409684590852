import { axios } from "../client";

export async function getRefundStatus(ticketId: string | null | undefined) {
  return axios
    .get<any>(`/v2/tickets/${ticketId}/refund/status`)
    .then((res) => {
      return res.data;
    })
    .catch((error) => error);
}
