import React from "react";
import { useTranslation } from "react-i18next";
import { Duration as DurationType } from "modules/utils/getDuration";

const DURATION_KEYS = ["d", "h", "m"] as const;

export const Duration: React.FC<{
  value: DurationType;
}> = ({ value }) => {
  const { t } = useTranslation("duration");
  return (
    <>
      {DURATION_KEYS.map((key) => {
        const keyValue = value[key];
        if (keyValue === 0) {
          return null;
        }
        return (
          <span key={key}>
            {keyValue} {t(`${key}`)}
          </span>
        );
      })}
    </>
  );
};
