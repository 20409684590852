import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import { parse, ParsedQuery, stringify } from "query-string";

type Setter = (val: string | null) => void;

export const useQueryParamState = (
  name: string,
  keepInHistory = false
): [ParsedQuery[typeof name], Setter] => {
  const history = useHistory();
  const location = useLocation();

  const paramValue = React.useMemo(() => {
    const values = parse(location.search);
    return values[name];
  }, [location.search, name]);

  const setValue = React.useCallback(
    (value: string | null) => {
      const { location: currentLocation } = history;
      const { pathname, search, state } = currentLocation;
      const searchObject = parse(search);

      if (value === null) {
        delete searchObject[name];
      } else {
        searchObject[name] = value;
      }
      const nextSearch = stringify(searchObject);

      const nextLocationObject = {
        pathname,
        search: nextSearch,
        state,
      };
      if (keepInHistory) {
        history.push(nextLocationObject);
      } else {
        history.replace(nextLocationObject);
      }
    },
    [history, name, keepInHistory]
  );

  return [paramValue, setValue];
};
