import React from "react";
import styled from "styled-components";
import { START_DATE, useDatepicker } from "@datepicker-react/hooks";
import { Month } from "modules/ui/atoms/Month/Month";
import { NavButton } from "modules/ui/atoms/NavButton/NavButton";
import { breakpoints } from "modules/ui/variables";
import { useWindowResizeListener } from "modules/utils/hooks/useWindowResizeListener";
import DatepickerContext from "./DatePicker.context";
// import classes from "./DatePicker.module.css";

const Wrapper = styled(({ ...rest }) => <div {...rest} />)`
  position: relative;
  @media (max-width: 768px) {
    width: 100%;
  }
`;

const MonthsWrapper = styled(({ ...rest }) => <div {...rest} />)`
  width: 100%;
  display: flex;
  margin: 20px 0 0;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  min-height: 395px;
  overflow: hidden;
  background: #fff;
  @media (max-width: 768px) {
    width: 90%;
    min-height: 325px;
  }
`;

interface Props {
  onChange?: (value: Date) => void;
  value?: Date;
  testId?: string;
  minBookingDate?: Date;
}

export function Datepicker(props: Props) {
  const { value, onChange, testId, minBookingDate } = props;
  const { windowWidth } = useWindowResizeListener();
  const {
    firstDayOfWeek,
    activeMonths,
    isDateSelected,
    isDateHovered,
    isFirstOrLastSelectedDate,
    isDateBlocked,
    isDateFocused,
    onDateHover,
    onDateSelect,
    onDateFocus,
    goToPreviousMonths,
    goToNextMonths,
  } = useDatepicker({
    startDate: null,
    endDate: null,
    focusedInput: START_DATE,
    onDatesChange: handleDateChange,
    minBookingDate,
    numberOfMonths: windowWidth > breakpoints.lg ? 2 : 1,
  });

  function handleDateChange(data: any) {
    onChange && onChange(data.startDate);
  }

  return (
    <DatepickerContext.Provider
      value={
        {
          focusedDate: value,
          isDateFocused,
          isDateSelected,
          isDateHovered,
          isDateBlocked,
          isFirstOrLastSelectedDate,
          onDateSelect,
          onDateFocus,
          minBookingDate,
          onDateHover,
        } as any
      }
    >
      <Wrapper data-testid={testId}>
        <NavButton onClick={goToPreviousMonths} isPrevious />
        <NavButton onClick={goToNextMonths} />

        <MonthsWrapper>
          {activeMonths.map((month, index) => (
            <Month
              index={index}
              key={`${month.year}-${month.month}`}
              year={month.year}
              month={month.month}
              firstDayOfWeek={firstDayOfWeek}
            />
          ))}
        </MonthsWrapper>
      </Wrapper>
    </DatepickerContext.Provider>
  );
}
