import React from "react";
import styled from "styled-components";
// import classes from "./GuideList.module.css";

type Props = {
  list: Array<string>;
};

const ListWrapper = styled.ol`
  margin: 0;
  padding-left: 25px;
  padding-right: 10px;
`;

const ListItem = styled.li`
  margin-bottom: 15px;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.15px;
  color: ${(props) => props.theme.custom["emperor-color"]};
  white-space: pre-wrap;
  word-break: break-word;
`;

export const GuideList: React.FC<Props> = ({ list }) => {
  return (
    <ListWrapper>
      {list.map((item) => (
        <ListItem key={item}>{item}</ListItem>
      ))}
    </ListWrapper>
  );
};
