import React from "react";
import { AsYouType } from "libphonenumber-js";
import { useRifm } from "rifm";
import styled from "styled-components";
import { TextFieldProps } from "@material-ui/core/TextField";
import PublicIcon from "@material-ui/icons/Public";
import { InputAdornment, Typography } from "@material-ui/core";
import { TextField } from "modules/ui/atoms/TextField";
import countryFlags from "./flags.json";
// import classes from "./PhoneInput.module.css";

const InputWrapper = styled(({ ...rest }) => <InputAdornment {...rest} />)`
  min-width: 24px;
`;

export const normalizePhone = (value: string) =>
  (value.match(/\d+/g) || []).join("");

const formatPhone = (value: string) => {
  const digits = normalizePhone(value).substr(0, 18);
  return new AsYouType().input("+" + digits);
};

const getCountryCode = (value: string) => {
  const digits = normalizePhone(value).substr(0, 18);
  const type = new AsYouType();
  type.input("+" + digits);
  return type.country;
};

const toValue = (val: unknown): string => {
  if (typeof val === "string" || typeof val === "number") {
    return String(val);
  }
  return "";
};

type PhoneInputProps = Omit<TextFieldProps, "onChange"> & {
  onChange: (str: any) => any;
};

export const PhoneInput = (props: PhoneInputProps) => {
  const { value, onChange, InputProps, ...rest } = props;
  const correctValue = toValue(value);

  const rifm = useRifm({
    accept: /\d+/g,
    value: correctValue,
    onChange: onChange,
    format: formatPhone,
  });

  const countryCode = getCountryCode(correctValue);
  // @ts-ignore
  const flagIndex = countryFlags.findIndex((cf) => cf.code === countryCode);

  return (
    <TextField
      type="tel"
      onChange={rifm.onChange}
      value={rifm.value}
      {...(rest as any)}
      InputProps={{
        ...InputProps,
        startAdornment: (
          <InputWrapper position="start">
            {flagIndex > -1 ? (
              <Typography>{countryFlags[flagIndex].flag}</Typography>
            ) : (
              <PublicIcon width={24} />
            )}
          </InputWrapper>
        ),
      }}
    />
  );
};
