import React from "react";
import { useTranslation } from "react-i18next";
// import clsx from "clsx";
import copy from "copy-to-clipboard";
import styled from "styled-components";
import Fade from "@material-ui/core/Fade";
import { makeStyles } from "@material-ui/core";
import { Button } from "modules/ui";
import { breakpoints } from "modules/ui/variables";
// import classes from "./InvoiceDetails.module.css";

type Props = {
  account: string;
  amount: string | number;
  methodCode?: string | "";
};

const FormWrapper = styled.div`
  background: #ffffff;
  box-sizing: border-box;
  border-radius: 3px;
  padding: 15px;
  max-width: 650px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
  border-radius: 3px;
`;

const InputBlockWrapper = styled.div`
  display: flex;
  margin-bottom: 10px;
`;

const InputLabel = styled.p`
  margin: 0;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 27px;
  color: #828282;
`;

const Input = styled.p`
  margin: 0;
  font-weight: 600;
  font-size: 16px;
  letter-spacing: 1px;
  color: #4f4f4f;
`;

const ImportantNote = styled.p`
  margin: 0;
  font-style: normal;
  font-weight: bold;
  font-size: 11px;
  line-height: 14px;
  color: #f79e1b;
`;

const InputWrapper = styled.div`
  display: flex;
  box-sizing: border-box;
  align-items: center;
  min-height: 40px;
  width: 100%;
  background: #f2f2f2;
  border-radius: 3px 0px 0px 3px;
  padding: 6px 10px;
  &.inputWrapperWithAllBorders {
    border-radius: 3px;
  }
`;

const Copied = styled.p`
  margin: 0;
  position: absolute;
`;

const useClassesButton = makeStyles((theme) => ({
  copyButon: {
    position: "relative",
    minWidth: "140px",
    backgroundColor: theme.custom.secondary.main,
    borderRadius: "0px 3px 3px 0px",
    "&:hover": {
      backgroundColor: theme.custom.primary.main,
    },
    [`@media(max-width: ${breakpoints.xs}px)`]: {
      minWidth: "70px",
    },
  },
}));

export const InvoiceDetails: React.FC<Props> = ({
  account,
  amount,
  methodCode,
}) => {
  const { t } = useTranslation("paymentsGuide");
  const [copied, setCopied] = React.useState<boolean>(false);
  const muiClasses = useClassesButton();

  const onCopy = React.useCallback(() => {
    setCopied(true);
    copy(account);
  }, [account]);

  React.useEffect(() => {
    const changeCopied = setTimeout(() => {
      if (copied) {
        setCopied(false);
      }
    }, 3000);
    return () => {
      clearTimeout(changeCopied);
    };
  }, [copied]);

  return (
    <FormWrapper>
      <InputLabel>{t("accountLabel", { methodCode })}</InputLabel>
      <InputBlockWrapper>
        <InputWrapper>
          <Input>{account}</Input>
        </InputWrapper>
        <Button type="button" className={muiClasses.copyButon} onClick={onCopy}>
          <Fade in={!copied}>
            <Copied>{t("copyButton")}</Copied>
          </Fade>
          <Fade in={copied}>
            <Copied>{t("successfullCopied")}</Copied>
          </Fade>
        </Button>
      </InputBlockWrapper>
      <InputLabel>{t("amountLabel")}</InputLabel>
      <InputBlockWrapper>
        <InputWrapper className="inputWrapperWithAllBorders">
          <Input>{amount}</Input>
        </InputWrapper>
      </InputBlockWrapper>
      <ImportantNote>{t("importantNote")}</ImportantNote>
    </FormWrapper>
  );
};
