/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import styled from "styled-components";
import { useHistory, useLocation } from "react-router";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { shouldRenderFeature } from "@bussr-io/feature-toggling";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { SearchComponent } from "..";
import { getRentalServiceCategories } from "modules/api/methods";
import {
  ONE_WAY_BOOKING,
  RENTAL_CATALOG,
  RENTAL_ENQUIRY,
} from "modules/auth/constants";
import { useLanguage } from "modules/localization";
import Rental from "modules/rental/pages/rental";
import RentalService from "modules/rental/pages/RentalService";
import { featureToggle } from "modules/utils/featureToggle";
import { IsDefaultOperator } from "modules/utils/IsDefaultOperator";

const TabContainer = styled.div`
  border: 1px solid #c4c4c4;
  margin-top: 20px;
  border-radius: 3px;
  background: #fff;

  @media (max-width: ${(props) =>
      props.theme.custom.breakpoints["md-breakpoint"]}) {
    width: 97%;
    margin: 20px;
  }
`;

const StyledTab = styled(Tab)`
  text-transform: capitalize;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
`;

const StyledTabs = styled(Tabs)`
  // border-bottom: 1px solid #c4c4c4;
  width: 40%;
  @media (max-width: ${(props) =>
      props.theme.custom.breakpoints["lg-breakpoint"]}) {
    width: 100%;
  }
`;

const TabSection = styled.div`
  border-bottom: 1px solid ${(props) => props.theme.custom["silver-color"]};
`;

function TabPanel(props: any) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`home_tab_${index}`}
      {...other}
    >
      {children}
    </div>
  );
}

type Props = {
  busSearchParams?: any;
  subscription?: any;
};

const HomeTabs = (props: Props) => {
  const { t } = useTranslation("search");
  const [tabIndex, setTabIndex] = React.useState(0);
  const [services, setServices] = React.useState([]);
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const language = useLanguage();
  const rental = useSelector((state: RootStateOrAny) => state.rental);
  const [showEnquiry, setShowEnquiry] = React.useState(false);
  const [showRental, setShowRental] = React.useState(false);
  const [showBusTab, setShowBusTab] = React.useState(true);

  const firebase_app = useSelector(
    (state: any) => state.remoteConfig.firebaseApp,
  );
  const criteria = {
    country: useSelector((state: any) => state.remoteConfig.countryName),
    operator: useSelector((state: any) => state.remoteConfig.operatorId),
  };

  const featureName1 = RENTAL_CATALOG;
  const featureName2 = RENTAL_ENQUIRY;

  React.useEffect(() => {
    async function getResult() {
      const toggleRental = await shouldRenderFeature(
        firebase_app,
        featureName1,
        criteria,
      );
      const toggleEnquiry = await shouldRenderFeature(
        firebase_app,
        featureName2,
        criteria,
      );

      const toggleBusTab = await featureToggle(
        ONE_WAY_BOOKING,
        firebase_app,
        criteria,
      );

      setShowBusTab(toggleBusTab);
      if (toggleRental) {
        if (rental.categories.length !== 0) {
          setShowRental(toggleRental);
        }
      }

      if (toggleEnquiry) {
        setShowEnquiry(toggleEnquiry);
      }
    }
    getResult();
  }, [criteria, firebase_app]);

  React.useEffect(() => {
    (async () => {
      // const data = await getRentalServices();
      // setServices(data);
      try {
        const response = await getRentalServiceCategories();
        setServices(response);
        dispatch({
          type: "RENTAL_SERVICE_CATEGORIES",
          payload: response,
        });
      } catch (err) {
        console.log(err);
      }
    })();

    renderTabs();
  }, []);

  const handleTabChange = (e: any, newValue: number) => {
    setTabIndex(newValue);
  };

  const renderTabs = () => {
    if (location.search === "?tab=1") {
      setTabIndex(1);
    } else if (location.search === "?tab=2") {
      setTabIndex(2);
    } else {
      setTabIndex(0);
    }
  };

  // console.log(props)

  return (
    <TabContainer
      style={{
        opacity: props.subscription ? 1 : 0.4,
        pointerEvents: props.subscription ? "visible" : "none",
      }}
    >
      <TabSection>
        <StyledTabs
          value={tabIndex}
          onChange={handleTabChange}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
        >
          {showBusTab ? (
            <StyledTab
              tabIndex={0}
              label={t("bus")}
              onClick={() => {
                const url = `/${language}/`;
                history.push(url);
              }}
            />
          ) : null}

          {/* {services.length && <StyledTab label={t("rental")} />} */}
          {showRental && (
            <StyledTab
              tabIndex={showBusTab ? 1 : 0}
              label={t("rental")}
              onClick={() => {
                const index = showBusTab ? "?tab=1" : "/";
                const url = `/${language}${index}`;
                history.push(url);
              }}
            />
          )}
          {showEnquiry && !IsDefaultOperator() && (
            <StyledTab
              tabIndex={showRental ? (showBusTab ? 2 : 1) : 1}
              label={t("contact")}
              onClick={() => {
                const index = showRental ? (showBusTab ? "2" : "1") : "1";
                const url = `/${language}?tab=${index}`;
                history.push(url);
              }}
            />
          )}
        </StyledTabs>
      </TabSection>
      {showBusTab ? (
        <TabPanel value={tabIndex} index={0}>
          <SearchComponent {...props.busSearchParams} />
        </TabPanel>
      ) : null}
      {/* {services.length ? (
        <TabPanel value={tabIndex} index={1}>
          <Rental showTitle={false} serviceData={services} />
        </TabPanel>
      ) : null} */}
      {showRental && (
        <TabPanel value={tabIndex} index={showBusTab ? 1 : 0}>
          <RentalService showTitle={false} showNote serviceData={services} />
        </TabPanel>
      )}
      {showEnquiry && !IsDefaultOperator() && (
        <TabPanel
          value={tabIndex}
          index={showRental ? (showBusTab ? 2 : 1) : 1}
        >
          <Rental showTitle={false} showNote serviceData={services} />
        </TabPanel>
      )}
    </TabContainer>
  );
};

export default HomeTabs;
