import { axios } from "../client";

type AuthResponse = {
  token: string;
  user: {};
};

export function getToken(firebaseToken: string) {
  return axios
    .post<AuthResponse>("/users/firebase-auth", { firebaseToken })
    .then((res) => {
      return res.data;
    });
}
