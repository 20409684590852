import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import { Dialog } from "@material-ui/core";
import { CircularProgress } from "@material-ui/core";
import { cancelReservation } from "modules/api/methods";
import { TicketsResponse } from "modules/api";
import { Price, Ride, Ticket as TicketDetails } from "modules/entities";
import { sumPrices } from "modules/utils/price";
import Close from "../organisms/cross.svg";
import { getOperatorCode } from "../../../modules/utils/getOperatorCode";
import GoogleAnalyticsService from "../../../modules/utils/GoogleAnalyticsService";
import { LOG_EVENTS } from "../../../modules/utils/LogEvents";

const DialogContainer = styled(Dialog)`
  position: relative;
  .MuiDialog-paper {
    text-align: center;
    padding: 30px 0px 40px;
    width: 100%;
  }
  .button-wrapper {
    display: flex;
    justify-content: space-evenly;
    margin-top: 30px;
    button {
      width: 100%;
      height: 60px;
      font-size: 16px;
      border-radius: 4px;
      border: 1px solid #ff5757;
      color: #ff5757;
      background: ${(props) => props.theme.custom["white-color"]};
      cursor: pointer;
    }
  }
  @media (max-width: ${(props) =>
    props.theme.custom.breakpoints["xs-breakpoint"]}) {
    margin: 0 15px;
    .MuiDialog-paper {
      width: 100%;
      max-width: 100%;
      margin: 0;
      padding: 50px 0px 30px;
      .button-wrapper {
        justify-content: center;
        button {
          max-width: inherit;
        }
      }
    }
  }
`;

const Wrapper = styled.div`
  padding: 0 15px;
`;

const Title = styled.h3`
  font-weight: 500;
  font-size: 22px;
  line-height: 18px;
  text-align: center;
  color: #4f4f4f;
  margin: 10px 0 15px;
`;
const Ticket = styled.h6`
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  text-transform: uppercase;
  color: #4f4f4f;
  margin: 0;
  opacity: 0.6;
`;
const RefundTitle = styled.p`
  font-weight: bold;
  font-size: 19px;
  line-height: 16px;
  color: #4f4f4f;
  text-align: left;
  margin: 40px 0 20px;
`;

const Divider = styled.hr`
  background: #dee0e1;
  height: 1px;
  border: none;
`;

const RefundWrapper = styled.div`
  .refundInner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 40px;
    &.points {
      height: 35px;
    }
    .title {
      font-weight: normal;
      font-size: 16px;
      line-height: 16px;
      color: #353f47;
    }
    .refund-status {
      font-weight: bold;
      font-size: 10px;
      line-height: 16px;
      text-align: center;
      color: #979797;
      border: 1px solid #979797;
      box-sizing: border-box;
      border-radius: 4px;
      padding: 2px 6px;
    }
    .amount {
      font-weight: bold;
      font-size: 16px;
      line-height: 16px;
      text-align: right;
      color: #353f47;
    }
    .total-title {
      font-weight: bold;
      font-size: 15px;
      line-height: 16px;
      text-transform: uppercase;
      color: #353f47;
    }
    .total-amount {
      font-weight: bold;
      font-size: 16px;
      line-height: 16px;
      text-align: right;
      color: #eb5757;
    }
    .points,
    .points-amount {
      font-weight: normal;
      font-size: 14px;
      line-height: 16px;
      color: rgba(53, 63, 71, 0.5);
      text-align: left;
    }
  }
`;

const Info = styled.p`
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  color: #9a9fa3;
  margin: 15px 0 0;
`;

const LoaderWrapper = styled.div`
  display: flex;
  position: absolute;
  top: 0;
  flex-direction: column;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9;
  align-items: center;
  justify-content: center;
  background: rgba(255, 255, 255, 0.8);
`;
const CloseIcon = styled.img`
  position: absolute;
  right: 30px;
  top: 20px;
  cursor: pointer;
`;
const Disclaimer = styled.p`
  background-color: #ffefd1;
  padding: 8px 10px;
  margin-top: 16px;
  border-radius: 4px;
  color: #8f6512;
  font-size: 14px;
  line-height: 18px;
  display: flex;
  align-items: center;
`;

const DiscountSec = styled.div`
  display: flex;
  justify-content: space-between;

  .discountCode {
    font-weight: 500;
    font-size: 16px;
    line-height: 12px;
    color: #43a047;
  }

  .discountValue {
    font-weight: 700;
    font-size: 16px;
    line-height: 16px;
    color: #43a047;
  }
`;

type CancelReservationProps = {
  open: boolean;
  toggleModal: () => void;
  ticket: TicketDetails;
  tickets?: Array<TicketDetails>;
  ride: Ride;
  setCancellation?: (value: boolean) => void;
  setCancelId?: (value: any) => void;
  ticketInfo: any;
};

export const CancelReservationConfirmation: React.FC<CancelReservationProps> =
  ({
    open,
    toggleModal,
    tickets,
    ticket,
    ride,
    setCancellation,
    setCancelId,
    ticketInfo,
  }) => {
    React.useEffect(() => {
      GoogleAnalyticsService.createEvent(
        LOG_EVENTS.LOG_EVENTS_NAME.SCREEN_VIEW,
        {
          firebase_screen: LOG_EVENTS.SCREEN_NAME.CANCEL_TRIP_SCREEN,
          firebase_screen_class: LOG_EVENTS.SCREEN_NAME.CANCEL_TRIP_SCREEN,
          operator_code: getOperatorCode(),
          source: "web",
        }
      );
    }, []);

    const { t } = useTranslation("ticket");
    const basePrice =
      tickets && sumPrices(tickets.map((ticket) => ticket.basePrice));
    const [pending, setPending] = React.useState(false);
    const ticketDetail = ticket;
    const refundRule = ticketDetail?.ride?.refundRule;
    const finalPrice =
      tickets && sumPrices(tickets.map((ticket) => ticket.price));
    // let basePrice = ride?.price;
    const refundStatus = ride.refundRule.type;
    const [discountValue, setDiscountPrice] = React.useState<any>(null);
    const [discountedValue, setDiscountedValue] = React.useState<any>(null);
    const [discountCode] = React.useState(ticketInfo?.ticketGroup?.campaign?.coupon);

    React.useEffect(() => {
      if (ticketInfo?.ticketGroup?.campaign) {
        const discount = ticketInfo?.ticketGroup?.basePrice?.value - ticketInfo?.ticketGroup?.price?.value;
        const discountedPrice = {
          currency: basePrice.currency,
          value: discount,
        };
        setDiscountPrice(discountedPrice);
        setDiscountedValue(ticketInfo?.ticketGroup?.price?.value);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    let refundableAmount: Price = { value: 0, currency: "IDR" };
    let deductions: Price = { value: 0, currency: "IDR" };
    if (refundRule?.type === "non_refundable" && basePrice) {
      refundableAmount.value = 0;
      deductions.value = discountedValue ? discountedValue - refundableAmount.value : basePrice.value - refundableAmount.value;
    }

    if (refundRule?.type === "partial_refundable" && basePrice && finalPrice) {
      refundableAmount.value = refundRule?.percent
        ? ((discountedValue ? discountedValue : finalPrice.value) * refundRule?.percent) / 100
        : 0;
      deductions.value = discountedValue ? discountedValue - refundableAmount.value : basePrice.value - refundableAmount.value;
    }

    if (refundRule?.type === "fully_refundable" && basePrice && finalPrice) {
      refundableAmount.value = discountedValue ? discountedValue : finalPrice.value;
      deductions.value = discountedValue ? discountedValue - refundableAmount.value : basePrice.value - refundableAmount.value;
    }

    const handleCancel = React.useCallback(() => {
      setPending(true);
      cancelReservation(ticket?.id)
        .then((tkt: TicketsResponse) => {
          toggleModal();
          setCancellation && setCancellation(true);
          setCancelId && setCancelId(tkt.ticket);
        })
        .catch(function (err) {
          console.log(err.message);
        });
    }, [setCancellation, ticket, toggleModal, setCancelId]);

    return (
      <DialogContainer
        // fullScreen={fullScreen}
        open={open}
        onClose={toggleModal}
        scroll="body"
      >
        <Wrapper>
          <CloseIcon src={Close} alt="" onClick={toggleModal} />
          <Title>{t("cancelTicket")}</Title>
          <Ticket>
            {t("ticketNumber")} {ticket.numCode || ticket?.bookCode}
          </Ticket>
          <RefundTitle>{t("refundCalculation")}</RefundTitle>
          <Divider />
          <RefundWrapper>
            <div className="refundInner">
              <h3 className="title">{t("amountPaid")}</h3>
              <p className="refund-status">{refundStatus && t(refundStatus)}</p>
              <h4 className="amount">
                {t("price", {
                  price: basePrice,
                })}
              </h4>
            </div>
            {discountValue ? (
              <DiscountSec>
                <p className="discountCode">{`‘${discountCode}’ Offer Discount`}</p>
                <p className="discountValue">
                  - {t("price", {
                    price: discountValue,
                  })}
                </p>
              </DiscountSec>
            ) : null}
            <div className="refundInner">
              <h3 className="title">{t("deductions")}</h3>
              <h4 className="amount">
                {t("price", {
                  price: deductions,
                })}
              </h4>
            </div>
            <Divider />
            <div className="refundInner">
              <h3 className="total-title">{t("totalRefundableAmount")}</h3>
              <h4 className="total-amount">
                {t("price", {
                  price: refundableAmount,
                })}
              </h4>
            </div>
            <div className="refundInner points">
              <h3 className="points">{t("refundAmount")}</h3>
              <h4 className="points-amount">
                {t("price", {
                  price: refundableAmount,
                })}
              </h4>
            </div>
            {/* <div className="refundInner points">
              <h3 className="points">
                Loyalty points to be refunded
                <br />
                1pts = Rp1
              </h3>
              <h4 className="points-amount">10 pts</h4>
            </div> */}
          </RefundWrapper>

          <div className="button-wrapper">
            <button onClick={handleCancel}>{t("confirmCancellation")}</button>
            {/* <button onClick={handleLogout}>{t("yes")}</button> */}
            {/* <button className="cancel" onClick={toggleModal}> */}
            {/* {t("no")} */}
            {/* </button> */}
          </div>
          {ride.refundRule.type !== "non_refundable" && (
            <>
              {ticket.paymentMethod !== "xendit_va" && (
                <Info>
                  {t("confirmTextOne")} <br /> {t("confirmTextTwo")}
                </Info>
              )}
              {ticket.paymentMethod === "xendit_va" && (
                <Disclaimer>
                  <span>
                    <ErrorOutlineIcon style={{ color: "#8F6512" }} />
                  </span>
                  <span style={{ flex: 1, marginLeft: 10 }}>
                    {t("disclaimer")}
                  </span>
                </Disclaimer>
              )}
            </>
          )}
        </Wrapper>
        {pending && (
          <LoaderWrapper>
            <CircularProgress color="secondary" />
          </LoaderWrapper>
        )}
      </DialogContainer>
    );
  };
