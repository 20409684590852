export interface FeatureToggleState {
  firebaseApp: string;
  countryName: string;
  operatorId: String;
  operatorCode:string;
}

export type InitializeApp = {
  type: "INITIALIZE_APP";
  payload: any;
};

export type UpdateCountry = {
  type: "UPDATE_COUNTRY";
  payload: any;
};

export type UpdateOperator = {
  type: "UPDATE_OPERATOR";
  payload: any;
};

export type SetOperatorCode = {
  type: "SET_OPERATOR_CODE";
  payload: any;
};

const initialState = {
  firebaseApp: "",
  countryName: "",
  operatorId: "",
  operatorCode:'',
};

// Use the initialState as a default value
export const RemoteConfigReducer = (
  state: FeatureToggleState = initialState,
  action: InitializeApp | UpdateCountry | UpdateOperator |SetOperatorCode 
) => {
  switch (action.type) {
    case "INITIALIZE_APP":
      return {
        ...state,
        firebaseApp: action.payload,
      };
    case "UPDATE_COUNTRY":
      return {
        ...state,
        countryName: action.payload,
      };
    case "UPDATE_OPERATOR":
      return {
        ...state,
        operatorId: action.payload,
      };

      case "SET_OPERATOR_CODE":
        localStorage.setItem('operatorCode', action.payload);
        return {
          ...state,
          operatorCode: action.payload,
        };  
    default:
      return state; 
  }
};
