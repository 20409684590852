/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import useSWR from "swr";
import { useSelector, RootStateOrAny } from "react-redux";
import { PaymentMethod } from "modules/entities";
import { ENDPOINTS } from "../endpoints";
// import { isPaymentMethodAllowed } from "../utils/isPaymentMethodAllowed";

export const usePaymentMethods = () => {
  const region = useSelector((state: RootStateOrAny) => state.region);
  const { data, error, isValidating } = useSWR<Array<PaymentMethod>>(
    ENDPOINTS.PAYMENT_METHODS
  );

  const paymentMethodFilter = region?.paymentMethodsToDisplay;

  const methods = React.useMemo(
    () =>
      Array.isArray(data)
        ? data.filter((method) => paymentMethodFilter.includes(method.code))
        : data,
    [data]
  );
  return { data: methods, error, isValidating };
};
