import React from "react";
// import clsx from "clsx";
// import classes from "./NavButton.module.css";
import styled from "styled-components";
import PrevMonthIcon from "./PrevMonthIcon.svg";
import NextMonthIcon from "./NextMonthIcon.svg";

const NavButtonWrapper = styled.button`
  position: absolute;
  top: 40%;
  width: 34px;
  height: 34px;
  background-color: ${(props) => props.theme.custom["alto-darker-color"]};
  border: none;
  border-radius: 50%;
  transition: background-color 0.2s ease;
  &:hover,
  &:focus {
    background-color: ${(props) => props.theme.custom.secondary.main};
    cursor: pointer;
  }
  &.isPrevious {
    left: 16px;
    background-image: url(${PrevMonthIcon});
    background-position: 13px 10px;
    background-repeat: no-repeat;
    background-size: 8px 14px;
    @media (max-width: ${(props) =>
        props.theme.custom.breakpoints["lg-breakpoint"]}) {
      left: 10px;
      top: 5%;
    }
  }
  &.next {
    right: 16px;
    background-image: url(${NextMonthIcon});
    background-position: 14px 9px;
    background-repeat: no-repeat;
    background-size: 8px 14px;
    @media (max-width: ${(props) =>
        props.theme.custom.breakpoints["lg-breakpoint"]}) {
      top: 5%;
      right: 10px;
    }
    @media (max-width: ${(props) =>
        props.theme.custom.breakpoints["md-breakpoint"]}) {
      top: 5%;
      right: 12%;
    }
  }
`;

export function NavButton({ children, onClick, isPrevious }: any) {
  const onButtonClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    onClick();
  };
  return (
    <NavButtonWrapper
      className={isPrevious ? "isPrevious" : "next"}
      onClick={onButtonClick}
    >
      {children}
    </NavButtonWrapper>
  );
}
