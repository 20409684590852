import React from "react";
import styled from "styled-components";
import VerificationIcon from "../icons/verification.svg";

const SvgIconContainer = styled.div`
  @media (max-width: ${(props) =>
      props.theme.custom.breakpoints["xs-breakpoint"]}) {
  }
`;

export const VerificationSuccessIcon = () => {
  return (
    <SvgIconContainer>
      <img src={VerificationIcon} alt="" />
    </SvgIconContainer>
  );
};
