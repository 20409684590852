import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import CheckIcon from "@material-ui/icons/Check";
import { useTickets } from "modules/api";
import { BookingButtons } from "../../molecules/BookingButtons/BookingButtons";

type BookingSuccessProps = {
  ticketId: string;
  bookingNumber: string;
  userMail: string;
};

interface ParamTypes {
  operatorCode: string;
}

const SuccessContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 140px 0 30px 0;
  color: ${(props) => props.theme.custom["emperor-color"]};
  @media (max-width: ${(props) =>
      props.theme.custom.breakpoints["xs-breakpoint"]}) {
    margin: 40px 0;
  }
`;

const IconWrapper = styled.div`
  border-radius: 50%;
  padding: 19px 16px 17px 16px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
  background-color: ${(props) => props.theme.custom.primary.main};
`;

const SuccessText = styled.p`
  font-style: normal;
  margin: 40px 0 9px 0;
  font-weight: 500;
  font-size: 20px;
  line-height: 23px;
  @media (max-width: ${(props) =>
      props.theme.custom.breakpoints["xs-breakpoint"]}) {
    font-size: 18px;
  }
`;

const ThankText = styled.p`
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
  margin: 0 0 18px 0;
  @media (max-width: ${(props) =>
      props.theme.custom.breakpoints["xs-breakpoint"]}) {
    font-size: 14px;
    padding: 0 30px;
    text-align: center;
  }
`;

const ConfirmationText = styled.p`
  width: 90%;
  font-size: 14px;
  line-height: 18px;
  margin: 20px 0px;
  padding: 0px 10px;
  text-align: center;
  box-sizing: border-box;
  @media (max-width: ${(props) =>
      props.theme.custom.breakpoints["xs-breakpoint"]}) {
    width: 80%;
    font-size: 14px;
  }
`;

const BoldValue = styled.span`
  font-weight: bold;
  // padding: 5px;
`;

export const BookingSuccess: React.FC<BookingSuccessProps> = ({
  userMail,
  ticketId,
  bookingNumber,
}) => {
  const { t } = useTranslation("bookingSuccess");
  const operatorCode = window.location.pathname.split("/")[1];
  const { data: ticketData } = useTickets(ticketId);

  return (
    <SuccessContainer>
      <IconWrapper>
        <CheckIcon style={{ width: "47px", height: "39px", color: "#fff" }} />
      </IconWrapper>
      <SuccessText>{t("success")}</SuccessText>
      <ThankText>
        {t("thanks")}{" "}
        <span style={{ textTransform: "capitalize", fontWeight: 700 }}>
          {operatorCode}
        </span>
      </ThankText>
      <ConfirmationText>
        {t("confirmationStart")}
        {/* <BoldValue>{firstTicket?.ticket?.accessCode} </BoldValue>
        {secondTicket?.ticket && (
          <BoldValue>, {secondTicket?.ticket?.accessCode}</BoldValue>
        )} */}
        &nbsp;
        {/* {bookingNumber ? <BoldValue>({bookingNumber})</BoldValue> : " "} */}
        {t("confirmationMiddle")}
        &nbsp;
        <BoldValue>{userMail}</BoldValue>&nbsp;
        {t("confirmationEnd")}
      </ConfirmationText>
      {ticketData?.tickets?.map((ticket) => (
        <BookingButtons key={ticket?.id} ticketId={ticket.id} ticket={ticket} />
      ))}
    </SuccessContainer>
  );
};
