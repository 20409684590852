import React from "react";
import { useTranslation } from "react-i18next";
// import clsx from "clsx";
import styled from "styled-components";
import { ReactComponent as BoardingIcon } from "./boarding-inf-icon.svg";
import { ReactComponent as TicketIcon } from "./bussr-ticket-icon.svg";
// import classes from "./BookingInstructions.module.css";

const InstructionsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  box-sizing: border-box;
  max-width: 1024px;
  padding: 36px 40px;
  @media (min-width: ${(props) =>
      props.theme.custom.breakpoints["xs-breakpoint"]}) and (max-width: ${(
      props
    ) => props.theme.custom.breakpoints["lg-breakpoint"]}) {
    padding: 20px 30px;
  }
  @media (max-width: ${(props) =>
      props.theme.custom.breakpoints["xs-breakpoint"]}) {
    flex-direction: column;
    border-top: none;
    padding: 0;
    padding-bottom: 40px;
  }
`;

const InstructionsBoxWrapper = styled.div`
  display: flex;
  &.bottomBorder {
    border-bottom: 1px solid ${(props) => props.theme.custom["alto-color"]};
    margin-bottom: 20px;
  }
  .boxContentWrrapper {
    padding: 0 10px 0 10px;
  }
  .boxTitleWrapper {
    display: flex;
    min-height: 40px;
    align-items: center;
    margin-bottom: 30px;
  }
  .boxTitle {
    margin: 0;
    font-weight: 500;
    font-size: 18px;
    line-height: 18px;
    color: ${(props) => props.theme.custom["emperor-color"]};
  }
  .paddingIcon {
    padding-top: 2px;
  }
  .list {
    padding: 0 0 0 18px;
    margin: 0px;
    max-width: 400px;
    li {
      font-weight: normal;
      font-size: 16px;
      line-height: 18px;
      color: ${(props) => props.theme.custom["emperor-color"]};
      margin-bottom: 25px;
      padding-left: 15px;
    }
  }
  @media (min-width: ${(props) =>
      props.theme.custom.breakpoints["xs-breakpoint"]}) and (max-width: ${(
      props
    ) => props.theme.custom.breakpoints["lg-breakpoint"]}) {
    padding: 21px 0;
    margin: 0 20px;
  }
  @media (max-width: ${(props) =>
      props.theme.custom.breakpoints["xs-breakpoint"]}) {
    border-top: 1px solid ${(props) => props.theme.custom["alto-color"]};
    padding: 21px 0;
    margin: 0 20px;

    .boxTitleWrapper {
      margin-bottom: 20px;
    }
    .boxTitle {
      font-size: 16px;
    }
    .list {
      li {
        font-size: 14px;
        margin-bottom: 20px;
      }
    }
  }
`;

export const BookingInstructions: React.FC = () => {
  const { t } = useTranslation("bookingInstructions");
  const operatorCode = window.location.pathname.split("/")[1];

  return (
    <InstructionsContainer>
      <InstructionsBoxWrapper>
        <div>
          <BoardingIcon />
        </div>
        <div className="boxContentWrrapper">
          <div className="boxTitleWrapper">
            <p className="boxTitle">{t("boardingHeader")}</p>
          </div>
          <ol className="list">
            <li>{t("boardingLi1")}</li>
            <li>{t("boardingLi2")}</li>
            <li>{t("boardingLi3")}</li>
          </ol>
        </div>
      </InstructionsBoxWrapper>
      <InstructionsBoxWrapper className="bottomBorder">
        <div className="paddingIcon">
          <TicketIcon />
        </div>
        <div className="boxContentWrrapper">
          <div className="boxTitleWrapper">
            <p className="boxTitle">
              <span style={{ textTransform: "capitalize" }}>
                {operatorCode}
              </span>{" "}
              {t("ticketHeader")}{" "}
            </p>
          </div>
          <ol className="list">
            <li>{t("ticketLi1")}</li>
            <li>{t("ticketLi2")}</li>
            <li>{t("ticketLi3")}</li>
          </ol>
        </div>
      </InstructionsBoxWrapper>
    </InstructionsContainer>
  );
};
