import React from "react";
import { useLocation, useRouteMatch, useHistory } from "react-router-dom";
import styled from "styled-components";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import { Popper } from "@material-ui/core";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import LanguageIcon from "@material-ui/icons/Language";
import { routes } from "modules/routing";
import { useLanguage } from "modules/localization";
import { Button } from "modules/ui";
import { TEST_ID } from "modules/ui/test-id";
// import classes from "./LanguageSelect.module.css";

const languages = ["en", "id"];

const InputWrapper = styled(({ ...rest }) => <div {...rest} />)`
  display: flex;
  min-height: 40px;
  min-width: 85px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const SelectWrapper = styled(({ ...rest }) => <div {...rest} />)`
  min-width: 60px;
  margin-top: 10px;
  background: ${(props) => props.theme.custom["white-color"]};
  border: 1px solid ${(props) => props.theme.custom["silver-color"]};
  box-sizing: border-box;
  box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.05);
  border-radius: 3px;
`;

const Select = styled(({ ...rest }) => <div {...rest} />)`
  padding: 10px;
  font-family: ${(props) => props.theme.custom["roboto-font"]};
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: ${(props) => props.theme.custom["emperor-color"]};
  &.selectActive {
    background: ${(props) => props.theme.custom["alabaster-color"]};
  }
`;

const PopperWrapper = styled(({ ...rest }) => <Popper {...rest} />)`
  z-index: 99;
`;

export const LanguageSelect = () => {
  const language = useLanguage();
  const { search, state } = useLocation();
  const history = useHistory();
  const match = useRouteMatch<{
    language?: string;
    path?: string | undefined;
  }>(`${routes.languagePrefix.pattern}/:path(.*)`);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [open, setOpen] = React.useState<boolean>(false);

  const selectedLanguage = languages.find((value) => value === language);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
    setOpen((prev) => !prev);
  };

  const handleClickAway = () => {
    setOpen(false);
    setAnchorEl(null);
  };

  const handleClickSelect = (value: string) => {
    if (value === selectedLanguage) {
      handleClickAway();
      return;
    } else {
      const currentPath = match?.params?.path || "";
      const next = `/${value}/${currentPath}`;

      handleClickAway();
      history.push({
        search,
        state,
        pathname: next,
      });
    }
  };
  const id = open ? "language-select" : undefined;

  return (
    <div>
      <InputWrapper>
        <ClickAwayListener onClickAway={handleClickAway}>
          <div style={{ color: "white" }}>
            <Button
              variant="text"
              color="inherit"
              onClick={handleClick}
              data-testid={TEST_ID.LANGUAGE_SELECT}
              startIcon={<LanguageIcon fontSize="small" />}
              endIcon={<KeyboardArrowDownIcon />}
            >
              {selectedLanguage?.toUpperCase() ?? ""}
            </Button>
            <PopperWrapper
              id={id}
              open={open}
              anchorEl={anchorEl}
              disablePortal
            >
              <SelectWrapper data-testid={TEST_ID.LANGUAGE_SELECT_DROPDOWN}>
                {languages.map((value) => (
                  <Select
                    key={value}
                    onClick={handleClickSelect.bind(null, value)}
                    className={language === value ? "selectActive" : ""}
                  >
                    {value.toUpperCase()}
                  </Select>
                ))}
              </SelectWrapper>
            </PopperWrapper>
          </div>
        </ClickAwayListener>
      </InputWrapper>
    </div>
  );
};
