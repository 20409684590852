export const breakpoints = {
  xs: 575,
  md: 768,
  lg: 1279,
};

export const BookingFormFieldsName = {
  firstName: "firstName",
  lastName: "lastName",
  email: "email",
  phone: "phone",
};

export const device = {
  mobile: `(min-width: ${breakpoints.xs})`,
};
