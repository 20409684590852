import firebase from "firebase/app";
import "firebase/auth";
import "firebase/analytics";
import { initializeRemoteConfig } from "@bussr-io/feature-toggling";
import { INITIALIZE_APP } from "./constants";
import SetParameter from "./store-middleware";

const initialize = async () => {
  const config = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
    measurementId: "G-J03HR3RB7J",
  };

  firebase.initializeApp(config);
  firebase.analytics();
};

initialize();

export const auth = firebase.auth();

export { firebase };

export const initializefirebaseApp = () => {
  const initializeApp = async () => {
    try {
      const configData = {
        apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
        authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
        databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
        projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
        storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
        messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
        appId: process.env.REACT_APP_FIREBASE_APP_ID,
        measurementId: "G-J03HR3RB7J",
      };
      const firebaseApp = await initializeRemoteConfig(configData, 3);
      return firebaseApp;
    } catch (error) {
      console.log(error);
    }
  };
  initializeApp()
    .then(async (appObj) => {
      try {
        SetParameter(INITIALIZE_APP, appObj);
      } catch (error) {
        console.log(error);
      }
    })
    .catch((error) => {
      console.log("couldn't connect to firebase");
      console.log(error);
    });
};

if (process.env.NODE_ENV !== "production") {
  // @ts-ignore
  window.auth = auth;
  // @ts-ignore
  window.firebase = firebase;
}
