import useSWR, { ConfigInterface } from "swr";
import { AxiosError } from "axios";
import { Ticket, TicketGroup } from "modules/entities";
import { ENDPOINTS } from "../endpoints";

export const useTicket = (
  id: string | null,
  config?: ConfigInterface<{ ticket: Ticket }, AxiosError["response"]>
) => {
  const { data, error, isValidating } = useSWR<
    { ticket: Ticket },
    AxiosError["response"]
  >(id ? `${ENDPOINTS.TICKETS}/${id}` : null, config);
  return { data: data ? data.ticket : undefined, error, isValidating };
};

export type TicketsResponse = {
  tickets: Array<Ticket>;
  // @deprecated use ticketGroup or tickets properties.
  ticket: Ticket;
  ticketGroup: TicketGroup;
};

export const useTickets = (
  id: string | null,
  config?: ConfigInterface<TicketsResponse, AxiosError["response"]>
) => {
  const { data, error, isValidating } = useSWR<
    TicketsResponse,
    AxiosError["response"]
  >(id ? `${ENDPOINTS.TICKETS}/${id}` : null, config);
  return { data, error, isValidating };
};
