import React from "react";
import { addDays, format, isBefore, startOfToday } from "date-fns";
import { enUS, id } from "date-fns/locale";
import styled from "styled-components";
import { withStyles } from "@material-ui/core";
import { useLanguage } from "modules/localization";
import { Button, TEST_ID } from "modules/ui";
import { breakpoints } from "modules/ui/variables";
import { useWindowResizeListener } from "modules/utils/hooks/useWindowResizeListener";
import { DateLink } from "modules/search-result-list/molecules/DateLink/DateLink";
import { ReactComponent as ArrowBack } from "../../icons/arrow-back.svg";
import { ReactComponent as ArrowForward } from "../../icons/arrow-forward.svg";
// import classes from "./DateCarousel.module.css";

const DateButtonWrapper = styled(({ ...rest }) => <DateButton {...rest} />)`
  @media (max-width: ${(props) =>
      props.theme.custom.breakpoints["xs-breakpoint"]}) {
    font-size: 12px !important;
  }
`;

const ButtonWrapper = styled.div`
  width: 135px;
  min-width: 94px;
  margin-right: 2.5%;
  @media (max-width: ${(props) =>
      props.theme.custom.breakpoints["md-breakpoint"]}) {
    width: 100%;
    min-width: auto;
  }
`;

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;

  & a button span.MuiButton-label {
    font-weight: 400;
  }

  @media (max-width: ${(props) =>
      props.theme.custom.breakpoints["xs-breakpoint"]}) {
    justify-content: center;
  }
`;

const ArrowButtonWrapper = styled.div`
  min-width: 40px;
  margin-right: 2.5%;
`;

interface CarouselProps {
  date: Date;
  minDate?: Date | string;
  minRightDate?: Date | string;
  onSearch?: () => void;
}

const dateFormat = "iiii, d LLL";

const ArrowButton = withStyles({
  root: {
    minWidth: "40px",
    height: "40px",
    padding: "0px",
    border: "1px solid #C4C4C4",
  },
})(Button);

const DateButton = withStyles((theme) => ({
  outlinedPrimary: {
    fontWeight: "bold",
  },
  root: {
    height: "40px",
    padding: "8px",
  },
  contained: {
    backgroundColor: theme.custom.secondary.main,
    "&:hover": {
      backgroundColor: theme.custom.primary.main,
      color: theme.custom["white-color"],
    },
  },
}))(Button);

export const DateCarousel: React.FC<CarouselProps> = ({
  date,
  minDate,
  minRightDate,
  onSearch,
}) => {
  const language = useLanguage();
  const currentLocale = language === "en" ? enUS : id;
  const leftDate = addDays(date, -1);
  const rightDate = addDays(date, 1);

  const { windowWidth } = useWindowResizeListener();
  const showLeftDate = windowWidth > breakpoints.xs;
  const showRightArrow =
    minDate && minDate instanceof Date
      ? isBefore(date, addDays(minDate, -1))
        ? true
        : false
      : true;
  const showRightDateLink =
    minDate && minDate instanceof Date
      ? isBefore(date, minDate)
        ? true
        : false
      : true;
  const showLeftArrow =
    minRightDate && minRightDate instanceof Date
      ? isBefore(minRightDate, addDays(date, -1))
        ? true
        : false
      : true;
  const showLeftDateLink =
    minRightDate && minRightDate instanceof Date
      ? isBefore(minRightDate, date)
        ? true
        : false
      : true;

  const handleResetRides = () => {
    if (onSearch) {
      onSearch();
    }
  };

  return (
    <ButtonsContainer data-test-id={TEST_ID.DATE_CAROUSEL}>
      {showLeftArrow && (
        <ArrowButtonWrapper onClick={handleResetRides}>
          {!isBefore(
            addDays(leftDate, showLeftDate ? -1 : 0),
            startOfToday()
          ) && (
            <DateLink date={date} daysToAdd={showLeftDate ? -2 : -1}>
              <ArrowButton variant="outlined">
                <ArrowBack />
              </ArrowButton>
            </DateLink>
          )}
        </ArrowButtonWrapper>
      )}
      {showLeftDateLink && showLeftDate && (
        <ButtonWrapper onClick={handleResetRides}>
          {!isBefore(leftDate, startOfToday()) && (
            <DateLink date={date} daysToAdd={-1}>
              <DateButton fullWidth variant="outlined">
                {format(leftDate, dateFormat, {
                  locale: currentLocale,
                })}
              </DateButton>
            </DateLink>
          )}
        </ButtonWrapper>
      )}
      <ButtonWrapper onClick={handleResetRides}>
        <DateButtonWrapper fullWidth color="secondary">
          {format(date, dateFormat, { locale: currentLocale })}
        </DateButtonWrapper>
      </ButtonWrapper>
      {showRightDateLink && (
        <ButtonWrapper onClick={handleResetRides}>
          <DateLink date={date} daysToAdd={1}>
            <DateButtonWrapper fullWidth variant="outlined">
              {format(rightDate, dateFormat, {
                locale: currentLocale,
              })}
            </DateButtonWrapper>
          </DateLink>
        </ButtonWrapper>
      )}
      {showRightArrow && (
        <div onClick={handleResetRides}>
          <DateLink date={date} daysToAdd={1}>
            <ArrowButton variant="outlined" data-testid="arrow-forward-button">
              <ArrowForward />
            </ArrowButton>
          </DateLink>
        </div>
      )}
    </ButtonsContainer>
  );
};
