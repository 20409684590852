import React, { FormEvent } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { Controller, FormContext, useForm } from "react-hook-form";
import { isAfter } from "date-fns";
import InputLabel from "@material-ui/core/InputLabel";
import Grid from "@material-ui/core/Grid";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import CheckIcon from "@material-ui/icons/Check";
import { DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import {
  getRentalServiceCategories,
  postRentalQuery,
} from "modules/api/methods";
import { useUserData } from "modules/auth";
import { Notifications } from "modules/notifications";
import { FormInput } from "../molecules/FormInput/FormInput";
import { PhoneField } from "../molecules/PhoneField";

const EMAIL_PATTERN =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const RentalWrapper = styled.div`
  max-width: 990px;
  margin: 0 auto;
  width: 100%;
  padding-bottom: 50px;
  margin-top: 20px;
  padding-top: 20px;

  @media (max-width: ${(props) =>
      props.theme.custom.breakpoints["md-breakpoint"]}) {
    max-width: fit-content;
    background: #f6f6f6;
    padding-bottom: 0;
    padding-top: 0;
  }
`;

// const RentalServiceWrapper = styled.div`
//   max-width: 990px;
//   margin: 0 auto;
//   width: 100%;
//   padding-bottom: 120px;

//   @media (max-width: ${(props) =>
//       props.theme.custom.breakpoints["md-breakpoint"]}) {
//     max-width: fit-content;
//     background: #f6f6f6;
//     padding-bottom: 0;
//   }
// `;

const Note = styled.div`
  background: #ffefd1;
  border-radius: 8px;
  padding: 12px 16px;
  margin-bottom: 16px;
  font-size: 12px;
  line-height: 16px;
  color: #8f6512;
  font-weight: 400;
  @media (max-width: ${(props) =>
      props.theme.custom.breakpoints["md-breakpoint"]}) {
    margin: 10px 16px;
  }
`;

const GridContainer = styled(Grid)`
  @media (max-width: ${(props) =>
      props.theme.custom.breakpoints["md-breakpoint"]}) {
    flex-direction: column-reverse;
  }

  .availableService {
    @media (max-width: ${(props) =>
        props.theme.custom.breakpoints["md-breakpoint"]}) {
      display: none;
    }
  }
`;

const GridCol = styled(Grid)`
  margin-bottom: 20px;

  @media (max-width: ${(props) =>
      props.theme.custom.breakpoints["md-breakpoint"]}) {
    padding: 0;
  }

  .space-after-asterik
    > div.MuiOutlinedInput-root.MuiInputBase-formControl
    > fieldset
    > legend
    > span {
    padding-right: 15px;
  }

  .red-asterik
    > label.MuiInputLabel-root.MuiInputLabel-formControl
    > span.MuiFormLabel-asterisk.MuiInputLabel-asterisk {
    color: red;
  }
  .grid-wrapper {
    background: #ffffff;
    border: 1px solid #e0e0e0;
    box-sizing: border-box;
    border-radius: 3px;
    padding: 30px 30px 100px;
    @media (max-width: ${(props) =>
        props.theme.custom.breakpoints["md-breakpoint"]}) {
      border: none;
      padding: 30px 15px 40px;
    }

    h4 {
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      color: #4f4f4f;
      margin-bottom: 18px;
    }
  }

  @media (max-width: ${(props) =>
      props.theme.custom.breakpoints["xs-breakpoint"]}) {
    margin-bottom: 15px;
  }
`;

const ButtonWrap = styled.button`
  width: 100%;
  height: 40px;
  background: ${(props) => props.theme.custom.secondary.main};
  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.15);
  border-radius: 3px;
  color: #fff;
  border: none;
  margin: auto;
  display: block;
  font-size: 12px;
  line-height: 14px;
  font-weight: 700;
  cursor: pointer;
`;

const Heading = styled.h2`
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  color: ${(props) => props.theme.palette.text.primary};
  margin: 32px 0 20px;
  @media (max-width: ${(props) =>
      props.theme.custom.breakpoints["xs-breakpoint"]}) {
    margin: 32px 10px;
  }
`;

const SuccessContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 140px 0 30px 0;
  justify-content: center;
  height: calc(100vh - 304px);
  color: ${(props) => props.theme.custom["emperor-color"]};

  @media (max-width: ${(props) =>
      props.theme.custom.breakpoints["xs-breakpoint"]}) {
    margin: 40px 0;
  }
`;

const IconWrapper = styled.div`
  border-radius: 50%;
  padding: 19px 16px 17px 16px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
  background-color: ${(props) => props.theme.custom.primary.main};
`;

const SuccessText = styled.p`
  font-style: normal;
  margin: 40px 0 9px 0;
  font-weight: 500;
  font-size: 20px;
  line-height: 23px;

  @media (max-width: ${(props) =>
      props.theme.custom.breakpoints["xs-breakpoint"]}) {
    font-size: 18px;
  }
`;

const TableOuter = styled.div`
  max-height: 400px;
  overflow-y: auto;
  padding-right: 20px;
  &::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: #fff;
  }

  &::-webkit-scrollbar {
    width: 5px;
    background-color: #fff;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #e0e0e0;
  }
`;

const TableWrap = styled.table`
  table-layout: fixed;
  width: 100%;
  border-collapse: collapse;
  thead {
    border-bottom: 1px solid #c4c4c4;
    border-top: 1px solid #c4c4c4;
    tr {
      th {
        text-align: left;
        font-weight: normal;
        font-size: 16px;
        line-height: 19px;
        text-transform: uppercase;
        color: #4f4f4f;
        padding: 7px 0;
      }
    }
  }
  tbody {
    tr {
      border-bottom: 1px solid #c4c4c4;
      border-top: 1px solid #c4c4c4;
      td {
        text-align: left;
        padding: 10px 0;
        &:nth-child(1) {
          width: 40%;
          text-align: left;
        }
        h6 {
          font-weight: bold;
          font-size: 12px;
          line-height: 14px;
          text-align: left;
          color: #4f4f4f;
        }
        p {
          font-size: 12px;
          line-height: 14px;
          color: #4f4f4f;
        }
      }
    }
  }
`;

const InactiveSearchButton = styled.button`
  width: 100%;
  min-height: 40px;
  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.15);
  color: #fff;
  border: none;
  cursor: pointer;
  background-color: ${(props) => props.theme.custom.secondaryInactive};
`;

// const VehiclePrice = styled.span`
//   font-weight: 500;
//   font-size: 14px;
//   line-height: 18px;
//   color: #19191d;
//   color: #5a5b6a;
//   margin-bottom: 12px;
// `;
// const VehicleInfo = styled.p`
//   font-style: normal;
//   font-weight: normal;
//   font-size: 10px;
//   background: #c9e8ff;
//   border-radius: 3px;
//   padding: 12px;
//   line-height: 15px;
//   color: #5a5b6a;
// `;

// const VehicleOuter = styled(({ ...rest }) => <Grid {...rest} />)`
//   align-items: center;
//   display: flex;
//   height: 100%;
//   flex-direction: column;
//   flex-wrap: nowrap;
// `;
let autoComplete: any;

declare global {
  interface Window {
    google: any;
  }
}

const defaultService = {
  description: "",
  externalOperatorId: "",
  name: "select",
  _id: "",
};

type Props = {
  showTitle?: Boolean;
  serviceData: Array<any>;
  showNote?: Boolean;
};

export default function Rental(props: Props) {
  const { showTitle, serviceData, showNote } = props;
  const methods = useForm();
  const { reset } = useForm();
  // const history = useHistory();
  const { t } = useTranslation("rental");
  const [services, setServices] = React.useState(serviceData);
  const [showCompleted, setShowCompleted] = React.useState(false);
  const [departDate, setDepartDate] = React.useState<MaterialUiPickersDate>(
    new Date(),
  );
  const [returnDate, setReturnDate] = React.useState<MaterialUiPickersDate>(
    new Date(),
  );
  const [mapLoaded, setMapLoaded] = React.useState(false);
  const [query, setQuery] = React.useState("");
  const [phone, setPhone] = React.useState<any>("");
  // const [search, setSearch] = React.useState<boolean>(false);
  const autoCompleteRef = React.useRef(null);
  const { value: userValue } = useUserData();
  const [isDirty, setIsDirty] = React.useState<boolean>(false);

  React.useEffect(() => {
    if (showTitle || showNote) {
      (async () => {
        const res = await getRentalServiceCategories();
        setServices(res);
      })();
    }
  }, [showTitle, showNote]);

  React.useEffect(() => {
    methods.control.setValue("fullName", userValue?.displayName);
    methods.control.setValue("phone", userValue?.phoneNumber);
    const phone = userValue?.phoneNumber;
    setPhone(phone);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userValue]);

  React.useEffect(() => {
    methods.register("departureDate", { required: true });
    methods.register("returnDate", { required: true });
    methods.register("address", { required: true });
    methods.register("phone", { required: true });

    methods.setValue("departureDate", new Date(), true);
    methods.setValue("returnDate", new Date(), true);
  }, [methods]);

  const handlePlaceSelect = async (updateQuery: any) => {
    const addressObject = autoComplete.getPlace();
    const tempAddr = addressObject.formatted_address;
    updateQuery(tempAddr);
  };

  React.useEffect(() => {
    const handleScriptLoad = (updateQuery: any, autoCompleteRef: any) => {
      autoComplete = new window.google.maps.places.Autocomplete(
        autoCompleteRef.current,
      );
      autoComplete.setFields(["address_components", "formatted_address"]);
      autoComplete.addListener("place_changed", () =>
        handlePlaceSelect(updateQuery),
      );
    };

    ((callback: any) => {
      if (!document.getElementById("googleMapsScript")) {
        const script = document.createElement("script");
        script.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}&libraries=places`;
        script.async = true;
        script.id = "googleMapsScript";
        script.onload = () => {
          setMapLoaded(true);
          callback.call();
        };
        document.body.appendChild(script);
      } else if (mapLoaded) {
        callback.call();
      }
    })(() => handleScriptLoad(setQuery, autoCompleteRef));
  }, [mapLoaded]);

  React.useEffect(() => {
    methods.control.setValue("address", query);
  }, [query, methods]);

  const onSubmit = async (e: FormEvent) => {
    e.preventDefault();
    const data = methods.getValues();
    data.returnDate = returnDate;
    data.departureDate = departDate;
    data.phone = phone;
    data.email = data.email.trim();

    if (services.length === 0) {
      delete data.rentalCategoryId;
    }

    if (!data.rentalCategoryId) {
      Notifications.enqueueSnackbar(t("selectService"), {
        preventDuplicate: true,
      });
      return;
    }

    if (!data.fullName.trim()) {
      Notifications.enqueueSnackbar(t("nameRequired"), {
        preventDuplicate: true,
      });
      return;
    }

    const regex = /0{5,}/;
    if (!data.phone) {
      Notifications.enqueueSnackbar(t("phoneRequired"), {
        preventDuplicate: true,
      });
      return;
    } else if (regex.test(data.phone) || data.phone.length < 10) {
      Notifications.enqueueSnackbar(t("validPhone"), {
        preventDuplicate: true,
      });
      return;
    }

    if (departDate && returnDate) {
      if (isAfter(departDate, returnDate)) {
        Notifications.enqueueSnackbar(t("minDateError"), {
          preventDuplicate: true,
        });
        return;
      }
    }

    for (const key in data) {
      if (data[key] === "") {
        delete data[key];
      }
    }

    const result = await postRentalQuery(data);

    if (result?.response?.data?.message) {
      Notifications.enqueueSnackbar(result?.response?.data?.message, {
        preventDuplicate: true,
      });
      return;
    }
    setShowCompleted(true);
  };

  const submitNewEnquiry = () => {
    reset();
    setPhone("");
    setShowCompleted(false);
  };

  return showCompleted ? (
    <SuccessContainer>
      <IconWrapper>
        <CheckIcon style={{ width: "47px", height: "39px", color: "#fff" }} />
      </IconWrapper>
      <SuccessText>{t("successText")}</SuccessText>
      <ButtonWrap
        type="submit"
        style={{ margin: 16, width: 120 }}
        onClick={() => submitNewEnquiry()}
      >
        {t("back")}
      </ButtonWrap>
    </SuccessContainer>
  ) : (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <RentalWrapper style={showTitle ? {} : { background: "transparent" }}>
        {showTitle && <Heading>{t("formTitle")}</Heading>}
        {showNote && <Note>{t("formInfo")}</Note>}
        <GridContainer container spacing={3}>
          <GridCol item xs={12} md={12} lg={7}>
            <div className="grid-wrapper">
              {showTitle && <h4>{t("formHead")}</h4>}
              <FormContext {...methods}>
                <form onSubmit={onSubmit}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={12} lg={12}>
                      <FormControl
                        variant="outlined"
                        fullWidth
                        onClick={() => setIsDirty(true)}
                        className="space-after-asterik"
                      >
                        <InputLabel id="rentalCategoryId">
                          {t("contactHead")}
                          <span style={{ color: "red", marginLeft: "3px" }}>
                            *
                          </span>
                        </InputLabel>
                        <Controller
                          name="rentalCategoryId"
                          rules={{ required: true }}
                          control={methods.control}
                          as={
                            <Select
                              id="rentalCategoryId"
                              name="rentalCategoryId"
                              required
                              labelId="rentalCategoryId"
                              label={t("contactHead")}
                              defaultValue={t("select")}
                            >
                              {services && services.length === 0 && (
                                <MenuItem value={t(`${defaultService.name}`)}>
                                  {t(`${defaultService.name}`)}
                                </MenuItem>
                              )}
                              {services.length &&
                                services?.map((service: any) => (
                                  <MenuItem
                                    key={service._id}
                                    value={service._id}
                                  >
                                    {service.name}
                                  </MenuItem>
                                ))}
                            </Select>
                          }
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={12} lg={6}>
                      <Controller
                        name="fullName"
                        rules={{ required: true }}
                        control={methods.control}
                        as={
                          <FormInput
                            name="fullName"
                            label={t("name")}
                            required
                            inputProps={{ maxLength: 50 }}
                            InputLabelProps={{ shrink: true }}
                          />
                        }
                      />
                    </Grid>
                    <Grid item xs={12} md={12} lg={6}>
                      <PhoneField
                        required
                        onTextChange={(value: string) => {
                          setPhone(value);
                          methods.control.setValue("phone", value, true);
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} md={12} lg={12}>
                      <Controller
                        name="email"
                        rules={{ required: true }}
                        control={methods.control}
                        as={
                          <FormInput
                            requiredErrorMessage={t("requiredEmail")}
                            validationPattern={EMAIL_PATTERN}
                            name="email"
                            label={t("email")}
                            required
                            inputProps={{ maxLength: 50 }}
                          />
                        }
                      />
                    </Grid>
                    <Grid item xs={12} md={12} lg={6}>
                      <DateTimePicker
                        inputVariant="outlined"
                        fullWidth
                        autoOk
                        ampm={false}
                        disablePast
                        label={t("startDate")}
                        value={departDate}
                        allowKeyboardControl={false}
                        onChange={(date) => {
                          setDepartDate(date);
                          methods.control.setValue("departureDate", date, true);
                        }}
                        required
                        className="red-asterik"
                      />
                    </Grid>
                    <Grid item xs={12} md={12} lg={6}>
                      <DateTimePicker
                        inputVariant="outlined"
                        fullWidth
                        autoOk
                        ampm={false}
                        disablePast
                        label={t("returnDate")}
                        value={returnDate}
                        allowKeyboardControl={false}
                        onChange={(date) => {
                          setReturnDate(date);
                          methods.control.setValue("returnDate", date, true);
                        }}
                        required
                        minDate={departDate}
                        minDateMessage={t("minDateError")}
                        className="red-asterik"
                      />
                    </Grid>
                    <Grid item xs={12} md={12} lg={12}>
                      <Controller
                        name="companyName"
                        control={methods.control}
                        as={
                          <FormInput
                            name="companyName"
                            label={t("company")}
                            inputProps={{ maxLength: 50 }}
                          />
                        }
                      />
                    </Grid>
                    <Grid item xs={12} md={12} lg={12}>
                      <FormInput
                        ref={autoCompleteRef}
                        name="address"
                        label={t("address")}
                        required
                        value={query}
                        placeholder={t("enterLocation")}
                        onChange={(e: {
                          target: { value: React.SetStateAction<string> };
                        }) => setQuery(e.target.value)}
                        inputProps={{ maxLength: 200 }}
                      />
                    </Grid>
                    <Grid item xs={12} md={12} lg={7}>
                      <Controller
                        name="passengerCount"
                        control={methods.control}
                        as={
                          <FormInput
                            type="number"
                            name="passengerCount"
                            label={t("passengers")}
                            InputProps={{ inputProps: { min: 0, max: 999 } }}
                          />
                        }
                      />
                    </Grid>
                    <Grid item xs={12} md={12} lg={5}>
                      <Controller
                        name="distancePerDay"
                        control={methods.control}
                        as={
                          <FormInput
                            type="number"
                            name="distancePerDay"
                            label={t("distance")}
                            InputProps={{ inputProps: { min: 0, max: 9999 } }}
                            value={methods.getValues("distancePerDay")}
                          />
                        }
                      />
                    </Grid>
                    <Grid item xs={12} md={12} lg={12}>
                      <Controller
                        name="additionalRequirements"
                        control={methods.control}
                        as={
                          <FormInput
                            multiline
                            name="additionalRequirements"
                            label={t("additional")}
                            inputProps={{ maxLength: 500 }}
                          />
                        }
                      />
                    </Grid>
                    <Grid item xs={12} md={12} lg={12}>
                      {!isDirty ? (
                        <InactiveSearchButton>
                          {t("submit")}
                        </InactiveSearchButton>
                      ) : (
                        <ButtonWrap type="submit">{t("submit")}</ButtonWrap>
                      )}
                    </Grid>
                  </Grid>
                </form>
              </FormContext>
            </div>
          </GridCol>
          <GridCol item xs={12} md={12} lg={5} className="availableService">
            <div className="grid-wrapper">
              <h4>{t("availableServices")}</h4>
              <TableOuter>
                <TableWrap>
                  <thead>
                    <tr>
                      <th>{t("serviceHead")}</th>
                      <th>{t("description")}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {services.length &&
                      services?.map((service: any) => (
                        <tr key={service._id}>
                          <td>{service.name}</td>
                          <td>{service.description}</td>
                        </tr>
                      ))}
                  </tbody>
                </TableWrap>
              </TableOuter>
            </div>
          </GridCol>
        </GridContainer>
      </RentalWrapper>
    </MuiPickersUtilsProvider>
  );
}
