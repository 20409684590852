import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { Price } from "modules/entities";
// import classes from "./CartContentTotal.module.css";

const ButtonFlex = styled.span`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  @media (max-width: ${(props) =>
      props.theme.custom.breakpoints["xs-breakpoint"]}) {
    text-align: start;
    justify-content: space-around;
    justify-items: end;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
  }
`;

const TextSmall = styled.span`
  font-size: 12px;
  line-height: 12px;
  margin-top: 6px;
  @media (max-width: ${(props) =>
      props.theme.custom.breakpoints["xs-breakpoint"]}) {
    display: none;
  }
`;

const FontBold = styled.span`
  font-weight: bold;
`;

interface TotalProps {
  price: Price | null | undefined;
}

export const CartContentTotal = (props: TotalProps) => {
  const { price } = props;
  const { t } = useTranslation("searchResult");
  return price?.value ? (
    <ButtonFlex>
      <TextSmall>Total</TextSmall>
      <FontBold>{t("price", { price })}</FontBold>
    </ButtonFlex>
  ) : null;
};
