import * as React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { Divider, withStyles } from "@material-ui/core";
import { Ride, StopElement, Ticket } from "modules/entities";
// import { getBusStop } from "modules/entities/helpers";
import { Duration } from "modules/ui";
import { getDuration } from "modules/utils/getDuration";
import { StopsDetails } from "modules/ticket/molecules/StopsDetails";
// import classes from "modules/ticket/organisms/TicketDetails.module.css";

const Text = styled.p`
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.4px;
  color: #4f4f4f;
  margin: 15px 0;
`;

const StyledDivider = withStyles((theme) => ({
  root: {
    margin: "16px 0px",
    [theme.breakpoints.down("sm")]: {
      margin: "16px -10px",
    },
  },
}))(Divider);

const rideDuration = (pickUpStop: StopElement, dropOutStop: StopElement) => {
  const from = new Date(pickUpStop.timeOfArrival);
  const to = new Date(dropOutStop.timeOfArrival);
  return getDuration(from, to);
};

type RideDetailsProps = {
  ticket: Ticket;
  ride: Ride;
  style?: React.CSSProperties;
};

export const getBusStop = (ride: any, id: string | undefined) => {
  return ride && ride.stops.find((stopEl: any) => stopEl.stopId._id === id);
};

export const RideDetails: React.FC<RideDetailsProps> = ({
  ride,
  ticket,
  style,
}) => {
  const { t } = useTranslation("ticket");
  const { dropOutStopId, pickUpStopId, timezone, ride: rideData } = ticket;
  const pickUpStop = rideData && getBusStop(rideData, pickUpStopId);
  const dropOutStop = rideData && getBusStop(rideData, dropOutStopId);
  const rideId = ride?.id;

  if (!pickUpStop || !dropOutStop) {
    return <p>error</p>;
  }

  return (
    <div style={style}>
      <StyledDivider />
      <StopsDetails
        pickUpStop={pickUpStop}
        dropUpStop={dropOutStop}
        rideId={rideId}
        timezoneFrom={timezone?.from}
        timezoneTo={timezone?.to}
      />
      <Text>
        {t("duration")}{" "}
        <Duration value={rideDuration(pickUpStop, dropOutStop)} />
      </Text>
      {/* <Text>
        {ride.externalOperator && (
          <p>Operated by {ride.externalOperator.name}</p>
        )}
      </Text> */}
    </div>
  );
};
