import {
  PaymentMethod,
  PaymentResponse,
  PaymentTypes,
  Ticket,
} from "modules/entities";
import { axios } from "../client";
import { ENDPOINTS } from "../endpoints";

type BookingParams = {
  rides: Array<{
    rideId: string;
    pickUpStopId: string;
    dropOutStopId: string;
    passengers: Array<{ firstName: string; lastName: string }>;
  }>;
  voucherCode: string;
  email: string;
  paymentMethod: PaymentMethod;
  eWalletPhoneNumber?: string | null | undefined;
};

const getPaymentParams = (method: PaymentMethod) => {
  switch (method.type) {
    case PaymentTypes.va: {
      return {
        bank: method.code,
        paymentMethod: method.type,
      };
    }

    case PaymentTypes.ovo: {
      return {
        bank: method.code,
        paymentMethod: method.type,
      };
    }

    case PaymentTypes.rapyd: {
      return {
        bank: method.code,
        paymentMethod: method.type,
      };
    }

    default: {
      throw new Error(`Payment method '${method.type}' is not implemented`);
    }
  }
};

type BookingResponse = {
  paymentResponse: PaymentResponse | null;
  ticket: Ticket;
};

type RapydPayResponse = {
  ticketGroupId: string;
  checkoutId: string;
  redirectUrl: string;
};

type XenditPayResponse = {
  ticketGroupId: string;
  checkoutId: string;
  redirectUrl: string;
};

type PaymentParams = {
  completePaymentUrl?: string;
  errorPaymentUrl?: string;
  id: string;
  type: string;
};

export const createBooking = ({
  rides,
  paymentMethod,
  voucherCode,
  email,
  eWalletPhoneNumber,
}: BookingParams) => {
  const body = {
    ...getPaymentParams(paymentMethod),
    voucherCode,
    email,
    rides,
    eWalletPhoneNumber,
  };
  return axios
    .post<BookingResponse>(ENDPOINTS.CREATE_BOOKING, body)
    .then((r) => r.data);
};

export const createBookingWithRapyd = (data: PaymentParams) => {
  return axios
    .post<RapydPayResponse>(`${ENDPOINTS.PAYMENT_CHECKOUT}`, data)
    .then((r) => r.data);
};

export const createBookingWithXendit = (data: PaymentParams) => {
  return axios
    .post<XenditPayResponse>(`${ENDPOINTS.PAYMENT_CHECKOUT}`, data)
    .then((r) => r.data);
};
