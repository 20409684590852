import React from "react";
import { format } from "date-fns";
import styled from "styled-components";
import { Popper } from "@material-ui/core";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import { Datepicker } from "modules/ui/molecules/DatePicker/DatePicker";
import { ReactComponent as DatePickerIcon } from "./DatePickerIcon.svg";
// import classes from "./DatePicker.module.css";

interface Props {
  label: string;
  value?: Date;
  onChange?: (value: Date) => void;
  onDateSelect?: (value: Date) => void;
  testId?: string;
  placeHolder?: string;
  minBookingDate?: Date;
  className?: string;
  showPlaceholder?: Boolean;
}

const InputWrapper = styled.div`
  display: flex;
  min-height: 42px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
  border: 1px solid #d8d8d8;
  box-sizing: border-box;
  border-radius: 3px;
  width: 212px;
  margin-left: 10px;
  @media (max-width: ${(props) =>
      props.theme.custom.breakpoints["md-breakpoint"]}) {
    width: 155px;
    margin-right: 10px;
    margin-left: 0px;
  }
  @media (max-width: ${(props) =>
    props.theme.custom.breakpoints["xs-breakpoint"]}) {
  width: 100%;
  margin-right: 0px;
  margin-left: 0px;
}
`;
const Label = styled.p`
  margin: 0px 0px 5px 5px;
  padding-left: 7px;
  font-family: ${(props) => props.theme.custom["roboto-font"]};
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: ${(props) => props.theme.custom["gray-color"]};
  @media (max-width: ${(props) =>
      props.theme.custom.breakpoints["lg-breakpoint"]}) {
    display: none;
  }
`;
const IconWrapper = styled.div`
  display: flex;
  padding: 11px 0px 11px 8px;
`;
const InputDateWrapper = styled.div`
  width: 90%;
  @media (max-width: ${(props) =>
      props.theme.custom.breakpoints["md-breakpoint"]}) {
    width: 95%;
  }
  .return-placeholder {
    color: #d2d2d2;
    font-style: italic;
  }
`;
const PopperWrapper = styled(({ ...rest }) => <Popper {...rest} />)`
  z-index: 99;
  @media (max-width: ${(props) =>
      props.theme.custom.breakpoints["md-breakpoint"]}) {
    width: 90%;
    transform: translate(-50%, -50%) !important;
    left: 50% !important;
    top: 40% !important;
  }
`;
const InputDate = styled.button`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: Roboto;
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 19px;
  padding: 10px;
  color: ${(props) => props.theme.custom["emperor-color"]};
  border: none;
  background: transparent;
`;

export function DatePicker(props: Props) {
  const {
    label,
    value = new Date(),
    onChange,
    testId,
    placeHolder,
    minBookingDate,
    onDateSelect,
    showPlaceholder,
  } = props;
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [open, setOpen] = React.useState<boolean>(false);
  const [tempPlaceHolder, setTempPlaceHolder] = React.useState(placeHolder);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    setAnchorEl(event.currentTarget);
    setOpen(true);
  };

  const handleFocus = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLButtonElement>
  ) => {
    if (!open) {
      setAnchorEl(event.currentTarget);
      setOpen((prev) => !prev);
    }
  };

  const handleClickAway = () => {
    setOpen(false);
    setAnchorEl(null);
  };

  const handlePickerKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === "Tab" || event.key === "Enter") {
      event.preventDefault();
      handleClickAway();
    }
  };

  const handleDateChange = (value: Date) => {
    const next = new Date(value.getTime());
    next.setHours(0, new Date().getTimezoneOffset() * -1, 0, 0);
    onChange && onChange(next);
    onDateSelect && onDateSelect(next);
    setOpen(false);
    setTempPlaceHolder("");
  };

  React.useEffect(() => {
    if (open) {
      const arrowButtons = ["ArrowLeft", "ArrowRight", "ArrowUp", "ArrowDown"];
      const callback = (event: KeyboardEvent) => {
        if (event.key === "Escape") {
          setOpen(false);
        }
        // prevent scroll from keyboard
        if (arrowButtons.includes(event.key)) {
          event.preventDefault();
        }
      };
      document.addEventListener("keydown", callback);
      return () => document.removeEventListener("keydown", callback);
    }
  }, [open]);

  const id = open ? testId : undefined;
  const activeDate = format(value, "E d MMM");
  return (
    <div>
      <Label>{label}</Label>
      <InputWrapper>
        <IconWrapper>
          <DatePickerIcon role="img" />
        </IconWrapper>

        <ClickAwayListener onClickAway={handleClickAway}>
          <InputDateWrapper>
            <InputDate
              data-testid={testId}
              aria-describedby={id}
              value={activeDate}
              onClick={handleClick}
              onFocus={handleFocus}
              className={showPlaceholder ? "return-placeholder" : ""}
            >
              {showPlaceholder ? tempPlaceHolder : activeDate}
            </InputDate>
            <PopperWrapper
              id={id}
              open={open}
              anchorEl={anchorEl}
              disablePortal
              onKeyDown={handlePickerKeyDown}
            >
              <Datepicker
                testId={testId ? `${testId}-content` : ""}
                onChange={handleDateChange}
                value={value}
                minBookingDate={minBookingDate}
              />
            </PopperWrapper>
          </InputDateWrapper>
        </ClickAwayListener>
      </InputWrapper>
    </div>
  );
}
