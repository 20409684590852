import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { Ride } from "modules/entities";
import { routes, Link } from "modules/routing";
import { TEST_ID } from "modules/ui";
import { multiplyPrice, sumPrices } from "modules/utils/price";
import { EmptyContent } from "../../atoms/CartContentEmpty/CartContentEmpty";
import { CartContentTicket } from "../../molecules/CartContentTicket/CartContentTicket";
import { BookButton } from "../../molecules/BookButton";
import { ShoppingCartProps } from "./ShoppingCartProps";
import { getOperatorCode } from "../../../utils/getOperatorCode";
import GoogleAnalyticsService from "../../../utils/GoogleAnalyticsService";
import { LOG_EVENTS } from "../../../utils/LogEvents";
import { SearchParams } from "modules/routing/query-types";
import { stringify } from "query-string";
// import classes from "./ShoppingCart.module.css";

const CartWrapper = styled.div`
  max-width: 271px;
  min-width: 231px;
  border: 1px solid ${(props) => props.theme.custom["alto-color"]};
  border-radius: 3px;
  font-family: Roboto;
  .ticketWrapper {
    padding: 14px;
    padding-top: 12px;
  }
  .emptyWrapper {
    padding: 11px 16px 16px 16px;
  }
  @media (max-width: ${(props) =>
      props.theme.custom.breakpoints["xs-breakpoint"]}) {
    border-top-width: 0px;
  }
  @media (max-width: ${(props) =>
      props.theme.custom.breakpoints["md-breakpoint"]}) {
    max-width: 100%;
    width: 100%;
  }
`;

const CartTitle = styled.div`
  font-size: 20px;
  padding-bottom: 18px;
  margin-top: 11px;
  padding-left: 9px;
  font-weight: 500;
  color: ${(props) => props.theme.custom["emperor-color"]};
  border-bottom: 1px solid ${(props) => props.theme.custom["alto-color"]};
  @media (max-width: ${(props) =>
      props.theme.custom.breakpoints["xs-breakpoint"]}) {
    display: none;
  }
`;

const ButtonContainer = styled.div`
  border-top: 1px solid ${(props) => props.theme.custom["alto-color"]};
  padding: 15px;
`;

export const ShoppingCart = (props: ShoppingCartProps) => {
  const {
    pickUpStopId,
    dropOutStopId,
    ride,
    passengersCount,
    onRemove,
    onReturnRemove,
    timezone,
    returnTimezone,
    returnRide,
    returnDropOutStopId,
    returnPickUpStopId,
  } = props;
  const urlParams = new URLSearchParams(window.location.search);
  const returnDate = urlParams.get("returnDate");
  const timezoneTo = urlParams.get("timezoneTo");
  const timezoneFrom = urlParams.get("timezoneFrom");
  const hasSelection = ride && pickUpStopId && dropOutStopId;
  const hasReturnSelection =
    returnRide && returnPickUpStopId && returnDropOutStopId;
  const { t } = useTranslation("searchResult");
  const routeObj: any = {
    rideId: ride?.id,
    passengers: passengersCount,
    dropOutStopId,
    pickUpStopId,
    timezoneFrom: timezoneFrom,
    timezoneTo: timezoneTo,
  };
  if (returnRide) {
    routeObj.returnRideId = returnRide.id;
    routeObj.returnPickUpStopId = returnPickUpStopId;
    routeObj.returnDropOutStopId = returnDropOutStopId;
  }

  const bookNowBtnPressed = () => {
    GoogleAnalyticsService.createEvent(
      LOG_EVENTS.LOG_EVENTS_NAME.PRESS_BUTTON_BOOK_RIDE_FROM,
      {
        search_result_from:
          LOG_EVENTS.LOG_METHOD_VALUE.CLICKED_BOOK_BUTTON_ON_RIDE_ITEM,
        operator_code: getOperatorCode(),
        source: "web",
      },
    );
    GoogleAnalyticsService.createEvent(
      LOG_EVENTS.LOG_EVENTS_NAME.PRESS_BUTTON_PROCEED_RIDE_TO,
      {
        search_result_to:
          LOG_EVENTS.LOG_METHOD_VALUE.CLICKED_PROCEED_BUTTON_ON_RIDE_ITEM,
        operator_code: getOperatorCode(),
        source: "web",
      },
    ); 
  };
  const queryParams = new URLSearchParams(window.location.search)

  const renderBookButton = () => {
    if (returnDate) {
      if (
        ride &&
        returnRide &&
        pickUpStopId &&
        dropOutStopId &&
        returnPickUpStopId &&
        returnDropOutStopId
      ) {
        return (
          <Link to={routes.newReservation.url(routeObj)}>
            <BookButton
              disabled={!ride || !returnRide}
              fullWidth
              ride={ride}
              returnRide={returnRide}
              passengersCount={passengersCount}
              onClick={() => {
                bookNowBtnPressed();
              }}
            />
          </Link>
        );
      } else {
        return (
          <BookButton
            disabled={!ride || !returnRide}
            fullWidth
            ride={ride}
            returnRide={returnRide}
            passengersCount={passengersCount}
            onClick={() => {
              bookNowBtnPressed();
            }}
          />
        );
      }
    } else {
      if (ride && pickUpStopId && dropOutStopId) {
        return (
          <Link to={routes.newReservation.url(routeObj)}>
            <BookButton
              disabled={!ride}
              fullWidth
              ride={ride}
              returnRide={returnRide}
              passengersCount={passengersCount}
              onClick={() => {
                bookNowBtnPressed();
              }}
            />
          </Link>
        );
      } else {
        return (
          <BookButton
            disabled={!ride}
            fullWidth
            ride={ride}
            passengersCount={passengersCount}
            onClick={() => {
              bookNowBtnPressed();
            }}
          />
        );
      }
    }
  };
  let onwardPrice, returnPrice;
  if (ride?.bestCampaignOffer?.discountDetails) {
    onwardPrice = {
      value: ride.bestCampaignOffer.discountDetails.discountedPrice,
      currency: ride?.price.currency,
    };
  } else {
    onwardPrice = ride?.price;
  }

  if (returnRide?.bestCampaignOffer?.discountDetails) {
    returnPrice = {
      value: returnRide.bestCampaignOffer.discountDetails.discountedPrice,
      currency: returnRide?.price.currency,
    };
  } else {
    returnPrice = returnRide?.price;
  }
  let finalPrice = onwardPrice;
  if (returnRide?.price && ride?.price) {
    finalPrice = sumPrices([onwardPrice, returnPrice]);
  }

  return (
    <CartWrapper data-test-id={TEST_ID.SHOPPING_CART}>
      <CartTitle>{t("cartTitle")}</CartTitle>
      <div
        className={
          hasSelection || hasReturnSelection ? "ticketWrapper" : "emptyWrapper"
        }
      >
        {hasSelection || (hasSelection && hasReturnSelection) ? (
          <>
            <CartContentTicket
              onShoppingCart
              price={ride && finalPrice && multiplyPrice(finalPrice, 1)}
              dropOutStopId={dropOutStopId as string}
              pickUpStopId={pickUpStopId as string}
              ride={ride as Ride}
              passengersCount={passengersCount}
              onRemove={onRemove}
              onReturnRemove={onReturnRemove}
              timezone={timezone}
              returnTimezone={returnTimezone}
              returnRide={returnRide}
              returnPrice={
                returnRide && multiplyPrice((returnRide as Ride).price, 1)
              }
              returnDropOutStopId={returnDropOutStopId}
              returnPickUpStopId={returnPickUpStopId}
            />
          </>
        ) : (
          <EmptyContent />
        )}
      </div>
      <ButtonContainer>{renderBookButton()}</ButtonContainer>
    </CartWrapper>
  );
};
