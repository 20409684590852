import React from "react";
import { Header } from "../Header/Header";
import { Footer } from "../Footer/Footer";
import { AppContainer } from "../../atoms/AppContainer/AppContainer";
import "./reset.min.css";

export const Layout: React.FC = ({ children }) => {
  return (
    <>
      <Header />
      <AppContainer>{children}</AppContainer>
      <Footer />
    </>
  );
};
