import React from "react";
import styled from "styled-components";
import { format } from "date-fns";
import { useTranslation } from "react-i18next";
import { useTheme } from "@material-ui/core";
import { TicketStatus } from "modules/entities";

const RefundStatusOuter = styled.div`
  background: #ffffff;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 3px;
  padding: 18px 23px;
  margin-bottom: 30px;
  h3 {
    font-weight: bold;
    font-size: 18px;
    line-height: 21px;
    color: #353f47;
    margin: 0;
  }
  .wrapper {
    margin-top: 30px;
  }
  .StepProgress {
    position: relative;
    padding-left: 35px;
    list-style: none;

    strong {
      display: block;
      font-weight: bold;
      font-size: 14px;
      line-height: 16px;
      color: #353f47;
      margin-bottom: 10px;
    }
  }
  .StepProgress-item {
    position: relative;
    &:last-child {
      &::before {
        display: none;
      }
    }
    &::before {
      display: inline-block;
      content: "";
      position: absolute;
      top: 0;
      left: -31px;
      width: 10px;
      height: 100%;
      border-left: 1px solid #dee0e1;
    }
    p {
      font-weight: normal;
      font-size: 14px;
      line-height: 18px;
      color: #353f47;
      opacity: 0.5;
      margin: 0;
      margin-bottom: 6px;

      &:last-child {
        margin-bottom: 0;
      }
    }
    &:not(:last-child) {
      padding-bottom: 20px;
    }
    &::after {
      content: "";
      display: inline-block;
      position: absolute;
      top: 0;
      left: -35px;
      width: 9px;
      height: 9px;
      border: 1px solid #dee0e1;
      border-radius: 50%;
      background-color: #fff;
    }
  }
  .StepProgress-item.progress {
    &::before {
      border-left: 2px solid #dee0e1;
      border-left: 2px solid #dee0e1;
    }
    &::after {
      content: "";
      color: #fff;
      text-align: center;
      border: 1px solid #dee0e1;
      background-color: #dee0e1;
    }
  }
`;

type StatusItem = {
  status: string;
  timestamp: Date | string;
  isCompleted?: boolean;
  description?: string;
  title?: string;
};

type Statuses = Array<StatusItem>;

export default function RefundStatus(props: any) {
  const theme = useTheme();
  const { refundStatus } = props;
  const { t } = useTranslation("ticket");
  let operatorCode = window.location.pathname.split("/")[1];
  operatorCode = operatorCode.charAt(0).toUpperCase() + operatorCode.slice(1);
  let refundInitiated = {
    title: `${operatorCode} has initiated a refund`,
    description:
      "It takes 4-5 business days to reflect the refund amount back to your payment method.",
    timeStamp: "",
  };
  let refundedAndCancelled = {
    title: `Operator has processed your refund`,
    description: "In Progress",
    timeStamp: "",
  };
  let refundCredited = {
    title: `Refund credited to your account`,
    description: "Not Started",
    timeStamp: "",
  };

  if (refundStatus?.filteredStatusHistory?.length) {
    const tempStatus = refundStatus?.filteredStatusHistory;
    tempStatus.forEach((statusItem: StatusItem) => {
      if (statusItem.status === TicketStatus.refund_initiated) {
        refundInitiated.description = statusItem.timestamp
          ? "Completed"
          : "It takes 4-5 business days to reflect the refund amount back to your payment method.";
        refundInitiated.timeStamp = statusItem.timestamp
          ? format(new Date(statusItem.timestamp), "E, dd MMMM yyyy")
          : "";
      } else if (
        statusItem.status === TicketStatus.refunded_and_ticket_cancelled
      ) {
        refundedAndCancelled.description = statusItem.timestamp
          ? "Completed"
          : "In Progress";
        refundedAndCancelled.timeStamp = statusItem.timestamp
          ? format(new Date(statusItem.timestamp), "E, dd MMMM yyyy")
          : "";
      } else if (statusItem.status === TicketStatus.refund_credited) {
        refundCredited.description = tempStatus[1]?.timestamp
          ? statusItem.timestamp
            ? `We have completed your request for refund. ${t("price", {
                price: refundStatus?.refundAmount?.refund,
              })} has been refunded to your bank account on ${format(
                new Date(statusItem.timestamp),
                "E, dd MMMM yyyy"
              )} + 10 points have been refunded to your Bussr loyalty points. In case there is still an issue with refund, please connect with your`
            : "In Progress"
          : "Not Started";
        refundCredited.timeStamp = statusItem.timestamp
          ? format(new Date(statusItem.timestamp), "E, dd MMMM yyyy")
          : "";
      }

      if (statusItem.timestamp) {
        statusItem.isCompleted = true;
        statusItem.timestamp = format(
          new Date(statusItem.timestamp),
          "E, dd MMMM yyyy"
        );
      } else {
        statusItem.timestamp = "";
        statusItem.isCompleted = false;
      }

      return statusItem;
    });
  }

  return (
    <RefundStatusOuter>
      <h3>Refund Status</h3>
      <div className="wrapper">
        <ul className="StepProgress">
          <li className="StepProgress-item progress">
            <strong>{refundInitiated.title}</strong>
            <p>{refundInitiated.description}</p>
            <p>{refundInitiated.timeStamp}</p>
          </li>
          <li
            className={
              refundedAndCancelled.timeStamp
                ? "StepProgress-item progress"
                : "StepProgress-item"
            }
          >
            <strong>{refundedAndCancelled.title}</strong>
            <p>
              {refundInitiated.timeStamp
                ? refundedAndCancelled.timeStamp
                  ? "Completed"
                  : "In Progress"
                : "Not Started"}
            </p>
            <p>{refundedAndCancelled.timeStamp}</p>
          </li>
          <li
            className={
              refundCredited.timeStamp
                ? "StepProgress-item progress"
                : "StepProgress-item"
            }
          >
            <strong>{refundCredited.title}</strong>
            <p>{refundCredited.description}</p>
            {refundCredited.timeStamp && (
              <a
                href={`mailto:${refundStatus?.customerSupport?.email}`}
                style={{
                  color: theme.custom.primary.main,
                  textTransform: "lowercase",
                  cursor: "pointer",
                }}
              >
                {refundStatus?.customerSupport?.email}
              </a>
            )}
            <p>{refundCredited.timeStamp}</p>
          </li>
        </ul>
      </div>
    </RefundStatusOuter>
  );
}
