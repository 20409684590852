import { PaymentMethod, PaymentTypes, Ticket } from "modules/entities";
import { NewReservationParams } from "modules/routing";
import { ReservationFormValues } from "modules/booking";
import { createBooking } from "./booking";

export const getCheckoutState = (
  paymentMethod: PaymentMethod,
  rideDetails: NewReservationParams,
  formValues: ReservationFormValues
): Promise<Ticket> => {
  switch (paymentMethod.type) {
    case PaymentTypes.va: {
      const rides = [
        {
          rideId: rideDetails.rideId,
          pickUpStopId: rideDetails.pickUpStopId,
          dropOutStopId: rideDetails.dropOutStopId,
          passengers: formValues.passengers,
        },
      ];
      // Two way booking
      if (rideDetails.returnRideId) {
        rides.push({
          rideId: rideDetails.returnRideId,
          pickUpStopId: rideDetails.dropOutStopId,
          dropOutStopId: rideDetails.pickUpStopId,
          passengers: formValues.passengers,
        });
      }
      return createBooking({
        rides,
        paymentMethod,
        voucherCode: formValues.voucher,
        email: formValues.email,
      })
        .then((res) => {
          return res.ticket;
        })
        .catch((error: any) => {
          return Promise.reject({
            code: error.response?.status,
            data: error.response?.data,
          });
        });
    }
    default: {
      throw new Error("Payment method is not implemented");
    }
  }
};
