import * as React from "react";
import { FormContext, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
import { isValidNumber } from "libphonenumber-js";
import styled from "styled-components";
import { enUS, id } from "date-fns/locale";
import { format } from "date-fns";
import { Revisit } from "assets/icons";
import { useSelector, RootStateOrAny } from "react-redux";
import { parse } from "querystring";
import { LinearProgress, useTheme, makeStyles } from "@material-ui/core";
import { useApplyVocher, useRide } from "modules/api";
import { axios } from "modules/api/client";
import { ENDPOINTS } from "modules/api/endpoints";
import { createReservation } from "modules/api/methods";
import { ApplyVoucherResult, PaymentTypes } from "modules/entities";
import { getBusStop, getBusStopName } from "modules/entities/helpers";
import { arePhonesEqual, useUserData } from "modules/auth";
import {
  BackButton,
  NewReservationParams,
  Redirect,
  routes,
} from "modules/routing";
import { useLanguage } from "modules/localization";
import { Button } from "modules/ui";
import { multiplyPrice } from "modules/utils/price";
import { getTimeValue } from "modules/utils/rideUtils";
import { Notifications } from "modules/notifications";
import { CartContentTicket } from "modules/search-result-list";
import { getOperatorCode } from "../../../modules/utils/getOperatorCode";
import { FirstBlock } from "../organisms/FirstBlock";
// import { SecondBlock } from "../organisms/SecondBlock";
import { ThirdBlock } from "../organisms/ThirdBlock";
// import { ApplyVoucher } from "../organisms/ApplyVoucher";
import {
  PhoheVerifyProvider,
  usePhoneVerify,
} from "../organisms/PhoneField/PhoheVerifyContext";
// import { VoucherSuccess } from "../molecules/VoucherSuccess/VoucherSuccess";
import {
  getBookingValues,
  getOrderId,
  saveBookingValues,
} from "../utils/storage";
import GoogleAnalyticsService from "../../../modules/utils/GoogleAnalyticsService";
import { LOG_EVENTS } from "../../../modules/utils/LogEvents";
import { getPaymentCollections } from "modules/api/methods/payment";
// import classes from "./CreateReservation.module.css";
import { TermsConditionsCheckbox } from "modules/select-payment/molecules/TermsConditionsCheckbox";
import OffersAndBenefits from "../organisms/OffersAndBenefits";
import { updateOperatorCode } from "modules/api/client";
import { useDispatch } from "react-redux";
import { getDefaultOperatorCode } from "../../../modules/utils/getDefaultOperator";

type ReservationPageContentProps = NewReservationParams & {
  voucherData?: ApplyVoucherResult;
};

const useStyles = makeStyles((theme) => ({
  enabledButton: {
    background: theme.custom.secondary.main,
    "&:disabled": {
      background: `${theme.custom.secondaryInactive}`,
      color: "#fff",
    },
  },
}));

const PageContainer = styled.div`
  background-color: ${(props) => props.theme.custom["concrete-color"]};
  display: flex;
  justify-content: center;
  @media (max-width: ${(props) =>
    props.theme.custom.breakpoints["xs-breakpoint"]}) {
    display: block;
  }
`;

const Container = styled.form`
  display: grid;
  grid-template-columns: auto 400px;
  column-gap: 40px;
  margin: 10px 0;
  @media (max-width: ${(props) =>
    props.theme.custom.breakpoints["lg-breakpoint"]}) {
    grid-template-columns: auto;
  }
  @media (min-width: ${(props) =>
    props.theme.custom.breakpoints["xs-breakpoint"]}) and (max-width: ${(
      props,
    ) => props.theme.custom.breakpoints["lg-breakpoint"]}) {
    padding: 0 24px;
  }
  @media (max-width: ${(props) =>
    props.theme.custom.breakpoints["xs-breakpoint"]}) {
    margin: 0 0 10px;
  }
`;

const RideDetailsDiv = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(4, min-content);
  grid-gap: 20px;
  @media (max-width: ${(props) =>
    props.theme.custom.breakpoints["xs-breakpoint"]}) {
    padding: 40px 15px 0;
    grid-gap: 0px;
  }
`;

const FormContainer = styled.div`
  display: grid;
  grid-template-rows: repeat(auto-fit, minmax(0, max-content));
  row-gap: 20px;
`;

const OrderTitle = styled.div`
  margin: 0px 0 27px 0;
  font-weight: 500;
  font-size: 20px;
  line-height: 23px;
  color: ${(props) => props.theme.custom["emperor-color"]};
`;

const ButtonWrapper = styled((rest) => <Button {...rest} />)`
  @media (max-width: ${(props) =>
    props.theme.custom.breakpoints["xs-breakpoint"]}) {
    margin-top: 20px;
  }
  background-color: ${(props) => props.theme.custom.secondary.main};
  &:hover {
    background-color: ${(props) => props.theme.custom.primary.main};
  }
`;

const BackButtonWrapper = styled.div`
  @media (max-width: ${(props) =>
    props.theme.custom.breakpoints["xs-breakpoint"]}) {
    display: none;
  }
`;

const TripDetailsWrapper = styled.div`
  display: none;
  @media (max-width: ${(props) =>
    props.theme.custom.breakpoints["xs-breakpoint"]}) {
    display: block;
    background: ${(props) => props.theme.custom.primary.main};
    padding: 0 0 18px;
    .trip__details {
      background: ${(props) => props.theme.custom["white-color"]};
      border-radius: 3px;
      padding: 10px 15px;
      margin: 0 15px;
      display: flex;
      align-items: center;
      .circle {
        background: ${(props) => props.theme.custom["silver-color"]};
        width: 16px;
        height: 16px;
        border-radius: 100px;
      }
      p {
        font-weight: normal;
        font-size: 10px;
        line-height: 14px;
        color: ${(props) => props.theme.custom["emperor-color"]};
        margin-left: 10px;
      }
    }
  }
`;

export const RideDetails: React.FC<ReservationPageContentProps> = ({
  rideId,
  dropOutStopId,
  pickUpStopId,
  passengers,
  voucherData,
  timezoneFrom,
  timezoneTo,
  returnRideId,
}) => {

  const { data: ride, error } = useRide(rideId, passengers);
  const [returnRide, setReturnRide] = React.useState<any>(null);

  React.useEffect(() => {
    GoogleAnalyticsService.createEvent(LOG_EVENTS.LOG_EVENTS_NAME.SCREEN_VIEW, {
      firebase_screen: LOG_EVENTS.SCREEN_NAME.CREATE_RESERVATION_SCREEN,
      firebase_screen_class: LOG_EVENTS.SCREEN_NAME.CREATE_RESERVATION_SCREEN,
      operator_code: getOperatorCode(),
      source: "web",
    });
    const fetchRides = async () => {
      if (returnRideId) {
        const response = await axios.get(`${ENDPOINTS.RIDES}/${returnRideId}?countOfPassengers=${passengers}`);
        setReturnRide(response.data);
      }
    };
    fetchRides();
  }, [returnRideId, passengers]);
  if (ride) {
    return (
      <CartContentTicket
        pickUpStopId={pickUpStopId}
        dropOutStopId={dropOutStopId}
        passengersCount={passengers}
        ride={ride}
        returnRide={returnRide}
        returnPickUpStopId={dropOutStopId}
        returnDropOutStopId={pickUpStopId}
        voucherData={voucherData}
        price={multiplyPrice(ride.price, passengers)}
        returnPrice={returnRide && multiplyPrice(returnRide.price, passengers)}
        timezone={{
          from: timezoneFrom || "",
          to: timezoneTo || "",
        }}
      />
    );
  } else if (error?.status === 404) {
    return <Redirect to={routes.home.url()} />;
  } else if (error) {
    return <p>{error?.data?.message}</p>;
  }
  return <LinearProgress />;
};

const useInitialValues = (params: NewReservationParams) => {
  return React.useMemo(() => {
    const id = getOrderId(params);
    const values = getBookingValues(id);
    if (values.valid) {
      return values.params;
    }
    return {
      passengers: Array(params.passengers)
        .fill("")
        .map(() => ({ firstName: "", lastName: "" })),
      email: "",
      voucher: "",
    };
  }, [params]);
};

const XENDIT_PH = "XENDIT_PH";

const ReservationPageContent: React.FC<ReservationPageContentProps> = (
  params,
) => {
  const {
    rideId,
    dropOutStopId,
    pickUpStopId,
    passengers,
    timezoneFrom,
    timezoneTo,
    returnRideId,
    returnDropOutStopId,
    returnPickUpStopId,
  } = params;
  const { t } = useTranslation("reservation");
  const [showOlderXendit, setShowOlderXendit] = React.useState(false);
  const region = useSelector((state: RootStateOrAny) => state.region);
  const [returnRide, setReturnRide] = React.useState<any>(null);
  const [gatewayName, setGatewayName] = React.useState<any>("XENDIT");
  const [termsConfirmed, setTermsConfirmed] = React.useState(false);
  const { data: ride } = useRide(rideId, passengers);
  const pickUpStop = ride && getBusStop(ride, pickUpStopId);
  const returnPickUpStop = ride && getBusStop(returnRide, returnPickUpStopId);
  const returnDropOutStop = ride && getBusStop(returnRide, returnDropOutStopId);
  const language = useLanguage();
  const baseUrl = `${window.location.origin}/${window.location.pathname.split("/")[1]
    }/${language}`;
  const theme = useTheme();
  const classes = useStyles();
  const wasManuallyEdited = ride?.wasManuallyEdited;
  const disableConversion = ride?.disableConversion;
  const ridePatternId = ride?.ridePatternId;
  // prettier-ignore
  const dropOutStop = ride && getBusStop(ride, dropOutStopId);
  const currentLocale = language === "en" ? enUS : id;
  const browserHistory = useHistory();
  const { value: userData } = useUserData();
  const initialValues = useInitialValues(params);
  initialValues.email = userData?.email || "";
  const methods = useForm({
    defaultValues: initialValues,
  });
  const voucher = React.useMemo(
    () => initialValues.voucher,
    [initialValues.voucher],
  );
  const campaign = useSelector(
    (state: RootStateOrAny) => state?.campaign?.data,
  );

  const verifyPhone = usePhoneVerify();
  const dispatch = useDispatch();

  React.useEffect(() => {
    const olderGateway: any = process.env.REACT_APP_SHOW_OLDER_XENDIT;
    setShowOlderXendit(olderGateway === "false" ? false : true);
    const fetchGateWay = async () => {
      getPaymentCollections()
        .then((res: any) => {
          setGatewayName(res.data.gatewayName);
        })
        .catch((err: any) => console.log(err));
    };
    if (olderGateway === "false" || region.name === "India") {
      fetchGateWay();
    }
  }, [region.name]);

  React.useEffect(() => {
    const fetchRides = async () => {  
      if (returnRideId) {
        const response = await axios.get(`${ENDPOINTS.RIDES}/${returnRideId}?countOfPassengers=${passengers}`);
        setReturnRide(response.data);
      }
    };
    fetchRides();
  }, [returnRideId, passengers]);

  const onSubmit = async (values: any, event: any) => {
    event.preventDefault();
    const { phone } = values;
    if (
      !isValidNumber(userData?.phoneNumber ?? "") ||
      !arePhonesEqual(userData?.phoneNumber, phone)
    ) {
      verifyPhone?.current?.();
      return;
    }
    GoogleAnalyticsService.createEvent(LOG_EVENTS.LOG_EVENTS_NAME.ADD_NAME, {
      passenger_details: LOG_EVENTS.LOG_METHOD_VALUE.USER_ENTERED_FIRST_NAME,
      operator_code: getOperatorCode(),
      source: "web",
    });
    const id = getOrderId(params);
    await saveBookingValues(id, {
      ...values,
      passengers: values.passengers.map((passenger: any) => ({
        firstName: passenger.firstName,
        lastName: passenger.lastName,
      })),
      voucher,
    });
    const showXendit =
      gatewayName === "xendit" || region.paymentGateway === XENDIT_PH;
    const showPayU = gatewayName === "payu";
    if (showOlderXendit && region.name === "Indonesia") {
      const url: string = `/${language}${routes.selectPayment.url(params)}`;
      browserHistory.push(url);
    } else if (showXendit || showPayU) {
      const rideDetails = parse(window.location.search) as unknown as any;
      rideDetails.dropOutStopId = rideDetails["?dropOutStopId"];
      delete rideDetails["?dropOutStopId"];
      const id = getOrderId(rideDetails);
      const userDetails = getBookingValues(id);
      if (userDetails.valid) {
        const rides = [
          {
            rideId: rideDetails.rideId,
            pickUpStopId: rideDetails.pickUpStopId,
            dropOutStopId: rideDetails.dropOutStopId,
            passengers: userDetails.params.passengers,
          },
        ];

        // Two way booking
        if (rideDetails.returnRideId) {
          rides.push({
            rideId: rideDetails.returnRideId,
            pickUpStopId: rideDetails.dropOutStopId,
            dropOutStopId: rideDetails.pickUpStopId,
            passengers: userDetails.params.passengers,
          });
        }

        createReservation({
          rides,
          paymentMethod: {
            code: "none",
            icon: "",
            minAmount: 0,
            name: "none",
            type: PaymentTypes.none,
          },
          voucherCode: userDetails.params.voucher,
          email: userDetails.params.email,
          couponCode: campaign?.code,
        })
          .then((r) => {
            if (showOlderXendit && region.name !== "India") {
              const paymentStatusUrl = `${baseUrl}${routes.paymentStatus.url({
                ticketId: r.ticket.id,
              })}`;
              const type = "booking";
              browserHistory.push({
                pathname: `/${language}${routes.xendit.pattern}`,
                search: `&su=${paymentStatusUrl}&eu=${paymentStatusUrl}&type=${type}&id=${r.ticket.id}`,
                state: {
                  su: paymentStatusUrl,
                  eu: paymentStatusUrl,
                  type,
                  id: r.ticket.id,
                  passengerCount: userDetails.params.passengers.length,
                },
              });
            } else {
              let callBackURL = "";
              if (region.name === "India") {
                callBackURL = `${process.env.REACT_APP_SERVER_PATH}/paymentcollection/api/v1/redirect/${getDefaultOperatorCode()}/${r.ticketGroup.id}`;
              } else {
                callBackURL = `${baseUrl}${routes.paymentStatus.url({
                  ticketId: r.ticket.id,
                })}`;
              }

              const paymentData = {
                serviceReferenceId: r.ticketGroup.id,
                serviceId: r.ticketGroup.ticketsId,
                operatorId: r.ticket.externalOperatorId,
                serviceType: "booking",
                actionType: "payment",
                amount: r.ticketGroup.price.value,
                currency: r.ticketGroup.price.currency,
                callbackUrl: callBackURL,
                customerInfo: {
                  firstName: userDetails.params.passengers[0].firstName,
                  lastName: userDetails.params.passengers[0].lastName,
                  email: r.ticket.email,
                  phone: userData?.phoneNumber,
                },
                productInfo: "Ticket",
                paymentMethod: "online",
              };

              browserHistory.push({
                pathname: `/${language}${routes.paymentGateway.pattern}`,
                state: { paymentData, gatewayName, ticketId: r.ticket.id, passengerCount: userDetails.params.passengers.length, },
              });
            }
          })
          .catch((e) => {
            Notifications.enqueueSnackbar(e?.response?.data?.message, {
              preventDuplicate: true,
            });
          });
      } else {
        Notifications.enqueueSnackbar("User details are not valid!", {
          preventDuplicate: true,
        });
      }
    }
  };

  const {
    // isValidating: voucherLoading,
    // error: voucherError,
    data: voucherData,
  } = useApplyVocher(rideId, voucher, passengers);

  const hasVoucher = Boolean(voucherData && voucher);
  const date =
    pickUpStop &&
    format(new Date(pickUpStop?.timeOfArrival), "EEE, d MMM, yyyy", {
      locale: currentLocale,
    });
  const returnDate =
    returnPickUpStop &&
    format(new Date(returnPickUpStop?.timeOfArrival), "EEE, d MMM, yyyy", {
      locale: currentLocale,
    });

  const backToSearch = () => {
    
    dispatch({
      type: "SET_OPERATOR_CODE",
      payload: window.location.pathname.split("/")[1].toUpperCase(),
    }); 
    updateOperatorCode(window.location.pathname.split("/")[1].toUpperCase());
    localStorage.removeItem("tabIndex");
    browserHistory.goBack();
  }
  
  return (
    <PageContainer>
      <Container onSubmit={methods.handleSubmit(onSubmit)}>
        <FormContext {...methods}>
          <FormContainer>
            <BackButtonWrapper>
              <BackButton to={routes.home.url()} onClick={() => backToSearch()} />
            </BackButtonWrapper>
            <TripDetailsWrapper>
              <div className="trip__details">
                <div className="circle"></div>
                <p>
                  {pickUpStop && getBusStopName(pickUpStop.stop)}
                  {returnRideId ? (
                    <Revisit
                      color={theme.palette.secondary.main}
                      style={{ margin: "0 10px", height: 10 }}
                    />
                  ) : (
                    "-"
                  )}{" "}
                  {dropOutStop && getBusStopName(dropOutStop.stop)} <br />
                  {date},{" "}
                  {pickUpStop &&
                    getTimeValue(
                      pickUpStop?.timeOfArrival,
                      timezoneFrom,
                      wasManuallyEdited,
                      disableConversion,
                      ridePatternId,
                    )}{" "}
                  -{" "}
                  {dropOutStop &&
                    getTimeValue(
                      dropOutStop?.timeOfArrival,
                      timezoneFrom,
                      wasManuallyEdited,
                      disableConversion,
                      ridePatternId,
                    )}
                  {returnRideId && (
                    <>
                      {"   ,"} {returnDate},
                      {returnPickUpStop &&
                        getTimeValue(
                          returnPickUpStop?.timeOfArrival,
                          timezoneFrom,
                          wasManuallyEdited,
                          disableConversion,
                          ridePatternId,
                        )}{" "}
                      -{" "}
                      {returnDropOutStop &&
                        getTimeValue(
                          returnDropOutStop?.timeOfArrival,
                          timezoneTo,
                          wasManuallyEdited,
                          disableConversion,
                          ridePatternId,
                        )}
                    </>
                  )}
                </p>
              </div>
            </TripDetailsWrapper>
            <FirstBlock passengers={passengers} />
            {/* <SecondBlock /> */}
            <ThirdBlock />
            <OffersAndBenefits
              rideId={ride?.id}
              price={ride?.price}
              noOfPassengers={passengers}
              autoAppliedCoupon={ride?.bestCampaignOffer?.coupon}
              returnRideId={returnRideId}
            />
          </FormContainer>
          <RideDetailsDiv>
            <OrderTitle>{t("orderTitle")}</OrderTitle>
            <RideDetails
              rideId={rideId}
              dropOutStopId={dropOutStopId}
              pickUpStopId={pickUpStopId}
              passengers={passengers}
              voucherData={hasVoucher ? voucherData : undefined}
              timezoneFrom={timezoneFrom}
              timezoneTo={timezoneTo}
              returnRideId={returnRideId}
            />
            {/* {hasVoucher ? (
              <VoucherSuccess
                onRemove={() => setVoucher("")}
                voucher={voucher}
                result={voucherData as ApplyVoucherResult}
              />
            ) : (
              <ApplyVoucher
                onChange={setVoucher}
                value={voucher}
                isInvalid={Boolean(voucherError)}
                isLoading={voucherLoading}
              />
            )} */}

            <div>
              <TermsConditionsCheckbox
                confirmed={termsConfirmed}
                setConfirmed={setTermsConfirmed}
              />
            </div>

            <ButtonWrapper
              fullWidth
              type="submit"
              data-testid="reservation-proceed-btn"
              disabled={!termsConfirmed}
              className={classes.enabledButton}
            >
              {t("proceedToPayment")}
            </ButtonWrapper>
          </RideDetailsDiv>
        </FormContext>
      </Container>
    </PageContainer>
  );
};

export const CreateReservationPage = () => {
  const { search } = useLocation();
  const result = React.useMemo(
    () => routes.newReservation.parse(search),
    [search],
  );
  if (result.valid) {
    return (
      <PhoheVerifyProvider>
        <ReservationPageContent {...result.params} />
      </PhoheVerifyProvider>
    );
  }
  return <Redirect to={routes.home.url()} />;
};
