import useSWR from "swr";
import { axios } from "../client";
import { ENDPOINTS } from "../endpoints";

export type Params = {
  email: string;
  phone: string;
};

export type BookingResponse = {
  statusCode: string;
  success: boolean;
  message: string;
  response?: any;
};

const fetchMe = (searchUrl: string) => () => {
  const url = `${searchUrl}`;
  const { data, error, isValidating } = useSWR(url);
  return { data, error, isValidating };
};

export const useFetchMe = fetchMe(ENDPOINTS.ME);

export const updateMe = ({ email, phone }: Params) => {
  const body = {
    email,
    phone,
  };
  return axios
    .patch<BookingResponse>(ENDPOINTS.UPDATE_ME, body)
    .then((r) => r.data)
    .catch((r) => r);
};
