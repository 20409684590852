import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import Typography from "@material-ui/core/Typography";
import DomainDisabledIcon from "@material-ui/icons/DomainDisabled";
import { updateOperatorCode } from "modules/api/client";
import { useHistory } from "react-router-dom";
import {defaultOperatorIN,defaultOperatorID,defaultOperatorPH} from 'modules/ui/constants'

const Container = styled.div`
  height: 100vh;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  display: flex;
  padding: 16px;
`;

export default function NotFound() {
  const history = useHistory();
  React.useEffect( () => {
    updateOperatorCode(defaultOperatorID);
    history.push("/"+defaultOperatorID);
  },[])
  const { t } = useTranslation("notFound");

  return (
    <Container>
      <DomainDisabledIcon color="inherit" fontSize="large" />
      <Typography
        variant="caption"
        style={{ textAlign: "center", marginTop: 16 }}
      >
        {t("title")} 
      </Typography>
    </Container>
  );
}
