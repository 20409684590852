import React from "react";
import firebase from "firebase/app";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Dialog } from "@material-ui/core";
import LogoutIcon from "./logout.svg";

const DialogContainer = styled(Dialog)`
  .MuiDialog-paper {
    text-align: center;
    padding: 30px 40px 40px;
  }
  p {
    font-weight: normal;
    font-size: 21px;
    line-height: 24px;
    color: ${(props) => props.theme.custom["emperor-color"]};
  }
  .button-wrapper {
    display: flex;
    justify-content: space-evenly;
    margin-top: 50px;
    button {
      max-width: 180px;
      width: 100%;
      height: 52px;
      font-size: 18px;
      border-radius: 4px;
      border: 1px solid ${(props) => props.theme.custom["redish-color"]};
      color: ${(props) => props.theme.custom["redish-color"]};
      background: ${(props) => props.theme.custom["white-color"]};
      cursor: pointer;
      &.cancel {
        border: 1px solid ${(props) => props.theme.custom.primary.main};
        color: ${(props) => props.theme.custom["white-color"]};
        background: ${(props) => props.theme.custom.secondary.main};
        margin-left: 15px;
      }
    }
  }
  @media (max-width: ${(props) =>
      props.theme.custom.breakpoints["xs-breakpoint"]}) {
    margin: 0 15px;
    .MuiDialog-paper {
      width: 100%;
      max-width: 100%;
      margin: 0;
      padding: 30px 0px 40px;
      .button-wrapper {
        justify-content: center;
        button {
          max-width: 150px;
        }
      }
    }
  }
`;

type LogoutModalProps = {
  open: boolean;
  setLoading: (value: boolean) => void;
  toggleModal: () => void;
};

export const LogoutModal: React.FC<LogoutModalProps> = ({
  open,
  toggleModal,
  setLoading,
}) => {
  const history = useHistory();
  const { t } = useTranslation("logout");
  const handleLogout = () => {
    setLoading(true);
    firebase
      .auth()
      .signOut()
      .then(
        function () {
          toggleModal();
          setTimeout(() => {
            setLoading(false);
            history.push("/");
          }, 100);
        },
        function (error) {
          console.error("Sign Out Error", error);
        }
      );
  };

  return (
    <DialogContainer
      // fullScreen={fullScreen}
      open={open}
      onClose={toggleModal}
      scroll="body"
    >
      <img src={LogoutIcon} alt="" />
      <p>{t("modalTitle")}</p>
      <div className="button-wrapper">
        <button onClick={handleLogout}>{t("yes")}</button>
        <button className="cancel" onClick={toggleModal}>
          {t("no")}
        </button>
      </div>
    </DialogContainer>
  );
};
