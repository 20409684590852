import React from "react";
import styled, { useTheme } from "styled-components";
import { useTranslation } from "react-i18next";
import { CircularProgress, Divider } from "@material-ui/core";
import { axios } from "modules/api/client";
import { ENDPOINTS } from "modules/api/endpoints";
import { Ticket } from "modules/entities";
import { TicketCard } from "./TicketCard";
import GoogleAnalyticsService from "../../../modules/utils/GoogleAnalyticsService";
import { LOG_EVENTS } from "../../../modules/utils/LogEvents";
import { getOperatorCode } from "../../../modules/utils/getOperatorCode";
import { MY_RIDE } from "modules/auth/constants";
import { featureToggle } from "modules/utils/featureToggle";
import { routes } from "modules/routing";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

const LoaderWrapper = styled.div`
  display: flex;
  position: fixed;
  top: 0;
  flex-direction: column;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9;
  align-items: center;
  justify-content: center;
  background: rgba(255, 255, 255, 0.5);
`;
const LoaderWrapper2 = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  z-index: 9;
  align-items: center;
  justify-content: center;
`;

const MyRideContainer = styled.div`
  max-width: 990px;
  margin: 20px auto;
  width: 100%;
`;

const TotalRide = styled.p`
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  text-align: right;
  color: #4f4f4f;
  margin-bottom: 6px;
  @media (max-width: ${(props) =>
      props.theme.custom.breakpoints["xs-breakpoint"]}) {
    padding: 0 10px;
  }
`;
const Heading = styled.h3`
  font-weight: 500;
  font-size: 30px;
  line-height: 35px;
  color: #4f4f4f;
  margin: 30px 0;
  @media (max-width: ${(props) =>
      props.theme.custom.breakpoints["xs-breakpoint"]}) {
    padding: 0 10px;
    margin: 15px 0;
  }
`;

const LoadmoreButton = styled.button`
  background-color: ${(props) => props.theme.custom.secondary.main};
  border: none;
  color: #fff;
  border-radius: 4px;
  max-width: 150px;
  width: 100%;
  text-align: center;
  height: 44px;
  margin: 30px auto;
  display: block;
  cursor: pointer;
`;
const NoRide = styled.div`
  font-size: 16px;
  color: #4f4f4f;
  text-align: center;
  margin-top: 50px;
`;

export const MyRides = () => {
  const history = useHistory();
  const theme: any = useTheme();
  const [hasMore, setHasMore] = React.useState(false);
  const [page, setPage] = React.useState(1);
  const [total, setTotal] = React.useState(0);
  const [isFetching, setIsFetching] = React.useState(false);
  const [loading, setLoading] = React.useState(true);
  const [data, setData]: any = React.useState([]);
  const perPage = 10;
  const { t } = useTranslation("myRides");
  const firebase_app = useSelector(
    (state: any) => state.remoteConfig.firebaseApp,
  );
  const criteria = {
    country: useSelector((state: any) => state.remoteConfig.countryName),
    operator: useSelector((state: any) => state.remoteConfig.operatorId),
  };

  React.useEffect(() => {
    async function getResult() {
      const toggleRides = await featureToggle(MY_RIDE, firebase_app, criteria);
      if (!toggleRides) {
        history.push(routes.home.url());
      }
    }

    getResult();
  }, [criteria, firebase_app, history]);

  const loadMoreItems = React.useCallback(() => {
    setIsFetching(true);
    const fetchMore = async () => {
      try {
        const res = await axios.get(
          `${ENDPOINTS.MY_RIDES}?page=${page}&perPage=${perPage}`,
        );
        setData((prevData: any) => {
          return prevData.concat(res?.data?.tickets);
        });
        setPage((prevPageNumber) => prevPageNumber + 1);
        setHasMore(res?.data?.meta?.page < res?.data?.meta?.totalPages);
        setIsFetching(false);
      } catch (error) {
        console.info(error);
      }
    };
    fetchMore();
  }, [page, perPage]);

  const updateRides = (tkt: Ticket) => {
    const newList = data.map((item: Ticket) => {
      if (item.id === tkt.id) {
        const updatedItem = {
          ...item,
          status: tkt.status,
        };

        return updatedItem;
      }

      return item;
    });
    setData(newList);
  };

  React.useEffect(() => {
    GoogleAnalyticsService.createEvent(LOG_EVENTS.LOG_EVENTS_NAME.SCREEN_VIEW, {
      firebase_screen: LOG_EVENTS.SCREEN_NAME.MY_RIDES_PAGE,
      firebase_screen_class: LOG_EVENTS.SCREEN_NAME.MY_RIDES_PAGE,
      operator_code: getOperatorCode(),
      source: "web",
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const fetchRides = async () => {
      try {
        const res = await axios.get(
          `${ENDPOINTS.MY_RIDES}?page=${page}&perPage=${perPage}`,
        );
        setData((prevData: any) => {
          return prevData.concat(res?.data?.tickets);
        });
        setTotal(res?.data?.meta?.total);
        setPage((prevPageNumber) => prevPageNumber + 1);
        setHasMore(res?.data?.meta?.page < res?.data?.meta?.totalPages);
        setIsFetching(false);
        setLoading(false);
      } catch (error) {
        console.info(error);
      }
    };
    if (page === 1) {
      fetchRides();
    }
  }, [page, perPage]);

  return (
    <>
      {loading ? (
        <LoaderWrapper>
          <CircularProgress color={theme?.custom?.primary?.main} />
        </LoaderWrapper>
      ) : (
        <MyRideContainer>
          <Heading>{t("myRides")}</Heading>
          {data.length === 0 ? (
            <NoRide>{t("noRides")}</NoRide>
          ) : (
            <>
              <TotalRide>
                {data?.length ? t("total") : ""} {total} {t("rides")}
              </TotalRide>
              <Divider />
              {data?.map((item: any, index: any) => (
                <TicketCard
                  key={index}
                  dropOutStopId={item.dropOutStopId}
                  pickUpStopId={item.pickUpStopId}
                  ride={item.ride}
                  timezone={item.timezone}
                  createdAt={item?.ride?.startTime}
                  price={item.price}
                  status={item.status}
                  id={item.id}
                  selectedTicket={{ ...item }}
                  setCancelId={updateRides}
                  paymentMethod={item.paymentMethod}
                />
              ))}{" "}
              {isFetching && (
                <LoaderWrapper2>
                  <CircularProgress color={theme?.custom?.primary?.main} />
                </LoaderWrapper2>
              )}
              {hasMore && (
                <LoadmoreButton onClick={loadMoreItems}>
                  Load more
                </LoadmoreButton>
              )}
            </>
          )}
        </MyRideContainer>
      )}
    </>
  );
};
