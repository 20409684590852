import React, { FormEvent } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { useDispatch } from "react-redux";
import { Controller, FormContext, useForm } from "react-hook-form";
import { isAfter, isSameDay } from "date-fns";
import InputLabel from "@material-ui/core/InputLabel";
import Grid from "@material-ui/core/Grid";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import {
  getRentalCategoryServices,
  getRentalServiceCategories,
} from "modules/api/methods";
import { routes } from "modules/routing";
import { useLanguage } from "modules/localization";
import { Notifications } from "modules/notifications";

const RentalWrapper = styled.div`
  max-width: 990px;
  margin: 0 auto;
  width: 100%;
  padding-bottom: 50px;
  margin-top: 20px;
  padding-top: 20px;

  @media (max-width: ${(props) =>
      props.theme.custom.breakpoints["md-breakpoint"]}) {
    max-width: fit-content;
    // background: #f6f6f6;
    padding-bottom: 0;
    padding-top: 0px;
  }
`;

const GridContainer = styled(Grid)`
  @media (max-width: ${(props) =>
      props.theme.custom.breakpoints["md-breakpoint"]}) {
    flex-direction: column-reverse;
  }

  .availableService {
    @media (max-width: ${(props) =>
        props.theme.custom.breakpoints["md-breakpoint"]}) {
      display: none;
    }
  }
`;

const GridCol = styled(Grid)`
  margin-bottom: 20px;

  @media (max-width: ${(props) =>
      props.theme.custom.breakpoints["md-breakpoint"]}) {
    padding: 0;
  }
  .space-after-asterik
    > div.MuiOutlinedInput-root.MuiInputBase-formControl
    > fieldset
    > legend
    > span {
    padding-right: 15px;
  }

  .red-asterik
    > label.MuiInputLabel-root.MuiInputLabel-formControl
    > span.MuiFormLabel-asterisk.MuiInputLabel-asterisk {
    color: red;
  }
  .grid-wrapper {
    background: #ffffff;
    border: 1px solid #e0e0e0;
    box-sizing: border-box;
    border-radius: 3px;
    padding: 30px 30px 100px;
    @media (max-width: ${(props) =>
        props.theme.custom.breakpoints["md-breakpoint"]}) {
      border: none;
      padding: 30px 15px 40px;
    }

    h4 {
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      color: #4f4f4f;
      margin-bottom: 18px;
    }
  }

  @media (max-width: ${(props) =>
      props.theme.custom.breakpoints["xs-breakpoint"]}) {
    margin-bottom: 15px;
  }
`;

const ButtonWrap = styled(({ ...rest }) => <button {...rest} />)`
  width: 100%;
  height: 40px;
  background: ${(props) => props.theme.custom.secondary.main};
  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.15);
  border-radius: 3px;
  color: #fff;
  border: none;
  margin: auto;
  display: block;
  font-size: 12px;
  line-height: 14px;
  font-weight: 700;
  cursor: pointer;
  margin-top: 20px;

  &:disabled: {
    background-color: ${(props) => props.theme.custom.secondaryInactive};
  }
`;

const InactiveSearchButton = styled.button`
  width: 100%;
  min-height: 40px;
  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.15);
  color: #fff;
  border: none;
  cursor: pointer;
  background-color: ${(props) => props.theme.custom.secondaryInactive};
  margin-top: 20px;
`;

const VehiclePrice = styled.span`
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: #19191d;
  color: #5a5b6a;
  margin-bottom: 12px;
`;
const VehicleInfo = styled.p`
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  background: #c9e8ff;
  border-radius: 3px;
  padding: 12px;
  line-height: 15px;
  color: #5a5b6a;
`;

const VehicleOuter = styled(({ ...rest }) => <Grid {...rest} />)`
  align-items: center;
  display: flex;
  height: 100%;
  flex-direction: column;
  flex-wrap: nowrap;
`;

const Section = styled.div`
  width: 100%;

  @media (min-width: ${(props) =>
      props.theme.custom.breakpoints["lg-breakpoint"]}) {
    width: 70%;
  }
`;

let autoComplete: any;

declare global {
  interface Window {
    google: any;
  }
}

const defaultService = {
  description: "",
  externalOperatorId: "",
  name: "select",
  _id: "",
};

type Props = {
  showTitle?: Boolean;
  serviceData: Array<any>;
  showNote?: Boolean;
};

export default function RentalService(props: Props) {
  const { showTitle, serviceData, showNote } = props;
  const methods = useForm();
  const { t } = useTranslation("rental");
  const history = useHistory();
  const dispatch = useDispatch();
  const language = useLanguage();
  const [services, setServices] = React.useState(serviceData);
  const [departDate, setDepartDate] = React.useState<MaterialUiPickersDate>(
    new Date(),
  );
  const [returnDate, setReturnDate] = React.useState<MaterialUiPickersDate>(
    new Date(),
  );
  const [mapLoaded, setMapLoaded] = React.useState(false);
  const [query, setQuery] = React.useState("");
  const autoCompleteRef = React.useRef(null);
  const [isDirty, setIsDirty] = React.useState<boolean>(false);
  const [lowestPriceData, setLowestPriceData] = React.useState<any>(null);
  const [rentalCategoryId, setRentalCategoryId] = React.useState<any>(null);

  React.useEffect(() => {
    if (showTitle || showNote) {
      (async () => {
        try {
          const response = await getRentalServiceCategories();
          setServices(response);
          dispatch({
            type: "RENTAL_SERVICE_CATEGORIES",
            payload: response,
          });
        } catch (err) {
          console.log(err);
        }
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showTitle, showNote]);

  React.useEffect(() => {
    methods.register("departureDate", { required: true });
    methods.register("returnDate", { required: true });

    methods.setValue("departureDate", new Date(), true);
    methods.setValue("returnDate", new Date(), true);
  }, [methods]);

  const handlePlaceSelect = async (updateQuery: any) => {
    const addressObject = autoComplete.getPlace();
    const tempAddr = addressObject.formatted_address;
    updateQuery(tempAddr);
  };

  React.useEffect(() => {
    const handleScriptLoad = (updateQuery: any, autoCompleteRef: any) => {
      autoComplete = new window.google.maps.places.Autocomplete(
        autoCompleteRef.current,
      );
      autoComplete.setFields(["address_components", "formatted_address"]);
      autoComplete.addListener("place_changed", () =>
        handlePlaceSelect(updateQuery),
      );
    };

    ((callback: any) => {
      if (!document.getElementById("googleMapsScript")) {
        const script = document.createElement("script");
        script.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}&libraries=places`;
        script.async = true;
        script.id = "googleMapsScript";
        script.onload = () => {
          setMapLoaded(true);
          callback.call();
        };
        document.body.appendChild(script);
      } else if (mapLoaded) {
        callback.call();
      }
    })(() => handleScriptLoad(setQuery, autoCompleteRef));
  }, [mapLoaded]);

  React.useEffect(() => {
    methods.control.setValue("address", query);
  }, [query, methods]);

  const onSubmit = async (e: FormEvent) => {
    e.preventDefault();
    const data = methods.getValues();
    data.returnDate = returnDate;
    data.departureDate = departDate;

    if (services.length === 0) {
      delete data.rentalServiceCategoryId;
    }

    if (departDate && returnDate) {
      if (isAfter(departDate, returnDate)) {
        Notifications.enqueueSnackbar(t("minDateError"), {
          preventDuplicate: true,
        });
        return;
      } else if (isSameDay(departDate, returnDate)) {
        Notifications.enqueueSnackbar(t("sameDateError"), {
          preventDuplicate: true,
        });
        return;
      }
    }
    if (!rentalCategoryId) {
      Notifications.enqueueSnackbar(t("selectService"), {
        preventDuplicate: true,
      });
      return;
    }
    const fromDate = departDate?.getTime();
    const toDate = returnDate?.getTime();
    // Push to rental service route
    if (fromDate && toDate) {
      const url = `/${language}${routes.rentalServiceListing.url({
        startDate: fromDate.toString(),
        returnDate: toDate.toString(),
        categoryId: rentalCategoryId,
      })}`;
      history.push(url);
    }
  };
  // const language = useLanguage();
  // const url = `/${language}${routes.rentalServiceListing.url(urlParams)}`;

  const onSelectCategory = (e: any) => {
    const { value } = e[0].target;
    setRentalCategoryId(value);
    fetchServices(value);
  };

  const fetchServices = async (value: any) => {
    const response = await getRentalCategoryServices(value);
    const result =
      response.length > 0 &&
      response.reduce((prev: any, curr: any) => {
        return prev.rate.amount < curr.rate.amount ? prev : curr;
      });

    setLowestPriceData(result);
  };

  const discountedPrice = (price: number, discount: number, type: string) => {
    let actualPrice;
    if (type === "Fixed") {
      actualPrice = price - discount;
    }
    if (type === "%") {
      actualPrice = (price * (100 - discount)) / 100;
    }
    return actualPrice?.toFixed(2);
  };

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <RentalWrapper>
        <GridContainer container spacing={3}>
          <GridCol item xs={12} md={12} lg={7}>
            <div className="grid-wrapper">
              {showTitle && <h4>{t("formHead")}</h4>}
              <FormContext {...methods}>
                <form onSubmit={onSubmit}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={12} lg={12}>
                      <FormControl
                        variant="outlined"
                        fullWidth
                        onClick={() => setIsDirty(true)}
                        className="space-after-asterik"
                      >
                        <InputLabel id="rentalServiceCategoryId">
                          {t("serviceHead")}{" "}
                          <span style={{ color: "red", marginLeft: "3px" }}>
                            *
                          </span>
                        </InputLabel>
                        <Controller
                          name="rentalServiceCategoryId"
                          rules={{ required: true }}
                          control={methods.control}
                          onChange={(e) => onSelectCategory(e)}
                          as={
                            <Select
                              id="rentalServiceId"
                              name="rentalServiceId"
                              required
                              labelId="rentalServiceId"
                              label={t("serviceHead")}
                              defaultValue={t("select")}
                            >
                              {services && services.length === 0 && (
                                <MenuItem value={t(`${defaultService.name}`)}>
                                  {t(`${defaultService.name}`)}
                                </MenuItem>
                              )}
                              {services.length &&
                                services?.map((service: any) => (
                                  <MenuItem
                                    key={service._id}
                                    value={service._id}
                                  >
                                    {service.name}
                                  </MenuItem>
                                ))}
                            </Select>
                          }
                        />
                      </FormControl>
                    </Grid>
                    {lowestPriceData ? (
                      <VehicleOuter item xs={12} md={12} lg={12}>
                        <Section>
                          <div className="vehicleContent">
                            <VehicleInfo>
                              {lowestPriceData?.description !== "" ? (
                                <div style={{ marginBottom: 10 }}>
                                  {/* 7 to 12 seaters, AC & Non AC, Manual & Automatic,
                                  Petrol & Diesel, Fully refundable */}
                                  {lowestPriceData?.description}
                                </div>
                              ) : null}
                              Price starting from &nbsp;
                              <VehiclePrice>
                                {lowestPriceData?.rate?.currency}{" "}
                                {discountedPrice(
                                  lowestPriceData.rate.amount,
                                  lowestPriceData?.discount?.value,
                                  lowestPriceData?.discount?.discountType,
                                )}{" "}
                                / {lowestPriceData?.rate?.duration}
                              </VehiclePrice>
                            </VehicleInfo>
                          </div>
                        </Section>
                      </VehicleOuter>
                    ) : null}

                    <Grid item xs={12} md={12} lg={6}>
                      <DateTimePicker
                        inputVariant="outlined"
                        fullWidth
                        autoOk
                        ampm={false}
                        disablePast
                        label={t("startDate")}
                        value={departDate}
                        allowKeyboardControl={false}
                        onChange={(date) => {
                          setDepartDate(date);
                          methods.control.setValue("departureDate", date, true);
                        }}
                        required
                        className="red-asterik"
                      />
                    </Grid>
                    <Grid item xs={12} md={12} lg={6}>
                      <DateTimePicker
                        inputVariant="outlined"
                        fullWidth
                        autoOk
                        ampm={false}
                        disablePast
                        label={t("returnDate")}
                        value={returnDate}
                        allowKeyboardControl={false}
                        onChange={(date) => {
                          setReturnDate(date);
                          methods.control.setValue("returnDate", date, true);
                        }}
                        required
                        minDate={departDate}
                        minDateMessage={t("minDateError")}
                        className="red-asterik"
                      />
                    </Grid>
                    <Grid item xs={12} md={12} lg={12}>
                      {!(
                        isDirty &&
                        departDate?.getDate() !== returnDate?.getDate()
                      ) ? (
                        <InactiveSearchButton>
                          {t("search")}
                        </InactiveSearchButton>
                      ) : (
                        <ButtonWrap type="submit">{t("search")}</ButtonWrap>
                      )}
                    </Grid>
                  </Grid>
                </form>
              </FormContext>
            </div>
          </GridCol>
        </GridContainer>
      </RentalWrapper>
    </MuiPickersUtilsProvider>
  );
}
