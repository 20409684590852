import * as React from "react";
import { Controller, FormContext, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next"; 
import { useSelector } from "react-redux";
import styled from "styled-components";
import { IconButton, makeStyles, useTheme } from "@material-ui/core";
import { TWO_WAY_BOOKING } from "modules/auth/constants";
import { useQueryParamState } from "modules/routing";
import { DatePicker } from "modules/ui/organisms/DatePicker/DatePicker";
import { breakpoints } from "modules/ui/variables";
import { SearchFormConstants } from "modules/ui/constants";
import { TEST_ID } from "modules/ui";
import { featureToggle } from "modules/utils/featureToggle";
import { SearchInput } from "./SearchInput/SearchInput";
import { SearchSelect } from "../molecules/PassengerAmountSelect/PassengerAmountSelect";
import { SearchButton } from "../molecules/SearchButton/SearchButton";
import { getOperatorCode } from "../../../modules/utils/getOperatorCode";
import GoogleAnalyticsService from "../../../modules/utils/GoogleAnalyticsService";
import { LOG_EVENTS } from "../../../modules/utils/LogEvents";

const InactiveSearchButton = styled.button`
  min-width: 132px; 
  min-height: 42px;
  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.15);
  color: #fff;
  border: none;
  cursor: pointer;
  background-color: ${(props) => props.theme.custom.secondaryInactive};
`;

const SearchWrapper = styled.form`
  display: flex;
  box-sizing: border-box;
  max-width: 100%;
  max-height: 101px;
  background-color: ${(props) => props.theme.custom["white-color"]};
  padding: 17px;
  // border-top: 1px solid ${(props) => props.theme.custom["silver-color"]};
  // box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.3);
  // border-radius: 3px;
  &.withoutShadow {
    box-shadow: none;
  }
  @media (min-width: ${(props) =>
    props.theme.custom.breakpoints["xs-breakpoint"]}) and (max-width: ${(
  props,
) => props.theme.custom.breakpoints["lg-breakpoint"]}) {
    width: 100%;
  }
  @media (max-width: ${(props) =>
    props.theme.custom.breakpoints["lg-breakpoint"]}) {
    flex-wrap: wrap;
    justify-content: space-between;
    background-color: #fff;
    border: none;
    box-shadow: none;
    border-radius: 0px;
    max-height: 100%;
  }
`;

const FromToBlock = styled.div`
  display: flex;
  position: relative;
  @media (min-width: ${(props) =>
      props.theme.custom.breakpoints["xs-breakpoint"]}) and (max-width: ${(
      props,
    ) => props.theme.custom.breakpoints["lg-breakpoint"]}) {
    width: 100%;
    margin-bottom: 15px;
  }
  @media (max-width: ${(props) =>
      props.theme.custom.breakpoints["lg-breakpoint"]}) {
    width: 100%;
    flex-direction: column;
  }
  @media (max-width: ${(props) =>
      props.theme.custom.breakpoints["md-breakpoint"]}) {
    width: 100%;
    flex-direction: row;
    display: flex;
  }
  @media (max-width: ${(props) =>
      props.theme.custom.breakpoints["xs-breakpoint"]}) {
    width: 100%;
    flex-direction: column;
    display: flex;
  }
`;

const SelectWrapper = styled.div`
  padding-left: 12px;
  &.passenger {
    @media (max-width: ${(props) =>
        props.theme.custom.breakpoints["md-breakpoint"]}) {
      width: 26%;
    }
    @media (max-width: ${(props) =>
        props.theme.custom.breakpoints["xs-breakpoint"]}) {
      width: 100%;
    }
  }
  &.dateWrapper {
    padding-left: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media (max-width: ${(props) =>
        props.theme.custom.breakpoints["md-breakpoint"]}) {
      width: 44%;
    }
    @media (max-width: ${(props) =>
        props.theme.custom.breakpoints["xs-breakpoint"]}) {
      width: 100%;
      flex-direction: column;
      & > div {
        width: 100%;

        &:last-child {
          margin-top: 16px;
        }
      }
    }
  }
  @media (max-width: ${(props) =>
      props.theme.custom.breakpoints["lg-breakpoint"]}) {
    width: 24%;
    padding-left: 0px;
  }
  @media (max-width: ${(props) =>
      props.theme.custom.breakpoints["xs-breakpoint"]}) {
    width: 100%;
    margin-bottom: 15px;
  }
`;

const ButtonWrapper = styled.div`
  padding-top: 23px;
  padding-left: 12px;

  @media (max-width: ${(props) =>
      props.theme.custom.breakpoints["lg-breakpoint"]}) {
    width: 23%;
    padding-left: 0px;
    padding-top: 0px;
    button {
      width: 100%;
      margin-top: 0px;
    }
  }
  @media (max-width: ${(props) =>
      props.theme.custom.breakpoints["xs-breakpoint"]}) {
    width: 100%;
    padding-top: 0px;
  }
`;

const ReverseIcon = (props: any) => (
  <svg
    width="30"
    height="30"
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="15" cy="15" r="15" fill="#CBCFD2" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.0076 21.5162L11.3985 19.9071L17.4219 19.9071C19.3589 19.9071 20.9292 18.3368 20.9292 16.3997L20.9292 14.2953L19.5263 14.2953L19.5263 16.3997C19.5263 17.562 18.5841 18.5041 17.4219 18.5041L11.3999 18.5041L13.0076 16.8964L12.0156 15.9044L8.71369 19.2063L12.0156 22.5082L13.0076 21.5162ZM16.2243 8.4788L17.8327 10.0872L11.8101 10.0872C9.87302 10.0872 8.30273 11.6575 8.30273 13.5945L8.30273 15.6989L9.70567 15.6989L9.70567 13.5945C9.70567 12.4323 10.6478 11.4901 11.8101 11.4901L17.8327 11.4901L16.2243 13.0985L17.2164 14.0905L20.5182 10.7887L17.2164 7.48677L16.2243 8.4788Z"
      fill="white"
    />
  </svg>
);

type DefaulValues = {
  departureType: string | undefined;
  arrivalType: string;
  departureName?: string;
  arrivalName?: string;
  arrivalId: string;
  departureId: string;
  passengersDefault: number;
  dateDefault: Date;
  returnDate?: Date;
};

type Props = {
  defaultValues?: DefaulValues;
  onSearchResult?: boolean;
};

const passengerOptions = [
  { value: 1 },
  { value: 2 },
  { value: 3 },
  { value: 4 },
];

const useClasses = makeStyles({
  reverseButton: {
    padding: 0,
    position: "absolute",
  },
  [`@media(max-width: ${breakpoints.xs}px)`]: {
    reverseButton: {
      top: 34,
      left: "calc(100% - 40px)",
      transform: "rotate(90deg)",
    },
  },
  [`@media(min-width: ${breakpoints.xs}px) and (max-width: ${breakpoints.lg}px)`]: {
    reverseButton: {
      top: 30,
      left: "calc(50% - 15px)",
    },
    fromInputOutline: {
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0,
    },
    toInputOutline: {
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,
    },
  },
  [`@media(min-width: ${breakpoints.lg}px)`]: {
    fromInputOutline: {
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0,
    },
    toInputOutline: {
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,
    },
    reverseButton: {
      top: 30,
      left: 165,
    },
  },
});

export const SearchComponent = ({
  defaultValues = {},
  onSearchResult,
  onSearch,
}: any) => {
  const {
    departureType,
    arrivalType,
    departureName,
    arrivalName,
    arrivalId,
    departureId,
    passengersDefault,
    dateDefault,
  } = defaultValues;
  const { t } = useTranslation("search");
  const methods = useForm();
  const muiClasses = useClasses();
  const [minimumDate, setMinimumDate] = React.useState<any>(dateDefault || new Date());
  const [isDirty, setIsDirty] = React.useState<boolean>(false);
  const defaultDateValue = dateDefault || new Date();
  const [returnDate] = useQueryParamState("returnDate");
  const hasReturnDate =
    returnDate || methods.getValues(SearchFormConstants.DATE_TO);
  const [showReturn, setShowReturn] = React.useState<boolean>(true);
  const firebase_app = useSelector(
    (state: any) => state.remoteConfig.firebaseApp,
  );
  const criteria = {
    country: useSelector((state: any) => state.remoteConfig.countryName),
    operator: useSelector((state: any) => state.remoteConfig.operatorId),
  };


  const onSubmit = (data: any) => {};

  const reverseLocations = (e: React.MouseEvent<HTMLButtonElement>) => {
    const values = methods.getValues();
    const { from, to } = values;
    methods.setValue(SearchFormConstants.FROM, to);
    methods.setValue(SearchFormConstants.TO, from);
  };

  React.useEffect(() => {
    async function getResult() {
      const toggleTwoWayBooking = await featureToggle(
        TWO_WAY_BOOKING,
        firebase_app,
        criteria,
      );
      setShowReturn(toggleTwoWayBooking);
    }

    getResult();
  }, [criteria, firebase_app]);

  React.useEffect(() => {
    if (departureId && departureName && departureType) {
      const defaultFrom = {
        id: departureId,
        name: departureName,
        type: departureType,
      };
      GoogleAnalyticsService.createEvent(
        LOG_EVENTS.LOG_EVENTS_NAME.SELECT_SEARCH_FROM,
        {
          home: LOG_EVENTS.LOG_METHOD_VALUE.SEARCH_FROM_SELECTED,
          stop_name: departureName,
          operator_code: getOperatorCode(),
          source: "web",
        },
      );
      GoogleAnalyticsService.createEvent(
        LOG_EVENTS.LOG_EVENTS_NAME.SEARCH_SUGGEST_FROM,
        {
          search_suggestions:
            LOG_EVENTS.LOG_METHOD_VALUE.SEARCH_SUGGESTION_FROM_FILLED,
          intercity: "intercity",
          intracity: "intracity",
          operator_code: getOperatorCode(),
          source: "web",
        },
      );
      methods.setValue(SearchFormConstants.FROM, defaultFrom);
    }

    if (arrivalId && arrivalName && arrivalType) {
      const defaultTo = {
        id: arrivalId,
        name: arrivalName,
        type: arrivalType,
      };
      GoogleAnalyticsService.createEvent(
        LOG_EVENTS.LOG_EVENTS_NAME.SELECT_SEARCH_TO,
        {
          home: LOG_EVENTS.LOG_METHOD_VALUE.SEARCH_TO_SELECTED,
          stop_name: arrivalName,
          operator_code: getOperatorCode(),
          source: "web",
        },
      );
      GoogleAnalyticsService.createEvent(
        LOG_EVENTS.LOG_EVENTS_NAME.SEARCH_SUGGEST_TO,
        {
          search_suggestions:
            LOG_EVENTS.LOG_METHOD_VALUE.SEARCH_SUGGESTION_TO_FILLED,
          intercity: "intercity",
          intracity: "intracity",
          operator_code: getOperatorCode(),
          source: "web",
        },
      );
      methods.setValue(SearchFormConstants.TO, defaultTo);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    departureId,
    arrivalId,
    departureName,
    arrivalName,
    departureType,
    arrivalType,
  ]);

  React.useEffect(() => {
    if (returnDate && !Array.isArray(returnDate)) {
      methods.setValue(SearchFormConstants.DATE_TO, new Date(returnDate));
      GoogleAnalyticsService.createEvent(
        LOG_EVENTS.LOG_EVENTS_NAME.SELECT_DATE_TO,
        {
          home: LOG_EVENTS.LOG_METHOD_VALUE.DATE_TO_SELECTED,
          value: new Date(returnDate),
          operator_code: getOperatorCode(),
          source: "web",
        },
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [returnDate]);

  const fromRef = React.useRef<HTMLInputElement>();
  const toRef = React.useRef<HTMLInputElement>();
  const theme: any = useTheme();
  
  return (
    <FormContext {...methods}>
      <SearchWrapper
        onSubmit={methods.handleSubmit(onSubmit)}
        className={onSearchResult ? "withoutShadow" : ""}
      >
        <FromToBlock onClick={() => setIsDirty(true)}>
          <Controller
            name={SearchFormConstants.FROM}
            rules={{ required: true }}
            onFocus={() => {
              fromRef.current?.focus();
              setIsDirty(true);
            }}
            as={
              <SearchInput
                outlineClassName={muiClasses.fromInputOutline}
                name={SearchFormConstants.FROM}
                label={t("fromLabel")}
                placeholder={t("fromLabel")}
                testId={TEST_ID.SEARCH_FROM_INPUT}
                defaultValue={departureName}
                inputRef={fromRef}
              />
            }
            defaultValue={null}
          />
          <Controller
            name={SearchFormConstants.TO}
            defaultValue={null}
            rules={{ required: true }}
            onFocus={() => {
              toRef.current?.focus();
              setIsDirty(true);
            }}
            as={
              <SearchInput
                name={SearchFormConstants.TO}
                label={t("toLabel")}
                outlineClassName={muiClasses.toInputOutline}
                placeholder={t("toLabel")}
                iconClassName="toIconWrapper"
                testId={TEST_ID.SEARCH_TO_INPUT}
                inputRef={toRef}
                defaultValue={arrivalName}
              />
            }
          />
          <IconButton
            type="button"
            tabIndex={-1}
            onClick={reverseLocations}
            className={muiClasses.reverseButton}
          >
            <ReverseIcon color={theme?.custom?.primary?.main} />
          </IconButton>
        </FromToBlock>
        <SelectWrapper className="dateWrapper" onClick={() => setIsDirty(true)}>
          <Controller
            as={
              <DatePicker
                testId={TEST_ID.SEARCH_DATEPICKER}
                label={t("datePickerLabel")}
              />
            }
            name={SearchFormConstants.DATE}
            defaultValue={defaultDateValue}
            minBookingDate={new Date()}
            onDateSelect={(date: Date) => {
              setMinimumDate(date);
              if (date.getTime() > minimumDate.getTime()) {
                if (methods.getValues(SearchFormConstants.DATE_TO))
                  methods.setValue(SearchFormConstants.DATE_TO, new Date(date));
                GoogleAnalyticsService.createEvent(
                  LOG_EVENTS.LOG_EVENTS_NAME.SELECT_DATE_FROM,
                  {
                    home: LOG_EVENTS.LOG_METHOD_VALUE.DATE_FROM_SELECTED,
                    value: new Date(date),
                    operator_code: getOperatorCode(),
                    source: "web",
                  },
                );
              }
            }}
          />
          {showReturn ? (
            <Controller
              as={
                <DatePicker
                  testId={TEST_ID.RETURN_SEARCH_DATEPICKER}
                  label={t("returnDatedatePickerLabel")}
                  placeHolder="Return(optional)"
                />
              }
              name={SearchFormConstants.DATE_TO}
              minBookingDate={minimumDate}
              showPlaceholder={hasReturnDate ? false : true}
            />
          ) : null}
        </SelectWrapper>
        <SelectWrapper className="passenger" onClick={() => setIsDirty(true)}>
          <Controller
            as={
              <SearchSelect
                label={t("passengerLabel")}
                options={passengerOptions}
              />
            }
            name={SearchFormConstants.PASSENGERS}
            defaultValue={passengersDefault || 1}
          />
        </SelectWrapper>
        <ButtonWrapper>
          {isDirty ? (
            <SearchButton onSearch={onSearch} />
          ) : (
            <InactiveSearchButton> {t("button")} </InactiveSearchButton>
          )}
        </ButtonWrapper>
      </SearchWrapper>
    </FormContext>
  );
};
