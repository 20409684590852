export const TEST_ID = {
  DATE_CAROUSEL: "date-carousel",
  OTP_INPUTS_CONTAINER: "otp-inputs-container",
  VERIFY_OTP: "verify-otp-button",
  OTP_RESEND: "otp-resend-button",
  VERIFY_PHONE: "phone-verification-button",
  PASSENGERS_COUNT_SELECT: "passengers-count-select",
  PASSENGERS_COUNT_DROPDOWN: "passengers-count-select-dropdown",
  SEARCH_BUTTON: "search-button",
  SEARCH_FROM_INPUT: "search-from-input",
  SEARCH_TO_INPUT: "search-to-input",
  SEARCH_DATEPICKER: "search-datepicker",
  RETURN_SEARCH_DATEPICKER: "return-search-datepicker",
  SHOPPING_CART: "shopping-cart",
  MOBILE_SHOPPING_CART: "shopping-cart",
  LANGUAGE_SELECT: "language-select",
  LANGUAGE_SELECT_DROPDOWN: "language-select-dropdown",
  PHONE_EMAIL_TEXT_BOX: "phone-email-text",
  COUNTRY_SELECT_BUTTON: "country-select-btn",
  COUNTRY_SEARCH: "country-search",
};
