import * as React from "react";
import { useHistory } from "react-router-dom";
import { AxiosError } from "axios";
import styled from "styled-components";
import {
  Dialog,
  DialogContent,
  useMediaQuery,
  useTheme,
  withStyles,
} from "@material-ui/core";
import { cancelReserve } from "modules/api";
import { useLanguage } from "modules/localization";
import { Button } from "modules/ui";
import { ReactComponent as BusIcon } from "./bus-ticket.svg";
import {getOperatorCode}  from "../../../utils/getOperatorCode";
import GoogleAnalyticsService from "../../../utils/GoogleAnalyticsService";
import {LOG_EVENTS} from "../../../utils/LogEvents";
// import classes from "./CancellationDialog.module.css";

type ConfirmationDialogProps = {
  open: boolean;
  onClose: () => void;
  ticketId: string;
  /**
   * The url to navigate after cancelling the ticket.
   */
  backUrl: string;
  unblockNavigation: () => void;
};

const Title = styled.h1`
  margin: 0;
  font-weight: 900;
  font-size: 22px;
  line-height: 24px;
  text-align: center;
  color: ${(props) => props.theme.custom["limed-spruce"]};
`;

const Description = styled.p`
  font-size: 18px;
  line-height: 21px;
  text-align: center;
  font-feature-settings: "lnum" on, "case" on, "kern" off;
`;

const Actions = styled.div`
  display: grid;
  width: 100%;
  gap: 27px;
  grid-template-columns: 1fr 1fr;
`;

const BusIconWrapper = styled(({ ...rest }) => <BusIcon {...rest} />)`
  width: 176.21px;
  height: 148.05px;
  @media (max-width: ${(props) =>
      props.theme.custom.breakpoints["xs-breakpoint"]}) {
    width: 309px;
    height: 164px;
    margin-bottom: 42px;
  }
`;

const StyledContent = withStyles((theme) => ({
  root: {
    padding: "20px 14px 10px 14px !important",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    [theme.breakpoints.up("sm")]: {
      padding: "30px 40px 40px 40px !important",
      maxWidth: 530,
      boxSizing: "border-box",
    },
  },
}))(DialogContent);

const DangerButton = withStyles({
  root: {
    height: 60,
    width: "100%",
  },
  outlinedPrimary: {
    color: "#FF5757",
    border: "2px solid #FF5757",
    "&:hover": {
      border: "2px solid #FF5757",
    },
  },
  containedPrimary: {
    backgroundColor: "#FF5757",
    color: "white",
    "&:hover": {
      backgroundColor: "#FF5757",
    },
  },
})(Button);

export const CancellationDialog: React.FC<ConfirmationDialogProps> = ({
  open,
  onClose,
  ticketId,
  backUrl,
  unblockNavigation,
}) => {
  const theme = useTheme();
  const history = useHistory();
  const lang = useLanguage();
  const fullScreen = useMediaQuery(theme.breakpoints.down("xs"));
  const [pending, setPending] = React.useState(false);

  React.useEffect(() => {
    GoogleAnalyticsService.createEvent(LOG_EVENTS.LOG_EVENTS_NAME.SHOWN_CANCEL_POP_UP, {
      payment_process: LOG_EVENTS.LOG_METHOD_VALUE.CANCEL_POP_UP_SHOWN,
      operator_code: getOperatorCode(),
      source:"web"
    });
  }, []);

  const handleCancel = React.useCallback(() => {
   
    setPending(true);
    const redirect = () => {
      const path = `/${lang}${backUrl}`;
      GoogleAnalyticsService.createEvent(LOG_EVENTS.LOG_EVENTS_NAME.CANCEL_PAYMENT_SUCCESS, {
        payment_process: LOG_EVENTS.LOG_METHOD_VALUE.PAYMENT_SUCCESS_CANCELED,
        operator_code: getOperatorCode(),
        source:"web"
      });
      unblockNavigation();
      setTimeout(() => {
        history.replace(path);
      }, 50);
    };

    if (ticketId) {
      return cancelReserve(ticketId)
        .then(redirect)
        .catch((err: AxiosError) => {
          if (err?.response?.status === 404) {
            redirect();
          }
        })
        .finally(() => {
          setPending(false);
        });
    } else {
      redirect();
    }
  }, [ticketId, backUrl, history, unblockNavigation, lang]);

  const continueClicked = () => {
    GoogleAnalyticsService.createEvent(LOG_EVENTS.LOG_EVENTS_NAME.CANCEL_PAYMENT_CANCEL, {
      payment_process: LOG_EVENTS.LOG_METHOD_VALUE.USER_CANCEL_PAYMENT,
      operator_code: getOperatorCode(),
      source:"web"
    });
    onClose();
  }

  return (
    <Dialog
      open={open}
      fullScreen={fullScreen}
      onClose={!pending ? onClose : undefined}
    >
      <StyledContent>
        <BusIconWrapper />
        <Title>Are you sure you want to end your booking progress?</Title>
        <Description>
          Cancelling your payment progress will cancel your booking progress
          using the current payment method.
        </Description>
        <Actions>
          <DangerButton
            disabled={pending}
            variant="outlined"
            onClick={handleCancel}
          >
            Yes, end now
          </DangerButton>
          <DangerButton
            disabled={pending}
            autoFocus
            variant="contained"
            onClick={continueClicked}
          >
            Continue
          </DangerButton>
        </Actions>
      </StyledContent>
    </Dialog>
  );
};
