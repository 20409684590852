import * as React from "react";

export const useModalVisibility = (): [boolean, () => void] => {
  const [isModalVisible, setIsModalVisible] = React.useState(false);

  const toggleModalVisibility: () => void = React.useCallback(() => {
    setIsModalVisible((prevVisibility) => {
      return !prevVisibility;
    });
  }, []);

  return [isModalVisible, toggleModalVisibility];
};
