import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { GuideDropDown } from "../../molecules/GuideDropDown/GuideDropDown";
// import classes from "./PaymentsGuide.module.css";

type Props = {
  method: string;
};

const GuideWrapper = styled.div`
  background: #ffffff;
  border: 1px solid #e0e0e0;
  box-sizing: border-box;
  border-radius: 3px;
  padding: 0 0px 0px;
  max-width: 650px;
`;

const GuideTitle = styled.p`
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 21px;
  margin: 0;
  color: ${(props) => props.theme.custom["emperor-color"]};
  @media (max-width: ${(props) =>
      props.theme.custom.breakpoints["xs-breakpoint"]}) {
    margin: 5px 0px -6px;
    text-transform: capitalize;
  }
`;

export const PaymentsGuide: React.FC<Props> = ({ method }) => {
  const { t } = useTranslation("paymentsGuide");
  const guideList: Array<{
    title: string;
    steps: Array<string>;
  }> = t(`guide.${method}`, { returnObjects: true });
  const [expanded, setExpanded] = React.useState<number | boolean>(0);

  const handleChange =
    (index: number) => (event: React.ChangeEvent<{}>, newExpanded: boolean) => {
      setExpanded(newExpanded ? index : false);
    };

  return (
    <>
      <GuideTitle>{t("title")}</GuideTitle>
      <GuideWrapper>
        {guideList.map((item, index) => (
          <GuideDropDown
            key={item.title}
            index={index}
            title={item.title}
            list={item.steps}
            expanded={expanded}
            onChange={handleChange}
          />
        ))}
      </GuideWrapper>
    </>
  );
};
