import * as React from "react";
import { useRouteMatch, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { useMediaQuery, useTheme } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { CircularProgress } from "@material-ui/core";
import { useRide, useTickets } from "modules/api";
import { getRefundStatus } from "modules/api/methods/getRefundStatus";
import { Ride, Ticket, TicketCancelStatus } from "modules/entities";
import { CANCELLATION } from "modules/auth/constants";
import { Redirect, routes } from "modules/routing";
import { useModalVisibility } from "modules/utils/hooks/useModalVisibility";
import { featureToggle } from "modules/utils/featureToggle";
import { TicketDetails } from "modules/ticket/organisms/TicketDetails";
import { QrCode } from "modules/ticket/molecules/QrCode";
import { PriceSummary } from "modules/ticket/molecules/PriceSummary";
// import { TicketsList } from "modules/ticket/molecules/TicketsList";
// import { Card } from "modules/ticket/atoms/Card";
import { RideDetails } from "modules/ticket/organisms/RideDetails";
// import classes from "./TicketPreview.module.css";
import { CancelReservationConfirmation } from "../organisms/CancelReservationConfirmation";
import Info from "../organisms/info.svg";
import RefundStatus from "../molecules/RefundStatus";
import { getOperatorCode } from "../../../modules/utils/getOperatorCode";
import GoogleAnalyticsService from "../../../modules/utils/GoogleAnalyticsService";
import { LOG_EVENTS } from "../../../modules/utils/LogEvents";
import { useSelector } from "react-redux";
import { updateOperatorCode } from "modules/api/client";

const MainContainer = styled.div`
  max-width: 730px;
  flex: 1;
  @media (max-width: ${(props) =>
    props.theme.custom.breakpoints["lg-breakpoint"]}) {
    padding: 0px 16px;
  }
`;

const Title = styled.h1`
  font-weight: 500;
  font-size: 24px;
  line-height: 18px;
  margin: 30px 0px;
  color: ${(props) => props.theme.custom["emperor-color"]};
`;

const CardWrapper = styled.div`
  padding: 10px;
  @media (max-width: ${(props) =>
    props.theme.custom.breakpoints["xs-breakpoint"]}) {
    background: ${(props) => props.theme.custom["white-color"]};
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
    border-radius: 3px;
  }
`;

const QrCodeWrapper = styled.div`
  padding: 0px 10px 8px;
  border-radius: 5px;
  // background: ${(props) => props.theme.custom["gray-color"]};
  color: white;
`;

const RideDetailWrapper = styled.div`
  display: flex;
  margin-bottom: 18px;
`;

const PageContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const CancelButton = styled.button`
  width: 100%;
  height: 44px;
  font-size: 16px;
  border-radius: 4px;
  border: 1px solid #ff5757;
  color: #ff5757;
  background: ${(props) => props.theme.custom["white-color"]};
  cursor: pointer;
  font-weight: 500;
  margin: 25px 0 30px;
`;

const CancelToaster = styled(Alert)`
  background: #ff5757;
  border-radius: 5px;
  margin-bottom: 25px;
  color: #fff;
  .MuiAlert-icon {
    display: none;
  }
  .info-icon {
    width: 16px;
    margin-right: 12px;
  }
  .MuiAlert-message {
    width: 100%;
    flex-direction: row;
    justify-content: flex-start;
    padding-left: 25px;
  }
`;
const LoaderWrapper = styled.div`
  display: flex;
  position: absolute;
  top: 0;
  flex-direction: column;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9;
  align-items: center;
  justify-content: center;
  background: rgba(255, 255, 255, 0.8);
`;

type Status = {
  status: string;
  timestamp: Date | string;
};
interface RefundStatus {
  customerSupport: {
    email: string;
    phoneNumber: string;
  };
  filteredStatusHistory: Array<Status>;
  refundAmount: {
    deduction: {
      currency: string;
      value: number;
    };
    refund: {
      currency: string;
      value: number;
    };
    total: {
      currency: string;
      value: number;
    };
  };
}

type TicketPreviewProps = {
  tickets: Array<Ticket>;
  ride: Ride;
  ticketId?: string;
  ticketInfo?: any;
};

export const TicketPreview: React.FC<TicketPreviewProps> = ({
  tickets,
  ride,
  ticketInfo,
  // ticketId,
}) => {
  const { ticketId }: any = useParams();
  const { t } = useTranslation("ticket");
  const ticketData = tickets.filter((item) => item.id === ticketId);
  const selectedTicket: Ticket = ticketData && ticketData[0];
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [cancellation, setCancellation] = React.useState(false);
  const [modalVisibility, setModalVisibility] = useModalVisibility();
  const [refundStatus, setRefundStatus] = React.useState<
    RefundStatus | undefined
  >(undefined);
  const [showCancelBtn, setShowCancelBtn] = React.useState(true);
  const firebase_app = useSelector(
    (state: any) => state.remoteConfig.firebaseApp,
  );
  const criteria = {
    country: useSelector((state: any) => state.remoteConfig.countryName),
    operator: useSelector((state: any) => state.remoteConfig.operatorId),
  };

  React.useEffect(() => {
    async function getResult() {
      const toggleCancelTicket = await featureToggle(
        CANCELLATION,
        firebase_app,
        criteria,
      );
      setShowCancelBtn(toggleCancelTicket);
    }

    getResult();
  }, [criteria, firebase_app]);

  React.useEffect(() => {
    GoogleAnalyticsService.createEvent(LOG_EVENTS.LOG_EVENTS_NAME.SCREEN_VIEW, {
      firebase_screen: LOG_EVENTS.SCREEN_NAME.TICKET_DETAILS_SCREEN,
      firebase_screen_class: LOG_EVENTS.SCREEN_NAME.TICKET_DETAILS_SCREEN,
      operator_code: getOperatorCode(),
      source: "web",
    });
    if (ticketId) {
      (async () => {
        const result = await getRefundStatus(ticketId);
        setRefundStatus(result);
      })();
    }
  }, [ticketId]);

  if (!selectedTicket) {
    return (
      <LoaderWrapper>
        <CircularProgress color="secondary" />
      </LoaderWrapper>
    );
  }

  return (
    <MainContainer>
      <Title>{t("ticketDetails")}</Title>
      {/* <TicketsList
        tickets={tickets}
        selectedTicket={selectedTicket}
        onChange={setTicket}
      /> */}
      {TicketCancelStatus.includes(selectedTicket?.status) || cancellation ? (
        <CancelToaster severity="error">
          {" "}
          <img src={Info} alt="" className="info-icon" />
          {t("cancelMessage")}
        </CancelToaster>
      ) : (
        ""
      )}
      <CardWrapper>
        <QrCodeWrapper>
          {tickets.map((t) => (
            <RideDetailWrapper key={t.id}>
              <QrCode ticket={t} />
              <TicketDetails ticket={t} ride={ride} />
            </RideDetailWrapper>
          ))}
        </QrCodeWrapper>
        {isMobile && (
          <RideDetails
            style={{ gridColumn: "1/3" }}
            ticket={selectedTicket}
            ride={ride}
          />
        )}
      </CardWrapper>
      <PriceSummary tickets={tickets} ticketInfo={ticketInfo} />
      {selectedTicket.ride?.refundRule?.type !== "non_refundable" &&
        TicketCancelStatus.includes(selectedTicket?.status) && (
          <RefundStatus refundStatus={refundStatus} />
        )}
      {modalVisibility && (
        <CancelReservationConfirmation
          open={modalVisibility}
          toggleModal={setModalVisibility}
          ticket={selectedTicket}
          tickets={tickets}
          ride={ride}
          setCancellation={setCancellation}
          ticketInfo={ticketInfo}
        />
      )}
      {TicketCancelStatus.includes(selectedTicket?.status) || cancellation ? (
        ""
      ) : showCancelBtn && selectedTicket.paymentMethod !== 'cash' ? (
        <CancelButton onClick={() => setModalVisibility()}>
          {t("cancelTrip")}
        </CancelButton>
      ) : null}
    </MainContainer>
  );
};

export const TicketPage = () => {
  let operator = localStorage.getItem("operatorCode");
  if (operator) {
    updateOperatorCode(operator);
  }

  const match = useRouteMatch<{ language: string; ticketId: string }>(
    `${routes.languagePrefix.pattern}${routes.ticketPreview.pattern}`,
  );
  const ticketId = match?.params?.ticketId ?? null;
  const { data: ticketData } = useTickets(ticketId);

  // const { data: ticketData } = useTickets(match?.params?.ticketId ?? null);
  const { data: rideData } = useRide(ticketData?.ticket?.rideId ?? null, 1);
  if (!match) {
    return <Redirect to={routes.home.url()} />;
  }
  if (!ticketData || !rideData) {
    return (
      <LoaderWrapper>
        <CircularProgress color="secondary" />
      </LoaderWrapper>
    );
  }
  const currentTicket = ticketData.tickets.find(
    (ticket) => ticket.id === ticketId,
  );
  const tickets = ticketData?.tickets.filter((ticket) => {
    if (ticket.rideId === currentTicket?.rideId) {
      return ticket;
    }
  });
  return (
    <PageContainer>
      <TicketPreview
        ticketId={ticketData.ticket.id}
        tickets={tickets}
        ride={rideData}
        ticketInfo={ticketData}
      />
    </PageContainer>
  );
};
