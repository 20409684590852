import React from "react";
import styled from "styled-components";
// import classes from "./SecondaryTitle.module.css";

const Heading = styled.h3`
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
  font-feature-settings: "cpsp" on;
  color: #4f4f4f;
`;

export const SecondaryTitle: React.FC = ({ children }) => {
  return <Heading>{children}</Heading>;
};
