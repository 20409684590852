import React from "react";

interface Icon {
  color?: string;
  style?: any;
}

export const Revisit = (props: Icon) => {
  return (
    <svg
      {...props}
      width="21"
      height="15"
      viewBox="0 0 21 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.5659 10.8682L3.13085 10.8682L5.675 13.4124C6.03314 13.7705 6.03314 14.3511 5.675 14.7092C5.31676 15.0674 4.73631 15.0673 4.37807 14.7092L0.268603 10.5995C-0.0895348 10.2414 -0.0895349 9.66084 0.268603 9.3027L4.37807 5.19324C4.55718 5.01412 4.79183 4.92466 5.02648 4.92466C5.26113 4.92466 5.49578 5.01422 5.6749 5.19324C6.03304 5.55138 6.03304 6.13194 5.6749 6.49007L3.13075 9.03423L15.5658 9.03423C17.5509 9.03423 19.1659 7.41919 19.1659 5.4341C19.1659 3.44901 17.5509 1.83398 15.5658 1.83398L8.00142 1.83398C7.49493 1.83398 7.08442 1.42347 7.08442 0.916987C7.08442 0.4105 7.49493 -8.28462e-06 8.00142 -8.3289e-06L15.5658 -8.9902e-06C18.5621 -9.25215e-06 20.9999 2.43777 20.9999 5.4341C20.9999 8.43044 18.5622 10.8682 15.5659 10.8682Z"
        fill={props.color || "#03AA00"}
      />
    </svg>
  );
};

export const BenefitsIcon = () => {
  return (
    <svg
      width="30"
      height="22"
      viewBox="0 0 38 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M37.5 11.25V3.75C37.5 1.66875 35.8125 0 33.75 0H3.75C1.6875 0 0.01875 1.66875 0.01875 3.75V11.25C2.08125 11.25 3.75 12.9375 3.75 15C3.75 17.0625 2.08125 18.75 0 18.75V26.25C0 28.3125 1.6875 30 3.75 30H33.75C35.8125 30 37.5 28.3125 37.5 26.25V18.75C35.4375 18.75 33.75 17.0625 33.75 15C33.75 12.9375 35.4375 11.25 37.5 11.25ZM33.75 8.5125C31.5188 9.80625 30 12.2437 30 15C30 17.7563 31.5188 20.1938 33.75 21.4875V26.25H3.75V21.4875C5.98125 20.1938 7.5 17.7563 7.5 15C7.5 12.225 6 9.80625 3.76875 8.5125L3.75 3.75H33.75V8.5125ZM16.875 20.625H20.625V24.375H16.875V20.625ZM16.875 13.125H20.625V16.875H16.875V13.125ZM16.875 5.625H20.625V9.375H16.875V5.625Z"
        fill="#787885"
      />
    </svg>
  );
};

export const VerifiedIcon = () => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.4166 7L11.9932 5.3725L12.1916 3.22L10.0858 2.74167L8.98325 0.875L6.99992 1.72667L5.01658 0.875L3.91408 2.73583L1.80825 3.20833L2.00659 5.36667L0.583252 7L2.00659 8.6275L1.80825 10.7858L3.91408 11.2642L5.01658 13.125L6.99992 12.2675L8.98325 13.1192L10.0858 11.2583L12.1916 10.78L11.9932 8.6275L13.4166 7ZM5.88575 9.75333L3.66908 7.53083L4.53242 6.6675L5.88575 8.02667L9.29825 4.6025L10.1616 5.46583L5.88575 9.75333Z"
        fill="#43A047"
      />
    </svg>
  );
};

export const CloseIcon = () => {
  return (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M14 1.41L12.59 0L7 5.59L1.41 0L0 1.41L5.59 7L0 12.59L1.41 14L7 8.41L12.59 14L14 12.59L8.41 7L14 1.41Z" fill="#323232"/>
    </svg>
  );
};

export const CopyIcon = () => {
  return (
    <svg width="12" height="14" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M3.33334 9.66671H8.66667V11H3.33334V9.66671ZM3.33334 7.00004H8.66667V8.33337H3.33334V7.00004ZM7.33334 0.333374H2.00001C1.26667 0.333374 0.666672 0.933374 0.666672 1.66671V12.3334C0.666672 13.0667 1.26 13.6667 1.99334 13.6667H10C10.7333 13.6667 11.3333 13.0667 11.3333 12.3334V4.33337L7.33334 0.333374ZM10 12.3334H2.00001V1.66671H6.66667V5.00004H10V12.3334Z" fill="white"/>
    </svg>
  );
};
