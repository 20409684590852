import React from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useHistory } from "react-router-dom";
import styled, { useTheme } from "styled-components";
import { useSelector, RootStateOrAny } from "react-redux";
import { Grid, CircularProgress, makeStyles } from "@material-ui/core";
import { usePaymentMethods, getCheckoutState } from "modules/api";
import { createReservation } from "modules/api/methods";
import { RapydMethods, Ticket, PaymentTypes } from "modules/entities";
import { routes, Redirect, useQueryParamState } from "modules/routing";
import { useLanguage } from "modules/localization";
import { Button, Placeholder } from "modules/ui";
import { Notifications } from "modules/notifications";
import { PaymentMethods } from "modules/select-payment/organisms/PaymentMethods";
import { PaymentInstructions } from "modules/select-payment/molecules/PaymentInstructions/PaymentInstructions";
import { getOrderId, getBookingValues, RideDetails } from "modules/booking";
import { TermsConditionsCheckbox } from "../molecules/TermsConditionsCheckbox";
import { GetCheckoutFailedDialog } from "../molecules/GetCheckoutFailedDialog/GetCheckoutFailed";
import { getOperatorCode } from "../../../modules/utils/getOperatorCode";
import GoogleAnalyticsService from "../../../modules/utils/GoogleAnalyticsService";
import { LOG_EVENTS } from "../../../modules/utils/LogEvents";

const useStyles = makeStyles((theme) => ({
  enabledButton: {
    background: theme.custom.secondary.main,
    "&:disabled": {
      background: `${theme.custom.secondaryInactive}`,
      color: "#fff",
    },
  },
}));

const PageContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  background: ${(props) => props.theme.custom["concrete-color"]};
  padding: 30px 24px;
  @media (max-width: ${(props) =>
      props.theme.custom.breakpoints["xs-breakpoint"]}) {
    padding: 10px 0px;
    background: white;
  }
`;

const Container = styled.div`
  max-width: 1100px;
  width: 100%;
`;

const MethodsContainer = styled.div`
  background: white;
  border: 1px solid #e0e0e0;
  border-radius: 3px;
  @media (max-width: ${(props) =>
      props.theme.custom.breakpoints["xs-breakpoint"]}) {
    border: none;
    border-radius: 0;
  }
`;

const ActionsContainer = styled.div`
  display: grid;
  grid-template-rows: repeat(auto-fit, minmax(0, max-content));
  row-gap: 20px;
  @media (max-width: ${(props) =>
      props.theme.custom.breakpoints["xs-breakpoint"]}) {
    padding: 0px 14px;
  }
`;

const Title = styled.p`
  font-size: 18px;
  line-height: 21px;
  color: #4f4f4f;
  margin: 0;
  font-weight: bold;
`;

const PaymentOuter = styled.div`
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  padding: 20px 20px;
`;

const InstructionsPlaceholder = styled(({ ...rest }) => (
  <Placeholder {...rest} />
))`
  background: #fff6d2;
`;

const ToggleButton = styled.button`
  background: #fff;
  border: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: bold;
  font-size: 18px;
  line-height: 21px;
  color: #4f4f4f;
`;

export const ArrowUpSVG = ({ color }: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="13"
    height="7"
    viewBox="0 0 8 6"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.646447 4.85355C0.451184 4.65829 0.451184 4.34171 0.646447 4.14645L3.64645 1.14645C3.84171 0.951185 4.15829 0.951185 4.35355 1.14645L7.35355 4.14645C7.54882 4.34171 7.54882 4.65829 7.35355 4.85355C7.15829 5.04882 6.84171 5.04882 6.64645 4.85355L4 2.20711L1.35355 4.85355C1.15829 5.04882 0.841709 5.04882 0.646447 4.85355Z"
      fill={color}
      stroke={color}
      stroke-width="0.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

export const ArrowDownSVG = ({ color }: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="13"
    height="7"
    viewBox="0 0 8 6"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.35355 1.14645C7.54882 1.34171 7.54882 1.65829 7.35355 1.85355L4.35355 4.85355C4.15829 5.04881 3.84171 5.04881 3.64645 4.85355L0.646447 1.85355C0.451185 1.65829 0.451185 1.34171 0.646447 1.14645C0.841709 0.951184 1.15829 0.951184 1.35355 1.14645L4 3.79289L6.64645 1.14645C6.84171 0.951184 7.15829 0.951184 7.35355 1.14645Z"
      fill={color}
      stroke={color}
      stroke-width="0.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

const useSavedValues = () => {
  const { search } = useLocation();
  const rideDetails = React.useMemo(
    () => routes.selectPayment.parse(search),
    [search],
  );
  const userDetails = React.useMemo(() => {
    if (!rideDetails.valid) {
      return { valid: false } as const;
    }
    const id = getOrderId(rideDetails.params);
    return getBookingValues(id);
  }, [rideDetails]);

  return {
    rideDetails,
    userDetails,
  };
};

type State = "idle" | "failed" | "pending" | "success";
export const SelectPaymentPage = () => {
  const browserHistory = useHistory();
  const language = useLanguage();
  const { t } = useTranslation("selectPayment");
  const { search } = useLocation();
  const result = React.useMemo(
    () => routes.selectPayment.parse(search),
    [search],
  );
  const { params }: any = result;
  const {
    rideId,
    dropOutStopId,
    pickUpStopId,
    passengers,
    timezoneFrom,
    timezoneTo,
    returnRideId,
  } = params;
  const { data } = usePaymentMethods();
  const theme: any = useTheme();
  const classes = useStyles();
  const [termsConfirmed, setTermsConfirmed] = React.useState(false);
  const [state, setState] = React.useState<State>("idle");
  const [ticket, setTicket] = React.useState<Ticket | null>(null);
  const [errorDialogOpen, setErrorOpen] = React.useState(false);
  const [methodCode, setMethodCode] = useQueryParamState("paymentMethod");
  const [toggleCart, setToggleCart] = React.useState(false);
  const region = useSelector((state: RootStateOrAny) => state.region);
  const showRapyd = region?.paymentGateway === "RAPYD";
  const rapydMethods = showRapyd
    ? RapydMethods?.filter((method) =>
        region?.paymentMethodTypeCategories.includes(method.code),
      )
    : [];
  const selectedMethod = showRapyd
    ? rapydMethods.find((method) => method.code === methodCode) ?? null
    : data?.find((method) => method.code === methodCode) ?? null;
  const [paymentMethod] = useQueryParamState("paymentMethod");
  const baseUrl = `${window.location.origin}/${
    window.location.pathname.split("/")[1]
  }/${language}`;

  React.useEffect(() => {
    GoogleAnalyticsService.createEvent(LOG_EVENTS.LOG_EVENTS_NAME.SCREEN_VIEW, {
      firebase_screen: LOG_EVENTS.SCREEN_NAME.SELECT_PAYMENT_SCREEN,
      firebase_screen_class: LOG_EVENTS.SCREEN_NAME.SELECT_PAYMENT_SCREEN,
      operator_code: getOperatorCode(),
      source: "web",
    });
    if (showRapyd) {
      if (rapydMethods.length !== 0) {
        setMethodCode(rapydMethods[0].code);
      }
    } else {
      if (data && !selectedMethod) {
        const first = data[0];
        if (first) {
          setMethodCode(first.code);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showRapyd]);

  const { rideDetails, userDetails } = useSavedValues();

  if (ticket) {
    return (
      <Redirect
        push
        to={routes.checkoutVA.url({ ...params, ticketId: ticket.id })}
      />
    );
  } else if (!rideDetails.valid || !userDetails.valid) {
    return <Redirect to={routes.home.url()} />;
  }

  const handlePay = () => {
    if (selectedMethod) {
      let url: string = "";
      GoogleAnalyticsService.createEvent(
        LOG_EVENTS.LOG_EVENTS_NAME.PRESS_BUTTON_CONFIRM,
        {
          payment_method: LOG_EVENTS.LOG_METHOD_VALUE.CLICKED_CONFIRM_BUTTON,
          operator_code: getOperatorCode(),
          source: "web",
        },
      );
      if (showRapyd) {
        url = `/${language}${routes.rapyd.url(search)}`;
        browserHistory.push(url);
      } else if (selectedMethod.code === "OVO") {
        url = `/${language}${routes.checkoutOVO.url(search)}`;
        browserHistory.push(url);
      } else if (selectedMethod.code === "CREDIT_CARD") {
        if (userDetails.valid) {
          const rides = [
            {
              rideId: rideDetails.params.rideId,
              pickUpStopId: rideDetails.params.pickUpStopId,
              dropOutStopId: rideDetails.params.dropOutStopId,
              passengers: userDetails.params.passengers,
            },
          ];

          // Two way booking
          if (rideDetails.params.returnRideId) {
            rides.push({
              rideId: rideDetails.params.returnRideId,
              pickUpStopId: rideDetails.params.dropOutStopId,
              dropOutStopId: rideDetails.params.pickUpStopId,
              passengers: userDetails.params.passengers,
            });
          }
          createReservation({
            rides,
            paymentMethod: {
              code:
                paymentMethod && !Array.isArray(paymentMethod)
                  ? paymentMethod
                  : "null",
              icon: "",
              minAmount: 0,
              name: "none",
              type: PaymentTypes.none,
            },
            voucherCode: userDetails.params.voucher,
            email: userDetails.params.email,
            couponCode: "",
          })
            .then((r) => {
              const paymentStatusUrl = `${baseUrl}${routes.paymentStatus.url({
                ticketId: r.ticket.id,
              })}`;
              const type = "booking";
              browserHistory.push({
                pathname: `/${language}${routes.xendit.pattern}`,
                // search: `&su=${paymentStatusUrl}&eu=${paymentStatusUrl}&type=${type}&id=${r.ticket.id}`,
                state: {
                  su: paymentStatusUrl,
                  eu: paymentStatusUrl,
                  type,
                  id: r.ticket.id,
                },
              });
            })
            .catch((e) => {
              Notifications.enqueueSnackbar(e?.response?.data?.message, {
                preventDuplicate: true,
              });
              // setLoading(false);
            });
        }
      } else {
        setState("pending");
        getCheckoutState(selectedMethod, rideDetails.params, userDetails.params)
          .then((ticket) => {
            setState("success");
            setTicket(ticket);
            GoogleAnalyticsService.createEvent(
              LOG_EVENTS.LOG_EVENTS_NAME.GET_PAYMENT_SUCCESS,
              {
                payment_process: LOG_EVENTS.LOG_METHOD_VALUE.PAYMENT_SUCCESSFUL,
                payment_value: ticket?.price.value,
                revenue: ticket?.price.value,
                currency_code: ticket?.price.currency,
                operator_code: getOperatorCode(),
                source: "web",
              },
            );
          })
          .catch((error) => {
            GoogleAnalyticsService.createEvent(
              LOG_EVENTS.LOG_EVENTS_NAME.GET_PAYMENT_ERROR,
              {
                payment_process: LOG_EVENTS.LOG_METHOD_VALUE.ERROR_ON_PAYMENT,
                operator_code: getOperatorCode(),
                source: "web",
              },
            );
            GoogleAnalyticsService.createEvent(
              LOG_EVENTS.LOG_EVENTS_NAME.GET_PAYMENT_FAILURE,
              {
                payment_process: LOG_EVENTS.LOG_METHOD_VALUE.FAIL_ON_PAYMENT,
                operator_code: getOperatorCode(),
                source: "web",
              },
            );
            setState("failed");
            if (error.code === 409) {
              setErrorOpen(true);
            }
          });
        return;
      }

      // browserHistory.push(url);
      return;
    }
  };

  const isPending = state === "pending";
  const payButtonDisabled = !termsConfirmed || !selectedMethod || isPending;
  const errorRedirectUrl = routes.home.url();

  // const backUrl = routes.newReservation.url(rideDetails.params);

  return (
    <PageContainer>
      <Container>
        {/* <BackButtonWrapper to={backUrl} /> */}
        <GetCheckoutFailedDialog
          open={errorDialogOpen}
          backTo={errorRedirectUrl}
          onClose={() => setErrorOpen(false)}
        />
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} lg={8}>
            {showRapyd ? (
              <MethodsContainer>
                <PaymentMethods
                  data={rapydMethods}
                  selectedItem={selectedMethod}
                  onChange={(method) =>
                    setMethodCode(method ? method.code : null)
                  }
                />
              </MethodsContainer>
            ) : data ? (
              <MethodsContainer>
                <PaymentMethods
                  data={data}
                  selectedItem={selectedMethod}
                  onChange={(method) =>
                    setMethodCode(method ? method.code : null)
                  }
                />
              </MethodsContainer>
            ) : (
              <Placeholder variant="rect" height={400} />
            )}
          </Grid>
          <Grid item xs={12} sm={6} lg={4}>
            <ActionsContainer>
              <Title>{t("paymentProcedure")}</Title>
              {data ? (
                selectedMethod && (
                  <PaymentInstructions method={selectedMethod} />
                )
              ) : (
                <InstructionsPlaceholder
                  variant="rect"
                  height={300}
                  color={"black"}
                />
              )}
              {/* <Title>{t("showCard")}</Title> */}
              <ToggleButton onClick={() => setToggleCart(!toggleCart)}>
                {" "}
                {t("showCard")}
                {toggleCart ? (
                  <ArrowUpSVG color={theme.custom.primary["main"]} />
                ) : (
                  <ArrowDownSVG color={theme.custom.primary["main"]} />
                )}
              </ToggleButton>
              {toggleCart && (
                <PaymentOuter>
                  <RideDetails
                    rideId={rideId}
                    dropOutStopId={dropOutStopId}
                    pickUpStopId={pickUpStopId}
                    passengers={passengers}
                    voucherData={undefined}
                    timezoneFrom={timezoneFrom}
                    timezoneTo={timezoneTo}
                    returnRideId={returnRideId}
                  />
                </PaymentOuter>
              )}
              <div>
                <TermsConditionsCheckbox
                  confirmed={termsConfirmed}
                  setConfirmed={setTermsConfirmed}
                />
              </div>
              <Button
                disabled={payButtonDisabled}
                className={classes.enabledButton}
                // color="secondary"
                color="primary"
                fullWidth
                onClick={handlePay}
                endIcon={
                  isPending && <CircularProgress size={16} color="inherit" />
                }
              >
                {t("pay")}
              </Button>
            </ActionsContainer>
          </Grid>
        </Grid>
      </Container>
    </PageContainer>
  );
};
