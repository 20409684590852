import React from 'react';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';

import { Colors } from './constants/colors.js';

const ProgressBar = ({ title }) => {
  return (
    <div className='progress-container'>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <CircularProgress style={{ color: Colors.indicatorBlue }} />
      </div>
      <Typography style={{ color: Colors.headerGray }} component='h1'>
        {title ? title : 'Loading'}
      </Typography>
    </div>
  );
};

export default ProgressBar;
