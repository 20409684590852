import * as React from "react";
// import clsx from "clsx";
// import classes from "./Card.module.css";
import styled from "styled-components";

const CardWrapper = styled(({ ...rest }) => <div {...rest} />)`
  .card {
    background: #ffffff;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
    border-radius: 3px;
  }
`;

export const Card = (
  props: React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  >
) => {
  const { className, ...rest } = props;
  return <CardWrapper {...rest} />;
};
