import React from "react";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import styled from "styled-components";
import Grid from "@material-ui/core/Grid";
import { Duration } from "modules/ui";
import { Duration as DurationType } from "modules/utils/getDuration";
// import classes from "modules/search-result-list/organisms/TripCard/TripCard.module.css";
import {
  // TripFacilities,
  TripFacilitiesProps,
} from "../TripFacilities/TripFacilities";

export interface TripAdditionalProps extends TripFacilitiesProps {
  duration: DurationType;
}

const TextCommon = styled(({ ...rest }) => <Grid {...rest} />)`
  font-family: ${(props) => props.theme.custom["roboto-font"]};
  font-style: normal;
  color: ${(props) => props.theme.custom["emperor-color"]};
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 5px;
  &.textLarge {
    font-size: 18px;
  }
  &.textLight {
    color: ${(props) => props.theme.custom["gray-color"]};
  }
  &.textSmall {
    font-size: 12px;
  }
`;
export const TripAdditional: React.FC<TripAdditionalProps> = (props) => {
  const { t } = useTranslation("searchResult");
  return (
    <Grid item container>
      <Grid item container wrap="nowrap">
        <Grid item sm={4} xs={2} container direction="column">
          <TextCommon item className={clsx("textSmall", "textLight")}>
            {t("duration")}:
          </TextCommon>
          <TextCommon>
            <Duration value={props.duration} />
          </TextCommon>
        </Grid>
        {/* <Grid item sm={4} xs={2} container direction="column">
          <TextCommon item className={clsx("textSmall", "textLight")}>
            {t("Distance")}:
          </TextCommon>
          <TextCommon>1,098 kms</TextCommon>
        </Grid> */}
        {/* <Grid item sm={8} xs={8} container direction="column">
          <TextCommon item className={clsx("textSmall", "textLight")}>
            {t("facility")}:
          </TextCommon>
          <Grid item container wrap="nowrap">
            <TripFacilities />
          </Grid>
        </Grid> */}
      </Grid>
    </Grid>
  );
};
