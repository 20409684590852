export type RentalBookingTitle = {
  type: "RENTAL_BOOKING_TITLE";
  payload: any;
};

export type RentalServiceCategories = {
  type: "RENTAL_SERVICE_CATEGORIES";
  payload: any;
};

export interface BookingState {
  title: string;
  categories: any;
}

const initialState = {
  title: "",
  categories: [],
};

export const rentalBookingReducer = (
  state: BookingState = initialState,
  action: RentalBookingTitle | RentalServiceCategories,
) => {
  switch (action.type) {
    case "RENTAL_BOOKING_TITLE": {
      return { ...state, title: action.payload };
    }

    case "RENTAL_SERVICE_CATEGORIES": {
      return { ...state, categories: action.payload };
    }

    default:
      return {
        ...state,
      };
  }
};
