import * as React from "react";
import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import { shouldRenderFeature } from "@bussr-io/feature-toggling";
import {
  getCustomerSupportInfo,
  getSubscriptionDetails,
} from "modules/api/methods/subscription";
import {
  ONE_WAY_BOOKING,
  RENTAL_CATALOG,
  RENTAL_ENQUIRY,
} from "modules/auth/constants";
import { AdvantageList } from "modules/ui/organisms/AdvantageList/AdvantageList";
import { useWindowResizeListener } from "modules/utils/hooks/useWindowResizeListener";
// import { SearchComponent } from "../organisms/Search";
import { getOperatorCode } from "modules/utils/getOperatorCode";
import { breakpoints } from "../../ui/variables";
import SearchBlock from "./search-block-bg.jpg";
import HomeTabs from "../organisms/HomeTabs";
import { SearchComponent } from "../organisms/Search";
// import BusIcon from "./BusIcon.svg";
// import BusIconMob from "./busIconMob.png";
import SuspendIcon from "./closed.svg";
import SuspendMobIcon from "./SuspendMob.png";
import { featureToggle } from "modules/utils/featureToggle";
import RentalService from "modules/rental/pages/RentalService";
import Rental from "modules/rental/pages/rental";
import { getRentalServiceCategories } from "modules/api/methods";
import OffersAndBenefits from 'modules/offersAndBenefits/OffersSliders';
import { updateOperatorCode } from "modules/api/client";

const SearchMainPageWrapper = styled.div`
  // display: flex;
  justify-content: center;
  align-items: flex-end;
  // max-height: 440px;
  padding: 0px 50px;
  padding-top: 100px;
  padding-bottom: 100px;
  background-image: url(${SearchBlock});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  // flex: 1;
  width: 100%;
  @media (max-width: ${(props) =>
      props.theme.custom.breakpoints["md-breakpoint"]}) {
    width: 100%;
    padding-top: 0px;
    padding-bottom: 0px;
    min-height: 276px;
    max-height: 276px;
  }
`;

const SearchMainPageMobileWrapper = styled.div`
  // display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: ${(props) =>
      props.theme.custom.breakpoints["md-breakpoint"]}) {
    width: 93%;
  }
`;

const Subscription = styled.div`
  color: #ffffff;
  font-size: 20px;
  font-weight: 400;
  text-align: center;
`;

const SubscriptionMob = styled.div`
  color: #4f4f4f;
  font-size: 13px;
  font-weight: normal;
  text-align: center;
  margin-top: 20px;
  margin-bottom: 20px;
  margin-left: 20px;
`;

export function SearchMainPage() {
  const { windowWidth } = useWindowResizeListener();
  const [showEnquiry, setShowEnquiry] = React.useState(false);
  const [showRental, setShowRental] = React.useState(false);
  const [subscription, setSubscription] = React.useState("active");
  const [customerSupport, setCustomerSupport] = React.useState<any>("");
  const [showBusTab, setShowBusTab] = React.useState(true);
  const [services, setServices] = React.useState([]);
  const dispatch = useDispatch();

  const firebase_app = useSelector(
    (state: any) => state.remoteConfig.firebaseApp,
  );
  const criteria = {
    country: useSelector((state: any) => state.remoteConfig.countryName),
    operator: useSelector((state: any) => state.remoteConfig.operatorId),
  };

  const featureName1 = RENTAL_CATALOG;
  const featureName2 = RENTAL_ENQUIRY;

  React.useEffect(() => {
    async function getResult() {
      const toggleRental = await shouldRenderFeature(
        firebase_app,
        featureName1,
        criteria,
      );
      const toggleEnquiry = await shouldRenderFeature(
        firebase_app,
        featureName2,
        criteria,
      );
      const toggleBusTab = await featureToggle(
        ONE_WAY_BOOKING,
        firebase_app,
        criteria,
      );

      setShowBusTab(toggleBusTab);

      if (toggleRental) {
        setShowRental(toggleRental);
      }

      if (toggleEnquiry) {
        setShowEnquiry(toggleEnquiry);
      }
    }
    getResult();
  }, [criteria, firebase_app, featureName1, featureName2]);

  React.useEffect(() => {
    (async () => {
      try {
        const response = await getRentalServiceCategories();
        setServices(response);
        dispatch({
          type: "RENTAL_SERVICE_CATEGORIES",
          payload: response,
        });
      } catch (err) {
        console.log(err);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    (async () => {
      const response = await getSubscriptionDetails(getOperatorCode());
      // setSubscription(response.status);
      setSubscription("active");
      if (response.status !== "active") {
        const customerSupportInfo = await getCustomerSupportInfo(
          getOperatorCode(),
        );
        setCustomerSupport(customerSupportInfo);
      }
    })();
  }, []);

  const subscriptionText1 =
    "We are not accepting online bookings at this point. We regret the inconvenience caused.";
  const subscriptionText2 = `If you have any questions then please contact ${
    customerSupport?.email || "custome support email"
  } & ${customerSupport?.phoneNumber || "<Mobile Number>"} for more details.`;

  const showTabs =
    (showBusTab && showRental && showEnquiry) ||
    (showBusTab && showRental) ||
    (showRental && showEnquiry) ||
    (showBusTab && showEnquiry);

  React.useEffect(() => {
    dispatch({
      type: "SET_OPERATOR_CODE",
      payload: window.location.pathname.split("/")[1].toUpperCase(),
    }); 
    updateOperatorCode(window.location.pathname.split("/")[1].toUpperCase());
  },[])

  return (
    <>
      {windowWidth > breakpoints.md && (
        <SearchMainPageWrapper>
          {/* {showEnquiry || showRental ? <HomeTabs /> : <SearchComponent />} */}
          {/* {windowWidth > breakpoints.lg && <SearchComponent />} */}

          {subscription !== "active" ? (
            <Subscription>
              <img style={{ width: 84, height: 84 }} src={SuspendIcon} alt="" />
              <p>{subscriptionText1}</p>
              <p>{subscriptionText2}</p>
            </Subscription>
          ) : null}
          <div style={{ background: "#ffffff" }}>
            {showTabs ? (
              <HomeTabs
                subscription={subscription === "active" ? true : false}
              />
            ) : showBusTab ? (
              <SearchComponent />
            ) : showRental ? (
              <RentalService
                showTitle={false}
                showNote
                serviceData={services}
              />
            ) : (
              <Rental showTitle={false} showNote serviceData={services} />
            )}
          </div>
        </SearchMainPageWrapper>
      )}

      {(windowWidth < breakpoints.xs || windowWidth <= breakpoints.md) && (
        <SearchMainPageMobileWrapper>
          {subscription !== "active" ? (
            <SubscriptionMob>
              <img
                style={{ width: 50, height: 50 }}
                src={SuspendMobIcon}
                alt=""
              />

              <p>{subscriptionText1}</p>
              <p>{subscriptionText2}</p>
            </SubscriptionMob>
          ) : null}
          <HomeTabs subscription={subscription === "active" ? true : false} />
        </SearchMainPageMobileWrapper>
      )}
      <OffersAndBenefits />
      <AdvantageList />
    </>
  );
}
