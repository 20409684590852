import React from "react";

export const FAQIcon = ({
    height = "24px",
    width = "24px",
    color = "#353F47",
    ...props
  }) => {
  return (
        <svg width="18" height="27" viewBox="0 0 18 27" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="0.75" y="0.75" width="16.5" height="25.5" rx="2.25" stroke="#647887" strokeWidth="1.5"/>
        <rect x="3" y="4" width="12" height="1" rx="0.5" fill="#647887"/>
        <rect x="3" y="7" width="12" height="1" rx="0.5" fill="#647887"/>
        <rect x="3" y="10" width="3" height="1" rx="0.5" fill="#647887"/>
        <rect x="8" y="10" width="7" height="1" rx="0.5" fill="#647887"/>
        <rect x="3" y="13" width="5" height="1" rx="0.5" fill="#647887"/>
        <rect x="3" y="16" width="5" height="1" rx="0.5" fill="#647887"/>
        <rect x="3" y="19" width="5" height="1" rx="0.5" fill="#647887"/>
        <rect x="3" y="22" width="5" height="1" rx="0.5" fill="#647887"/>
        <rect x="10" y="13" width="5" height="1" rx="0.5" fill="#647887"/>
        <rect x="10" y="16" width="5" height="1" rx="0.5" fill="#647887"/>
        <rect x="10" y="19" width="5" height="1" rx="0.5" fill="#647887"/>
        <rect x="10" y="22" width="5" height="1" rx="0.5" fill="#647887"/>
        </svg>
  )
}

export const ContactUsIcon = () => {
    return(
        <svg width="22" height="26" viewBox="0 0 22 26" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M21 19.8511V22.8511C21.0023 23.4143 20.767 23.9523 20.3521 24.333C19.9371 24.7137 19.3809 24.9018 18.82 24.8511C15.7428 24.5168 12.787 23.4653 10.19 21.7811C7.77383 20.2458 5.72534 18.1973 4.19 15.7811C2.49998 13.1723 1.44824 10.2021 1.12 7.11111C1.06947 6.55199 1.25627 5.99737 1.63477 5.58273C2.01326 5.1681 2.5486 4.93164 3.11 4.93111H6.11C7.11387 4.92123 7.9695 5.65708 8.11 6.65111C8.23662 7.61118 8.47145 8.55384 8.81 9.46111C9.08474 10.192 8.90902 11.0159 8.36 11.5711L7.09 12.8411C8.51356 15.3447 10.5865 17.4176 13.09 18.8411L14.36 17.5711C14.9152 17.0221 15.7391 16.8464 16.47 17.1211C17.3773 17.4597 18.3199 17.6945 19.28 17.8211C20.2856 17.963 21.0252 18.8359 21 19.8511Z" stroke="#647887" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M13 3.93111C13 3.93111 15.7366 5.36314 15.1439 9.28051" stroke="#647887" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M13.9903 1C13.9903 1 19.8795 4.38723 17.4343 10.7148" stroke="#647887" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>

    )
}

export const BackArrowIcon = () => {
    return(
        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M13 7.00001H1" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M7 13L1 7L7 1" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>

    )
}