import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import enOtp from "modules/auth/locales/otp-en.json";
import idOtp from "modules/auth/locales/otp-id.json"
import enLogin from "modules/auth/locales/login-en.json";
import idLogin from "modules/auth/locales/login-id.json";
import enLogout from "modules/auth/locales/logout-en.json";
import idLogout from "modules/auth/locales/logout-id.json";
import enRouting from "modules/routing/locales/routing-en.json";
import idRouting from "modules/routing/locales/routing-id.json";
import enHeader from "modules/ui/locales/header-en.json";
import enFooter from "modules/ui/locales/footer-en.json";
import enDatePicker from "modules/ui/locales/datepicker-en.json";
import enAdvantage from "modules/ui/locales/advantage-en.json";
import idHeader from "modules/ui/locales/header-id.json";
import idFooter from "modules/ui/locales/footer-id.json";
import idDatePicker from "modules/ui/locales/datepicker-id.json";
import idAdvantage from "modules/ui/locales/advantage-id.json";
import enDuration from "modules/ui/locales/duration-en.json";
import idDuration from "modules/ui/locales/duration-id.json";
import enLoading from "modules/ui/atoms/Loading/loading-en.json";
import idLoading from "modules/ui/atoms/Loading/loading-id.json";
import enNotFound from "modules/ui/molecules/NotFound/notFound-en.json";
import idNotFound from "modules/ui/molecules/NotFound/notFound-id.json";
import idSearch from "modules/search/locales/search-id.json";
import enSearchResult from "modules/search-result-list/locales/search-result-en.json";
import idSearchResult from "modules/search-result-list/locales/search-result-id.json";
import enPaymentMethods from "modules/select-payment/locales/payment-methods-en.json";
import idPaymentMethods from "modules/select-payment/locales/payment-methods-id.json";
import enApplyVocher from "modules/booking/locales/apply-voucher-en.json";
import idApplyVocher from "modules/booking/locales/apply-voucher-id.json";
import enPaymentInstructions from "modules/select-payment/locales/payment-instructions-en.json";
import idPaymentInstructions from "modules/select-payment/locales/payment-instructions-id.json";
import enPaymentsGuide from "modules/checkout-va/locales/payments-guide-en.json";
import idPaymentsGuide from "modules/checkout-va/locales/payments-guide-id.json";
import enSearch from "modules/search/locales/search-en.json";
import enReservation from "modules/booking/locales/reservation-form-en.json";
import idReservation from "modules/booking/locales/reservation-form-id.json";
import enSelectPayment from "modules/select-payment/locales/select-payment-en.json";
import idSelectPayment from "modules/select-payment/locales/select-payment-id.json";
import enBookingInstructions from "modules/checkout-success/locales/booking-instuctions-en.json";
import idBookingInstructions from "modules/checkout-success/locales/booking-instuctions-id.json";
import enBookingSuccess from "modules/checkout-success/locales/booking-success-en.json";
import idBookingSuccess from "modules/checkout-success/locales/booking-success-id.json";
import enTicket from "modules/ticket/translations/ticket-en.json";
import idTicket from "modules/ticket/translations/ticket-id.json";
import enTerms from "modules/legal/translations/terms-en.json";
import enPrivacy from "modules/legal/translations/privacy-en.json";
import enProfile from "modules/profile/locales/profile-en.json";
import idProfile from "modules/profile/locales/profile-id.json";
import enTermsNCondition from "modules/terms//locales/terms-en.json";
import idTermsNCondition from "modules/terms//locales/terms-id.json";
import enRental from "modules/rental/locales/rental-en.json";
import idRental from "modules/rental/locales/rental-id.json";
import enCheckoutOVO from "modules/checkut-ovo/locales/checkout-ovo-en.json";
import idCheckoutOVO from "modules/checkut-ovo/locales/checkout-ovo-id.json";
import enMyRides from "modules/myRides/locales/rides-en.json";
import idMyRides from "modules/myRides/locales/rides-id.json";
import enContactUs from "modules/contact-us/locales/contactus-en.json";
import idContactUs from "modules/contact-us/locales/contactus-id.json";
import enOffers from "modules/offersAndBenefits/locales/offer-en.json";
import idOffers from "modules/offersAndBenefits/locales/offer-id.json";

export const getI18n = (language: string) => {
  const i18n = i18next.use(initReactI18next);

  i18n.use(initReactI18next).init({
    defaultNS: "search",
    ns: [
      "search",
      "header",
      "footer",
      "datepicker",
      "searchResult",
      "paymentInstructions",
      "paymentsGuide",
      "paymentMethods",
    ],
    resources: {
      en: {
        advantage: enAdvantage,
        applyVoucher: enApplyVocher,
        datepicker: enDatePicker,
        duration: enDuration,
        footer: enFooter,
        header: enHeader,
        otp: enOtp,
        paymentInstructions: enPaymentInstructions,
        paymentMethods: enPaymentMethods,
        paymentsGuide: enPaymentsGuide,
        reservation: enReservation,
        routing: enRouting,
        search: enSearch,
        searchResult: enSearchResult,
        selectPayment: enSelectPayment,
        ticket: enTicket,
        terms: enTerms,
        privacy: enPrivacy,
        bookingInstructions: enBookingInstructions,
        bookingSuccess: enBookingSuccess,
        login: enLogin,
        loading: enLoading,
        notFound: enNotFound,
        profile: enProfile,
        termsNCondition: enTermsNCondition,
        logout: enLogout,
        rental: enRental,
        checkoutOVO: enCheckoutOVO,
        myRides: enMyRides,
        contactUs: enContactUs,
        offer: enOffers,
      },
      id: {
        advantage: idAdvantage,
        applyVoucher: idApplyVocher,
        datepicker: idDatePicker,
        duration: idDuration,
        footer: idFooter,
        header: idHeader,
        paymentInstructions: idPaymentInstructions,
        paymentMethods: idPaymentMethods,
        paymentsGuide: idPaymentsGuide,
        reservation: idReservation,
        routing: idRouting,
        otp: idOtp,
        search: idSearch,
        searchResult: idSearchResult,
        selectPayment: idSelectPayment,
        ticket: idTicket,
        bookingInstructions: idBookingInstructions,
        bookingSuccess: idBookingSuccess,
        login: idLogin,
        loading: idLoading,
        notFound: idNotFound,
        profile: idProfile,
        termsNCondition: idTermsNCondition,
        logout: idLogout,
        rental: idRental,
        checkoutOVO: idCheckoutOVO,
        myRides: idMyRides,
        contactUs:idContactUs,
        offer: idOffers
      },
    },
    lng: language,
    fallbackLng: "en",
    interpolation: {
      format: (value, format) => {
        const rawRegion = sessionStorage.getItem("opRegion");
        let region = { locale: "id-ID", currency: "IDR" };

        if (rawRegion) {
          region = JSON.parse(rawRegion);
        }

        if (format === "currency" && value) {
          const { value: amount } = value;

          const result = new Intl.NumberFormat(region?.locale, {
            style: "currency",
            currency: region?.currency,
            currencyDisplay: "code",
          }).format(amount);

          return result;
        }

        return value;
      },
    },
  });

  return i18n;
};
