import { mutate } from "swr";
import {
  Ticket,
  TicketStatus,
  PaymentMethod,
  PaymentTypes,
} from "modules/entities";
import { axios } from "../client";
import { ENDPOINTS } from "../endpoints";
import { TicketsResponse } from "../hooks/ticket";

type BookingParams = {
  rides: Array<{
    rideId: string;
    pickUpStopId: string;
    dropOutStopId: string;
    passengers: Array<{ firstName: string; lastName: string }>;
  }>;
  voucherCode: string;
  email: string;
  paymentMethod: PaymentMethod;
  couponCode: string;
};

type BookingResponse = {
  paymentResponse: PaymentResponse | null;
  ticket: Ticket;
};

const getPaymentParams = (method: PaymentMethod) => {
  switch (method.type) {
    case PaymentTypes.va: {
      return {
        bank: method.code,
        paymentMethod: method.type,
      };
    }

    case PaymentTypes.ovo: {
      return {
        bank: method.code,
        paymentMethod: method.type,
      };
    }

    case PaymentTypes.rapyd: {
      return {
        bank: method.code,
        paymentMethod: method.type,
      };
    }

    case PaymentTypes.none: {
      return {
        bank: "",
        paymentMethod: null,
      };
    }

    default: {
      throw new Error(`Payment method '${method.type}' is not implemented`);
    }
  }
};

export const createReservation = ({
  rides,
  paymentMethod,
  voucherCode,
  email,
  couponCode,
}: BookingParams) => {
  const body = {
    ...getPaymentParams(paymentMethod),
    rides,
    voucherCode,
    email,
    couponCode,
  };
  return axios
    .post<any>(ENDPOINTS.CREATE_RESERVATION, body)
    .then((r) => r.data);
};

export const cancelReservation = (ticketId: string) => {
  return axios.patch(`/v2/tickets/${ticketId}/cancel`).then((res: any) => {
    return mutate(
      `${ENDPOINTS.TICKETS}/${ticketId}`,
      (data: TicketsResponse) => {
        if (data) {
          data.ticket = {
            ...data.ticket,
            status: res.data.ticket.status,
          };
          data.tickets = data.tickets.map((ticket) => ({
            ...ticket,
            status: res.data.ticket.status || TicketStatus.payment_cancelled,
          }));
          data.ticketGroup = {
            ...data.ticketGroup,
            status: res.data.ticket.status || TicketStatus.payment_cancelled,
          };
          return { ...data };
        }
      },
    );
  });
};

export const cancelReserve = (ticketId: string) => {
  return axios
    .patch(`/v2/tickets/${ticketId}/cancel-reserve`)
    .then((res: any) => {
      return mutate(
        `${ENDPOINTS.TICKETS}/${ticketId}`,
        (data: TicketsResponse) => {
          if (data) {
            data.ticket = {
              ...data.ticket,
              status: res.data.ticket.status,
            };
            data.tickets = data.tickets.map((ticket) => ({
              ...ticket,
              status: res.data.ticket.status,
            }));
            data.ticketGroup = {
              ...data.ticketGroup,
              status: res.data.ticket.status,
            };
            return { ...data };
          }
        },
      );
    });
};
