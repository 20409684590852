const convertToUtc = (timeStr) => {
  const currentTimeLocalOffset = new Date(timeStr).getTimezoneOffset();
  const sign = currentTimeLocalOffset < 0 ? '+' : '-';
  const currentTimeHrs = Math.floor(Math.abs(currentTimeLocalOffset)/60);
  const currentTimeMins = Math.abs(currentTimeLocalOffset) % 60;
  const hrsStr = currentTimeHrs >= 10 ? currentTimeHrs.toString() : `0${currentTimeHrs}`;
  const minStr = currentTimeMins >= 10 ? currentTimeMins.toString() : `0${currentTimeMins}`;
  const timeAddStr = `${sign}${hrsStr}:${minStr}`;
  const origTime = new Date(timeStr).toISOString()
  const newTime = origTime.substring(0, origTime.length - 1) + timeAddStr
  return new Date(newTime);
};

export default convertToUtc;
