import { Price } from "modules/entities";

export const subtractPrice = (priceA: Price, priceB: Price): Price => {
  console.assert(
    priceA.currency === priceB.currency,
    "Price currency in math operations should be the same",
  );
  return {
    currency: priceA.currency,
    value: priceA.value - priceB.value,
  };
};

// @todo move price calculation to backend
export const multiplyPrice = (price: Price, factor: number) => {
  return {
    currency: price.currency,
    value: factor * price.value,
  };
};

export const sumPrices = (prices: Array<any>) => {
  const [first] = prices;
  if (!first) {
    return {
      currency: "",
      value: 0,
    };
  }
  return prices.reduce(
    (acc, cur) => {
      console.assert(
        acc.currency === cur.currency,
        "Price currency in math operations should be the same",
      );
      return {
        value: cur.value + acc.value,
        currency: acc.currency,
      };
    },
    {
      value: 0,
      currency: first.currency,
    },
  );
};
