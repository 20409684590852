/* eslint-disable react-hooks/exhaustive-deps */
import * as React from "react";
import { useTranslation } from "react-i18next";
import { useFormContext } from "react-hook-form";
import { isValidNumber } from "libphonenumber-js";
import styled from "styled-components";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {
  TextField,
  Typography,
  CircularProgress,
  Button as DefaultButton,
  Card,
} from "@material-ui/core";
import KeyboardArrowDown from "@material-ui/icons/KeyboardArrowDown";
import { usePhoneAuthentication, usePhoneAuthErrorMessage } from "modules/auth";
import { EMAIL_PATTERN } from "modules/auth/constants";
import { useUserData } from "modules/auth";
import { Button, TEST_ID } from "modules/ui";
import countryFlags from "modules/ui/molecules/PhoneInput/flags.json";
import { BookingFormFieldsName } from "modules/ui/variables";
import { OtpModal } from "../OtpModal/OtpModal";
import { usePhoneVerify } from "./PhoneEmailVerifyContext";
import GoogleAnalyticsService from "../../../utils/GoogleAnalyticsService";
import { LOG_EVENTS } from "../../../utils/LogEvents";
import { getOperatorCode } from "../../../utils/getOperatorCode";
// import classes from "../Block.module.css";

const FormContainer = styled.form`
  width: 100%;
  display: grid;
  grid-auto-rows: max-content;
  grid-template-columns: 100%;
  row-gap: 1rem;
  margin-top: 10px;
  .phoneEmail {
    label {
      top: 10px;
      font-size: 14px;
    }
    input {
      flex: 1;
    }
    &:focus-within label,
    [data-shrink="true"] {
      top: 0px;
      font-size: 14px;
    }
  }
`;

const SearchContainer = styled(({ ...rest }) => <Card {...rest} />)`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  z-index: 1;
  height: 86px;
  padding: 1rem 1.5rem;
`;

const PhoneEmail = styled(({ ...rest }) => <TextField {...rest} />)`
  input {
    flex: 1;
    font-size: 14px;
  }
  &:focus-within label,
  &:[data-shrink="true"] {
    top: 0px;
  }
  & label.Mui-focused {
    color: ${(props) => props.theme.custom.primary.main};
  }
  & .MuiOutlinedInput-root {
    &.Mui-focused fieldset {
      border-color: ${(props) => props.theme.custom.primary.main};
    }
  }
`;

const ContinueButton = styled(({ ...rest }) => <Button {...rest} />)`
  background-color: ${(props) => props.theme.custom.secondary.main};
  &:disabled {
    background-color: ${(props) => props.theme.custom.secondaryInactive};
    opacity: 0.5;
    color: ${(props) => props.theme.custom["white-color"]};
  }
`;

const authButtonId = "phone-email-verification-button";

type IField = {
  hasError: boolean;
  errorText: string;
};

let IText: string;

type Code = {
  dial_code: string;
  flag: string;
  name: string;
  code: string;
};

const useOnSubmit = (phone: string) => {
  const value = usePhoneVerify();
  const { setError } = useFormContext();
  const logIn = usePhoneAuthentication(authButtonId);
  const [phoneToShowInModal, setPhone] = React.useState("");
  const [otpModalOpen, setOtpModalOpen] = React.useState(false);
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [verificationId, setVerificationId] = React.useState<null | string>(
    null,
  );

  const onSubmit = async () => {
    try {
      setIsSubmitting(true);
      const res = await logIn(phone);
      setPhone(phone);
      setVerificationId(res);
      setOtpModalOpen(true);
      GoogleAnalyticsService.createEvent(
        LOG_EVENTS.LOG_EVENTS_NAME.SELECT_PHONE_NUMBER,
        {
          registration: LOG_EVENTS.LOG_METHOD_VALUE.PHONE_NUMBER_SELECTED,
          operator_code: getOperatorCode(),
          source: "web",
        },
      );
    } catch (e) {
      const errorType = e?.code || "generic";
      setError("commonPhone", "submit", errorType);
    } finally {
      setIsSubmitting(false);
    }
  };

  React.useEffect(() => {
    GoogleAnalyticsService.createEvent(LOG_EVENTS.LOG_EVENTS_NAME.SCREEN_VIEW, {
      firebase_screen: LOG_EVENTS.SCREEN_NAME.COUNTRY_PICKER_SCREEN,
      firebase_screen_class: LOG_EVENTS.SCREEN_NAME.COUNTRY_PICKER_SCREEN,
      operator_code: getOperatorCode(),
      source: "web",
    });
    if (value) {
      value.current = onSubmit;
    }
  });

  return {
    onSubmit,
    isSubmitting,
    verificationId,
    phoneToShowInModal,
    otpModalOpen,
    setOtpModalOpen,
  };
};

type FieldProps = {
  closeLoginModal: () => void;
  hideLoginModal: (state: boolean) => void;
};

async function lookupCountry({ latitude, longitude }: any) {
  const URL = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`;

  const locationData = await fetch(URL).then((res) => res.json());

  const [{ address_components }] = locationData.results.filter(
    ({ types }: any) => types.includes("country"),
  );

  const [{ short_name }] = address_components;

  return short_name;
}

export default lookupCountry;

export const PhoneEmailField: React.FC<FieldProps> = ({
  closeLoginModal,
  hideLoginModal,
}) => {
  const { errors } = useFormContext();
  const { t } = useTranslation("login");
  const [text, setText] = React.useState<typeof IText>("");
  const [openSearchBox, setOpenSearchBox] = React.useState(false);
  const [dialCode, setDialCode] = React.useState<typeof IText>("+62");
  const showPhone = !isNaN(Number(text.trim()));
  const submitError = usePhoneAuthErrorMessage(errors.commonPhone?.message);
  const [codeValue, setCodeValue] = React.useState<Code | null>(
    countryFlags[97],
  );

  const [inputValue, setInputValue] = React.useState("");
  const [field, setField] = React.useState<IField>({
    hasError: false,
    errorText: "",
  });
  const {
    onSubmit,
    isSubmitting,
    verificationId,
    phoneToShowInModal,
    otpModalOpen,
    setOtpModalOpen,
  } = useOnSubmit(`${dialCode}${text}`);
  const [countryCode, setCountry] = React.useState("");
  const { value: userValue } = useUserData();
  const { watch } = useFormContext();
  const authorizedUserPhone = userValue?.phoneNumber ?? "";
  const currentPhone = watch(BookingFormFieldsName.phone, authorizedUserPhone);

  React.useEffect(() => {
    const code = countryFlags.filter((flag) => flag.code === countryCode)[0];
    setCodeValue(code);
    setDialCode(code?.dial_code ? code?.dial_code : "+62");
    const phoneCode = code?.dial_code ? code?.dial_code : "+62";
    const phone = currentPhone.replace(phoneCode, "");
    setText(phone);
  }, [countryCode]);

  const handleNavigator = async (pos: any) => {
    const { latitude, longitude } = pos.coords;
    const userCountryCode = await lookupCountry({ latitude, longitude });
    setCountry(userCountryCode);
  };

  React.useEffect(() => {
    navigator.geolocation.getCurrentPosition(handleNavigator, () =>
      console.warn("permission was rejected"),
    );
  }, []);

  const handleSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault();

    if (showPhone) {
      if (isValidNumber(`${dialCode}${text}`)) {
        GoogleAnalyticsService.createEvent(
          LOG_EVENTS.LOG_EVENTS_NAME.ADD_PHONE_NUMBER,
          {
            registration: LOG_EVENTS.LOG_METHOD_VALUE.PHONE_NUMBER_ADDED,
            operator_code: getOperatorCode(),
            source: "web",
          },
        );
        setField({ hasError: false, errorText: "" });
        onSubmit();
      } else {
        setField({
          hasError: true,
          errorText: t("phoneError"),
        });
        return;
      }
    } else {
      if (EMAIL_PATTERN.test(text)) {
        setField({ hasError: false, errorText: "" });
      } else {
        setField({ hasError: true, errorText: t("emailError") });
        return;
      }
    }
  };

  const handleTextChange = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (e.target.value.trim().length > 15 && showPhone) {
        return;
      }
      setText(
        e.target.value.replace(/[^0-9.]/g, "").replace(/(\..*)\./g, "$1"),
      );
      setField({ hasError: false, errorText: "" });
    },
    [setText, setField, showPhone],
  );

  const handleOpenCountrySearchBox = React.useCallback(() => {
    setOpenSearchBox(true);
  }, [setOpenSearchBox]);

  if (otpModalOpen) {
    hideLoginModal(true);
  }

  return (
    <FormContainer onSubmit={handleSubmit}>
      <PhoneEmail
        data-testid={TEST_ID.PHONE_EMAIL_TEXT_BOX}
        error={field.hasError}
        fullWidth
        variant="outlined"
        label={t("emailPhone")}
        className="phoneEmail"
        style={{ height: "3.5rem" }}
        onChange={handleTextChange}
        value={text}
        InputProps={{
          style: {
            height: "3.5rem",
            padding: "0 12px",
          },
          startAdornment: showPhone && (
            <DefaultButton
              data-testid={TEST_ID.COUNTRY_SELECT_BUTTON}
              disableRipple
              style={{ padding: 0 }}
              onClick={handleOpenCountrySearchBox}
            >
              {
                //@ts-ignore
                `${codeValue?.flag || countryFlags[97].flag} ${
                  codeValue?.dial_code || countryFlags[97].dial_code
                }`
              }
              <KeyboardArrowDown />
            </DefaultButton>
          ),
        }}
      />
      {openSearchBox && (
        <div style={{ position: "relative", marginTop: "-1rem" }}>
          <SearchContainer>
            <Autocomplete
              value={codeValue}
              onClose={() => {
                setOpenSearchBox(false);
                if (!codeValue) {
                  setDialCode("+62");
                }
              }}
              onChange={async (event: any, newValue: Code | null) => {
                await setCodeValue(newValue);
                //@ts-ignore
                await setDialCode(newValue ? newValue?.dial_code : "+62");
              }}
              inputValue={inputValue}
              onInputChange={(event, newInputValue) => {
                setInputValue(newInputValue);
              }}
              autoHighlight
              options={countryFlags}
              getOptionLabel={(option) =>
                // @ts-ignore
                `${option.flag} ${option.name} (${option.dial_code})`
              }
              style={{ width: "100%" }}
              renderInput={(params) => (
                <TextField
                  data-testid={TEST_ID.COUNTRY_SEARCH}
                  {...params}
                  variant="outlined"
                  label={t("searchText")}
                />
              )}
            />
          </SearchContainer>
        </div>
      )}
      {submitError || field.hasError ? (
        <Typography component="p" color="error" variant="caption">
          {submitError || field.errorText}
        </Typography>
      ) : null}
      <ContinueButton
        id={authButtonId}
        data-testid={TEST_ID.VERIFY_PHONE}
        color="secondary"
        fullWidth
        style={{ height: "3.5rem" }}
        disabled={text.trim().length < 1 || isSubmitting}
        // className={classes.continueButton}
        type="submit"
        startIcon={
          isSubmitting && <CircularProgress size={16} color="inherit" />
        }
      >
        {t("continue")}
      </ContinueButton>
      {verificationId && (
        <OtpModal
          onClose={() => {
            setOtpModalOpen(false);
            closeLoginModal();
          }}
          open={otpModalOpen}
          phone={phoneToShowInModal}
          verificationId={verificationId}
        />
      )}
    </FormContainer>
  );
};
