import React from 'react'
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { ENDPOINTS } from "modules/api/endpoints";
import { onboardingAxios } from "modules/api/client";
import GoogleAnalyticsService from "../../../modules/utils/GoogleAnalyticsService";
import { LOG_EVENTS } from "../../../modules/utils/LogEvents";
import { getDefaultOperatorCode } from "../../../modules/utils/getDefaultOperator";

const TitleWrapper = styled.h3`
  margin: 29px 0px 33px 29px;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 18px;
  font-feature-settings: "cpsp" on;
  color: #4f4f4f;
  align-items: center;
  background: #F4F4F4;
`;

const ContentWrapper = styled.div`
  margin: 28px 0px 35px 0px;
  padding: 16px 60px;
  text-align: "justify";
  font-size: 14px;
  line-height: 18px;

  @media (max-width: 575px) {
    padding: 16px 30px;
  }  

  & ul{
    list-style-type: unset;
  }
`;
const ContainerWrapper = styled.div`
  padding-left: 11%;
  background: #fff;
  @media (max-width: ${(props) =>
      props.theme.custom.breakpoints["md-breakpoint"]}) {
    padding-left: 10px;
  }
`;
const Container = styled.div`
`;

const Header = styled.div`
  background: #F4F4F4;
  padding-left: 13.3%;
    @media (max-width: 575px)  {
      padding-left: 10px;
    }
    @media (max-width: 768px)  {
      padding-left: 5%;
    } 
`;

const PrivacyPolicy = () => {
    const { t } = useTranslation("footer");
    const [policy, setPolicyData] = React.useState<any>("");

    React.useEffect(() => {
    GoogleAnalyticsService.createEvent(LOG_EVENTS.LOG_EVENTS_NAME.SCREEN_VIEW, {
        firebase_screen: LOG_EVENTS.SCREEN_NAME.TERMS_AND_CONDITION_SCREEN,
        firebase_screen_class: LOG_EVENTS.SCREEN_NAME.TERMS_AND_CONDITION_SCREEN,
        operator_code: getDefaultOperatorCode(),
    });
    const fetchTermsAndConditions = async () => {
        try {
        const res = await onboardingAxios.get(
            ENDPOINTS.ONBOARDING.PRIVACY_POLICY.replace('{operatorCode}', getDefaultOperatorCode()), {
            }
        );
        setPolicyData(res.data);
        } catch (err) {
        }
    };
    fetchTermsAndConditions();
    }, []);

    return (
        <>
          <Header>
            <TitleWrapper>{t("privacyPolicy")}</TitleWrapper>
          </Header>
          <ContainerWrapper>
              <Container>
                <ContentWrapper className="content" dangerouslySetInnerHTML={{ __html: policy }} />
            </Container> 
          </ContainerWrapper>
        </>
    );
}

export default PrivacyPolicy