import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { ENDPOINTS } from "modules/api/endpoints";
import { onboardingAxios } from "modules/api/client";
import GoogleAnalyticsService from "../../../modules/utils/GoogleAnalyticsService";
import { LOG_EVENTS } from "../../../modules/utils/LogEvents";
import { getDefaultOperatorCode } from "../../../modules/utils/getDefaultOperator";
import { isEmpty,includes } from "lodash";
import {getOperatorCode} from 'modules/utils/getOperatorCode'

const TitleWrapper = styled.h3`
  margin: 29px 0px 33px 29px;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 18px;
  font-feature-settings: "cpsp" on;
  color: #4f4f4f;
  align-items: center;
  background: #F4F4F4;
`;

const ContentTitle = styled.h3`
  margin: 35px 35px;
  margin-bottom: 0px;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 18px;
  font-feature-settings: "cpsp" on;
  color: #4f4f4f;
  align-items: center;
`;

const ContentWrapper = styled.div`
  margin-top: 0px;
  margin-bottom: 25px;
  padding: 16px 60px;
  text-align: "justify";
  font-size: 14px;
  line-height: 18px;

  & ul{
    list-style-type: unset;
  }
`;
const ContainerWrapper = styled.div`
  padding-left: 13%;
  background: #fff;
  @media (max-width: ${(props) =>
      props.theme.custom.breakpoints["md-breakpoint"]}) {
    padding-left: 10px;
  }
`;
const Container = styled.div`
`;

const Header = styled.div`
  background: #F4F4F4;
  padding-left: 13%;
  @media (max-width: 575px)  {
    padding-left: 10px;
  }
  @media (max-width: 768px)  {
    padding-left: 1.2%;
  } 
`;

export const TermsNConditions = () => {
  const { t } = useTranslation("termsNCondition");
  const [termsData, setTermsData] = React.useState<any[]>([]);
  let policyTypes = ['bookingPolicy','cancellationPolicy'];
  let useTermsOperator = localStorage.getItem('USE_TERMS_OPERATOR');
  let operatorCode:any = null;
  if (useTermsOperator === 'true'){
    operatorCode = getOperatorCode();
  }else{
    operatorCode = getDefaultOperatorCode();
  }
  React.useEffect(() => {
    GoogleAnalyticsService.createEvent(LOG_EVENTS.LOG_EVENTS_NAME.SCREEN_VIEW, {
      firebase_screen: LOG_EVENTS.SCREEN_NAME.TERMS_AND_CONDITION_SCREEN,
      firebase_screen_class: LOG_EVENTS.SCREEN_NAME.TERMS_AND_CONDITION_SCREEN,
      operator_code: operatorCode,
    });
    const fetchTermsAndConditions = async () => {
      try {
        const res = await onboardingAxios.get(
          ENDPOINTS.ONBOARDING.TERMS_SERVICE.replace('{operatorCode}', operatorCode), {
            // params: {
            //   policyType: 'termsAndConditions'
            // }
          }
        );
        setTermsData(res.data);
      } catch (err) {
      }
    };

    fetchTermsAndConditions();
  }, []);

  return (
    <>
      <Header>
        <TitleWrapper>{t("terms_of_service")}</TitleWrapper>
      </Header>
      <ContainerWrapper>
          <Container>
          {(!isEmpty(termsData) ?
            termsData.map((terms,i) => {
              return (
                    includes(policyTypes,terms.policyType) 
                    ? 
                      <div key={i}>
                        <ContentTitle>{terms ? terms.name : ''}</ContentTitle>
                        <ContentWrapper className="content" dangerouslySetInnerHTML={{ __html: terms.data }} />
                      </div>
                    : ''  
                )    
            })
          : '')}
        </Container> 
      </ContainerWrapper>
    </>
  );
};
