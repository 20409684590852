import React from "react";
import { useTranslation } from "react-i18next";
import { Box, Grid, Typography } from "@material-ui/core";
import CheckCircleRoundedIcon from "@material-ui/icons/CheckCircleRounded";

export const PhoneVerified = ({marginTop=16}) => {
  const { t } = useTranslation("reservation");
  return (
    <Grid
      container
      alignItems="center"
      justify="flex-start"
      style={{ marginTop: marginTop }}
    >
      <CheckCircleRoundedIcon color={"primary"} />
      <Box marginLeft={1}>
        <Typography variant="body2" color="textSecondary" display="inline">
          {t("phoneVerified")}
        </Typography>
      </Box>
    </Grid>
  );
};
