import * as React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { Divider } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { Price, Ticket } from "modules/entities";
import { subtractPrice, sumPrices } from "modules/utils/price";
// import classes from "./PriceSummary.module.css";

const RowWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 15px 0px;
`;

const PriceWrapper = styled.div`
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  color: ${(props) => props.theme.custom["limed-spruce"]};
`;

const Container = styled.section`
  padding: 16px;
`;

const SectionLabel = styled.h4`
  font-size: 18px;
  line-height: 21px;
  color: ${(props) => props.theme.custom["limed-spruce"]};
  margin: 0;
  padding: 0;
  display: inline-block;
`;

const ReceiptWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media (max-width: ${(props) =>
    props.theme.custom.breakpoints["xs-breakpoint"]}) {
    margin-bottom: 20px;
  }
`;

// const SendReceiptText = styled.p`
//   font-weight: bold;
//   font-size: 14px;
//   line-height: 16px;
//   text-align: right;
//   color: ${(props) => props.theme.custom.primary.main};
//   display: inline-block;
// `;

const RefundableText = styled.p`
  margin: 0;
  font-size: 10px;
  line-height: 16px;
  color: #979797;
  border: 1px solid #979797;
  border-radius: 4px;
  padding: 2px 4px 0;
`;
const RefundableRow = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 10px 0;
  h3,
  h4 {
    text-transform: none;
    color: #353f47;
    font-size: 18px;
  }
  h4 {
    font-size: 16px;
  }
  p {
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    color: rgba(53, 63, 71, 0.5);
    margin-bottom: 0px;
    small {
      font-size: 10px;
      margin-bottom: 20px;
      display: block;
    }
  }
`;

const DiscountSec = styled.div`
  display: flex;
  justify-content: space-between;

  .discountCode {
    font-weight: 500;
    font-size: 16px;
    line-height: 12px;
    color: #43a047;
  }

  .discountValue {
    font-weight: 700;
    font-size: 16px;
    line-height: 16px;
    color: #43a047;
  }
`;

type PriceSummaryProps = {
  tickets: Array<Ticket>;
  ticketInfo: any;
};

type RowProps = {
  value: Price;
  label: JSX.Element;
  negative?: boolean;
  refundable: string | null | undefined;
};

const Row: React.FC<RowProps> = ({ label, value, negative, refundable }) => {
  const { t } = useTranslation("ticket");
  return (
    <RowWrapper>
      <div>{label}</div>
      {refundable && <RefundableText>{t(refundable)}</RefundableText>}
      <PriceWrapper style={{ color: value.value === 0 ? "#FF5757" : "" }}>
        {negative ? "- " : ""}
        {t("price", {
          price: value,
        })}
      </PriceWrapper>
    </RowWrapper>
  );
};

export const PriceSummary: React.FC<PriceSummaryProps> = ({
  tickets,
  ticketInfo,
}) => {
  // @todo use ticket value
  const passengersCount = 1;
  const { t } = useTranslation("ticket");

  const basePrice = sumPrices(tickets.map((ticket) => ticket.basePrice));
  const finalPrice = sumPrices(tickets.map((ticket) => ticket.price));
  const discountPrice = sumPrices(tickets.map((ticket) => ticket.discount));
  const voucherPrice = sumPrices(
    tickets
      .filter((ticket) => ticket.usedVoucherCode)
      .map((ticket) => subtractPrice(ticket.basePrice, ticket.price)),
  );
  const [discountCode] = React.useState(ticketInfo?.ticketGroup?.campaign?.coupon);
  const [discountValue, setDiscountPrice] = React.useState<any>(null);
  const [discountedValue, setDiscountedValue] = React.useState<any>(null);

  React.useEffect(() => {
    if (ticketInfo?.ticketGroup?.campaign) {
      const discount = ticketInfo?.ticketGroup?.basePrice?.value - ticketInfo?.ticketGroup?.price?.value;
      const discountedPrice = {
        currency: basePrice.currency,
        value: discount,
      };
      setDiscountPrice(discountedPrice);
      setDiscountedValue(ticketInfo?.ticketGroup?.price?.value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const hasDiscount = discountPrice.value > 0;
  const hasVoucher = voucherPrice.value > 0;
  const ticketDetail = tickets[0];
  const refundRule = ticketDetail?.ride?.refundRule;
  let refundableAmount: Price = { value: 0, currency: "IDR" };
  let deductions: Price = { value: 0, currency: "IDR" };
  if (refundRule?.type === "non_refundable") {
    refundableAmount.value = 0;
    deductions.value = discountedValue ? discountedValue - refundableAmount.value : basePrice.value - refundableAmount.value;
  }

  if (refundRule?.type === "partial_refundable") {
    refundableAmount.value = refundRule?.percent
      ? ((discountedValue ? discountedValue : finalPrice.value) * refundRule?.percent) / 100
      : 0;
    deductions.value = discountedValue ? discountedValue - refundableAmount.value : basePrice.value - refundableAmount.value;
  }

  if (refundRule?.type === "fully_refundable") {
    refundableAmount.value = discountedValue ? discountedValue : finalPrice.value;
    deductions.value = discountedValue ? discountedValue - refundableAmount.value : basePrice.value - refundableAmount.value;
  }

  return (
    <Container>
      <ReceiptWrapper>
        <SectionLabel>Price</SectionLabel>
        {/* <SendReceiptText>Send Receipt</SendReceiptText> */}
      </ReceiptWrapper>
      <Row
        // @todo use ticketGroup
        value={basePrice}
        label={t("ticket", { count: passengersCount })}
        refundable={refundRule?.type}
      />
      {discountValue ? (
        <DiscountSec>
          <p className="discountCode">{`‘${discountCode}’ Offer Discount`}</p>
          <p className="discountValue">
            - {t("price", {
              price: discountValue,
            })}
          </p>
        </DiscountSec>
      ) : null}

      {hasDiscount && (
        <Row
          value={discountPrice}
          label={t("discount")}
          negative
          refundable={""}
        />
      )}
      {hasVoucher && (
        <Row
          value={voucherPrice}
          label={t("voucher")}
          negative
          refundable={""}
        />
      )}
      {refundRule?.type !== "fully_refundable" && (
        <Row value={deductions} label={t("deductions")} refundable="" />
      )}
      <Divider />
      {/* refundable row */}
      <RefundableRow>
        <h3>{t("refunableAmount")}</h3>
        <h4>
          {t("price", {
            price: refundableAmount,
          })}
        </h4>
      </RefundableRow>
      <Divider style={{ marginBottom: 28 }} />
      {refundRule?.type === "non_refundable" && (
        <Alert icon={false} severity="success">
          {t("noRefundAlert")}
        </Alert>
      )}
      {/* {refundRule?.type !== "non_refundable" && (
        <>
          <RefundableRow>
            <p>Amount to be refunded</p>
            <p>{t("price", {
            price: refundableAmount,
          })}</p>
          </RefundableRow>
          <RefundableRow>
            <p>
              Loyalty points to be refunded <br />
              <small>1pts = Rp1</small>
            </p>
            <p>10 pts</p>
          </RefundableRow>
        </>
      )} 
      <Divider style={{ margin: "28px 0" }} />
      */}
    </Container>
  );
};
