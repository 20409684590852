import React from "react";

export const ColoredSvg = ({ color }) => (
  <svg
    width="13"
    height="11"
    viewBox="0 0 13 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.819279 1.55815L0.654161 1.73089L0.819279 1.90364L4.2569 5.5L0.81928 9.09636L0.654162 9.26911L0.81928 9.44185L1.51791 10.1727L1.69863 10.3618L1.87935 10.1727L6.18072 5.67274L6.34584 5.5L6.18072 5.32726L1.87935 0.827257L1.69863 0.638191L1.51791 0.827257L0.819279 1.55815Z"
      fill={color}
      stroke={color}
      strokeWidth="0.5"
    />
    <path
      d="M6.81928 1.55815L6.65416 1.73089L6.81928 1.90364L10.2569 5.5L6.81928 9.09636L6.65416 9.26911L6.81928 9.44185L7.51791 10.1727L7.69863 10.3618L7.87935 10.1727L12.1807 5.67274L12.3458 5.5L12.1807 5.32726L7.87935 0.827257L7.69863 0.638191L7.51791 0.827257L6.81928 1.55815Z"
      fill={color}
      stroke={color}
      strokeWidth="0.5"
    />
  </svg>
);
