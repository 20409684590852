import * as React from "react";
import styled from "styled-components";
import { makeStyles } from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import { breakpoints } from "modules/ui/variables";
import { BookingInstructions } from "../organisms/BookingInstructions/BookingInstructions";
import { BookingSuccess } from "../organisms/BookingSuccess/BookingSuccess";
// import classes from "./CheckoutSuccess.module.css";
import { updateOperatorCode } from "modules/api/client";

type CheckoutSuccessProps = {
  isSuccess: boolean;
  bookingNumber: string;
  userMail: string;
  ticketId: string;
  externalOperatorId:string;
};

const useClasses = makeStyles({
  divider: {
    width: "1140px",
    backgroundColor: "#E0E0E0",
  },
  [`@media(max-width: ${breakpoints.xs}px)`]: {
    divider: {
      display: "none",
    },
  },
});

const SuccessContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const CheckoutSuccessTemplate: React.FC<CheckoutSuccessProps> = ({
  isSuccess,
  bookingNumber,
  userMail,
  ticketId,
  externalOperatorId,
}) => {
  const muiClasses = useClasses();
  React.useEffect( ()=>{
    updateOperatorCode(externalOperatorId);
  },[externalOperatorId])
  return (
    <SuccessContainer>
      {isSuccess && (
        <BookingSuccess
          bookingNumber={bookingNumber}
          userMail={userMail}
          ticketId={ticketId}
        />
      )}
      <Divider className={muiClasses.divider} />
      <BookingInstructions />
    </SuccessContainer>
  );
};
