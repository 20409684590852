import React from "react";
import { RouteComponentProps, withRouter } from "react-router";
import { useTranslation } from "react-i18next";
// import clsx from "clsx";
import styled from "styled-components";
import { Link, routes } from "modules/routing";
import { FooterMenuItem } from "../../atoms/FooterMenuItem/FooterMenuItem";
import AppVersion from "modules/common/molecules/AppVersion";
// import classes from "./Footer.module.css";
import { isEmpty } from "lodash";
import { useLocation } from 'react-router-dom';

const FooterWrapper = styled.footer`
  display: flex;
  padding: 25px 15px;
  justify-content: center;
  background-color: ${(props) => props.theme.custom.primary.main};
  &.footerMarginBottom {
    padding-bottom: 25px;
  }
  @media (max-width: 768px) {
    padding: 25px;
  }
  @media (max-width: 575px) {
    padding: 35px 10px 0px 0px;
  }
`;

const FooterDiv = styled.footer`
  display: block;
  flex-wrap: wrap;
  width: 100%;
  justify-content: center;
  max-width: 1140px;
  @media (max-width: 768px) {
    width: 100%;
    flex-wrap: nowrap;
  }
  @media (max-width: 575px) {
    width: 100%;
    flex-wrap: wrap;
  }
`;

const FooterLeftBlock = styled.div`
  
  align-items: center;
  margin-bottom: 10px;
  
  a {
    text-decoration: none;

    .footerMenuMarginLeft {
      font-weight: 500;
      font-size: 16px;
      padding: 6px 30px 5px 30px;
      margin-left:0px;
      text-align:center;
      
      @media (max-width: 575px) {
        padding: 6px 15px 5px 10px;
      }  
    }

    .active-tab{
      color: #1B4EA3;
    }
    .br_none{
      border-right: none;
    }
  }
  @media (max-width: 768px) {
    width: 100%;
    justify-content: center;
  }
  @media (max-width: 575px) {
    width: 100%;
    justify-content: center;
  }
`;

const FooterVersion = styled.div`
    display:flex;
    justify-content:center;
    align-items:center;
    width:100%;
    @media (max-width: 768px) {
      width: 100%;
      justify-content: flex-start;
    }
    @media (max-width: 575px) {
      width: 100%;
      justify-content: center;
    }
    &:before {
      line-height:5px;
      margin-top:2px;
      width:100%;
    }
`;

const DesktopFooter = styled.div`
  display: none;
  justify-content: center;
  @media (min-width: 1024px) {
    display:flex;
  }
`;

const MobileFooter = styled.div`
  display: none;
  justify-content: center;
  .footerMenuMarginLeft{
    border-right: 1px solid #fff;
    font-size: 12px !important;
  }
  @media (max-width: 768px) {
    display:flex;
  }
`;

function FooterComponent({ location: { search } }: RouteComponentProps) {
  const { t } = useTranslation("footer");
  const location = useLocation();
  let path = location.pathname.split("/").pop();
  path = `/${path}`;

  let footer_options = [
    {
      link:routes.aboutUs.url(),
      value:t("aboutus")
    },
    {
      link:routes.termsNConditions.url(),
      value:t("terms")
    },
    {
      link:routes.privacypolicy.url(),
      value:t("privacyPolicy")
    },
    {
      link:routes.faq.url(),
      value:t("faq")
    },
    {
      link:routes.contactUs.url(),
      value:t("contactus")
    },
  ]

  let mob_footer_options = [
    {
      link:routes.termsNConditions.url(),
      value:t("terms")
    },
    {
      link:routes.privacypolicy.url(),
      value:t("privacyPolicy")
    }
  ]
  
  return (
    <FooterWrapper className={`${search && "footerMarginBottom"}`}>
      <FooterDiv>
        <FooterLeftBlock>
          <DesktopFooter>
            {!isEmpty(footer_options) ?
              footer_options.map((val,i) => {
                return(
                  <Link to={val.link} key={i} target={val.value === t("terms") || val.value === t("privacyPolicy") ? '_blank' : '#'}
                  onClick={() => localStorage.setItem('USE_TERMS_OPERATOR','false') }
                  >
                    <FooterMenuItem 
                      value={val.value}
                      parentClass={`footerMenuMarginLeft ${path === val.link ? 'active-tab' : ''}`}
                    />
                  </Link>
                )
              })
            : ''}
          </DesktopFooter>
          <MobileFooter>
            {!isEmpty(mob_footer_options) ?
              mob_footer_options.map((val,i) => {
                return(
                  <Link to={val.link} key={i} target="_blank">
                    <FooterMenuItem 
                      value={val.value}
                      parentClass={`footerMenuMarginLeft ${path === val.link ? 'active-tab' : ''} ${val.value === t("privacyPolicy") ? 'br_none' : ''}`}
                    />
                  </Link>
                )
              })
            : ''}
          </MobileFooter>

          {/*<FooterMenuItem value={t("legalNotice")} />*/}
          {/* <Link to={"#"}>
            <FooterMenuItem value={t("legalNotice")} />
          </Link> */}
          
            
          
          
          <Link to={routes.privacyPolicy.url()}>
            {/* <FooterMenuItem
              value={t("policy")}
              parentClass="footerMenuMarginLeft"
            /> */}
          </Link>
        </FooterLeftBlock>
        <FooterVersion>
            <AppVersion></AppVersion>
        </FooterVersion>
      </FooterDiv>
    </FooterWrapper>
  );
}

export const Footer = withRouter(FooterComponent);
