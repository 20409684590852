import React from "react";
import { useLocation, useRouteMatch, useHistory } from "react-router-dom";
import styled from "styled-components";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import { Popper } from "@material-ui/core";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import { routes } from "modules/routing";
import { Button } from "modules/ui";
import { TEST_ID } from "modules/ui/test-id";
import { ENDPOINTS } from "modules/api/endpoints";
import { onboardingAxios } from "modules/api/client";
import GoogleAnalyticsService from "modules/utils/GoogleAnalyticsService";
import { updateOperatorCode } from "modules/api/client";
import {CountryFlagIN,CountryFlagID,CountryFlagPH,CountryFlagSG,CountryFlagMY,CountryFlagAE} from './icon'
import {defaultOperator} from 'modules/ui/constants'
import {useSelector,RootStateOrAny} from 'react-redux'


const InputWrapper = styled(({ ...rest }) => <div {...rest} />)`
  display: flex;
  min-height: 40px;
  min-width: 85px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const SelectWrapper = styled(({ ...rest }) => <div {...rest} />)`
  min-width: 60px;
  margin-top: 10px;
  background: ${(props) => props.theme.custom["white-color"]};
  border: 1px solid ${(props) => props.theme.custom["silver-color"]};
  box-sizing: border-box;
  box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.05);
  border-radius: 3px;
  .country-name {
    padding-left: 38px;
    color: #19191d;
    font-weight: 400;
    font-size: 14px;
    position: relative;
    display: block;
    margin-top: -24px;
  }
`;

const Select = styled(({ ...rest }) => <div {...rest} />)`
  padding: 10px;
  font-family: ${(props) => props.theme.custom["roboto-font"]};
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: ${(props) => props.theme.custom["emperor-color"]};
  &.selectActive {
    background: ${(props) => props.theme.custom["alabaster-color"]};
    border-radius: 4px;
    font-weight: 500;
    font-size: 14px;
    margin: 8px;
    .country-name {
      font-weight: bold;
    }
  }
`;

const PopperWrapper = styled(({ ...rest }) => <Popper {...rest} />)`
  z-index: 99;
`;

export const RegionSelect = () => {
  const { search, state } = useLocation();
  const history = useHistory();
  const match = useRouteMatch<{
    language?: string;
    path?: string | undefined;
  }>(`${routes.languagePrefix.pattern}/:path(.*)`);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [open, setOpen] = React.useState<boolean>(false);

  const region = useSelector((state: RootStateOrAny) => state.region);
  const [regionData,setRegionData] = React.useState<any>([]);
  const [selectedRegion,setSelectedRegion] = React.useState<String | null>(null);

  React.useEffect(() => {
    setSelectedRegion(region.countryCode)
  },[region])

  React.useEffect( () => {

    // GoogleAnalyticsService.createEvent(LOG_EVENTS.LOG_EVENTS_NAME.SCREEN_VIEW, {
    //   firebase_screen: LOG_EVENTS.SCREEN_NAME.TERMS_AND_CONDITION_SCREEN,
    //   firebase_screen_class: LOG_EVENTS.SCREEN_NAME.TERMS_AND_CONDITION_SCREEN,
    //   operator_code: getOperatorCode(),
    // });

    const getRegionData = async () => {
      try{
          const res = await onboardingAxios.get(
              ENDPOINTS.ONBOARDING.REGION,{
                  params: {
                    type: 'aboutus'
                  }
                }
          )
          setRegionData(res.data)
      }catch(e){

      }
  }; 
  getRegionData();
  },[])

  const selectRegion = (region:String) => {
    setSelectedRegion(region)
    updateOperatorCode(defaultOperator+"-"+region);
    handleClickAway();
    const url: string = `/${defaultOperator}-${region}`;
    window.location.href = url;
  }

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
    setOpen((prev) => !prev);
  };

  const handleClickAway = () => {
    setOpen(false);
    setAnchorEl(null);
  };

  const id = open ? "language-select" : undefined;

  return (
    <div>
      <InputWrapper>
        <ClickAwayListener onClickAway={handleClickAway}>
          <div style={{ color: "white" }}>
            <Button
              variant="text"
              color="inherit"
              onClick={handleClick}
              data-testid={TEST_ID.LANGUAGE_SELECT}
              // startIcon={<LanguageIcon fontSize="small" />}
              endIcon={<KeyboardArrowDownIcon />}
            >
              {selectedRegion === 'IN' ? <CountryFlagIN /> 
                : [selectedRegion === 'ID' ? <CountryFlagID /> 
                : selectedRegion === 'PH' ? <CountryFlagPH /> 
                :selectedRegion === 'SG' ? <CountryFlagSG />
                :selectedRegion === 'MY' ? <CountryFlagMY />
                :selectedRegion === 'AE' ? <CountryFlagAE />
                :''
              ]}

            </Button>
            <PopperWrapper
              id={id}
              open={open}
              anchorEl={anchorEl}
              disablePortal
            >
              <SelectWrapper data-testid={TEST_ID.LANGUAGE_SELECT_DROPDOWN}>
                {regionData.map((value:any) => (
                  <Select
                    key={value.countryCode}
                    onClick={() => selectRegion(value.countryCode)}
                    className={selectedRegion === value.countryCode ? "selectActive" : ""}
                  >
                    {value.countryCode === 'IN' ? <CountryFlagIN /> 
                      : [value.countryCode === 'ID' ? <CountryFlagID /> 
                      : value.countryCode === 'PH' ? <CountryFlagPH /> 
                      :value.countryCode === 'SG' ? <CountryFlagSG />
                      :value.countryCode === 'MY' ? <CountryFlagMY />
                      :value.countryCode === 'AE' ? <CountryFlagAE />
                      :''
                    ]}
                    <span className="country-name">{value.name}</span>
                  </Select>
                ))}
              </SelectWrapper>
            </PopperWrapper>
          </div>
        </ClickAwayListener>
      </InputWrapper>
    </div>
  );
};
