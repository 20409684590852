import React from "react";
import { withStyles } from "@material-ui/core/styles";
import MuiExpansionPanel from "@material-ui/core/ExpansionPanel";
import MuiExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import MuiExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { GuideList } from "../GuideList/GuideList";

const ExpansionPanel = withStyles((theme) => ({
  root: {
    boxShadow: "none",
    paddingLeft: "15px",
    paddingRight: "15px",
    "&:not(:last-child)": {
      borderBottom: "1px solid #E0E0E0 !important",
    },
    "&:before": {
      display: "none",
    },
    "&$expanded": {
      margin: "auto",
    },
  },
  expanded: {},
}))(MuiExpansionPanel);

const ExpansionPanelSummary = withStyles((theme) => ({
  root: {
    padding: 0,
  },
  expandIcon: {
    color: theme.custom.primary.main,
  },
}))(MuiExpansionPanelSummary);

const ExpansionPanelSummaryText = withStyles({
  root: {
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "16px",
    lineHeight: "22px",
    letterSpacing: "0.15px",
    color: "#4F4F4F",
  },
})(Typography);

const ExpansionPanelDetails = withStyles(() => ({
  root: {
    padding: 0,
  },
}))(MuiExpansionPanelDetails);

type Props = {
  title: string;
  list: Array<string>;
  index: number;
  expanded: number | boolean;
  onChange: Function;
};

export const GuideDropDown: React.FC<Props> = ({
  title,
  index,
  list,
  expanded,
  onChange,
}) => {
  return (
    <ExpansionPanel
      square
      expanded={index === expanded}
      onChange={onChange(index)}
    >
      <ExpansionPanelSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls={`panel${index}d-content`}
        id="panel1d-header"
      >
        <ExpansionPanelSummaryText>{title}</ExpansionPanelSummaryText>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails>
        <GuideList list={list} />
      </ExpansionPanelDetails>
    </ExpansionPanel>
  );
};
