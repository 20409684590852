export const TicketStatusDetails = {
  PRE_DRAFT: "pre_draft",
  DRAFT: "draft",
  RESERVED: "reserved",
  PAID: "paid",
  REMOVED: "removed",
  PAYMENT_CANCELLED: "payment_cancelled",
  ON_THE_WAT: "on_the_way",
  USED: "used",
  CANCELLED: "cancelled",
  REFUND_INITIATED: "refund_initiated",
  REFUND_PENDING_FOR_APPROVAL: "refund_pending_for_approval",
  REFUND_CREDITED: "refund_credited",
  REFUNDED_AND_TICKET_CANCELLED: "refunded_and_ticket_cancelled",
  REFUND_REJECT_AND_TICKET_CANCELLED: "refund_reject_and_ticket_cancelled",
  REFUND_FAILED_AND_TICKET_CANCELLED: "refund_failed_and_ticket_cancelled",
};
