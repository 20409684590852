import React from "react";
import styled from "styled-components";
import Backdrop from "@material-ui/core/Backdrop";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import Typography from "@material-ui/core/Typography";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import { withStyles } from "@material-ui/core";
import { breakpoints } from "modules/ui/variables";
import { useWindowResizeListener } from "modules/utils/hooks/useWindowResizeListener";
import { BookButton } from "../../molecules/BookButton";
import { ShoppingCartProps } from "./ShoppingCartProps";
import { ShoppingCart } from "./ShoppingCart";

const CartTypo = styled(Typography)`
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 1.25px;
  color: ${(props) => props.theme.custom["limed-spruce"]};
`;

const PanelSummary = withStyles({
  root: {
    flexDirection: "row-reverse",
    padding: 0,
    "&.Mui-expanded": {
      minHeight: 40,
    },
  },
  content: {
    margin: 0,
    justifyContent: "space-between",
    alignItems: "center",
    "&.MuiExpansionPanelSummary-content": {
      margin: 0,
      padding: "14px 10px 14px 0",
      "&.Mui-expanded": {
        padding: 0,
      },
    },
  },
})(ExpansionPanelSummary);

const ExpansionPanelWrapper = withStyles({
  root: {
    "&.MuiExpansionPanel-root": {
      margin: 0,
    },
  },
})(ExpansionPanel);

const ExpansionDetailsWrapper = withStyles({
  root: {
    padding: 0,
  },
})(ExpansionPanelDetails);

export const MobileShoppingCart = (props: ShoppingCartProps) => {
  const {
    ride,
    pickUpStopId,
    dropOutStopId,
    passengersCount,
    onRemove,
    onReturnRemove,
    timezone,
    returnRide,
    returnDropOutStopId,
    returnPickUpStopId,
  } = props;
  const { windowWidth } = useWindowResizeListener();
  const [open, setOpen] = React.useState<boolean>(false);
  const anchorRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    let root = document.documentElement;
    const defaultPadding = windowWidth > breakpoints.md ? 25 : 90;
    const paddingProperty = open
      ? anchorRef.current!.clientHeight + 65
      : defaultPadding;
    root.style.setProperty("--padding-bottom", `${paddingProperty}px`);
  }, [open, windowWidth]);
  const urlParams = new URLSearchParams(window.location.search);
  const returnDate = urlParams.get("returnDate");
  const disabled = React.useMemo(() => {
    if (returnDate) {
      if (ride && returnRide) {
        return false;
      } else {
        return true;
      }
    } else {
      if (ride) {
        return false;
      } else {
        return true;
      }
    }
  }, [returnRide, returnDate, ride]);

  return (
    <>
      <Backdrop open={open} onClick={() => setOpen(false)}>
        <></>
      </Backdrop>
      <ExpansionPanelWrapper
        expanded={open}
        onChange={(event, next) => setOpen(next)}
      >
        <PanelSummary
          expandIcon={<ExpandLessIcon />}
          aria-controls="shopping-cart-content"
          id="shopping-cart-header"
        >
          <CartTypo variant="button">Cart:</CartTypo>
          {!open && (
            <BookButton
              disabled={disabled}
              ride={ride}
              returnRide={returnRide}
              passengersCount={passengersCount}
            />
          )}
        </PanelSummary>
        <ExpansionDetailsWrapper ref={anchorRef}>
          <ShoppingCart
            pickUpStopId={pickUpStopId}
            dropOutStopId={dropOutStopId}
            ride={ride}
            passengersCount={passengersCount}
            onRemove={onRemove}
            onReturnRemove={onReturnRemove}
            timezone={timezone}
            returnRide={returnRide}
            returnDropOutStopId={returnDropOutStopId}
            returnPickUpStopId={returnPickUpStopId}
          />
        </ExpansionDetailsWrapper>
      </ExpansionPanelWrapper>
    </>
  );
};
