import React from "react";
import { useTranslation } from "react-i18next";
import { useFormContext } from "react-hook-form";
import styled from "styled-components";
import { useUserData } from "modules/auth";
import { BookingFormFieldsName } from "modules/ui/variables";
import { Label } from "../atoms/Label/Label";
import { FormInput } from "../molecules/FormInput/FormInput";
import { PhoneField } from "./PhoneField/PhoneField";
import Info from "../icons/info.svg";

const EMAIL_PATTERN =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

// const UNSUBSCRIBE_EMAIL = "unsubscribe@bussr.com";

const Wrapper = styled.div`
  background: ${(props) => props.theme.custom["white-color"]};
  border: 1px solid ${(props) => props.theme.custom["alto-color"]};
  box-sizing: border-box;
  border-radius: 3px;
  padding: 15px 30px;
  max-width: 680px;
  @media (max-width: ${(props) =>
    props.theme.custom.breakpoints["xs-breakpoint"]}) {
    padding: 15px 16px;
    border: none;
  }
`;

const InfoWrapper = styled.div`
  .infoIcon__wrapper {
    display: flex;
    justify-content: space-between;
    img {
      width: 22px;
      height: 22px;
    }
  }
`;

const PhoneEmailGroup = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 25px;
  row-gap: 14px;
  margin-top: 14px;
  @media (max-width: ${(props) =>
    props.theme.custom.breakpoints["xs-breakpoint"]}) {
    grid-template-columns: 1fr;
  }
`;

const FieldWrapper = styled.div`
  display: grid;
  // gap: 17px;
  &.fieldwrapperError {
    // gap: 22px;
  }
`;

const InfoDescriptionText = styled.p`
  font-family: ${(props) => props.theme.custom["roboto-font"]};
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 18px;
  color: ${(props) => props.theme.custom["gray-color"]};
  margin-top: 16px;
  margin-bottom: 30px;
`;

export const ThirdBlock = () => {
  const { t } = useTranslation("reservation");
  const { errors } = useFormContext();
  const { value: userValue } = useUserData();

  return (
    <Wrapper>
      <InfoWrapper>
        <div className="infoIcon__wrapper">
          <Label number={2} label={t("contactTitle")} />
          {/* <img src={Info} alt="" /> */}
        </div>
      </InfoWrapper>
      <PhoneEmailGroup>
        <FieldWrapper
          className={
            (errors[BookingFormFieldsName.email] ||
              errors[BookingFormFieldsName.phone]) &&
            "fieldwrapperError"
          }
          data-testid="reservation-email-input"
        >
          <FormInput
            defaultValue={userValue?.email}
            name={BookingFormFieldsName.email}
            label={t("emailLabel")}
            requiredErrorMessage={errors?.email?.message || t("required")}
            validationPattern={EMAIL_PATTERN}
          />
          <InfoDescriptionText>{t("emailDescription")}</InfoDescriptionText>
        </FieldWrapper>
        <div>
          <PhoneField />
        </div>
      </PhoneEmailGroup>
    </Wrapper>
  );
};
