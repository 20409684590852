import React, { useContext, useRef } from "react";
import { format } from "date-fns";
import styled from "styled-components";
import { useDay } from "@datepicker-react/hooks";
import { useTheme } from "@material-ui/core/styles";
import DatepickerContext from "modules/ui/molecules/DatePicker/DatePicker.context";
import { getColor } from "modules/ui/utils/getColor";
// import classes from "./Day.module.css";

const DayWrapper = styled(({ ...rest }) => <button {...rest} />)`
  padding: 12px;
  font-family: ${(props) => props.theme.custom["roboto-font"]};
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  border: 0;
  &:hover {
    cursor: pointer;
  }
  &:focus {
    background-color: ${(props) => props.theme.custom.primary} !important;
    color: ${(props) => props.theme.palette.text} !important;
  }
  @media (max-width: 768px) {
    width: 100%;
  }
`;
export function Day({ dayLabel, date }: any) {
  const dayRef = useRef(null);
  const theme = useTheme();
  const {
    focusedDate,
    isDateFocused,
    isDateSelected,
    isDateHovered,
    isDateBlocked,
    isFirstOrLastSelectedDate,
    onDateSelect,
    onDateFocus,
    onDateHover,
  } = useContext(DatepickerContext);
  const {
    isSelected,
    isSelectedStartOrEnd,
    disabledDate,
    onClick,
    onKeyDown,
    onMouseEnter,
    tabIndex,
  } = useDay({
    date,
    focusedDate,
    isDateFocused,
    isDateSelected,
    isDateHovered,
    isDateBlocked,
    isFirstOrLastSelectedDate,
    onDateFocus,
    onDateSelect,
    onDateHover,
    dayRef,
  });

  if (!dayLabel) {
    return <div />;
  }

  const isDaySelected = format(date, "MM.dd") === format(focusedDate, "MM.dd");

  const getColorFn = getColor(
    isSelected,
    isSelectedStartOrEnd || isDaySelected,
    disabledDate
  );

  return (
    <DayWrapper
      onClick={onClick}
      onKeyDown={onKeyDown}
      onMouseEnter={onMouseEnter}
      tabIndex={tabIndex}
      type="button"
      ref={dayRef}
      style={{
        color: getColorFn({
          selectedFirstOrLastColor: "#FFFFFF",
          normalColor: "#001217",
          selectedColor: "#FFFFFF",
          disabledColor: "#808285",
        }),
        background: getColorFn({
          selectedFirstOrLastColor: theme.custom.primary.main,
          normalColor: "#FFFFFF",
          selectedColor: theme.custom.primary.main,
          disabledColor: "#FFFFFF",
        }),
      }}
    >
      {dayLabel}
    </DayWrapper>
  );
}
