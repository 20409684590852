import React from "react";
import { CityName, BusStopName } from "modules/common";

type SearchPointProps = {
  type: "City" | "BusStop";
  id: string;
};

export const SearchPoint: React.FC<SearchPointProps> = ({ type, id }) => {
  if (type === "City") {
    return <CityName id={id} />;
  } else {
    return <BusStopName id={id} />;
  }
};
