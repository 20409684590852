import React from "react";
import styled from "styled-components";
// import classes from "./Advantage.module.css";

interface Props {
  title: string;
  content: string;
  icon: string;
}
const AdvantageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 265px;
  max-height: 209px;
  padding: 30px 15px 25px 15px;
  margin: 0px 12px 30px 12px;
  background: ${(props) => props.theme.custom["white-color"]};
  border: 1px solid ${(props) => props.theme.custom["alto-color"]};
  box-sizing: border-box;
  border-radius: 4px;
  img {
    min-height: 70px;
  }
  @media (max-width: ${(props) =>
      props.theme.custom.breakpoints["lg-breakpoint"]}) {
    max-width: 329px;
    max-height: 217px;
  }
  @media (max-width: ${(props) =>
      props.theme.custom.breakpoints["md-breakpoint"]}) {
    max-width: 100%;
    width: 44%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 23px 32px;
    border: 1px solid ${(props) => props.theme.custom["silver-color"]};
    border-radius: 4px;
    background: transparent;
    img {
      max-width: 70px;
      max-height: 70px;
      min-height: 70px;
      margin-bottom: 15px;
    }
  }
  @media (max-width: ${(props) =>
      props.theme.custom.breakpoints["xs-breakpoint"]}) {
    max-width: 100%;
    width: 100%;
    flex-direction: row;
    justify-content: start;
    align-items: start;
    margin-left: 0px;
    margin-right: 0px;
    margin-bottom: 0px;
    border-bottom: 1px solid ${(props) => props.theme.custom["silver-color"]};
    border-top: 0px;
    border-radius: 0px;
    border-left: none;
    border-right: none;
    background: transparent;
    &:last-child {
      border-bottom: 0;
    }
    img {
      max-width: 43px;
      max-height: 43px;
      min-height: 43px;
    }
  }
`;
const AdvantageContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  @media (max-width: ${(props) =>
      props.theme.custom.breakpoints["md-breakpoint"]}) {
    text-align: center;
    margin: 0;
    justify-content: center;
    align-items: center;
    padding: 0 55px;
  }
  @media (max-width: ${(props) =>
      props.theme.custom.breakpoints["xs-breakpoint"]}) {
    margin-left: 30px;
    padding: 0;
    justify-content: flex-start;
    text-align: left;
    align-items: flex-start;
  }
`;
const AdvantageTitle = styled.p`
  font-family: ${(props) => props.theme.custom["roboto-font"]};
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  margin: 15px 0px 10px 0px;
  color: ${(props) => props.theme.custom["emperor-color"]};
  @media (max-width: ${(props) =>
      props.theme.custom.breakpoints["md-breakpoint"]}) {
    text-align: left;
    margin-top: 0px;
  }
`;
const AdvantageContent = styled.p`
  font-family: ${(props) => props.theme.custom["roboto-font"]};
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 0.5px;
  color: ${(props) => props.theme.custom["emperor-color"]};
  margin: 0px;
  @media (max-width: ${(props) =>
      props.theme.custom.breakpoints["md-breakpoint"]}) {
    text-align: center;
  }
  @media (max-width: ${(props) =>
      props.theme.custom.breakpoints["xs-breakpoint"]}) {
    text-align: left;
  }
`;

export function Advantage(props: Props) {
  const { title, content, icon } = props;
  return (
    <AdvantageWrapper>
      <img src={icon} alt="advantage-icon" />
      <AdvantageContentWrapper>
        <AdvantageTitle>{title}</AdvantageTitle>
        <AdvantageContent>{content}</AdvantageContent>
      </AdvantageContentWrapper>
    </AdvantageWrapper>
  );
}
