import useSWR, { ConfigInterface } from "swr";
import { BusStop } from "modules/entities";
import { ENDPOINTS } from "../endpoints";

export const useBusStop = (
  id: string,
  config?: ConfigInterface<BusStop, any>,
) => {
  const { isValidating, data, error } = useSWR<BusStop>(
    `${ENDPOINTS.BUS_STOPS}${id}`,
    config,
  );

  return {
    isValidating,
    data,
    error,
  };
};
