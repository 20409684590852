import React from "react";
import { useParams } from "react-router";
import { useTickets } from "modules/api";
import { TicketStatus } from "modules/entities";
import { Redirect, routes } from "modules/routing";
import { Loading } from "modules/ui/atoms/Loading";
import { Notifications } from "modules/notifications";

const TICKET_REFRESH_INTERVAL = 5000;

export function PaymentStatus() {
  const { ticketId } = useParams();
  const { data: tickets } = useTickets(ticketId, {
    refreshInterval: TICKET_REFRESH_INTERVAL,
  });

  if (tickets?.ticket?.status === TicketStatus.payment_cancelled) {
    Notifications.enqueueSnackbar("Payment Failed", {
      preventDuplicate: true,
    });
    return <Redirect to="/" />;
  } else if (tickets?.ticket?.status === TicketStatus.paid) {
    return (
      <Redirect
        to={{
          pathname: routes.checkoutSuccess.url({
            ticketId: tickets?.ticket.id,
          }),
          state: {
            ticketId: tickets?.ticket.id,
          },
        }}
      />
    );
  } else {
    return <Loading title="Fetching Status" />;
  }
}
