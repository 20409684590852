import * as React from "react";

type fn = () => void;

const PhoheVerifyContext = React.createContext<React.MutableRefObject<fn | null> | null>(
  null
);

export const PhoheVerifyProvider: React.FC = ({ children }) => {
  const ref = React.useRef<fn | null>(null);
  return (
    <PhoheVerifyContext.Provider value={ref}>
      {children}
    </PhoheVerifyContext.Provider>
  );
};

export const usePhoneVerify = () => React.useContext(PhoheVerifyContext);
