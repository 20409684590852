import isString from "lodash/isString";
import * as yup from "yup";
import {
  NewReservationParams,
  RentalServiceParams,
  SearchParams,
  SelectPaymentParams,
} from "./query-types";

type ParseResultOk<Params> = { valid: true; params: Params };
type ParseResultError = { valid: false };

export type ParseResult<Params> = ParseResultOk<Params> | ParseResultError;

const passengersCount = yup.number().min(1).max(4);

const reservationSchema = yup.object().shape({
  pickUpStopId: yup.string().required(),
  dropOutStopId: yup.string().required(),
  rideId: yup.string().required(),
  passengers: passengersCount,
});

export const parseNewReservation = (
  values: any
): ParseResult<NewReservationParams> => {
  if (reservationSchema.isValidSync(values)) {
    return {
      valid: true,
      params: reservationSchema.cast(values),
    };
  }
  return {
    valid: false,
  };
};

export const parseSelectPayment = (
  values: any
): ParseResult<SelectPaymentParams> => {
  const result = parseNewReservation(values);
  if (!result.valid) {
    return result;
  }
  const params: SelectPaymentParams = {
    ...result.params,
  };
  if (isString(values.paymentCode)) {
    params.paymentCode = values.paymentCode;
  }
  return {
    valid: true,
    params,
  };
};

const point = yup.string().oneOf(["BusStop", "City"]) as yup.StringSchema<
  "BusStop" | "City"
>;

const searchSchema = yup.object().shape({
  departureId: yup.string().required(),
  departureType: point,
  arrivalId: yup.string().required(),
  arrivalType: point,
  passengers: passengersCount,
  arrivalName:yup.string(),
  departureName:yup.string(),
  date: yup
    .date()
    .required()
    .transform((date: Date) => {
      date.setHours(0, new Date().getTimezoneOffset() * -1, 0, 0);
      return date;
    }),
});

export const parseSearch = (values: any): ParseResult<SearchParams> => {
  if (searchSchema.isValidSync(values)) {
    return {
      valid: true,
      params: searchSchema.cast(values),
    };
  }
  return { valid: false };
};

const rentalSchema = yup.object().shape({
  categoryId: yup.string().required(),
  startDate: yup.string().required(),
  returnDate: yup.string().required(),
  address: yup.string().required(),
  serviceId: yup.string(),
});

export const parseRentalService = (
  values: any
): ParseResult<RentalServiceParams> => {
  if (rentalSchema.isValidSync(values)) {
    return {
      valid: true,
      params: rentalSchema.cast(values),
    };
  }
  return { valid: false };
};
