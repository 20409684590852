export interface RegionState {
  countryCode: string;
  currency: string;
  locale: string;
  name: string;
  paymentGateway: string;
  paymentMethodTypeCategories: Array<string>;
  paymentMethodTypeToExclude: Array<string>;
  timeZone: string;
}

const initialState = {
  countryCode: "",
  currency: "",
  locale: "",
  name: "",
  paymentGateway: "",
  paymentMethodTypeCategories: [],
  paymentMethodTypeToExclude: [],
  timeZone: "",
};

export type RegionGotAction = {
  type: "GOT_REGION";
  payload: any;
};

export const regionReducer = (
  state: RegionState = initialState,
  action: RegionGotAction,
) => {
  switch (action.type) {
    case "GOT_REGION": {
      return { ...action.payload };
    }

    default:
      return {
        ...state,
      };
  }
};
