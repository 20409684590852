import * as yup from "yup";
import { NewReservationParams } from "modules/routing";
import { ParseResult } from "modules/routing/parsers";
import { safeParse } from "modules/utils/safePars";

export type ReservationFormValues = {
  passengers: Array<{ firstName: string; lastName: string }>;
  voucher: string;
  email: string;
};

const reservationValuesSchema = yup.object().shape({
  voucher: yup.string(),
  email: yup.string().required().email(),
  passengers: yup
    .array(
      yup.object().shape({
        firstName: yup.string().required(),
        lastName: yup.string(),
      })
    )
    .min(1)
    .max(4),
});

/**
 * utility to get an id of the order for storing data in browser storage.
 */
export const getOrderId = ({
  dropOutStopId,
  passengers,
  pickUpStopId,
  rideId,
}: NewReservationParams) => {
  return `${pickUpStopId}.${dropOutStopId}.${rideId}.${passengers}`;
};

export const saveBookingValues = (
  key: string,
  values: ReservationFormValues
) => {
  const str = JSON.stringify({ values });
  window.sessionStorage.setItem(key, str);
};

export const getBookingValues = (
  key: string
): ParseResult<ReservationFormValues> => {
  const str = window.sessionStorage.getItem(key);
  if (str) {
    const result = safeParse(str);
    if (reservationValuesSchema.isValidSync(result?.values)) {
      return {
        valid: true,
        params: reservationValuesSchema.cast(result?.values),
      };
    }
  }
  return {
    valid: false,
  };
};
