/* eslint-disable react-hooks/exhaustive-deps */
import * as React from "react";
import { useTranslation } from "react-i18next";
import { useFormContext } from "react-hook-form";
import styled from "styled-components";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {
  TextField,
  Button as DefaultButton,
  Card,
  TextFieldProps,
} from "@material-ui/core";
import KeyboardArrowDown from "@material-ui/icons/KeyboardArrowDown";
import { useUserData } from "modules/auth";
import countryFlags from "modules/ui/molecules/PhoneInput/flags.json";
import { BookingFormFieldsName } from "modules/ui/variables";

const SearchContainer = styled(({ ...rest }) => <Card {...rest} />)`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  z-index: 1;
  height: 85px;
  padding: 1rem 1.5rem;
`;

const Phone = styled(({ ...rest }) => <TextField {...rest} />)`
  input {
    flex: 1;
    font-size: 14px;
  }
  z-index: 0;
  &:focus-within label,
  &:[data-shrink="true"] {
    top: 0px;
  }
  & label.Mui-focused {
    color: ${(props) => props.theme.custom.primary.main};
  }
  & .MuiOutlinedInput-root {
    &.Mui-focused fieldset {
      border-color: ${(props) => props.theme.custom.primary.main};
    }
  }
  & label.MuiInputLabel-root.MuiInputLabel-formControl {
    & span.MuiFormLabel-asterisk.MuiInputLabel-asterisk {
      color: red;
    }
  }
`;

const ErrorMsg = styled.p`
  color: red;
  font-size: 12px;
  padding-top: 5px;
`;

type Code = {
  dial_code: string;
  flag: string;
  name: string;
  code: string;
};

type MainProps = TextFieldProps & { onTextChange: (val: any) => void };

async function lookupCountry({ latitude, longitude }: any) {
  const URL = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`;

  const locationData = await fetch(URL).then((res) => res.json());

  const [{ address_components }] = locationData.results.filter(
    ({ types }: any) => types.includes("country"),
  );

  const [{ short_name }] = address_components;

  return short_name;
}

export default lookupCountry;

export const PhoneField: React.FC<MainProps> = ({ onTextChange, required }) => {
  const { t } = useTranslation("login");
  const [countryCode, setCountry] = React.useState("");
  const [openSearchBox, setOpenSearchBox] = React.useState(false);
  const [text, setText] = React.useState<string>("");
  const [inputValue, setInputValue] = React.useState("");
  const [codeValue, setCodeValue] = React.useState<Code | null>(
    countryFlags[97],
  );
  const [errorMsg, setErrorMsg] = React.useState("");
  const [dialCode, setDialCode] = React.useState<string>("+62");

  const { value: userValue } = useUserData();
  const { watch } = useFormContext();
  const authorizedUserPhone = userValue?.phoneNumber ?? "";
  const currentPhone = watch(BookingFormFieldsName.phone, authorizedUserPhone);

  React.useEffect(() => {
    const code = countryFlags.filter((flag) => flag.code === countryCode)[0];
    setCodeValue(code);
    setDialCode(code?.dial_code ? code?.dial_code : "+62");
    const phoneCode = code?.dial_code ? code?.dial_code : "+62";
    const phone = currentPhone.replace(phoneCode, "");
    setText(phone);
  }, [countryCode]);

  const handleOpenCountrySearchBox = React.useCallback(() => {
    setOpenSearchBox(true);
  }, [setOpenSearchBox]);

  const handleTextChange = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (e.target.value.trim().length > 15) {
        return;
      }
      const regex = /0{5,}/;
      if (regex.test(e.target.value)) {
        setErrorMsg("Please enter valid mobile number");
      } else {
        setErrorMsg("");
      }
      setText(e.target.value);
      onTextChange(dialCode + e.target.value);
    },
    [setText, dialCode, onTextChange],
  );

  const handleNavigator = async (pos: any) => {
    const { latitude, longitude } = pos.coords;
    const userCountryCode = await lookupCountry({ latitude, longitude });
    setCountry(userCountryCode);
  };

  React.useEffect(() => {
    navigator.geolocation.getCurrentPosition(handleNavigator, () =>
      console.warn("permission was rejected"),
    );
  }, []);

  return (
    <>
      <Phone
        error={errorMsg === "" ? false : true}
        required={required}
        type="tel"
        fullWidth
        variant="outlined"
        label={t("emailPhone")}
        className="phoneEmail"
        style={{ height: 40 }}
        value={text}
        onChange={handleTextChange}
        InputProps={{
          style: {
            height: 40,
            padding: "0 12px",
          },
          maxLength: 15,
          startAdornment: (
            <DefaultButton
              disableRipple
              style={{ padding: 0 }}
              onClick={handleOpenCountrySearchBox}
            >
              {
                //@ts-ignore
                `${codeValue?.flag || countryFlags[97].flag} ${
                  codeValue?.dial_code || countryFlags[97].dial_code
                }`
              }
              <KeyboardArrowDown />
            </DefaultButton>
          ),
        }}
      />
      {openSearchBox && (
        <div style={{ position: "relative", zIndex: 100 }}>
          <SearchContainer>
            <Autocomplete
              value={codeValue}
              onClose={() => {
                setOpenSearchBox(false);
                if (!codeValue) {
                  setDialCode("+62");
                }
              }}
              onChange={async (event: any, newValue: Code | null) => {
                await setCodeValue(newValue);
                //@ts-ignore
                await setDialCode(newValue ? newValue?.dial_code : "+62");
                onTextChange(newValue?.dial_code + text);
              }}
              inputValue={inputValue}
              onInputChange={(event, newInputValue) => {
                setInputValue(newInputValue);
              }}
              autoHighlight
              options={countryFlags}
              getOptionLabel={(option) =>
                // @ts-ignore
                `${option.flag} ${option.name} (${option.dial_code})`
              }
              style={{ width: "100%" }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label={t("searchText")}
                />
              )}
            />
          </SearchContainer>
        </div>
      )}
      <ErrorMsg>{errorMsg}</ErrorMsg>
    </>
  );
};
