import * as React from "react";
import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { isValidNumber } from "libphonenumber-js";
import parsePhoneNumber from "libphonenumber-js";
import styled from "styled-components";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { CircularProgress, Grid, Typography } from "@material-ui/core";
import {
  arePhonesEqual,
  usePhoneAuthentication,
  usePhoneAuthErrorMessage,
  useUserData,
} from "modules/auth";
import countryFlags from "modules/ui/molecules/PhoneInput/flags.json";
import { Button, TEST_ID, TextField } from "modules/ui";
import { BookingFormFieldsName } from "modules/ui/variables";
import { PhoneInputV2 } from "modules/ui/molecules/PhoneInput/PhoneInputV2";
import { Card } from "modules/ticket/atoms/Card";
import { PhoneVerified } from "../../molecules/PhoneVerified";
import { OtpModal } from "../OtpModal/OtpModal";
import { usePhoneVerify } from "./PhoheVerifyContext";

const CustomButton = styled(({ ...rest }) => <Button {...rest} />)`
  background-color: ${(props) => props.theme.custom.secondary.main};
  &:hover {
    background-color: ${(props) => props.theme.custom.primary.main};
  }
`;

const InfoDescriptionText = styled.p`
  font-family: ${(props) => props.theme.custom["roboto-font"]};
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 18px;
  color: ${(props) => props.theme.custom["gray-color"]};
  margin: 6px 0 0 0;
`;

const FieldWrapper = styled.div`
  display: grid;
  gap: 17px;
  button[data-testid="country-select-btn"] {
    min-width: 82px;
  }
  &.fieldwrapperError {
    gap: 22px;
  }
`;

const SearchContainer = styled(({ ...rest }) => <Card {...rest} />)`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  z-index: 1;
  height: 60px;
  padding: 1rem 1.5rem;
`;

const authButtonId = "phone-verification-button";

const useOnSubmit = (
  authorizedUserPhone: string | null,
  codeValue: Code | null
) => {
  const { triggerValidation, setError, getValues, clearError } =
    useFormContext();

  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [verificationId, setVerificationId] = React.useState<null | string>(
    null
  );
  const [otpModalOpen, setOtpModalOpen] = React.useState(false);
  const [phoneToShowInModal, setPhone] = React.useState("");
  const logIn = usePhoneAuthentication(authButtonId);

  const onSubmit = async () => {
    const { phone } = getValues();
    const fullPhoneNumber = codeValue?.dial_code + phone;
    if (
      authorizedUserPhone &&
      arePhonesEqual(fullPhoneNumber, authorizedUserPhone)
    ) {
      return;
    }
    const valid = await triggerValidation("phone");
    if (!valid) {
      return;
    }
    try {
      setIsSubmitting(true);
      clearError("commonPhone");
      const res = await logIn(fullPhoneNumber);
      setPhone(fullPhoneNumber);
      setVerificationId(res);
      setOtpModalOpen(true);
    } catch (e) {
      const errorType = e?.code || "generic";
      setError("commonPhone", "submit", errorType);
    } finally {
      setIsSubmitting(false);
    }
  };
  const value = usePhoneVerify();

  React.useEffect(() => {
    if (value) {
      value.current = onSubmit;
    }
  });

  return {
    onSubmit,
    isSubmitting,
    verificationId,
    phoneToShowInModal,
    otpModalOpen,
    setOtpModalOpen,
  };
};
export type Props = {
  label?: string;
};

type IField = {
  hasError: boolean;
  errorText: string;
};

type Code = {
  dial_code: string;
  flag: string;
  name: string;
  code: string;
};

const getCountryCode = (value: string) => {
  const phoneNumber = parsePhoneNumber(value);
  return phoneNumber?.country;
};

const getPhoneNumber = (value: string) => {
  const phoneNumber = parsePhoneNumber(value);
  return phoneNumber?.nationalNumber;
};

const getCallingCode = (value: string) => {
  const phoneNumber = parsePhoneNumber(value);
  return phoneNumber?.countryCallingCode;
};

export const PhoneFieldV2: React.FC<Props> = ({ label }) => {
  const { value: userValue } = useUserData();
  const [openSearchBox, setOpenSearchBox] = React.useState(false);
  const { errors, control, watch, setValue, register } = useFormContext();
  const { t } = useTranslation("reservation");
  const [inputValue, setInputValue] = React.useState("");
  const authorizedUserPhone = userValue?.phoneNumber ?? "";
  const currentPhone = watch(BookingFormFieldsName.phone, authorizedUserPhone);
  const countryCode = getCountryCode(authorizedUserPhone);
  const flagIndex = countryFlags.findIndex((cf) => cf.code === countryCode);
  const [codeValue, setCodeValue] = React.useState<Code | null>(
    flagIndex ? countryFlags[flagIndex] : countryFlags[97]
  );
  const fullPhoneNumber = codeValue?.dial_code + currentPhone;
  const errorMessage = errors.phone?.message;
  const errorLabel = errorMessage ? t(errorMessage) : undefined;

  React.useEffect(() => {
    register("countryCode");
  }, [register]);

  React.useEffect(() => {
    if (authorizedUserPhone) {
      const phoneNumber = getPhoneNumber(authorizedUserPhone);
      setValue(BookingFormFieldsName.phone, phoneNumber);
      setValue("countryCode", getCallingCode(authorizedUserPhone));
    }
  }, [authorizedUserPhone, setValue]);

  const isPhoneVerified =
    arePhonesEqual(fullPhoneNumber, authorizedUserPhone) &&
    isValidNumber(fullPhoneNumber);

  const submitError = usePhoneAuthErrorMessage(errors.commonPhone?.message);

  const {
    onSubmit,
    isSubmitting,
    verificationId,
    phoneToShowInModal,
    otpModalOpen,
    setOtpModalOpen,
  } = useOnSubmit(authorizedUserPhone, codeValue);

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === "Enter") {
      event.preventDefault();
      if (!isSubmitting) {
        onSubmit();
      }
    }
  };

  const handleOpenCountrySearchBox = React.useCallback(() => {
    setOpenSearchBox(true);
  }, [setOpenSearchBox]);

  const validationRules = {
    required: "required",
    validate: (phone: string) => {
      if (!isValidNumber(codeValue?.dial_code + phone)) {
        return "incorrectPhone";
      }
    },
  };

  return (
    <FieldWrapper
      className={
        (errors[BookingFormFieldsName.email] ||
          errors[BookingFormFieldsName.phone]) &&
        "fieldwrapperError"
      }
    >
      <Controller
        name={BookingFormFieldsName.phone}
        rules={validationRules}
        control={control}
        error={Boolean(errorMessage)}
        helperText={errorLabel}
        variant="outlined"
        label={label === "" ? label : t("phoneLabel")}
        onKeyDown={handleKeyDown}
        color="primary"
        as={PhoneInputV2}
        codeValue={codeValue}
        handleOpenCountrySearchBox={handleOpenCountrySearchBox}
        data-testid={TEST_ID.VERIFY_PHONE}
      />
      {openSearchBox && (
        <div style={{ position: "relative" }}>
          <SearchContainer>
            <Autocomplete
              value={codeValue}
              onClose={() => {
                setOpenSearchBox(false);
              }}
              onChange={async (event: any, newValue: Code | null) => {
                (await newValue) && setCodeValue(newValue);
              }}
              inputValue={inputValue}
              onInputChange={(event, newInputValue) => {
                setInputValue(newInputValue);
              }}
              autoHighlight
              options={countryFlags}
              getOptionLabel={(option) =>
                // @ts-ignore
                `${option.flag} ${option.name} (${option.dial_code})`
              }
              style={{ width: "100%" }}
              renderInput={(params) => (
                <TextField
                  data-testid={TEST_ID.COUNTRY_SEARCH}
                  {...params}
                  variant="outlined"
                  label={t("searchText")}
                />
              )}
            />
          </SearchContainer>
        </div>
      )}
      {!isPhoneVerified && (
        <InfoDescriptionText>{t("phoneDescription")}</InfoDescriptionText>
      )}
      {submitError && (
        <Typography color="error" variant="caption">
          {submitError}
        </Typography>
      )}
      <Grid container justify="flex-end">
        {isPhoneVerified && <PhoneVerified marginTop={-12}/>}
        <CustomButton
          id={authButtonId}
          data-testid={TEST_ID.VERIFY_PHONE}
          type="button"
          disableElevation={false}
          onClick={onSubmit}
          // should be in the document for google captcha
          style={{ display: isPhoneVerified ? "none" : undefined }}
          startIcon={
            isSubmitting && <CircularProgress size={16} color="inherit" />
          }
          disabled={isSubmitting}
        >
          {t("verify")}
        </CustomButton>
        {verificationId && (
          <OtpModal
            onClose={() => setOtpModalOpen(false)}
            open={otpModalOpen}
            phone={phoneToShowInModal}
            verificationId={verificationId}
          />
        )}
      </Grid>
    </FieldWrapper>
  );
};
