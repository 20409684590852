import React from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { useDispatch } from "react-redux";
import { BusStop, City } from "modules/entities";
import { Link, routes } from "modules/routing";
import { Button, TEST_ID } from "modules/ui";
import {getOperatorCode}  from "../../../utils/getOperatorCode"
import GoogleAnalyticsService from "../../../utils/GoogleAnalyticsService";
import {LOG_EVENTS} from "../../../utils/LogEvents";

const CustomButton = styled(({ ...rest }) => <Button {...rest} />)`
  min-width: 132px;
  min-height: 42px;
  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.15);
  background-color: ${(props) => props.theme.custom.secondary.main};
  &:hover {
    background-color: ${(props) => props.theme.custom.primary.main};
  }
`;

type CityValue = {
  type: "City";
} & City;

type BusStopValue = {
  type: "BusStop";
} & BusStop;

type PointValue = null | BusStopValue | CityValue;

type FormValues = {
  from: PointValue;
  to: PointValue;
  date: null | Date;
  passengers: null | number;
  returnDate: null | Date;
  tabIndex: number;
};

const getLink = ({
  from,
  to,
  date,
  returnDate,
  passengers,
  tabIndex,
}: FormValues): string | null => {
  if (!from || !to || !date || !passengers) {
    return null;
  }
  const query: any = {
    arrivalId: to.id,
    arrivalType: to.type,
    departureId: from.id,
    departureType: from.type,
    passengers,
    date,
    tabIndex,
    arrivalName:from?.name,
    departureName:to?.name,
  };
  if (returnDate) {
    query["returnDate"] = returnDate;
  }
  return routes.search.url(query);
};

type Props = {
  onSearch?: () => void;
};

export const SearchButton = (props: Props) => {
  
  const { t } = useTranslation("search");
  const { watch } = useFormContext();
  const { from, to, date, passengers, returnDate } = watch();
  const dispatch = useDispatch();

  const link = getLink({ from, to, date, returnDate, passengers, tabIndex: 0 });
  const handleSetSearchParams = () => {
    localStorage.removeItem("tabIndex");
    if (props.onSearch) {
      props.onSearch();
      GoogleAnalyticsService.createEvent(LOG_EVENTS.LOG_EVENTS_NAME.PRESS_BUTTON_SEARCH, {
        home: LOG_EVENTS.LOG_METHOD_VALUE.SEARCH_BUTTON_PRESSED,
        operator_code: getOperatorCode(),
        source:"web"
      });
    }

    dispatch({
      type: "SET_SEARCH_PARAMS",
      payload: {
        arrivalId: from?.id,
        arrivalType: from?.type,
        departureId: to?.id,
        departureType: to?.type,
        date,
        passengers,
        returnDate,
        tabIndex: 0,
        arrivalName:from?.name,
        departureName:to?.name,
      },
    });
  };

  const button = (
    <CustomButton
      type="submit"
      variant="contained"
      color="primary"
      data-testid={TEST_ID.SEARCH_BUTTON}
      fullWidth
    >
      {t("button")}
    </CustomButton>
  );

  return link ? (
    <Link to={link} onClick={handleSetSearchParams}>
      {button}
    </Link>
  ) : (
    button
  );
};
