interface GetColor {
  selectedFirstOrLastColor: string;
  normalColor: string;
  selectedColor: string;
  disabledColor: string;
}

export function getColor(
  isSelected: boolean,
  isSelectedStartOrEnd: boolean,
  isDisabled: boolean
) {
  return ({
    selectedFirstOrLastColor,
    normalColor,
    selectedColor,
    disabledColor,
  }: GetColor) => {
    if (isSelectedStartOrEnd) {
      return selectedFirstOrLastColor;
    } else if (isSelected) {
      return selectedColor;
    } else if (isDisabled) {
      return disabledColor;
    } else {
      return normalColor;
    }
  };
}
