import React, { FormEvent } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { parse } from "querystring";
import { useSelector, RootStateOrAny } from "react-redux";
import { Controller, FormContext, useForm } from "react-hook-form";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { isAfter } from "date-fns";
import { isValidNumber } from "libphonenumber-js";
import Grid from "@material-ui/core/Grid";
import { DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import { createRentalBooking, getRentalService } from "modules/api/methods";
import { usePriceData } from "modules/api/hooks/rental";
import { Price } from "modules/entities";
import { useUserData } from "modules/auth";
import { BackButton, routes } from "modules/routing";
import { useLanguage } from "modules/localization";
import { Notifications } from "modules/notifications";
import { FormInput } from "../molecules/FormInput/FormInput";
import { PhoneField } from "../molecules/PhoneField";
import { TermsConditionsCheckbox } from "modules/select-payment/molecules/TermsConditionsCheckbox";
import { getPaymentCollections } from "modules/api/methods/payment";
import { getOperatorCode } from "modules/utils/getOperatorCode";
import { getDefaultOperatorCode } from "modules/utils/getDefaultOperator";
import { updateOperatorCode } from "modules/api/client";

const EMAIL_PATTERN =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const RentalWrapper = styled.div`
  max-width: 1155px;
  // margin: 0 auto;
  margin-bottom: 20px;
  width: 100%;
  padding-bottom: 120px;
  // font-family: ${(props) => props.theme.custom["inter-font"]};
  margin: 30px auto 0;
  .header {
    margin: 30px auto 0;
  }
  @media (max-width: ${(props) =>
      props.theme.custom.breakpoints["md-breakpoint"]}) {
    max-width: fit-content;
    background: #f6f6f6;
    padding-bottom: 0;
    margin: 30px auto 0;
  }
`;

const Note = styled.div`
  background: #ffefd1;
  border-radius: 8px;
  padding: 12px 16px;
  margin-bottom: 16px;
  font-size: 12px;
  line-height: 16px;
  color: #8f6512;
  font-weight: 400;
  @media (max-width: ${(props) =>
      props.theme.custom.breakpoints["md-breakpoint"]}) {
    margin: 10px 16px;
  }
`;

const GridContainer = styled(Grid)`
  @media (max-width: ${(props) =>
      props.theme.custom.breakpoints["md-breakpoint"]}) {
    flex-direction: column-reverse;
  }
  border-radius: 3px;
  .availableService {
    @media (max-width: ${(props) =>
        props.theme.custom.breakpoints["md-breakpoint"]}) {
      display: none;
    }
  }
`;

const GridCol = styled(Grid)`
  margin-bottom: 20px;
  padding: 20px;
  @media (max-width: ${(props) =>
      props.theme.custom.breakpoints["md-breakpoint"]}) {
    padding: 0;
  }

  .grid-wrapper {
    background: #ffffff;
    border: 1px solid #e0e0e0;
    box-sizing: border-box;
    border-radius: 3px;
    padding: 30px 30px 100px;
    @media (max-width: ${(props) =>
        props.theme.custom.breakpoints["md-breakpoint"]}) {
      border: none;
      padding: 30px 15px 40px;
    }

    h4 {
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      color: #4f4f4f;
      margin-bottom: 18px;
    }
  }

  @media (max-width: ${(props) =>
      props.theme.custom.breakpoints["xs-breakpoint"]}) {
    margin-bottom: 15px;
  }
`;

const ButtonWrap = styled.button`
  width: 100%;
  height: 40px;
  margin-bottom: 20px;
  margin-top: 20px;
  background: ${(props) => props.theme.custom.secondary.main};
  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.15);
  border-radius: 3px;
  color: #fff;
  border: none;
  margin: auto;
  display: block;
  font-size: 12px;
  line-height: 14px;
  font-weight: 700;
  cursor: pointer;
  &:disabled {
    background: ${(props) => props.theme.custom.secondaryInactive};
    cursor: default;
  }
  @media (min-width: ${(props) =>
      props.theme.custom.breakpoints["md-breakpoint"]}) {
    width: 70%;
  }
`;

const Heading = styled.h2`
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  color: ${(props) => props.theme.palette.text.primary};
  margin: 32px 0 20px;
  @media (max-width: ${(props) =>
      props.theme.custom.breakpoints["xs-breakpoint"]}) {
    margin: 32px 10px;
  }
`;

const VehicleName = styled.h4`
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  color: #19191d;
  margin-bottom: 8px;
`;
const VehicleInfo = styled.p`
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 15px;
  color: #5a5b6a;
`;
const VehicleWrapper = styled(Grid)`
  align-items: center;
  // border-bottom: 1px solid #d8d8d8;
  display: flex;
  height: 100%;
  flex-direction: column;
  flex-wrap: nowrap;

  // vechileOuter start
  .vechileOuter {
    width: 100%;
    display: flex;
    height: 100%;
    padding-left: 10px;
    flex-wrap: nowrap;
    .vehicleContent {
      padding-right: 25px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
    }
    .vehicleImage {
      display: flex;
      height: 100%;
    }
  }

  // vechileOuter end

  .proceedOuter {
    width: 100%;
    display: flex;
    padding: 15px 0 0 10px;
    align-items: center;
    p {
      font-weight: 600;
      font-size: 10px;
      line-height: 18px;
      color: #5a5b6a;
      display: inline-block;
      span {
        font-size: 18px;
        color: #19191d;
      }
    }
    .linethrough {
      font-weight: 500;
      font-size: 10px;
      line-height: 15px;
      text-decoration-line: line-through;
      margin: 0 15px;
      color: #5a5b6a;
    }
    .discount {
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 18px;
      color: #ff0000;
    }
  }
`;
const DateTimePickerWrapper = styled(DateTimePicker)`
  z-index: 0;
`;

const ButtonWrapper = styled(Grid)`
  padding: 8px;
  display: flex;
  justify-content: center;
`;

const Seprator = styled.div`
  border-top: 0.5px solid #ccc;
`;

const Container = styled.div`
  background: #f6f6f6;
`;

const PriceWrapper = styled.div`
  padding: 10px 15px;
  margin-top: 10px;
  margin-bottom: 10px;
  background: #fff;
  color: #4f4f4f;
  border-radius: 3px;
  .total-title {
    font-size: 20px;
    margin-bottom: 10px;
    // font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
  }
  .outer-amount {
    color: #5a5b6a;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 0px;
    .amount-title {
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
    }
    .amount {
      font-style: normal;
      font-weight: 600;
      font-size: 13px;
      line-height: 18px;
    }
  }
`;

const BackButtonWrapper = styled.div`
  @media (max-width: ${(props) =>
      props.theme.custom.breakpoints["md-breakpoint"]}) {
    display: none;
  }
`;

let autoComplete: any;

declare global {
  interface Window {
    google: any;
  }
}

type Props = {
  showTitle?: Boolean;
  serviceData: Array<any>;
  showNote?: Boolean;
};

interface ParamTypes {
  serviceId: string;
}

const discountedPrice = (price: number, discount: number, type: any) => {
  const actualPrice =
    type === "%" ? (price * (100 - discount)) / 100 : price - discount;
  return actualPrice;
};

type PriceProps = {
  priceData?: Price;
};

const RENTAL_BOOKING_TYPE = "rental_catalog_booking";

export const PriceBlock = (priceData: any) => {
  const price = priceData.price;
  const discount = priceData.discount;
  const total = priceData.total;
  const rate = priceData?.rate;
  const { t } = useTranslation("rental");
  return (
    <>
      <PriceWrapper className="price-section">
        <h3 className="total-title">{t("priceDetails")}</h3>
        <div className="outer-amount">
          <p className="amount-title">
            {t("fareFor")} {priceData?.days} day(s)
          </p>
          <p className="amount">{t("price", { price })}</p>
        </div>
        <div className="outer-amount">
          <p className="amount-title">
            Discount({rate?.value}
            {rate?.type === "%" ? rate?.type : ""})
          </p>
          <p className="amount">{t("discount", { discount })}</p>
        </div>
        <Seprator />
        <div className="outer-amount">
          <p className="amount-title">{t("totalAmt")}</p>
          <p className="amount">{t("total", { total })}</p>
        </div>
      </PriceWrapper>
    </>
  );
};
export default function RentalForm(props: Props) {
  const { showTitle, showNote } = props;
  const methods = useForm();
  const { serviceId } = useParams<ParamTypes>();
  const dispatch = useDispatch();
  const { search } = useLocation();
  const params: any = parse(search);
  const departDateString = new URLSearchParams(search).get("startDate") || "";
  const returnDateString = new URLSearchParams(search).get("returnDate") || "";
  const { t } = useTranslation("rental");
  const [service, setService]: any = React.useState(null);
  // const [showCompleted, setShowCompleted] = React.useState(false);
  const [departDate, setDepartDate]: any =
    React.useState<MaterialUiPickersDate>(new Date(+departDateString));
  const [returnDate, setReturnDate]: any =
    React.useState<MaterialUiPickersDate>(
      new Date(new Date(+returnDateString)),
    );
  const browserHistory = useHistory();
  const language = useLanguage();
  const baseUrl = `${window.location.origin}/${
    window.location.pathname.split("/")[1]
  }/${language}`;
  const [mapLoaded, setMapLoaded] = React.useState(false);
  const [query, setQuery] = React.useState("");
  const [phone, setPhone] = React.useState<string>("");
  const autoCompleteRef = React.useRef(null);
  const { value: userValue } = useUserData();
  const priceData: any = usePriceData(returnDate, departDate, service);
  const [termsConfirmed, setTermsConfirmed] = React.useState(false);
  const [gatewayName, setGatewayName] = React.useState<any>("XENDIT");
  const [showOlderXendit, setShowOlderXendit] = React.useState(false);
  const region = useSelector((state: RootStateOrAny) => state.region);

  React.useEffect(() => {
    const olderGateway: any = process.env.REACT_APP_SHOW_OLDER_XENDIT;
    setShowOlderXendit(olderGateway === "false" ? false : true);
    const fetchGateWay = async () => {
      getPaymentCollections()
        .then((res: any) => {
          setGatewayName(res.data.gatewayName);
        })
        .catch((err: any) => console.log(err));
    };
    if (olderGateway === "false" || region.name === "India") {
      fetchGateWay();
    }
  }, [region.name]);

  React.useEffect(() => {
    dispatch({
      type: "RENTAL_BOOKING_TITLE",
      payload: t("bookNow"),
    });
  }, [dispatch, t]);

  React.useEffect(() => {
    const fetchService = async () => {
      const response = await getRentalService(serviceId);
      setService(response ? response[0] : {});
    };
    fetchService();
  }, [serviceId]);

  React.useEffect(() => {
    methods.control.setValue("fullName", userValue?.displayName);
    methods.control.setValue("email", userValue?.email);
    methods.control.setValue("phone", userValue?.phoneNumber);
    setPhone(userValue?.phoneNumber ? userValue?.phoneNumber : "");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userValue]);

  React.useEffect(() => {
    methods.register("departureDate", { required: true });
    methods.register("returnDate", { required: true });
    methods.register("address", { required: true });
    methods.register("phone", { required: true });

    methods.setValue("departureDate", new Date(), true);
    methods.setValue("returnDate", new Date(), true);
  }, [methods]);

  React.useEffect(() => {
    const handleScriptLoad = (updateQuery: any, autoCompleteRef: any) => {
      autoComplete = new window.google.maps.places.Autocomplete(
        autoCompleteRef.current,
      );
      autoComplete.setFields(["address_components", "formatted_address"]);
      autoComplete.addListener("place_changed", () =>
        handlePlaceSelect(updateQuery),
      );
    };

    ((callback: any) => {
      if (!document.getElementById("googleMapsScript")) {
        const script = document.createElement("script");
        script.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}&libraries=places`;
        script.async = true;
        script.id = "googleMapsScript";
        script.onload = () => {
          setMapLoaded(true);
          callback.call();
        };
        document.body.appendChild(script);
      } else if (mapLoaded) {
        callback.call();
      }
    })(() => handleScriptLoad(setQuery, autoCompleteRef));
  }, [mapLoaded]);

  React.useEffect(() => {
    methods.control.setValue("address", query);
  }, [query, methods]);

  const handlePlaceSelect = async (updateQuery: any) => {
    const addressObject = autoComplete.getPlace();
    const tempAddr = addressObject.formatted_address;
    updateQuery(tempAddr);
  };

  const onSubmit = async (e: FormEvent) => {
    e.preventDefault();
    updateOperatorCode(getOperatorCode());
    const data = methods.getValues();

    data.returnDate = returnDate;
    data.departureDate = departDate;

    if (departDate && returnDate) {
      if (isAfter(departDate, returnDate)) {
        Notifications.enqueueSnackbar(t("minDateError"), {
          preventDuplicate: true,
        });
        return;
      } else if (departDate.getTime() === returnDate.getTime()) {
        Notifications.enqueueSnackbar(t("sameDateError"), {
          preventDuplicate: true,
        });
        return;
      }
    }

    if (priceData.price.value === 0) {
      Notifications.enqueueSnackbar(t("zeroPrice"), {
        preventDuplicate: true,
      });
      return;
    }

    if (!data.email || !data.email.match(EMAIL_PATTERN)) {
      Notifications.enqueueSnackbar(t("validEmail"), {
        preventDuplicate: true,
      });
      return;
    }

    if (!isValidNumber(phone)) {
      Notifications.enqueueSnackbar(t("validPhone"), {
        preventDuplicate: true,
      });
      return;
    }
    data.phone = phone;
    data.email = data.email.trim();

    if (!data.fullName.trim()) {
      Notifications.enqueueSnackbar(t("fullName"), {
        preventDuplicate: true,
      });
      return;
    }

    if (departDate && returnDate) {
      if (isAfter(departDate, returnDate)) {
        Notifications.enqueueSnackbar(t("minDateError"), {
          preventDuplicate: true,
        });
        return;
      }
    }

    createRentalBooking({
      rentalServiceId: serviceId,
      name: data.fullName,
      phone: data.phone,
      email: data.email,
      departureDate: departDate,
      returnDate: returnDate,
      address: data.address,
      price: priceData?.total,
      status: "new",
      discount: {
        amount: priceData.discount.value,
        currency: priceData.discount.currency,
        type: service?.discount?.discountType,
        value: service?.discount?.value,
      },
    })
      .then((r) => {
				//todo : r should contain actual operator id but returning maas-id
        if (showOlderXendit && region.name !== "India") {
          const paymentStatusUrl = `${baseUrl}${routes.rentalPaymentStatus.url({
            id: r._id,
          })}`;
          const type = RENTAL_BOOKING_TYPE;
          browserHistory.push({
            pathname: `/${language}${routes.xendit.pattern}`,
            state: {
              su: paymentStatusUrl,
              eu: paymentStatusUrl,
              type,
              id: r._id,
            },
          });
        } else {
          let callBackURL = "";
          if (region.name === "India") {
            callBackURL = `${process.env.REACT_APP_SERVER_PATH}/paymentcollection/api/v1/redirect/${getDefaultOperatorCode()}/${r._id}`;
          } else {
            callBackURL = `${baseUrl}${routes.rentalPaymentStatus.url({ 
              id: r._id,
            })}`;
          }

          const paymentData = {
            serviceReferenceId: r._id,
            serviceId: [],
            // operatorId: r.externalOperatorId,
						operatorId: getOperatorCode(),
            serviceType: "rental_catalog_booking",
            actionType: "payment",
            amount: r.price.value,
            currency: r.price.currency,
            callbackUrl: callBackURL,
            customerInfo: {
              firstName: r.name,
              lastName: r.name,
              email: r.email,
              phone: r.phone,
            },
            productInfo: "rental",
            paymentMethod: "online",
          };
          browserHistory.push({
            pathname: `/${language}${routes.paymentGateway.pattern}`,
            state: { paymentData, gatewayName },
          });
        }
      })
      .catch((e) => {
        Notifications.enqueueSnackbar(e?.response?.data?.message, {
          preventDuplicate: true,
        });
      });
  };

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Container>
        <RentalWrapper style={showTitle ? {} : { background: "transparent" }}>
          <div className="header">
            <BackButtonWrapper>
              <BackButton to={routes.home.url()} />
            </BackButtonWrapper>
          </div>
          {service && (
            <Heading>
              <VehicleWrapper>
                <div className="vechileOuter">
                  <div className="vehicleContent">
                    <VehicleName>{service?.name}</VehicleName>
                    <VehicleInfo>{service?.description}</VehicleInfo>
                  </div>
                </div>
                {/* <Seprator /> */}
                <div className="proceedOuter">
                  <p>
                    <span>
                      {service?.rate?.currency}{" "}
                      {discountedPrice(
                        service?.rate.amount,
                        service?.discount?.value,
                        service?.discount?.discountType,
                      )}{" "}
                      / <span>{service?.rate?.duration.toLowerCase()}</span>
                    </span>
                  </p>
                  {service?.discount?.value ? (
                    <span className="linethrough">
                      {service?.rate.currency}. {service?.rate.amount}
                    </span>
                  ) : (
                    ""
                  )}
                  {service?.discount?.value ? (
                    <span className="discount">
                      {service?.discount?.value}
                      {service?.discount?.discountType === "%"
                        ? service?.discount?.discountType
                        : ""}{" "}
                      OFF
                    </span>
                  ) : (
                    ""
                  )}
                </div>
                {/* <Seprator /> */}
                {/* <ButtonBook onClick={() => history.push(url)}>Book Now</ButtonBook> */}
              </VehicleWrapper>
            </Heading>
          )}
          {showNote && <Note>{t("formInfo")}</Note>}
          <GridContainer container spacing={3}>
            <GridCol item xs={12} md={12} lg={12}>
              <div className="grid-wrapper">
                {showTitle && <h4>{t("formHead")}</h4>}
                <FormContext {...methods}>
                  <form>
                    <Grid container spacing={3}>
                      <Grid item xs={12} md={12} lg={4}>
                        <Controller
                          name="fullName"
                          rules={{ required: true }}
                          control={methods.control}
                          as={
                            <FormInput
                              name="fullName"
                              label={t("name")}
                              required
                              inputProps={{ maxLength: 50 }}
                              InputLabelProps={{ shrink: true }}
                            />
                          }
                        />
                      </Grid>
                      <Grid item xs={12} md={12} lg={4}>
                        <PhoneField
                          required
                          name="phone"
                          onTextChange={(value: string) => {
                            setPhone(value);
                            methods.control.setValue("phone", value, true);
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} md={12} lg={4}>
                        <Controller
                          name="email"
                          rules={{ required: true }}
                          control={methods.control}
                          as={
                            <FormInput
                              requiredErrorMessage={t("requiredEmail")}
                              validationPattern={EMAIL_PATTERN}
                              name="email"
                              label={t("email")}
                              required
                              inputProps={{ maxLength: 50 }}
                            />
                          }
                        />
                      </Grid>
                      <Grid item xs={12} md={12} lg={4}>
                        <DateTimePickerWrapper
                          inputVariant="outlined"
                          fullWidth
                          autoOk
                          ampm={false}
                          disablePast
                          label={t("startDate")}
                          value={departDate}
                          allowKeyboardControl={false}
                          onChange={(date) => {
                            setDepartDate(date);
                            methods.control.setValue(
                              "departureDate",
                              date,
                              true,
                            );
                            const url = `/${language}${routes.rentalService.url(
                              {
                                categoryId: params["?categoryId"],
                                startDate: date?.getTime().toString() || "",
                                returnDate: params["returnDate"],
                                serviceId: service?._id,
                              },
                            )}`;
                            browserHistory.push(url);
                          }}
                          required
                        />
                      </Grid>
                      <Grid item xs={12} md={12} lg={4}>
                        <DateTimePickerWrapper
                          inputVariant="outlined"
                          fullWidth
                          autoOk
                          ampm={false}
                          disablePast
                          label={t("returnDate")}
                          value={returnDate}
                          allowKeyboardControl={false}
                          onChange={(date) => {
                            setReturnDate(date);
                            methods.control.setValue("returnDate", date, true);
                            const url = `/${language}${routes.rentalService.url(
                              {
                                categoryId: params["?categoryId"],
                                returnDate: date?.getTime().toString() || "",
                                startDate: params["startDate"],
                                serviceId: service?._id,
                              },
                            )}`;
                            browserHistory.push(url);
                          }}
                          required
                          minDate={departDate}
                          minDateMessage={t("minDateError")}
                        />
                      </Grid>
                      <Grid item xs={12} md={12} lg={4}>
                        <FormInput
                          ref={autoCompleteRef}
                          name="address"
                          label={t("address")}
                          required
                          value={query}
                          placeholder={t("enterLocation")}
                          onChange={(e: {
                            target: { value: React.SetStateAction<string> };
                          }) => setQuery(e.target.value)}
                          inputProps={{ maxLength: 200 }}
                        />
                      </Grid>
                      {/* <Grid item xs={12} md={12} lg={12}>
                      <ButtonWrap type="submit">{t("submit")}</ButtonWrap>
                    </Grid> */}
                    </Grid>
                  </form>
                </FormContext>
              </div>
            </GridCol>
          </GridContainer>
          <Grid item xs={12} md={12} lg={6}>
            <PriceBlock
              priceData={priceData}
              price={priceData?.price}
              discount={priceData?.discount}
              total={priceData?.total}
              days={priceData?.days}
              rate={priceData?.rate}
            />
          </Grid>
          <div>
            <TermsConditionsCheckbox
              confirmed={termsConfirmed}
              setConfirmed={setTermsConfirmed}
            />
          </div>
          <ButtonWrapper>
            <Grid item xs={12} md={12} lg={6}>
              <ButtonWrap
                type="button"
                onClick={onSubmit}
                disabled={!termsConfirmed}
              >
                {t("proceed")}
              </ButtonWrap>
            </Grid>
          </ButtonWrapper>
        </RentalWrapper>
      </Container>
    </MuiPickersUtilsProvider>
  );
}
