import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { format } from "date-fns";
import { enUS, id } from "date-fns/locale";
import { Typography, Divider } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { useSearch } from "modules/api"; 
import { useCityOrBusStop } from "modules/api";
import { ENDPOINTS } from "modules/api/endpoints";
import { axios } from "modules/api/client";
import { SearchResult } from "modules/entities";
import { routes, Redirect } from "modules/routing";
import { SearchParams } from "modules/routing/query-types";
import { useLanguage } from "modules/localization";
import { TEST_ID } from "modules/ui";
import { ErrorBoundary } from "modules/utils/ErrorBoundary";
import { MobileShoppingCart } from "modules/search-result-list/organisms/ShoppingCart/MobileShoppingCart";
import HomeTabs from "modules/search/organisms/HomeTabs";
import { DateCarousel } from "../organisms/DateCarousel/DateCarousel";
import { ShoppingCart } from "../organisms/ShoppingCart/ShoppingCart";
import { SearchResultList } from "../organisms/SearchResultList/SearchResultList";
import { SearchPoint } from "../molecules/SearchPoint";
import { Revisit } from "./icon";
import { getOperatorCode } from "../../../modules/utils/getOperatorCode";
import GoogleAnalyticsService from "../../../modules/utils/GoogleAnalyticsService";
import { LOG_EVENTS } from "../../../modules/utils/LogEvents";
import { updateOperatorCode } from "modules/api/client";
import { IsDefaultOperator } from "modules/utils/IsDefaultOperator";
import {defaultOperatorIN,defaultOperatorID,defaultOperatorPH,defaultOperatorSG,defaultOperatorMY,defaultOperatorAE} from 'modules/ui/constants'

// function getColor(color: string, amount: number) {
//   return (
//     "#" +
//     color
//       .replace(/^#/, "")
//       .replace(/../g, (color) =>
//         (
//           "0" +
//           Math.min(255, Math.max(0, parseInt(color, 16) + amount)).toString(16)
//         ).substr(-2)
//       )
//   );
// }

const SearchComponentWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 50px;
  @media (max-width: ${(props) =>
      props.theme.custom.breakpoints["md-breakpoint"]}) {
    padding-top: 0px;
  }
`;
const DividerSearchComponent = styled.div`
  display: none;
  padding-top: 10px;
  @media (min-width: ${(props) =>
      props.theme.custom.breakpoints["md-breakpoint"]}) and (max-width: ${(
      props,
    ) => props.theme.custom.breakpoints["lg-breakpoint"]}) {
    display: block;
  }
  @media (max-width: ${(props) =>
      props.theme.custom.breakpoints["md-breakpoint"]}) {
    display: block;
    padding: 0px 14px;
  }
`;

const MainContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 540px 1fr;
  grid-template-rows: 1fr;
  grid-column-gap: 11px;
  grid-template-areas: "filters results cart";
  margin: 50px 14px 20px;
  @media (max-width: ${(props) =>
      props.theme.custom.breakpoints["md-breakpoint"]}) {
    grid-template-rows: 0fr;
    grid-column-gap: 0px;
    grid-template-columns: 1fr;
    grid-template-areas: "results";
    margin: 20px 14px;
  }
`;

const FilterContainer = styled.div`
  grid-area: filters;
`;

const ResultContainer = styled.div`
  grid-area: results;
  display: grid;
  grid-auto-rows: max-content;
  row-gap: 20px;
`;

const CartContainer = styled.div`
  grid-area: cart;
  @media (max-width: ${(props) =>
      props.theme.custom.breakpoints["md-breakpoint"]}) {
    display: none;
  }
`;
const CartContainerMobileWrapper = styled.div`
  display: none;
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: ${(props) => props.theme.custom["white-color"]};
  box-shadow: 0px -2px 4px rgba(0, 0, 0, 0.15);
  border-radius: 5px 5px 0px 0px;
  @media (max-width: ${(props) =>
      props.theme.custom.breakpoints["md-breakpoint"]}) {
    display: block;
  }
`;

// const FilterSection = styled.div`
//   display: none;
//   @media (max-width: ${(props) =>
//       props.theme.custom.breakpoints["md-breakpoint"]}) {
//     background: ${(props) => props.theme.custom["white-color"]};
//     border: 1px solid ${(props) => props.theme.custom["light-grey"]};
//     box-sizing: border-box;
//     border-radius: 3px;
//     padding: 12px 15px;
//     margin: 20px 15px;
//     display: flex;
//     align-items: center;
//     justify-content: center;
//   }
//   p {
//     font-weight: normal;
//     font-size: 12px;
//     line-height: 10px;
//     margin: 0 0 0 10px;
//     color: ${(props) => props.theme.custom["emperor-color"]};
//   }
// `;

const PlaceName = styled(Typography)`
  font-family: ${(props) => props.theme.custom["roboto-font"]};
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 23px;
  color: ${(props) => props.theme.custom["emperor-color"]};
`;

const TabContainer = styled(AppBar)`
  height: 72px;
  box-shadow: none;
  z-index: 0;

  button {
    padding: 6px;
    background-color: ${(props) => props.theme.custom.secondaryInactive};
    color: #fff;

    &.Mui-selected {
      opacity: 1;
      background-color: ${(props) => props.theme.custom.secondary.main};
    }
  }

  & .MuiTab-wrapper {
    align-items: flex-start;
    text-transform: capitalize;
    font-size: 13px;
    font-weight: 400;
    line-height: 16px;
    color: #fff;
  }
`;

const TabDate = styled.div`
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
`;

const dateFormat = "iii, d LLL";

function TabPanel(props: any) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`from_two_tabs-${index}`}
      aria-labelledby={`from_to_tabs-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  );
}

const SearchPageResult: React.FC<SearchParams> = ({
  departureType,
  departureId,
  arrivalId,
  arrivalType,
  date,
  passengers,
  returnDate,
  returnPickUpStopId,
  returnDropOutStopId,
  pickUpStopId,
  dropOutStopId,
  fromRideId,
  toRideId,
  tabIndex,
  arrivalName,
  departureName,
  ...propTypes
}) => {
  const searchParams = React.useMemo(
    () => ({
      departureType,
      departureId,
      arrivalId,
      arrivalType,
      date,
      passengers,
      returnDate,
      arrivalName,
      departureName,
    }),
    [
      arrivalId,
      arrivalType,
      date,
      departureId,
      departureType,
      passengers,
      returnDate,
      arrivalName,
      departureName,
    ],
  );
  const theme = useTheme();
  const history = useHistory();
  const language = useLanguage();
  const { t } = useTranslation("searchResult");
  const urlParams = new URLSearchParams(window.location.search);
  const [tabValue, setTabValue] = React.useState(
    tabIndex ? parseInt(tabIndex) : 0,
  );
  const currentLocale = language === "en" ? enUS : id;
  const { results, isLoading } = useSearch(searchParams);
  const [returnRide, setReturnRide] = React.useState<any>(null);
  const [fromRide, setFromRide] = React.useState<any>(null);
  const returnDateParam =
    returnDate && !Array.isArray(returnDate) ? new Date(returnDate) : "";
  const [tabOneRide, setTabOneRide] = React.useState<any>();
  const [tabTwoRide, setTabTwoRide] = React.useState<any>();
  const [tabSelected, setTabSelected] = React.useState<any>();
  const dispatch = useDispatch();
  const [rideResult,setRideResult] = React.useState<any>();

  React.useEffect( () => {
    const queryParams = new URLSearchParams(window.location.search)
    const tabIndex = queryParams.get("tabIndex");
    const oppArr = [defaultOperatorIN,defaultOperatorID,defaultOperatorPH,defaultOperatorSG,defaultOperatorMY,defaultOperatorAE];
    let sTab = localStorage.getItem("tabIndex");
  
    if (!oppArr.includes(getOperatorCode()) && IsDefaultOperator() && tabIndex !== sTab) {
      let isBigEnough = (element:any, index:any, array:any) => {
      return (element.ride?.externalOperator?.id === getOperatorCode()); 
    }       
    let filtered_array = results.filter(isBigEnough);
    setRideResult(filtered_array);
  }else{
    setRideResult(results);
  }   
  },[results,tabIndex])

  React.useEffect(() => {
    GoogleAnalyticsService.createEvent(LOG_EVENTS.LOG_EVENTS_NAME.SCREEN_VIEW, {
      firebase_screen: LOG_EVENTS.SCREEN_NAME.SEARCH_RIDE_SCREEN,
      firebase_screen_class: LOG_EVENTS.SCREEN_NAME.SEARCH_RIDE_SCREEN, 
      operator_code: getOperatorCode(),
      source: "web",
    });
    const fetchRides = async () => {
      if (toRideId && passengers) {
        const response = await axios.get(
          `${ENDPOINTS.RIDES}/${toRideId}?countOfPassengers=${passengers}`,
        );
        setReturnRide(response.data);
      }
      if (fromRideId && passengers) {
        const response = await axios.get(
          `${ENDPOINTS.RIDES}/${fromRideId}?countOfPassengers=${passengers}`,
        );
        setFromRide(response.data);
      }
    };
    fetchRides();
  }, [toRideId, fromRideId, passengers]);

  const toggle = (nextResult: SearchResult, tabIndex: number) => {
    let next: any;
    
    if (tabIndex === 0) {
      next = tabOneRide?.ride?.id === nextResult.ride?.id ? null : nextResult;
      let operatorCode = next?.ride?.externalOperator.operatorCode;

      if (!operatorCode){
        operatorCode = window.location.pathname.split("/")[1].toUpperCase();
      }
      dispatch({
        type: "SET_OPERATOR_CODE",
        payload: operatorCode,
      });
      updateOperatorCode(operatorCode);

      urlParams.delete("fromRideId");
      urlParams.delete("timezoneFrom");
      urlParams.delete("pickUpStopId");
      urlParams.delete("dropOutStopId");

      if (next) {
        if (IsDefaultOperator()){
          const queryParams = new URLSearchParams(window.location.search)
          const tabInd:any = queryParams.get("tabIndex");
          const tab = localStorage.getItem("tabIndex");
          if(!tab){
            localStorage.setItem("tabIndex",tabInd);
          }
        }

        urlParams.append("fromRideId", `${next.ride.id}`);
        urlParams.append("pickUpStopId", `${next?.pickUpStopId}`);
        urlParams.append("dropOutStopId", `${next?.dropOutStopId}`);
        urlParams.append("timezoneFrom", `${next?.timezone?.from}`);
        setTabOneRide(next);
        setTabSelected("0");
      } else {
        localStorage.removeItem("tabIndex");
        setFromRide(null);
        setTabOneRide(null);
        setReturnRide(null);
        setTabTwoRide(null);
      }
      history.push({ search: urlParams.toString() });
    } else {
      next = tabTwoRide?.ride?.id === nextResult.ride?.id ? null : nextResult;
      let operatorCode = next?.ride?.externalOperator.operatorCode;

      if (!operatorCode){
        operatorCode = window.location.pathname.split("/")[1].toUpperCase();
      }
      dispatch({
        type: "SET_OPERATOR_CODE",
        payload: operatorCode,
      });
      updateOperatorCode(operatorCode);
      urlParams.delete("toRideId");
      urlParams.delete("timezoneTo");
      urlParams.delete("returnPickUpStopId");
      urlParams.delete("returnDropOutStopId");

      if (next) { 

        if (IsDefaultOperator()){
          const queryParams = new URLSearchParams(window.location.search)
          const tabInd:any = queryParams.get("tabIndex");
          const tab = localStorage.getItem("tabIndex");
          if(!tab){
            localStorage.setItem("tabIndex",tabInd);
          }
        }

        urlParams.append("toRideId", `${next.ride.id}`);
        urlParams.append("returnPickUpStopId", `${next?.pickUpStopId}`);
        urlParams.append("returnDropOutStopId", `${next?.dropOutStopId}`);
        urlParams.append("timezoneTo", `${next?.timezone?.to}`);
        setTabTwoRide(next);
        setTabSelected("1");
      } else {
        localStorage.removeItem("tabIndex");
        setReturnRide(null);
        setTabTwoRide(null);
      }
      history.push({ search: urlParams.toString() });
    }
  };

  const handleRemove = React.useCallback(() => {
    urlParams.delete("fromRideId");
    urlParams.delete("timezoneFrom");
    urlParams.delete("pickUpStopId");
    urlParams.delete("dropOutStopId");
    history.push({ search: urlParams.toString() });
    setFromRide(null);
    setTabOneRide(null);
  }, [history, urlParams]);

  const handleReturnRemove = React.useCallback(() => {
    urlParams.delete("toRideId");
    urlParams.delete("timezoneTo");
    urlParams.delete("returnPickUpStopId");
    urlParams.delete("returnDropOutStopId");
    history.push({ search: urlParams.toString() });
    setReturnRide(null);
    setTabTwoRide(null);
  }, [history, urlParams]);

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    event.preventDefault();
    setTabValue(newValue);
    if (newValue === 0) {
      GoogleAnalyticsService.createEvent(
        LOG_EVENTS.LOG_EVENTS_NAME.SELECT_RIDE_FROM,
        {
          search_result_from:
            LOG_EVENTS.LOG_METHOD_VALUE
              .CLICKED_FROM_BUTTON_ON_SEARCH_RESULT_PAGE,
          operator_code: getOperatorCode(),
          source: "web",
        },
      );
    } else {
      GoogleAnalyticsService.createEvent(
        LOG_EVENTS.LOG_EVENTS_NAME.SELECT_RIDE_TO,
        {
          search_result_to:
            LOG_EVENTS.LOG_METHOD_VALUE.CLICKED_TO_BUTTON_ON_SEARCH_RESULT_PAGE,
          operator_code: getOperatorCode(),
          source: "web",
        },
      );
    }
    const urlParams = new URLSearchParams(window.location.search);
    urlParams.delete("tabIndex");
    urlParams.append("tabIndex", `${newValue}`);
    history.push({ search: urlParams.toString() });
  };

  const departureData = useCityOrBusStop(departureId, departureType);
  const arrivalData = useCityOrBusStop(arrivalId, arrivalType);

  const handleResetParams = () => {
    setFromRide(null);
    setReturnRide(null);
    setTabOneRide(null);
    setTabTwoRide(null);
  };


  return (
    <ErrorBoundary>
      <SearchComponentWrapper>
        <HomeTabs
          busSearchParams={{
            defaultValues: {
              departureName: departureData.data?.name,
              arrivalName: arrivalData.data?.name,
              departureType,
              arrivalType,
              arrivalId,
              departureId,
              passengersDefault: passengers,
              dateDefault: date,
              returnDate: searchParams.returnDate,
            },
            onSearchResult: true,
            onSearch: handleResetParams,
          }}
          subscription={true}
        />
      </SearchComponentWrapper>
      <DividerSearchComponent>
        <Divider />
      </DividerSearchComponent>
      {/* <FilterSection>
        <img src={Filter} alt="" />
        <p>{t("filterHeading")}</p>
      </FilterSection> */}

      <DividerSearchComponent>
        <Divider />
      </DividerSearchComponent>
      <MainContainer>
        <FilterContainer></FilterContainer>
        <ResultContainer>
          <React.Suspense
            fallback={
              <Typography variant="h5" color="textPrimary">
                Loading...
              </Typography>
            }
          >
            <PlaceName variant="h5" color="textPrimary" align="center">
              <SearchPoint type={departureType} id={departureId} />
              {returnDate ? (
                <Revisit
                  color={theme.palette.secondary.main}
                  style={{ margin: "0 10px", height: 15 }}
                />
              ) : (
                " - "
              )}
              <SearchPoint type={arrivalType} id={arrivalId} />
            </PlaceName>
          </React.Suspense>
          {returnDate && (
            <TabContainer position="static" color="primary">
              <Tabs
                value={tabValue}
                onChange={handleTabChange}
                aria-label="from_two_tabs"
                variant="fullWidth"
              >
                <Tab
                  style={{ opacity: "1" }}
                  icon={
                    <TabDate>
                      {format(date, dateFormat, {
                        locale: currentLocale,
                      })}
                    </TabDate>
                  }
                  label={t("selectYourDepartureBus")}
                />
                <Tab
                  style={{ opacity: "1" }}
                  icon={
                    <TabDate>
                      {format(new Date(returnDate), dateFormat, {
                        locale: currentLocale,
                      })}
                    </TabDate>
                  }
                  label={t("selectYourReturnBus")}
                />
              </Tabs>
            </TabContainer>
          )}
          <TabPanel value={tabValue} index={0}>
            <DateCarousel
              date={date}
              minDate={returnDateParam}
              onSearch={handleResetParams}
            />
            <Divider />
            <SearchResultList
              toggle={(result) => toggle(result, 0)}
              selectedResult={tabOneRide}
              results={rideResult}
              isLoading={isLoading}
              selectedTab={tabSelected}
            />
          </TabPanel>
          {returnDate && (
            <TabPanel value={tabValue} index={1}>
              <DateCarousel
                date={new Date(returnDate)}
                minRightDate={date}
                onSearch={handleResetParams}
              />
              <Divider />
              <SearchResultList
                toggle={(result) => toggle(result, 1)}
                selectedResult={tabTwoRide}
                results={rideResult}
                isLoading={isLoading}
                selectedTab={tabSelected}
              />
            </TabPanel>
          )}
        </ResultContainer>
        <CartContainer>
          {/* Shopping cart DESKTOP */}
          <ShoppingCart
            pickUpStopId={pickUpStopId}
            dropOutStopId={dropOutStopId}
            ride={fromRide}
            passengersCount={passengers}
            onRemove={handleRemove}
            onReturnRemove={handleReturnRemove}
            timezone={tabOneRide?.timezone}
            // Two way booking props
            returnPickUpStopId={returnPickUpStopId}
            returnDropOutStopId={returnDropOutStopId}
            returnRide={returnRide}
          />
        </CartContainer>
      </MainContainer>
      <CartContainerMobileWrapper data-test-id={TEST_ID.MOBILE_SHOPPING_CART}>
        <MobileShoppingCart
          pickUpStopId={pickUpStopId}
          dropOutStopId={dropOutStopId}
          ride={fromRide}
          passengersCount={passengers}
          onRemove={handleRemove}
          timezone={fromRide?.timezone}
          returnPickUpStopId={returnPickUpStopId}
          returnDropOutStopId={returnDropOutStopId}
          returnRide={returnRide}
          onReturnRemove={handleReturnRemove}
          returnTimezone={tabTwoRide?.timezone}
        />
      </CartContainerMobileWrapper>
    </ErrorBoundary>
  );
};
export const SearchPage = React.memo(SearchPageResult);

export const SearchResultPage = () => {
  const dispatch = useDispatch();
  const { search } = useLocation();
  const searchParams = React.useMemo(
    () => routes.search.parse(search),
    [search],
  );
  if (searchParams.valid) {
    dispatch({
      type: "SET_SEARCH_PARAMS",
      payload: { ...searchParams.params },
    });
  }

  if (!searchParams.valid) {
    return <Redirect to={routes.home.url()} />;
  }
  return <SearchPage {...searchParams.params} />;
}; 
