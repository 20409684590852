import React from "react";
import { useTranslation } from "react-i18next";
import { FormContext, useForm } from "react-hook-form";
import styled from "styled-components";
import {
  Dialog,
  Typography,
  makeStyles,
  IconButton,
  // withStyles,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import loginWithGoogle from "modules/auth/utils/loginWithGoogle";
// import loginWithFacebook from "modules/auth/utils/loginWithFacebook";
import { Button } from "modules/ui";
import { PhoneEmailVerifyProvider } from "modules/booking/organisms/PhoneEmailField/PhoneEmailVerifyContext";
import { PhoneEmailField } from "modules/booking/organisms/PhoneEmailField/PhoneEmailField";
// import { ReactComponent as AppleIcon } from "./apple.svg";
// import { ReactComponent as FacebookIcon } from "./fb.svg";
import { ReactComponent as GoogleIcon } from "./google.svg";
import GoogleAnalyticsService from "../../../utils/GoogleAnalyticsService"
import {LOG_EVENTS} from "../../../utils/LogEvents"
import {getOperatorCode}  from "../../../utils/getOperatorCode";
import classes from "./LoginModal.module.css";
import { Link,routes } from "modules/routing";

type LoginModalProps = {
  open: boolean;
  toggleModal: () => void;
};

const DialogContainer = styled(Dialog)`
  @media (max-width: ${(props) =>
      props.theme.custom.breakpoints["xs-breakpoint"]}) {
    margin: 0 15px;
    .MuiDialog-paper {
      width: 100%;
      max-width: 100%;
      margin: 0;
    }
  }
`;

const LoginContainer = styled.div`
  padding: 50px;
  @media (max-width: ${(props) =>
      props.theme.custom.breakpoints["xs-breakpoint"]}) {
    padding: 15px 27px;
  }
`;

const ContentContainer = styled.div`
  display: grid;
  grid-auto-rows: max-content;
  grid-template-columns: 100%;
  row-gap: 1rem;
  place-items: center;
`;

const TermLink = styled(({ ...rest }) => <Typography {...rest} />)`
  margin: 22px 0;
  text-align: center;
  font-size: 13px;
`;

const Title = styled(({ ...rest }) => <Typography {...rest} />)`
  text-align: center;
  margin-bottom: 1rem;
  @media (max-width: ${(props) =>
      props.theme.custom.breakpoints["xs-breakpoint"]}) {
    font-size: 22px;
    font-weight: 500;
    color: ${(props) => props.theme.custom["emperor-color"]};
  }
`;

const OutlinedButton = styled(({ ...rest }) => <Button {...rest} />)`
  padding: ${(props) => props.padding};
  justify-content: ${(props) => props.justify};
  height: ${(props) => props.height};
  color: ${(props) => props.theme.palette.text};
`;

const useClasses = makeStyles({
  icon: {
    height: "1.15rem",
  },
  btnText: {
    flex: 1,
  },
});

const LoginModalContainer: React.FC<LoginModalProps> = ({
  open,
  toggleModal,
}) => {
  const styles = useClasses();
  const { t } = useTranslation("login");
  // const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const methods = useForm();
  const [hideModal, setHideModal] = React.useState(false);

  const handleGoogleLogin = async () => {
    const [success, error] = await loginWithGoogle();
    if (success)
    {
      GoogleAnalyticsService.createEvent(LOG_EVENTS.LOG_EVENTS_NAME.SCREEN_VIEW, {
      firebase_screen: LOG_EVENTS.SCREEN_NAME.GOOGLE_LOGIN_SCREEN, 
      firebase_screen_class: LOG_EVENTS.SCREEN_NAME.GOOGLE_LOGIN_SCREEN,
      operator_code: getOperatorCode(),
      source:"web"
    });
      GoogleAnalyticsService.createEvent(LOG_EVENTS.LOG_EVENTS_NAME.LOGIN, {
        login: LOG_EVENTS.LOG_METHOD_VALUE.LOGIN_CLICKED,
        method: LOG_EVENTS.LOG_METHOD_VALUE.GOOGLE_LOGIN,
        operator_code: getOperatorCode(),
        source:"web"
      });
    }
    if (success || error) {
      toggleModal();
    }
  };

  // const handleFacebookLogin = async () => {
  //   const [success, error] = await loginWithFacebook();
  // if (success)
  // {
    // GoogleAnalyticsService.createEvent(LOG_EVENTS.LOG_EVENTS_NAME.SCREEN_VIEW, {
    //   firebase_screen: LOG_EVENTS.SCREEN_NAME.FACEBOOK_LOGIN_SCREEN, 
    //   firebase_screen_class: LOG_EVENTS.SCREEN_NAME.FACEBOOK_LOGIN_SCREEN,
   // operator_code: getOperatorCode()
    // });
  //   GoogleAnalyticsService.createEvent(LOG_EVENTS.LOG_EVENTS_NAME.LOGIN, {
  //     login: LOG_EVENTS.LOG_METHOD_VALUE.LOGIN_CLICKED,
  //     method: LOG_EVENTS.LOG_METHOD_VALUE.FACEBOOK_LOGIN,
  //     operator_code: getOperatorCode()
  //   });
  // }
  //   if (success || error) {
  //     toggleModal();
  //   }
  // };

  return (
    <DialogContainer
      hidden={hideModal}
      // fullScreen={fullScreen}
      open={open}
      onClose={toggleModal}
      scroll="body"
    >
      <LoginContainer>
        <div className={classes.closeIcon}>
          <IconButton aria-label="cancel" onClick={toggleModal}>
            <CloseIcon fontSize="small" />
          </IconButton>
        </div>
        <Title variant="h6">{t("title")}</Title>
        <ContentContainer>
          <Typography variant="body2" align="center">
            {t("subTitle")}
          </Typography>
          <FormContext {...methods}>
            <PhoneEmailField
              closeLoginModal={toggleModal}
              hideLoginModal={setHideModal}
            />
          </FormContext>
          <Typography variant="body2" color="textSecondary">
            {t("optional")}
          </Typography>
          {/* <OutlinedButton
            onClick={handleFacebookLogin}
            type="submit"
            justify="flex-start"
            padding="0 2rem"
            variant="outlined"
            color="default"
            fullWidth
            height="3.5rem"
            startIcon={<FacebookIcon className={styles.icon} />}
          >
            <span className={styles.btnText}>
              {t("loginWith", { text: "Facebook" })}
            </span>
          </OutlinedButton> */}
          <OutlinedButton
            onClick={handleGoogleLogin}
            type="submit"
            variant="outlined"
            color="default"
            height="3.5rem"
            fullWidth
            justify="flex-start"
            padding="0 2rem"
            startIcon={<GoogleIcon className={styles.icon} />}
          >
            <span className={styles.btnText}>
              {t("loginWith", { text: "Google" })}
            </span>
          </OutlinedButton>
          {/* <OutlinedButton
            type="submit"
            variant="outlined"
            color="default"
            fullWidth
            height="3.5rem"
            justify="flex-start"
            padding="0 2rem"
            startIcon={<AppleIcon className={styles.icon} />}
          >
            <span className={styles.btnText}>
              {t("loginWith", { text: "Apple" })}
            </span>
          </OutlinedButton> */}

          <TermLink variant="body2" color="textSecondary">
            {t("terms")}&nbsp;
            <b>
              <Link
                style={{ textDecoration: "none", color: "#1976d2", paddingRight: "10px" }}
                to={routes.termsNConditions.url()}
                onClick={() => toggleModal()}
                target="_blank"
              >
                {t("termsLink")}
              </Link>

              <Link
                style={{ textDecoration: "none", color: "#1976d2", paddingLeft: "10px", borderLeft:"1px solid #000"}}
                to={routes.privacypolicy.url()}
                target="_blank"
                onClick={() => toggleModal()}
              >
                {t("privacyPolicy")}
              </Link>
            </b>
          </TermLink>
        </ContentContainer>
      </LoginContainer>
    </DialogContainer>
  );
};

export const LoginModal = (props: LoginModalProps) => {
  React.useEffect(() => {
    GoogleAnalyticsService.createEvent(LOG_EVENTS.LOG_EVENTS_NAME.SCREEN_VIEW, {
      firebase_screen: LOG_EVENTS.SCREEN_NAME.WELCOME_SCREEN, 
      firebase_screen_class: LOG_EVENTS.SCREEN_NAME.WELCOME_SCREEN,
      operator_code: getOperatorCode(),
      source:"web"
    });
  }, []);

  return (
    <PhoneEmailVerifyProvider>
      <LoginModalContainer {...props} />
    </PhoneEmailVerifyProvider>
  );
};
