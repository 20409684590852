import React from "react";
import styled from "styled-components";

const VersionWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;
const VersionInfo = styled.div`
     font-size:12px;
     margin-top:5px;
     color: white;
`;
const AppVersion: React.FC<{}> = () => {
  return (
    <VersionWrapper>
      <hr style={{ width: "100%", border: '0.1px solid #fff' }}></hr>
      <VersionInfo>v{process.env.REACT_APP_PASSENGER_APP_VERSION}</VersionInfo>
    </VersionWrapper>
  );
};

export default AppVersion;