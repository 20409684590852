import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { useHistory, useLocation } from "react-router-dom";
import { RootStateOrAny, useSelector } from "react-redux";
import { CircularProgress, Grid } from "@material-ui/core";
import AccountCircle from "@material-ui/icons/AccountCircle";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import { useTheme } from "@material-ui/core/styles";
import { AuthProvider, LoginModal, useUserData } from "modules/auth";
import { LogoutModal } from "modules/auth/organisms/LogoutModal/LogoutModal";
import { LANGUAGE, MY_RIDE, PROFILE } from "modules/auth/constants";
import { Link, routes } from "modules/routing";
import { useLanguage } from "modules/localization";
import { Button } from "modules/ui";
import { BurgerMenu } from "modules/ui/molecules/BurgerMenu/BurgerMenu";
import { breakpoints } from "modules/ui/variables";
import { useModalVisibility } from "modules/utils/hooks/useModalVisibility";
import { getOperatorCode } from "modules/utils/getOperatorCode";
import useWindowResizeListener from "modules/utils/hooks/useWindowResizeListener";
import { featureToggle } from "modules/utils/featureToggle";
import { HeaderMenuItem } from "../../atoms/HeaderMenuItem/HeaderMenuItem";
import { LanguageSelect } from "../../molecules/LanguageSelect/LanguageSelect";
import { ReactComponent as ArrowBackIosIcon } from "./backArrow.svg";
import { ReactComponent as EnquiryIcon } from "./enquiry.svg";
import { ProfileIcon, RidesIcon, LogoutIcon } from "./icon";
import GoogleAnalyticsService from "../../../utils/GoogleAnalyticsService";
import { LOG_EVENTS } from "../../../utils/LogEvents";
import {RegionSelect} from "../../molecules/RegionSelect"
import {IsDefaultOperator} from 'modules/utils/IsDefaultOperator'

const HeaderWrapper = styled(({ ...rest }) => <header {...rest} />)`
  display: flex;
  padding: 13px 15px;
  justify-content: center;
  background-color: ${(props) => props.theme.custom.primary.main};
  height: 60px;
`;

const HeaderDiv = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  max-width: 1140px;
  align-items: center;
`;

const HeaderLeftBlock = styled.div`
  display: flex;
  align-items: center;
  width: 80%;
  @media (max-width: 1200px) {
    width: 90%;
  }
  @media (max-width: 768px) {
    .logo {
      width: 100%;
      text-align: center;
      // margin-left: -20px;
      height: 24px;
      padding-top: 2px;
    }
  }
`;

const HeaderIcon = styled.img`
  padding-left: 5px;
  height: 28px;
  @media (max-width: 575px) {
    height: 20px;
    max-width: 120px;
    padding-left: 55px;
  }
`;

const HeaderRightBlock = styled.div`
  display: flex;
  align-items: center;
  align-self: center;

  @media (max-width: 768px) {
    // flex-direction: column;
  }
`;

const HeaderMenuWrapper = styled.div`
  display: flex;
  margin-left: 85px;
  @media (max-width: 1200px) {
    display: none;
  }
`;

const LoginItemWrapper = styled.div`
  display: block;
  @media (max-width: 1200px) {
    display: none;
  }
`;

const ProfileContainer = styled(({ ...rest }) => <Button {...rest} />)`
  margin-left: 1.5rem;
  position: relative;
  .custom-dropdown {
    display: none;
    position: absolute;
    background: ${(props) => props.theme.custom["white-color"]};
    border-radius: 3px;
    top: 50px;
    width: 206px;
    max-width: 206px;
    right: 0;
    border: 1px solid #ddd;
    box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.2);
    z-index: 9;
    &.show {
      display: block;
    }
    ul {
      margin: 0;
      padding: 10px 0 10px;
      list-style: none;
      li {
        font-weight: normal;
        font-size: 16px;
        line-height: 36px;
        color: ${(props) => props.theme.custom["limed-spruce"]};
        text-align: left;
        display: flex;
        padding: 6px 22px 6px;
        align-items: center;
        svg {
          width: 25px;
          margin-right: 25px;
        }
        &:hover {
          background: ${(props) => props.theme.custom["alabaster-color"]};
        }
      }
    }
  }
`;

const LoaderWrapper = styled.div`
  display: flex;
  position: fixed;
  top: 0;
  flex-direction: column;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9;
  align-items: center;
  justify-content: center;
  background: rgba(255, 255, 255, 0.5);
`;

const HeaderLogoText = styled.p`
  color: #ffffff;
  margin-left: 10px;
  padding-bottom: 2px;
`;

function HeaderContainer() {
  const theme = useTheme();
  const language = useLanguage();
  const history = useHistory();
  const location = useLocation();
  // const { countryCode } = useSelector((state: RootStateOrAny) => state.region);
  const { t } = useTranslation("header");
  const { value: userData } = useUserData();
  const [loginModal, loginModalVisibility] = useModalVisibility();
  const [logoutModal, logoutModalVisibility] = useModalVisibility();
  const [hideMenu, setHideMenu] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const handleClickAway = () => {
    setHideMenu(false);
  };
  const [showProfile, setShowProfile] = React.useState<boolean>(true);
  const [showRides, setShowRides] = React.useState<boolean>(true);
  const [showLanguage, setShowLanguage] = React.useState<boolean>(true);
  const firebase_app = useSelector(
    (state: any) => state.remoteConfig.firebaseApp,
  );
  const criteria = {
    country: useSelector((state: any) => state.remoteConfig.countryName),
    operator: useSelector((state: any) => state.remoteConfig.operatorId),
  };
  const defaultOperator:boolean = IsDefaultOperator();

  React.useEffect(() => {
    async function getResult() {
      const toggleProfile = await featureToggle(
        PROFILE,
        firebase_app,
        criteria,
      );
      const toggleRides = await featureToggle(MY_RIDE, firebase_app, criteria);
      const toggleLanguage = await featureToggle(
        LANGUAGE,
        firebase_app,
        criteria,
      );
      setShowProfile(toggleProfile);
      setShowRides(toggleRides);
      setShowLanguage(toggleLanguage);
    }

    getResult();
  }, [criteria, firebase_app]);

  const handleBackClick = () => {
    GoogleAnalyticsService.createEvent(
      LOG_EVENTS.LOG_EVENTS_NAME.PRESS_BACK_BUTTON,
      {
        back_btn: LOG_EVENTS.LOG_METHOD_VALUE.BACK_BUTTON_PRESSED,
        operator_code: getOperatorCode(),
        source: "web",
      },
    );
    history.goBack();
  };

  const handleEnquiryClick = () => {
    const url = `/${language}?tab=2`;
    history.push(url);
  };

  const handleModalVisiblity = (type: string) => {
    if (type === "login") {
      loginModalVisibility();
    }
    if (type === "logout") {
      logoutModalVisibility();
    }
  };
  const { windowWidth } = useWindowResizeListener();
  const mobile = windowWidth < breakpoints.xs || windowWidth <= breakpoints.md;
  const rentalPath =
    mobile && location.pathname.includes("/rental-service-listing");
  const rental = useSelector((state: RootStateOrAny) => state.rental);


  return (
    <>
      <HeaderWrapper>
        <ClickAwayListener onClickAway={handleClickAway}>
          <HeaderDiv>
            <HeaderLeftBlock>
              {location &&
              (location.search === "" ||
                location.search === "?tab=1" ||
                location.search === "?tab=2") ? (
                <BurgerMenu
                  onClick={(type: string) => handleModalVisiblity(type)}
                />
              ) : (
                mobile && (
                  <div style={{ padding: 10 }}>
                    <ArrowBackIosIcon onClick={handleBackClick} />
                  </div>
                )
              )}
              {rentalPath ? (
                <HeaderLogoText className="logo">{rental.title}</HeaderLogoText>
              ) : (
                <div className="logo">
                  <Link to={routes.home.url()}>
                    {theme.custom.isLogoText || !theme.custom.logo ? (
                      <HeaderLogoText>{theme.custom.logoText}</HeaderLogoText>
                    ) : (
                      <HeaderIcon src={theme.custom.logo} alt="" />
                    )}
                  </Link>
                </div>
              )}
              <HeaderMenuWrapper>
                {/* <Link to={routes.rental.url()}>
                  <HeaderMenuItem value={t("rental")} />
                </Link> */}
                {/* <HeaderMenuItem value={t("routes")} className="marginLeft" />
                <HeaderMenuItem value={t("help")} className="marginLeft" /> */}
              </HeaderMenuWrapper>
            </HeaderLeftBlock>
            {rentalPath ? (
              <div>
                <EnquiryIcon onClick={handleEnquiryClick} />
              </div>
            ) : (
              <HeaderRightBlock>
                
                {defaultOperator ? 
                  <RegionSelect /> 
                : null}
                
                {showLanguage && <LanguageSelect />}
                <LoginItemWrapper>
                  <Grid
                    container
                    alignItems="center"
                    style={{ color: "white" }}
                  >
                    {userData ? (
                      <ProfileContainer
                        variant="text"
                        color="inherit"
                        startIcon={
                          <AccountCircle htmlColor="#FFFFFF" fontSize="large" />
                        }
                        onClick={() => {
                          setHideMenu(true);
                        }}
                        endIcon={<KeyboardArrowDownIcon />}
                      >
                        <div
                          className={`custom-dropdown ${
                            hideMenu ? "show" : ""
                          }`}
                        >
                          <ul>
                            {showRides ? (
                              <Link to="rides">
                                <li>
                                  <RidesIcon />
                                  {t("myRides")}
                                </li>
                              </Link>
                            ) : null}

                            {/* <li>
                            <LoyalityIcon />
                            {t("loyalityPoints")}
                          </li> */}
                            {showProfile ? (
                              <Link to="profile">
                                <li>
                                  <ProfileIcon />
                                  {t("profile")}
                                </li>
                              </Link>
                            ) : null}

                            <li onClick={() => handleModalVisiblity("logout")}>
                              <LogoutIcon />
                              {t("logout")}
                            </li>
                          </ul>
                        </div>
                        {userData?.displayName
                          ? `${t("salute")} ${userData?.displayName}`
                          : t("saluteAnonymus")}
                      </ProfileContainer>
                    ) : (
                      <HeaderMenuItem
                        value={t("login")}
                        onClick={loginModalVisibility}
                      />
                    )}
                  </Grid>
                </LoginItemWrapper>
              </HeaderRightBlock>
            )}
          </HeaderDiv>
        </ClickAwayListener>
      </HeaderWrapper>
      {loginModal && (
        <LoginModal open={loginModal} toggleModal={loginModalVisibility} />
      )}
      {logoutModal && (
        <LogoutModal
          open={logoutModal}
          toggleModal={logoutModalVisibility}
          setLoading={setLoading}
        />
      )}
      {loading && (
        <LoaderWrapper>
          <CircularProgress color="secondary" />
        </LoaderWrapper>
      )}
    </>
  );
}

function Header() {
  return (
    <AuthProvider>
      <HeaderContainer />
    </AuthProvider>
  );
}

export { Header };
