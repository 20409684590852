import React from "react";
import styled from "styled-components";
// import classes from "./Label.module.css";

interface Props {
  label: string;
}

const LabelText = styled.div`
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
  color: ${(props) => props.theme.custom["emperor-color"]};
  margin-bottom: 10px;
`;

export const Label = (props: Props) => {
  const { label } = props;
  return <LabelText>{label} *</LabelText>;
};
