import React from "react";
import {
  Checkbox as MuiCheckbox,
  CheckboxProps,
  withStyles,
} from "@material-ui/core";

const StyledCheckbox = withStyles({
  root: {
    color: "#6400D7",
  },
  checked: {
    color: "#6400D7!important",
  },
})(MuiCheckbox);

export const Checkbox: React.FC<CheckboxProps> = (props) => {
  return <StyledCheckbox {...props} />;
};
