import * as React from "react";
import { PaymentMethodCodes, PaymentResponse } from "modules/entities";
import { defaultBank } from "../constants";

export const useAccountNumber = (
  paymentResponse: PaymentResponse | null,
  methodCode: string | null
): string => {
  return React.useMemo(() => {
    if (!paymentResponse || !paymentResponse.invoice) {
      return "";
    }
    const codeToSearch =
      methodCode === PaymentMethodCodes.other ? defaultBank : methodCode;
    const accountNumber = paymentResponse.invoice.banks.find(
      (item) => item.bank_code === codeToSearch
    );
    return accountNumber?.bank_account_number || "";
  }, [paymentResponse, methodCode]);
};
