import { store } from "../../store";

const SetParameter = async (key: any, value: any) => {
  await store.dispatch({
    type: key,
    payload: value,
  });
};

export default SetParameter;
