import React from 'react'

export const CountryFlagIN = ({
    height = "24px",
    width = "24px",
    color,
    ...props
  }: React.SVGProps<SVGSVGElement>) => (
    <svg width="24" height="18" viewBox="0 0 24 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.25" y="0.678711" width="23.5" height="16.6429" rx="1.75" fill="white" stroke="#F5F5F5" strokeWidth="0.5"/>
    <mask id="mask0_5932_8036"  maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="18">
    <rect x="0.25" y="0.678711" width="23.5" height="16.6429" rx="1.75" fill="white" stroke="white" strokeWidth="0.5"/>
    </mask>
    <g mask="url(#mask0_5932_8036)">
    <path fillRule="evenodd" clipRule="evenodd" d="M1 6.42871H23V1.42871H1V6.42871Z" fill="#FFA44A"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M1 16.4287H23V11.4287H1V16.4287Z" fill="#1A9F0B"/>
    <path d="M11.9999 11.0475C13.1308 11.0475 14.0475 10.1308 14.0475 8.99993C14.0475 7.86906 13.1308 6.95231 11.9999 6.95231C10.8691 6.95231 9.95231 7.86906 9.95231 8.99993C9.95231 10.1308 10.8691 11.0475 11.9999 11.0475Z" fill="#181A93" fill-opacity="0.15" stroke="#181A93" strokeWidth="0.666667"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M12.0001 9.57157C12.3157 9.57157 12.5716 9.31573 12.5716 9.00014C12.5716 8.68455 12.3157 8.42871 12.0001 8.42871C11.6845 8.42871 11.4287 8.68455 11.4287 9.00014C11.4287 9.31573 11.6845 9.57157 12.0001 9.57157Z" fill="#181A93"/>
    </g>
    </svg>

  );

  export const CountryFlagID = ({
    height = "24px",
    width = "24px",
    color,
    ...props
  }: React.SVGProps<SVGSVGElement>) => (
    <svg width="24" height="18" viewBox="0 0 24 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.25" y="0.678711" width="23.5" height="16.6429" rx="1.75" fill="white" stroke="#F5F5F5" strokeWidth="0.5"/>
    <mask id="mask0_5932_8057" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="18">
    <rect x="0.25" y="0.678711" width="23.5" height="16.6429" rx="1.75" fill="white" stroke="white" strokeWidth="0.5"/>
    </mask>
    <g mask="url(#mask0_5932_8057)">
    <path fillRule="evenodd" clipRule="evenodd" d="M0 9.57157H24V0.428711H0V9.57157Z" fill="#E12237"/>
    </g>
    </svg>


  );

  export const CountryFlagPH = ({
    height = "24px",
    width = "24px",
    color,
    ...props
  }: React.SVGProps<SVGSVGElement>) => (
    <svg width="28" height="20" viewBox="0 0 28 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.25" y="0.25" width="27.5" height="19.5" rx="1.75" fill="white" stroke="#F5F5F5" strokeWidth="0.5"/>
    <mask id="mask0_8742_196303" maskUnits="userSpaceOnUse" x="0" y="0" width="28" height="20">
    <rect x="0.25" y="0.25" width="27.5" height="19.5" rx="1.75" fill="white" stroke="white" strokeWidth="0.5"/>
    </mask>
    <g mask="url(#mask0_8742_196303)">
    <path fillRule="evenodd" clipRule="evenodd" d="M0 20H28V9.33331H12.4444L13.3333 9.99998L0 20Z" fill="#DD1C34"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M28 9.33333H12.4444L0 0H28V9.33333Z" fill="#0D4BC3"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M2.00004 4.00002C2.36823 4.00002 2.66671 3.70154 2.66671 3.33335C2.66671 2.96516 2.36823 2.66669 2.00004 2.66669C1.63185 2.66669 1.33337 2.96516 1.33337 3.33335C1.33337 3.70154 1.63185 4.00002 2.00004 4.00002ZM10 10.6667C10.3682 10.6667 10.6667 10.3682 10.6667 10C10.6667 9.63183 10.3682 9.33335 10 9.33335C9.63185 9.33335 9.33337 9.63183 9.33337 10C9.33337 10.3682 9.63185 10.6667 10 10.6667ZM2.66671 16.6667C2.66671 17.0349 2.36823 17.3334 2.00004 17.3334C1.63185 17.3334 1.33337 17.0349 1.33337 16.6667C1.33337 16.2985 1.63185 16 2.00004 16C2.36823 16 2.66671 16.2985 2.66671 16.6667ZM4.53212 11.3266L3.3911 13.0796L3.82383 11.0332C3.75419 10.9763 3.69041 10.9125 3.63354 10.8429L1.58711 11.2756L3.34008 10.1346C3.33565 10.0904 3.33337 10.0455 3.33337 10C3.33337 9.95459 3.33565 9.90969 3.34008 9.86543L1.58711 8.72441L3.63354 9.15714C3.69042 9.08751 3.75419 9.02373 3.82383 8.96685L3.3911 6.92042L4.53212 8.6734C4.57638 8.66896 4.62128 8.66669 4.66671 8.66669C4.71214 8.66669 4.75704 8.66896 4.8013 8.6734L5.94232 6.92042L5.50959 8.96685C5.57922 9.02373 5.643 9.0875 5.69988 9.15714L7.74631 8.72441L5.99333 9.86543C5.99777 9.90969 6.00004 9.95459 6.00004 10C6.00004 10.0455 5.99777 10.0904 5.99333 10.1346L7.74631 11.2756L5.69988 10.8429C5.643 10.9125 5.57922 10.9763 5.50959 11.0332L5.94232 13.0796L4.8013 11.3266C4.75704 11.3311 4.71214 11.3334 4.66671 11.3334C4.62128 11.3334 4.57638 11.3311 4.53212 11.3266Z" fill="#FDD64D"/>
    </g>
    </svg>
  );

  export const CountryFlagSG = ({
    height = "24px",
    width = "24px",
    color,
    ...props
  }: React.SVGProps<SVGSVGElement>) => (
    <svg width="28" height="20" viewBox="0 0 28 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.25" y="0.25" width="27.5" height="19.5" rx="1.75" fill="white" stroke="#F5F5F5" strokeWidth="0.5"/>
    <mask id="mask0_8742_195727" maskUnits="userSpaceOnUse" x="0" y="0" width="28" height="20">
    <rect x="0.25" y="0.25" width="27.5" height="19.5" rx="1.75" fill="white" stroke="white" strokeWidth="0.5"/>
    </mask>
    <g mask="url(#mask0_8742_195727)">
    <path fillRule="evenodd" clipRule="evenodd" d="M0 10.6667H28V0H0V10.6667Z" fill="#E12237"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M6.66663 9.33334C7.61174 9.33334 8.48029 9.00557 9.16481 8.45749C8.8022 8.59274 8.40971 8.66668 7.99996 8.66668C6.15901 8.66668 4.66663 7.17429 4.66663 5.33334C4.66663 3.49239 6.15901 2.00001 7.99996 2.00001C8.40971 2.00001 8.8022 2.07394 9.16481 2.2092C8.48029 1.66112 7.61173 1.33334 6.66663 1.33334C4.45749 1.33334 2.66663 3.1242 2.66663 5.33334C2.66663 7.54248 4.45749 9.33334 6.66663 9.33334ZM7.99996 4.66668C7.99996 5.03487 7.70148 5.33334 7.33329 5.33334C6.9651 5.33334 6.66663 5.03487 6.66663 4.66668C6.66663 4.29849 6.9651 4.00001 7.33329 4.00001C7.70148 4.00001 7.99996 4.29849 7.99996 4.66668ZM8.66663 8.00001C9.03482 8.00001 9.33329 7.70153 9.33329 7.33334C9.33329 6.96515 9.03482 6.66668 8.66663 6.66668C8.29844 6.66668 7.99996 6.96515 7.99996 7.33334C7.99996 7.70153 8.29844 8.00001 8.66663 8.00001ZM12 7.33334C12 7.70153 11.7015 8.00001 11.3333 8.00001C10.9651 8.00001 10.6666 7.70153 10.6666 7.33334C10.6666 6.96515 10.9651 6.66668 11.3333 6.66668C11.7015 6.66668 12 6.96515 12 7.33334ZM12.6666 5.33334C13.0348 5.33334 13.3333 5.03487 13.3333 4.66668C13.3333 4.29849 13.0348 4.00001 12.6666 4.00001C12.2984 4.00001 12 4.29849 12 4.66668C12 5.03487 12.2984 5.33334 12.6666 5.33334ZM10.6666 2.66668C10.6666 3.03487 10.3681 3.33334 9.99996 3.33334C9.63177 3.33334 9.33329 3.03487 9.33329 2.66668C9.33329 2.29849 9.63177 2.00001 9.99996 2.00001C10.3681 2.00001 10.6666 2.29849 10.6666 2.66668Z" fill="white"/>
    </g>
    </svg>
  );

  export const CountryFlagMY = ({
    height = "24px",
    width = "24px",
    color,
    ...props
  }: React.SVGProps<SVGSVGElement>) => (
    <svg width="28" height="20" viewBox="0 0 28 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="28" height="20" rx="2" fill="white"/>
    <mask id="mask0_8742_195216" maskUnits="userSpaceOnUse" x="0" y="0" width="28" height="20">
    <rect width="28" height="20" rx="2" fill="white"/>
    </mask>
    <g mask="url(#mask0_8742_195216)">
    <path fillRule="evenodd" clipRule="evenodd" d="M28 0H13.3333V1.33333H28V0ZM28 2.66667H13.3333V4H28V2.66667ZM13.3333 5.33333H28V6.66667H13.3333V5.33333ZM28 8H13.3333V9.33333H28V8ZM0 10.6667H28V12H0V10.6667ZM28 13.3333H0V14.6667H28V13.3333ZM0 16H28V17.3333H0V16ZM28 18.6667H0V20H28V18.6667Z" fill="#E1244A"/>
    <rect width="16" height="10.6667" fill="#0C3F8E"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M6.66663 9.33331C7.61174 9.33331 8.48029 9.00554 9.16481 8.45746C8.8022 8.59271 8.40971 8.66665 7.99996 8.66665C6.15901 8.66665 4.66663 7.17426 4.66663 5.33331C4.66663 3.49236 6.15901 1.99998 7.99996 1.99998C8.40971 1.99998 8.8022 2.07391 9.16481 2.20917C8.48029 1.66109 7.61173 1.33331 6.66663 1.33331C4.45749 1.33331 2.66663 3.12417 2.66663 5.33331C2.66663 7.54245 4.45749 9.33331 6.66663 9.33331ZM9.5096 7.7359L10.6666 6.42665L11.8236 7.7359L11.5214 6.015L13.2664 5.9267L11.7325 5.09002L12.7515 3.67067L11.141 4.34825L10.6666 2.66665L10.1922 4.34825L8.58174 3.67067L9.60071 5.09002L8.06682 5.9267L9.81182 6.015L9.5096 7.7359Z" fill="#FFD34F"/>
    </g>
    </svg>
  );

  export const CountryFlagAE = ({
    height = "24px",
    width = "24px",
    color,
    ...props
  }: React.SVGProps<SVGSVGElement>) => (
    <svg width="28" height="20" viewBox="0 0 28 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.25" y="0.25" width="27.5" height="19.5" rx="1.75" fill="white" stroke="#F5F5F5" strokeWidth="0.5"/>
    <mask id="mask0_8742_196446" maskUnits="userSpaceOnUse" x="0" y="0" width="28" height="20">
    <rect x="0.25" y="0.25" width="27.5" height="19.5" rx="1.75" fill="white" stroke="white" strokeWidth="0.5"/>
    </mask>
    <g mask="url(#mask0_8742_196446)">
    <path fillRule="evenodd" clipRule="evenodd" d="M8 6.66667H28V0H8V6.66667Z" fill="#12833B"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M8 20H28V13.3334H8V20Z" fill="#262626"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M0 20H8V0H0V20Z" fill="#FF323E"/>
    </g>
    </svg>
  );

  
