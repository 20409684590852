import React from "react";

export const SearchIcon = ({
  height = "24px",
  width = "24px",
  color,
  ...props
}: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.23052 18.4263L5.65684 14C6.19194 14.8637 6.91446 15.5863 7.77817 16.1213L3.35183 20.5476C3.01257 20.8869 2.58597 21.0756 2.17112 21.1063C1.75626 21.1371 1.35307 21.0099 1.06065 20.7175C0.777303 20.4342 0.638976 20.0398 0.67106 19.6071C0.703145 19.1744 0.901804 18.755 1.23052 18.4263Z"
      fill={color}
    />
    <path
      d="M13.5 17C18.1869 17 22 13.1869 22 8.5C22 3.81308 18.1869 0 13.5 0C8.81308 0 5 3.81308 5 8.5C5 13.1869 8.81308 17 13.5 17ZM7.61622 8.5C7.61622 5.25567 10.2557 2.61622 13.5 2.61622C16.7443 2.61622 19.3838 5.25567 19.3838 8.5C19.3838 11.7443 16.7443 14.3838 13.5 14.3838C10.2557 14.3838 7.61622 11.7443 7.61622 8.5Z"
      fill={color}
    />
    <path
      d="M13.5 13C15.9853 13 18 10.9853 18 8.5C18 6.01472 15.9853 4 13.5 4C11.0147 4 9 6.01472 9 8.5C9 10.9853 11.0147 13 13.5 13Z"
      fill={color}
    />
  </svg>
);

export const RidesIcon = ({
  height = "24px",
  width = "24px",
  color = "#353F47",
  ...props
}: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="25"
    height="25"
    viewBox="0 0 25 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21.963 12.8417H3.05C2.4701 12.8417 2 13.3118 2 13.8917V22.2917C2 22.8716 2.4701 23.3417 3.05 23.3417H21.963C21.9544 23.2832 21.95 23.2233 21.95 23.1624C21.95 22.5444 22.4061 22.0329 23 21.9461V20.7933C22.4061 20.7065 21.95 20.1951 21.95 19.577C21.95 18.959 22.4061 18.4475 23 18.3607V17.5153C22.4061 17.4285 21.95 16.917 21.95 16.299C21.95 15.681 22.4061 15.1695 23 15.0827V14.2372C22.4061 14.1505 21.95 13.639 21.95 13.0209C21.95 12.9601 21.9544 12.9002 21.963 12.8417ZM7.775 14.583C8.06495 14.583 8.3 14.3479 8.3 14.058C8.3 13.768 8.06495 13.533 7.775 13.533C7.48505 13.533 7.25 13.768 7.25 14.058C7.25 14.3479 7.48505 14.583 7.775 14.583ZM8.3 16.1579C8.3 16.4479 8.06495 16.6829 7.775 16.6829C7.48505 16.6829 7.25 16.4479 7.25 16.1579C7.25 15.868 7.48505 15.6329 7.775 15.6329C8.06495 15.6329 8.3 15.868 8.3 16.1579ZM7.775 18.7829C8.06495 18.7829 8.3 18.5479 8.3 18.2579C8.3 17.968 8.06495 17.7329 7.775 17.7329C7.48505 17.7329 7.25 17.968 7.25 18.2579C7.25 18.5479 7.48505 18.7829 7.775 18.7829ZM8.3 20.3579C8.3 20.6479 8.06495 20.8829 7.775 20.8829C7.48505 20.8829 7.25 20.6479 7.25 20.3579C7.25 20.068 7.48505 19.8329 7.775 19.8329C8.06495 19.8329 8.3 20.068 8.3 20.3579ZM7.775 22.983C8.06495 22.983 8.3 22.7479 8.3 22.458C8.3 22.168 8.06495 21.933 7.775 21.933C7.48505 21.933 7.25 22.168 7.25 22.458C7.25 22.7479 7.48505 22.983 7.775 22.983Z"
      fill={color}
    />
    <circle cx="21" cy="4" r="4" fill={color} />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.21 2.42054C15.0844 2.34884 14.968 2.25597 14.8663 2.14222C14.8208 2.0914 14.7799 2.03813 14.7433 1.98291L3.77515 11.7916H21.4148L22.5818 10.748C22.531 10.7055 22.4826 10.6588 22.4371 10.608C22.2522 10.4013 22.1413 10.1541 22.1024 9.89894C21.745 9.9653 21.3765 10 20.9999 10C17.6862 10 14.9999 7.31371 14.9999 4C14.9999 3.45336 15.073 2.92379 15.21 2.42054Z"
      fill={color}
    />
  </svg>
);

export const ProfileIcon = ({
  height = "24px",
  width = "24px",
  color = "#353F47",
  ...props
}: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="18"
    height="21"
    viewBox="0 0 18 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="9.08004" cy="5.87997" r="5.04" fill={color} />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.4799 20.1102H0.680176C0.707 15.4939 4.45747 11.76 9.08003 11.76C13.7026 11.76 17.4531 15.4939 17.4799 20.1102Z"
      fill={color}
    />
  </svg>
);

export const LoyalityIcon = ({
  height = "24px",
  width = "24px",
  color = "#353F47",
  ...props
}: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="18"
    height="24"
    viewBox="0 0 18 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.76923 17.5385C13.6123 17.5385 17.5385 13.6123 17.5385 8.76923C17.5385 3.92612 13.6123 0 8.76923 0C3.92612 0 0 3.92612 0 8.76923C0 13.6123 3.92612 17.5385 8.76923 17.5385ZM8.76922 15.6923C12.5927 15.6923 15.6923 12.5927 15.6923 8.76923C15.6923 4.94572 12.5927 1.84615 8.76922 1.84615C4.94571 1.84615 1.84614 4.94572 1.84614 8.76923C1.84614 12.5927 4.94571 15.6923 8.76922 15.6923Z"
      fill={color}
    />
    <circle cx="8.76929" cy="8.76929" r="6" fill={color} />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.69226 17.0271V24H13.8461L13.8461 24L13.8461 17.0271C12.3696 17.9368 10.6307 18.4616 8.76918 18.4616C6.90768 18.4616 5.16875 17.9368 3.69226 17.0271ZM13.8461 24H3.69227L8.76919 19.9385L13.8461 24Z"
      fill={color}
    />
  </svg>
);

export const LogoutIcon = ({
  height = "24px",
  width = "24px",
  color = "#353F47",
  ...props
}: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="30"
    height="30"
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M26.6099 14.61L21.4799 9.45001C21.15 9.12001 20.6099 9.36001 20.6099 9.87001V13.2H13.83C13.47 13.2 13.2 13.5 13.2 13.83V16.11C13.2 16.47 13.5 16.74 13.83 16.74H20.6099V20.1C20.6099 20.58 21.15 20.82 21.4799 20.49L26.6099 15.36C26.8199 15.18 26.8199 14.82 26.6099 14.61Z"
      fill={color}
    />
    <path
      d="M17.1 20.8799H8.30996C7.73996 20.8799 7.28996 20.4299 7.28996 19.8599V10.1399C7.28996 9.56995 7.73996 9.11995 8.30996 9.11995H17.1C17.4 9.11995 17.64 8.87995 17.64 8.57995V5.60995C17.64 5.30995 17.4 5.06995 17.1 5.06995H4.49996C3.80996 5.06995 3.20996 5.57995 3.20996 6.20995V23.8199C3.20996 24.4499 3.77996 24.9599 4.49996 24.9599H17.1C17.4 24.9599 17.64 24.7199 17.64 24.4199V21.4499C17.64 21.1199 17.37 20.8799 17.1 20.8799Z"
      fill={color}
    />
  </svg>
);
