import { onboardingAxios } from "../client";
import { ENDPOINTS } from "../endpoints";

export async function getSubscriptionDetails(operatorCode: any) {
  return onboardingAxios
    .get<any>(
      `${ENDPOINTS.ONBOARDING.SUBSCRIPTION}/${operatorCode}/subscription`
    )
    .then((res) => {
      return res.data;
    })
    .catch((error) => error);
}

export async function getCustomerSupportInfo(operatorCode: any) {
  return onboardingAxios
    .get<any>(
      `${ENDPOINTS.ONBOARDING.SUBSCRIPTION}/${operatorCode}/customer-support`
    )
    .then((res) => {
      return res.data;
    })
    .catch((error) => error);
}
