import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { PaymentMethod } from "modules/entities";
// import classes from "./PaymentInstructions.module.css";

type PaymentInstructionsProps = {
  method: PaymentMethod;
  title?: string;
};

const InstructionsBlockWrapper = styled.div`
  padding: 15px 22px;
  background: #fff6d2;
  border: 1px solid #cfb551;
  box-sizing: border-box;
  border-radius: 3px;
`;

const Title = styled.p`
  margin-top: 0px;
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  color: ${(props) => props.theme.custom["emperor-color"]};
`;

const InstructionsWrapper = styled.ol`
  margin: 0;
  padding: 0px 12px;
`;

const Instructions = styled.li`
  margin-top: 20px;
  padding-left: 10px;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  color: ${(props) => props.theme.custom["emperor-color"]};
`;

export const PaymentInstructions: React.FC<PaymentInstructionsProps> = ({
  method,
  title,
}) => {
  const { t } = useTranslation("paymentInstructions");

  const { name, type } = method;

  const instructions = t(type, { returnObjects: true });

  return (
    <InstructionsBlockWrapper>
      <Title>
        {t("title")} {title || name}
      </Title>
      <InstructionsWrapper>
        {Array.isArray(instructions)
          ? instructions.map((instruction, index) => (
              <Instructions key={instruction + index}>
                {instruction}
              </Instructions>
            ))
          : null}
      </InstructionsWrapper>
    </InstructionsBlockWrapper>
  );
};
