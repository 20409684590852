import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { parse } from "querystring";
import Grid from "@material-ui/core/Grid";
import { getRentalCategoryServices } from "modules/api/methods";
import { BackButton, routes } from "modules/routing";
import { useLanguage } from "modules/localization";
// import VehicleImage from "../images/vehicle.png";
import { ReactComponent as EnquiryIcon } from "../images/enquiry.svg";
import NoImageIcon from "../images/noImage.svg";
import ProgressBar from 'modules/customProgressBar';
import { updateOperatorCode } from "modules/api/client";

const VehicleName = styled.h4`
  font-weight: 600;
  font-size: 16px;
  line-height: 18px;
  color: #19191d;
  margin-bottom: 12px;
`;
const VehicleInfo = styled.p`
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 15px;
  color: #5a5b6a;
`;

const MainWrapper = styled.div`
  background: #f6f6f6;
  min-height: 100vh;
  display: flex;
  width: 100%;
  flex-direction: column;
  flex-wrap: nowrap;
  // font-family: ${(props) => props.theme.custom["inter-font"]};
  .header {
    // margin: 30px auto 0;
  }
  .title {
    font-weight: 600;
    font-size: 18px;
    line-height: 18px;
  }
  @media (max-width: ${(props) =>
    props.theme.custom.breakpoints["xs-breakpoint"]}) {
    background: none;
  }
`;
const VehicleWrapper = styled(Grid)`
  align-items: center;
  background: #fff;
  // border-bottom: 1px solid #d8d8d8;
  display: flex;
  height: 100%;
  flex-direction: column;
  flex-wrap: nowrap;
  margin: 0 auto;
  @media (max-width: ${(props) =>
    props.theme.custom.breakpoints["xs-breakpoint"]}) {
    margin: unset;
    width: 100%;
  }

  // vechileOuter start
  .vechileOuter {
    display: flex;
    height: 100%;
    margin: 0 auto;
    padding: 30px 0 20px;
    width: 90%;
    justify-content: space-between;
    flex-wrap: nowrap;
    border-bottom: 1px solid #d8d8d8;
    @media (max-width: ${(props) =>
    props.theme.custom.breakpoints["xs-breakpoint"]}) {
      padding: 15px;
      border-bottom: none;
      margin: unset;
      width: 100%;
    }
    .vehicleContent {
      width: 70%;
      justify-content: space-around;
      padding-right: 25px;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      display: flex;
      p {
        text-align: justify;
      }
    }
    .vehicleImage {
      display: flex;
      height: 100%;
      width: 25%;
      @media (max-width: ${(props) =>
    props.theme.custom.breakpoints["xs-breakpoint"]}) {
        width: 30%;
      }
    }
  }

  // vechileOuter end

  .proceedOuter {
    width: 100%;
    display: flex;
    padding: 10px 0px;
    justify-content: space-between;
    align-items: center;
    p {
      font-weight: 500;
      font-size: 10px;
      line-height: 18px;
      color: #5a5b6a;
      display: inline-block;
      span {
        font-size: 18px;
        color: #19191d;
      }
    }
    .linethrough {
      font-weight: 500;
      font-size: 10px;
      line-height: 15px;
      text-decoration-line: line-through;
      margin: 0 15px;
      color: #5a5b6a;
    }
    .discount {
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 18px;
      color: #ff0000;
    }
  }
`;
// const Seprator = styled.div`
//   border-top: 0.5px solid #ccc;
//   width: 92%;
// `;

const MobileWrapper = styled.div`
  @media (min-width: ${(props) =>
    props.theme.custom.breakpoints["md-breakpoint"]}) {
    display: none;
  }
  width: 100%;
  .proceedOuter {
    flex-direction: column;
    align-items: flex-start;
    padding: 0 15px;
  }
`;

const DesktopView = styled.div`
  width: 100%;
  @media (max-width: ${(props) =>
    props.theme.custom.breakpoints["xs-breakpoint"]}) {
    display: none;
  }
`;

const ButtonBook = styled.button`
  background: #5a5b6a;
  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.15);
  border-radius: 3px;
  color: #fff;
  border: none;
  width: 20%;
  @media (max-width: ${(props) =>
    props.theme.custom.breakpoints["md-breakpoint"]}) {
    width: 92%;
  }
  @media (max-width: ${(props) =>
    props.theme.custom.breakpoints["xs-breakpoint"]}) {
    width: 100%;
  }
  margin: 20px 0;
  height: 40px;
  font-weight: bold;
  font-size: 12px;
  line-height: 14px;
  cursor: pointer;
`;

const NoServices = styled.div`
  font-size: 16px;
  color: #4f4f4f;
  text-align: center;
  margin-top: 50px;
`;

const Container = styled.div`
  @media (min-width: ${(props) =>
    props.theme.custom.breakpoints["md-breakpoint"]}) {
    width: 60%;
  }
  margin: 30px auto 0;
`;

const BackButtonWrapper = styled.div`
  @media (max-width: ${(props) =>
    props.theme.custom.breakpoints["md-breakpoint"]}) {
    display: none;
  }
`;

const IconWrapper = styled.div`
  display: flex;
  margin: 20px auto;
  justify-content: space-between;
  align-items: center;
  .icon {
    border-radius: 50%;
    width: 46px;
    height: 46px;
    padding: 5px;
    background: ${(props) => props.theme.custom.primary.main};
  }
  @media (max-width: ${(props) =>
    props.theme.custom.breakpoints["xs-breakpoint"]}) {
    display: none;
  }
`;

const HeaderText = styled.div`
  color: black;
`;

const discountedPrice = (price: number, discount: number, type: string) => {
  let actualPrice;
  if (type === "Fixed") {
    actualPrice = price - discount;
  }
  if (type === "%") {
    actualPrice = (price * (100 - discount)) / 100;
  }
  return actualPrice?.toFixed(2);
};

export default function Vehicle() {
  const language = useLanguage();
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation("rental");
  const { search } = useLocation();
  const params: any = parse(search);
  const [services, setServices] = React.useState<any>([]);
  const [loader, setLoader] = React.useState<boolean>(true);
  const rental = useSelector((state: RootStateOrAny) => state.rental);

  React.useEffect(() => {
    const fetchServices = async () => {
      const response = await getRentalCategoryServices(params["?categoryId"]);
      setServices(response);
      setLoader(false);
    };
    fetchServices();
    // eslint-disable-next-line
  }, []);

  React.useEffect(() => {
    console.log(services)
  })

  React.useEffect(() => {
    if (services && services.length > 0) {
      dispatch({
        type: "RENTAL_BOOKING_TITLE",
        payload: services[0]?.rentalCategoryId?.name,
      });
    }
  }, [dispatch, services]);

  const handleEnquiryClick = () => {
    const url = `/${language}?tab=2`;
    history.push(url);
  };

  // if (services.length === 0) {
  //   return <NoServices>{t("noServices")}</NoServices>;
  //}
  return (
    <div>
      {loader ? (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: 500,
          }}
        >
          <ProgressBar title='loading' />
        </div>
      ) : (!(services.length === 0)) ? (

        <>
          <MainWrapper>
            <Container>
              <div className="header">
                <BackButtonWrapper>
                  <BackButton to={routes.home.url()} />
                </BackButtonWrapper>
              </div>
              <IconWrapper>
                <HeaderText className="title">{rental.title}</HeaderText>
                <EnquiryIcon className="icon" onClick={handleEnquiryClick} />
              </IconWrapper>
              {services.map((service: any) => {
                return (
                  <>
                    <VehicleWrapper key={service?._id}>
                      <div className="vechileOuter">
                        <div className="vehicleContent">
                          <VehicleName>{service.name}</VehicleName>
                          <VehicleInfo>{service.description}</VehicleInfo>
                          <DesktopView>
                            <div className="proceedOuter">
                              <div>
                                <p>
                                  <span>
                                    {service.rate.currency}{" "}
                                    {discountedPrice(
                                      service.rate.amount,
                                      service?.discount?.value,
                                      service?.discount?.discountType,
                                    )}{" "}
                                    /{" "}
                                    <span>
                                      {service.rate.duration.toLowerCase()}
                                    </span>
                                  </span>
                                </p>
                                {service?.discount?.value ? (
                                  <span className="linethrough">
                                    {service.rate.currency}. {service.rate.amount}
                                  </span>
                                ) : (
                                  ""
                                )}
                                {service?.discount?.value ? (
                                  <span className="discount">
                                    {" "}
                                    {service?.discount?.discountType === "Fixed"
                                      ? service?.discount?.value
                                      : `${service?.discount?.value}%`}{" "}
                                    OFF
                                  </span>
                                ) : (
                                  ""
                                )}
                              </div>
                              <ButtonBook
                                onClick={() => {
                                  updateOperatorCode(service?.externalOperatorId);
                                  dispatch({
                                    type: "SET_OPERATOR_CODE",
                                    payload: service?.externalOperatorId,
                                  });
                                  const url = `/${language}${routes.rentalService.url(
                                    {
                                      categoryId: params["?categoryId"],
                                      startDate: params["startDate"],
                                      returnDate: params["returnDate"],
                                      serviceId: service?._id,
                                    },
                                  )}`;
                                  history.push(url);
                                }}
                              >
                                Book Now
                              </ButtonBook>
                            </div>
                          </DesktopView>
                        </div>
                        <div className="vehicleImage">
                          <img src={service.imageUrl || NoImageIcon} alt="" />
                        </div>
                      </div>
                      <MobileWrapper>
                        <div className="proceedOuter">
                          <div>
                            <p>
                              <span>
                                {service.rate.currency}{" "}
                                {discountedPrice(
                                  service.rate.amount,
                                  service?.discount?.value,
                                  service?.discount?.discountType,
                                )}{" "}
                                / <span>{service.rate.duration.toLowerCase()}</span>
                              </span>
                            </p>
                            {service?.discount?.value ? (
                              <span className="linethrough">
                                {service.rate.currency}. {service.rate.amount}
                              </span>
                            ) : (
                              ""
                            )}
                            {service?.discount?.value ? (
                              <span className="discount">
                                {" "}
                                {service?.discount?.discountType === "Fixed"
                                  ? service?.discount?.value
                                  : `${service?.discount?.value}%`}{" "}
                                OFF
                              </span>
                            ) : (
                              ""
                            )}
                          </div>
                          <ButtonBook
                            onClick={() => {
                              const url = `/${language}${routes.rentalService.url({
                                categoryId: params["?categoryId"],
                                startDate: params["startDate"],
                                returnDate: params["returnDate"],
                                serviceId: service?._id,
                              })}`;
                              history.push(url);
                              dispatch({
                                type: "SET_OPERATOR_CODE",
                                payload: service?.externalOperatorId,
                              });
                            }}
                          >
                            Book Now
                          </ButtonBook>
                        </div>
                      </MobileWrapper>
                    </VehicleWrapper>
                  </>
                );
              })}
            </Container>
          </MainWrapper>
        </>
      ) :
        (
          <NoServices>{t("noServices")}</NoServices>
        )
      }
    </div>

  );
}
