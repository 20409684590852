import React from "react";
import { I18nextProvider } from "react-i18next";
import { useLanguage } from "../hooks/useLanguage";
import { getI18n } from "../i18n";

export const I18nProvider: React.FC = ({ children }) => {
  const language = useLanguage();
  const i18n = React.useMemo(() => getI18n(language), [language]);
  return <I18nextProvider i18n={i18n}>{children}</I18nextProvider>;
};
