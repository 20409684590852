import * as Sentry from "@sentry/browser";

export const initializeErrorMonitoring = () => {
  if (process.env.NODE_ENV === "production") {
    Sentry.init({
      dsn: process.env.REACT_APP_SENTRY_DSN,
      environment: process.env.REACT_APP_ENVIRONMENT,
      release: process.env.REACT_APP_COMMIT_SHA,
    });
  }
};
