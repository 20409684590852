import React from "react";
import { useFormContext } from "react-hook-form";
import styled from "styled-components";
import { makeStyles } from "@material-ui/core/styles";
import { TextField } from "modules/ui";
interface Props {
  name: string;
  label?: string;
  requiredErrorMessage?: string;
  validationPattern?: RegExp;
  onChange?: (e: any) => void;
  validationRules?: {
    root: string;
    index: number;
    key: string;
  };
  value?: string;
  smallText?: string;
}

interface Validation {
  required?: string | boolean;
  pattern?: { value: RegExp; message: string };
}

const useClasses = makeStyles({
  root: {
    width: "100%",
  },
});

export const FormInput = (props: Props) => {
  const {
    name,
    label,
    requiredErrorMessage,
    validationPattern,
    validationRules,
    value,
    smallText,
    onChange,
  } = props;
  const { register, errors } = useFormContext();
  const validation: Validation = {
    required: requiredErrorMessage,
  };
  if (validationPattern) {
    validation.pattern = {
      message: "Please, enter valid value",
      value: validationPattern,
    };
  }
  const classes = useClasses();

  const getError = () => {
    if (!validationRules || !errors[validationRules.root]) {
      return !!errors[name];
    } else {
      const { root, index, key } = validationRules;
      return !!errors[root][index] && !!errors[root][index][key];
    }
  };
  const MoreInfo = styled.small`
    font-weight: normal;
    font-size: 10px;
    line-height: 18px;
    color: #828282;
    display: block;
    margin-top: 5px;
    &.errorShow {
      margin-top: 20px;
    }
  `;
  return (
    <div className="form-outer">
      <TextField
        label={label}
        error={getError()}
        inputRef={register(validation)}
        name={name}
        onChange={onChange}
        variant="outlined"
        color="secondary"
        classes={classes}
        value={value}
        helperText={getError() && requiredErrorMessage}
      />
      <MoreInfo className={`${getError() ? "errorShow" : ""}`}>
        {smallText}
      </MoreInfo>
    </div>
  );
};
