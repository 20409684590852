import React from "react";
import { Redirect, routes } from "modules/routing";

export class ErrorBoundary extends React.Component {
  state = { hasError: false };

  static getDerivedStateFromError(error: Error) {
    return { hasError: true };
  }

  render() {
    if (this.state.hasError) {
      return <Redirect to={routes.home.url()} />;
    }

    return this.props.children;
  }
}
