import React from "react";
import styled from "styled-components";
import { useSelector, RootStateOrAny } from "react-redux";
import { useTranslation } from "react-i18next";
import { BenefitsIcon } from "assets/icons";
import TextField from "./Component/TextField";
import RadioField from "./Component/RadioField";
import InfoImg from "../icons/info.svg";
import { getCampaigns } from "modules/api/hooks/campaigns";
import { useDispatch } from "react-redux";
import moment from "moment";
import { validateCoupon } from "modules/api/hooks/campaigns";

const Wrapper = styled.div`
  background: ${(props) => props.theme.custom["white-color"]};
  border: 1px solid ${(props) => props.theme.custom["alto-color"]};
  box-sizing: border-box;
  border-radius: 3px;
  padding: 15px 30px;
  max-width: 680px;
  @media (max-width: ${(props) =>
    props.theme.custom.breakpoints["xs-breakpoint"]}) {
    padding: 15px 16px;
    border: none;
  }
`;

const Section = styled.div`
  display: flex;
  justify-content: space-between;
`;

const ImgAndHeading = styled.div`
  display: flex;
  align-items: center;
`;

const HeadText = styled.p`
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  color: #4f4f4f;
  margin-left: 10px;
`;

const InfoIcon = styled.div`
  display: flex;
`;

const FieldSection = styled.div`
  margin-top: 10px;
`;

const TextMsg = styled.p`
  margin-top: 10px;
  font-weight: 400;
  font-size: 10px;
  color: #787885;
`;

const OffersAndBenefits = ({ rideId, price, noOfPassengers, autoAppliedCoupon, returnRideId }: any) => {
  const { t } = useTranslation("reservation");
  const dispatch = useDispatch();
  const [coupon, setCoupon] = React.useState("");
  const [ErrorCoupon, setErrorCoupon] = React.useState("");
  const [removeCoupon, setRemoveCoupon] = React.useState(false);
  const [errorMsg, setErrorMsg] = React.useState("");
  const [couponData, setCouponData] = React.useState<any>([]);
  let [validCoupon] = React.useState<any>(null);
  const [data, setData] = React.useState<any>(null);
  const [appliedCoupon, setAppliedCoupon] = React.useState(null);
  const hasToken = useSelector((state: RootStateOrAny) => state?.user?.hasToken);

  React.useEffect(() => {
    if (coupon === "") {
      dispatch({
        type: "CAMPAIGN_DETAILS",
        payload: [],
      });
    }
  }, [dispatch, coupon])

  React.useEffect(() => {
    const getAllCouponCodes = async () => {
      const res = await getCampaigns(rideId);
      setData(res.data);
    };
    if (rideId && hasToken) {
      getAllCouponCodes();
    }
  }, [rideId, hasToken]);

  React.useEffect(() => {
    if (data) {
      const tempArray: any = [];
      data.map((offer: any) => {
        const maxDiscount =
          offer.couponData.type === "percentage"
            ? ((offer.couponData.discountValue / 100) * price.value) > offer.couponData.maxDiscount ? offer.couponData.maxDiscount : (offer.couponData.discountValue / 100) * price.value
            : offer.couponData.discountValue;
        const startDate = moment(offer?.couponValidity?.startDate).format(
          "MMM DD",
        );
        const endDate = moment(offer?.couponValidity?.endDate).format("MMM DD");
        const values = {
          code: offer.coupon,
          details: `Save ${price.currency} ${maxDiscount}/- Make bookings between ${startDate} to ${endDate} `,
          discount: Number(maxDiscount),
          limitExhaust: offer?.error?.type === 'usage' ? offer.coupon : '',
        };
        tempArray.push(values);
        if (offer?.error?.type === 'usage') {
          setErrorCoupon(offer.coupon);
        }

        return values;
      });

      setCouponData(tempArray);
      setCoupon(autoAppliedCoupon);

      // dispatch({
      //   type: "CAMPAIGN_DETAILS",
      //   payload: tempArray[0],
      // });

      if (autoAppliedCoupon) {
        const applyCoupon = async (id: any) => {
          try {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            validCoupon = await validateCoupon(autoAppliedCoupon, id, noOfPassengers);
            if (validCoupon.data) {
              setErrorMsg("");
              setRemoveCoupon(true);
              const maxDiscount =
                validCoupon.data.couponData.type === "percentage"
                  ? (validCoupon.data.couponData.discountValue / 100) * price.value
                  : validCoupon.data.couponData.discountValue;
              const values = {
                code: validCoupon.data.coupon,
                discount: Number(maxDiscount),
                details: validCoupon?.data?.discountDetails?.discount,
              };
              const onewayDiscount = validCoupon?.data?.discountDetails?.discount;
              dispatch({
                type: "CAMPAIGN_DETAILS",
                payload: values,
              });
              if (returnRideId) {
                couponApplyReturn(autoAppliedCoupon, returnRideId, noOfPassengers, onewayDiscount);
              }
            } else {
              setErrorMsg(t("validCoupon"));
            }
          } catch (err) {
            setRemoveCoupon(false);
            setErrorCoupon(err.response.data.couponCode);
            setErrorMsg(err.response.data.message);
          }
        };

        applyCoupon(rideId);
      }
    }
  }, [data, dispatch, noOfPassengers, price, rideId]);

  const selectCoupon = (value: any, i: any) => {
    setCoupon(value);
    setRemoveCoupon(false);
    dispatch({
      type: "CAMPAIGN_DETAILS",
      payload: [],
    });
  };

  const onRemoveCoupon = () => {
    setRemoveCoupon(false);
    setAppliedCoupon(null);
    setCoupon("");
    dispatch({
      type: "CAMPAIGN_DETAILS",
      payload: [],
    });
  };

  const onApplyCoupon = async () => {
    if (coupon === "") {
      setErrorMsg(t("enterCoupon"));
    } else {
      // Call Apply Coupon API here
      couponApply(coupon, rideId, noOfPassengers);
    }
  };

  const couponApply = async (coupon: any, rideId: any, noOfPassengers: any) => {
    try {
      validCoupon = await validateCoupon(coupon, rideId, noOfPassengers);
      if (validCoupon.data) {
        setErrorMsg("");
        setRemoveCoupon(true);
        const maxDiscount =
          validCoupon.data.couponData.type === "percentage"
            ? (validCoupon.data.couponData.discountValue / 100) * price.value
            : validCoupon.data.couponData.discountValue;
        const values = {
          code: validCoupon.data.coupon,
          discount: Number(maxDiscount),
          details: validCoupon?.data?.discountDetails?.discount
        };
        const onewayDiscount = validCoupon?.data?.discountDetails?.discount;
        dispatch({
          type: "CAMPAIGN_DETAILS",
          payload: values,
        });

        if (returnRideId) {
          couponApplyReturn(coupon, returnRideId, noOfPassengers, onewayDiscount);
        }
        setAppliedCoupon(validCoupon.data.coupon);
      } else {
        setErrorMsg(t("validCoupon"));
      }
    } catch (err) {
      setRemoveCoupon(false);
      setErrorCoupon(err.response.data.couponCode);
      setErrorMsg(err.response.data.message);
    }
  };

  const couponApplyReturn = async (coupon: any, rideId: any, noOfPassengers: any, onewayDiscount: number) => {
    try {
      validCoupon = await validateCoupon(coupon, rideId, noOfPassengers);
      if (validCoupon.data) {
        setErrorMsg("");
        setRemoveCoupon(true);
        const maxDiscount =
          validCoupon.data.couponData.type === "percentage"
            ? (validCoupon.data.couponData.discountValue / 100) * price.value
            : validCoupon.data.couponData.discountValue;
        const values = {
          code: validCoupon.data.coupon,
          discount: Number(maxDiscount),
          details: validCoupon?.data?.discountDetails?.discount + onewayDiscount
        };
        dispatch({
          type: "CAMPAIGN_DETAILS",
          payload: values,
        });
      } else {
        setErrorMsg(t("validCoupon"));
      }
    } catch (err) {
      setRemoveCoupon(false);
      setErrorCoupon(err.response.data.couponCode);
      setErrorMsg(err.response.data.message);
    }
  };

  const handleChange = (e: any) => {
    const { value } = e.target;
    setErrorMsg("");
    setCoupon(value);
  };

  return (
    <Wrapper>
      <Section>
        <ImgAndHeading>
          <BenefitsIcon />
          <HeadText>{t("offersAndBenefits")}</HeadText>
        </ImgAndHeading>
        <InfoIcon>
          {/* <img src={InfoImg} alt="" /> */}
        </InfoIcon>
      </Section>

      <FieldSection>
        <TextField
          removeCoupon={removeCoupon}
          onRemoveCoupon={onRemoveCoupon}
          onApplyCoupon={onApplyCoupon}
          handleChange={handleChange}
          offerCode={coupon}
          errorMsg={errorMsg}
        />
      </FieldSection>

      {!hasToken ? <TextMsg>{t("loginMessage")}</TextMsg> : <TextMsg>{t("enterCodeText")}</TextMsg>}
      {couponData?.map((data: any, i: any) => (
        <FieldSection key={i}>
          <RadioField
            index={i}
            data={data}
            selectCoupon={selectCoupon}
            coupon={coupon}
            ErrorCoupon={ErrorCoupon}
            autoAppliedCoupon={autoAppliedCoupon}
            appliedCoupon={appliedCoupon}
          />
        </FieldSection>
      ))}
    </Wrapper>
  );
};

export default OffersAndBenefits;
