export type CampaignData = {
  type: "CAMPAIGN_DETAILS";
  payload: any;
};

export type CampaignList = {
  type: "CAMPAIGN_LIST";
  payload: any;
};

export interface BookingState {
  data: any;
}

const initialState = {
  data: [],
};

export const campaignReducer = (
  state: BookingState = initialState,
  action: CampaignData | CampaignList,
) => {
  switch (action.type) {
    case "CAMPAIGN_DETAILS": {
      return { ...state, data: action.payload };
    }

    case "CAMPAIGN_LIST": {
      return { ...state, listData: action.payload };
    }

    default:
      return {
        ...state,
      };
  }
};
