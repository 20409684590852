import { withStyles } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";

export const Placeholder = withStyles({
  root: {
    backgroundColor: "white",
    border: "1px solid #E0E0E0",
    borderRadius: 3,
  },
})(Skeleton);
