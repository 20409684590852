import { Ride } from "modules/entities";

export type SearchOutboundRideAction = {
  type: "SEARCH_OUTBOUND_RIDE";
  payload: any;
};

export type SearchInboundRideAction = {
  type: "SEARCH_INBOUND_RIDE";
  payload: any;
};

export type SetSearchParamsAction = {
  type: "SET_SEARCH_PARAMS";
  payload: any;
};

export type AddRideAction = {
  type: "ADD_RIDE";
  payload: any;
};

export type SetOperatorCodection = {
  type: "SET_OPERATOR_CODE";
  payload: any;
};

export interface BookingState {
  searchParams: {
    arrivalId: string;
    arrivalType: string;
    departureId: string;
    departureType: string;
    date: Date;
    returnDate?: Date;
    passengers: number;
    tabIndex: number;
    arrivalName:string,
    departureName:string,
  };
  outBoundRides: Array<Ride>;
  inBoundRides: Array<Ride>;
  inBoundRide?: Ride | Record<string, unknown>;
  outBoundRide?: Ride | Record<string, unknown>;
}

const initialState = {
  searchParams: {
    arrivalId: "",
    arrivalType: "",
    departureId: "",
    departureType: "",
    date: new Date(),
    returnDate: undefined,
    passengers: 0,
    tabIndex: 0,
    arrivalName:"",
    departureName:"",
  },
  outBoundRides: [],
  inBoundRides: [],
  inboundRide: {},
  outBoundRide: {},
  selectedOperatorCode:window.location.pathname.split("/")[1].toUpperCase(),
};

export const bookingReducer = (
  state: BookingState = initialState,
  action:
    | AddRideAction
    | SearchInboundRideAction
    | SearchOutboundRideAction
    | SetSearchParamsAction
    |SetOperatorCodection,
) => {
  switch (action.type) {
    case "SET_SEARCH_PARAMS": {
      return {
        ...state,
        searchParams: {
          ...action.payload,
        },
      };
    }

    case "SEARCH_INBOUND_RIDE": {
      return { ...action.payload };
    }

    case "SEARCH_OUTBOUND_RIDE": {
      return { ...action.payload };
    }

    case "ADD_RIDE": {
      return { ...action.payload };
    }

    case "SET_OPERATOR_CODE": {
      return {
        ...state,
        selectedOperatorCode: {
          ...action.payload,
        },
      };
    }

    default:
      return {
        ...state,
      };
  }
};
