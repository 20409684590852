import React from "react";
// import clsx from "clsx";
// import classes from "./FooterMenuItem.module.css";
import styled from "styled-components";

interface Props {
  value: string;
  parentClass?: string;
}

const MenuItem = styled.div`
  padding: 6px 5px 5px 5px;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 19px;
  color: ${(props) => props.theme.custom["white-color"]};
  &.footerMenuMarginLeft {
    margin-left: 50px;
  }
  @media (max-width: 768px) {
    &.footerMenuMarginLeft {
      margin-left: 30px;

      &:first-child {
        margin-left: 0px;
      }
    }
  }
  &.footerMenuBold {
    font-weight: 500;
  }
`;

export function FooterMenuItem(props: Props) {
  const { value, parentClass } = props;
  return <MenuItem className={parentClass}>{value}</MenuItem>;
}
