import React from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { enUS, id as ind } from "date-fns/locale";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { format } from "date-fns";
import { Divider } from "@material-ui/core";
import { useTickets } from "modules/api";
import { getBusStop } from "modules/entities/helpers";
import { Ride, Timezone, Ticket, Price } from "modules/entities";
import { CANCELLATION } from "modules/auth/constants";
import { routes } from "modules/routing";
import { useLanguage } from "modules/localization";
import { Duration } from "modules/ui";
import { getDuration } from "modules/utils/getDuration";
import { useModalVisibility } from "modules/utils/hooks/useModalVisibility";
import { featureToggle } from "modules/utils/featureToggle";
import { CancelReservationConfirmation } from "modules/ticket/organisms/CancelReservationConfirmation";
import { MyRideInfo } from "./MyRideInfo";
import { TicketStatusDetails } from "./TicketStatus";
import {IsDefaultOperator} from 'modules/utils/IsDefaultOperator'
import { useDispatch } from "react-redux";
import { updateOperatorCode } from "modules/api/client";
import { useHistory } from "react-router-dom";

const CardWrapper = styled.div`
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  margin-top: 24px;
  padding: 20px;

  @media (max-width: ${(props) =>
      props.theme.custom.breakpoints["xs-breakpoint"]}) {
    flex-wrap: wrap;
    justify-content: center;
  }
`;

const TripDetailsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const TripInfoWrapper = styled.div`
  margin-bottom: 13px;
  display: flex;
  height: 100%;
`;

const ViewDetailsBtn = styled.button`
  width: 100px;
  margin: 10px 0;
  background-color: ${(props) => props.theme.custom.secondary.main};
  color: #fff;
  border-radius: 3px;
  padding: 10px;
  box-sizing: border-box;
  cursor: pointer;
  border: none;
`;

const CancelTripBtn = styled.button`
  width: 100px;
  margin: 10px 0;
  background: #fff;
  color: #ff5757;
  padding: 10px;
  border: 1px solid #ff5757;
  box-sizing: border-box;
  border-radius: 3px;
  cursor: pointer;
`;

const ButtonSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

const PaidSection = styled.p`
  color: #03aa00;
  font-weight: bold;
  text-align: end;
  padding: 5px;
  font-size: 10px;
  &.cancelled {
    color: #ff5757;
  }
  &.refunded_and_ticket_cancelled {
    color: #ff5757;
  }
  &.cancellation_in_process {
    color: #ff5757;
  }
  &.refund_reject_and_ticket_cancelled {
    color: #ff5757;
  }
  &.refund_failed_and_ticket_cancelled {
    color: #ff5757;
  }
`;

// const AmtSection = styled.p`
//   color: #4f4f4f;
//   text-align: end;
//   padding: 5px;
//   font-size: 16px;
//   font-weight: 500;
// `;

const TripDate = styled.p`
  color: #4f4f4f;
  text-align: start;
  margin-bottom: 10px;
  font-size: 16px;
  font-weight: 500;
`;

const TextCommon = styled.p`
  font-family: ${(props) => props.theme.custom["roboto-font"]};
  font-style: normal;
  color: ${(props) => props.theme.custom["emperor-color"]};
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 5px;
  &.textLarge {
    font-size: 18px;
  }
  &.textLight {
    color: ${(props) => props.theme.custom["gray-color"]};
  }
  &.textSmall {
    font-size: 12px;
  }
`;

const OperatorWrapper = styled.p`
  padding-bottom: 15px;
  font-size: 10px;
  .op-name{
    font-weight: 600 !important;
    padding-left:4px;
    font-size: 12px;
    color: #4A4B57;
  }
  `;

type TripCardProps = {
  ride: Ride;
  pickUpStopId: string;
  dropOutStopId: string;
  selected?: boolean;
  onSelect?: () => void;
  timezone?: Timezone;
  createdAt?: string;
  price?: Price;
  status?: string;
  id?: any;
  selectedTicket: Ticket;
  childRef?: any;
  key?: number;
  setCancellation?: (value: boolean) => void;
  setCancelId?: (value: Ticket) => void;
  paymentMethod?: string;
};

export const TicketCard: React.FC<TripCardProps> = ({
  key,
  ride,
  pickUpStopId,
  dropOutStopId,
  timezone,
  createdAt,
  price,
  status,
  id,
  selectedTicket,
  setCancellation,
  setCancelId,
  paymentMethod,
}) => {
  const [showCancelBtn, setShowCancelBtn] = React.useState(true);
  const { t } = useTranslation("myRides");
  const dispatch = useDispatch();
  const language = useLanguage();
  const firebase_app = useSelector(
    (state: any) => state.remoteConfig.firebaseApp,
  );
  const criteria = {
    country: useSelector((state: any) => state.remoteConfig.countryName),
    operator: useSelector((state: any) => state.remoteConfig.operatorId),
  };

  React.useEffect(() => {
    async function getResult() {
      const toggleCancelTicket = await featureToggle(
        CANCELLATION,
        firebase_app,
        criteria,
      );
      setShowCancelBtn(toggleCancelTicket);
    }

    getResult();
  }, [criteria, firebase_app]);

  const history = useHistory();
  const url =
    (id && `/${language}${routes.ticketPreview.url({ ticketId: id })}`) || "";
  const [modalVisibility, setModalVisibility] = useModalVisibility();
  const { data: ticketData } = useTickets(id);
  const currentLocale = language === "en" ? enUS : ind;
  // prettier-ignore
  const pickUpStop = React.useMemo(() => getBusStop(ride, pickUpStopId), [pickUpStopId, ride])
  // prettier-ignore
  const dropOutStop = React.useMemo(() => getBusStop(ride, dropOutStopId), [dropOutStopId, ride])

  const duration = React.useMemo(() => {
    const from = new Date(pickUpStop?.timeOfArrival ?? "");
    const to = new Date(dropOutStop?.timeOfArrival ?? "");
    return getDuration(from, to);
  }, [dropOutStop, pickUpStop]);

  if (!pickUpStop || !dropOutStop) {
    return null;
  }

  const currentTicket = ticketData?.tickets.find((ticket) => ticket.id === id);
  const tickets = ticketData?.tickets.filter((ticket) => {
    if (ticket.rideId === currentTicket?.rideId) {
      return ticket;
    }
  });

  const CancelTrip =() => {
    dispatch({
      type: "SET_OPERATOR_CODE",
      payload: ride?.externalOperatorId,
    });
    updateOperatorCode(ride?.externalOperatorId || null);
    setModalVisibility()
  }

  const viewDetails = () => {
    dispatch({
      type: "SET_OPERATOR_CODE",
      payload: ride?.externalOperatorId,
    });
    updateOperatorCode(ride?.externalOperatorId || null);
    history.push(url);
  }

  return (
    <>
      <CardWrapper key={key}>
        <TripDetailsWrapper>
          <TripDate>
            {createdAt &&
              format(new Date(createdAt), "EEE, d MMM, yyyy", {
                locale: currentLocale,
              })}
          </TripDate>
          {IsDefaultOperator() ?
            <OperatorWrapper>{t('operatedBy')}
            <span className="op-name">{ride?.externalOperatorId || ""}</span>
            </OperatorWrapper>
          : ''}
          <TripInfoWrapper>
          
            <MyRideInfo
              pickUpStop={pickUpStop}
              dropOutStop={dropOutStop}
              timezone={timezone}
              stops={ride?.stops}
              wasManuallyEdited={ride?.wasManuallyEdited}
              disableConversion={ride?.disableConversion}
              ridePatternId={ride?.ridePatternId}
            ></MyRideInfo>
            <ButtonSection>
              {status && (
                <PaidSection className={status}>{t(`${status}`)}</PaidSection>
              )}

              {/* <AmtSection>{t("price", { price })}</AmtSection> */}
              {id && (
                <Link to="#" style={{ textAlign: "right" }} onClick={() => viewDetails()}>
                  <ViewDetailsBtn >{t("viewDetails")}</ViewDetailsBtn>
                </Link>
              )}
              {status &&
              status === TicketStatusDetails.PAID &&
              paymentMethod !== "cash" ? (
                showCancelBtn ? (
                  <CancelTripBtn onClick={() => CancelTrip()}>
                    {t("cancelTrip")}
                  </CancelTripBtn>
                ) : null
              ) : null}
            </ButtonSection>
          </TripInfoWrapper>
          <TextCommon>{t("duration")}:</TextCommon>
          <TextCommon>
            <Duration value={duration} />
          </TextCommon>
          {/* <TripAdditional duration={duration} facilities={undefined} /> */}
        </TripDetailsWrapper>
      </CardWrapper>
      <Divider />

      {modalVisibility && (
        <CancelReservationConfirmation
          open={modalVisibility}
          tickets={tickets}
          toggleModal={setModalVisibility}
          ticket={selectedTicket}
          ride={ride}
          setCancellation={setCancellation}
          setCancelId={setCancelId}
          ticketInfo={ticketData}
        />
      )}
    </>
  );
};
