import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { Ticket } from "modules/entities";
import { Link, routes } from "modules/routing";
import { Button } from "modules/ui";
// import classes from "./BookingButtons.module.css";

type BookingButtonsProps = {
  ticketId: string;
  ticket?: Ticket;
};

const ButtonsContainer = styled.div`
  width: 403px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  @media (max-width: ${(props) =>
      props.theme.custom.breakpoints["xs-breakpoint"]}) {
    justify-content: center;
  }
  .ticket-wrapper {
    display: flex;
    margin-bottom: 20px;
  }
`;

const BookingStyle = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: "3px";
  width: 188px;
  height: 59px;
  margin-right: 5px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
  background: none;
  color: ${(props) => props.theme.custom["emperor-color"]};
  @media (max-width: ${(props) =>
      props.theme.custom.breakpoints["xs-breakpoint"]}) {
    margin-right: 10px;
    width: 144px;
    height: 44px;
  }
`;

const BookingStyleTitle = styled.div`
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  color: #b3b3b3;
  @media (max-width: ${(props) =>
      props.theme.custom.breakpoints["xs-breakpoint"]}) {
    font-size: 10px;
    line-height: 12px;
  }
`;

const BookingStyleNumber = styled.span`
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: ${(props) => props.theme.custom["emperor-color"]};
  @media (max-width: ${(props) =>
      props.theme.custom.breakpoints["xs-breakpoint"]}) {
    flex-direction: column;
  }
`;

const TicketButton = styled(({ ...rest }) => <Button {...rest} />)`
  width: 188px;
  height: 59px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
  color: ${(props) => props.theme.custom.secondary} @media
    (max-width: ${(props) => props.theme.custom.breakpoints["xs-breakpoint"]}) {
    margin-right: 10px;
    width: 144px;
    height: 44px;
  }
`;
// const AddCalender = styled.p`
//   font-weight: 500;
//   font-size: 14px;
//   line-height: 16px;
//   text-align: center;
//   color: ${(props) => props.theme.custom.primary.main};
// `;

export const BookingButtons: React.FC<BookingButtonsProps> = ({
  ticketId,
  ticket,
}) => {
  const { t } = useTranslation("bookingSuccess");
  // const { data: ticketData } = useTicket(ticketId);

  return (
    <>
      {/* <AddCalender>ADD TO CALENDAR</AddCalender> */}
      <ButtonsContainer>
        {/* <Link
          to={routes.ticketPreview.url({
            ticketId,
          })}
          target="_blank"
        >
          <TicketButton color="primary">{t("tickets")}</TicketButton>
        </Link> */}
        {ticket?.id && (
          <div className="ticket-wrapper">
            <BookingStyle>
              <BookingStyleTitle>{t("title")}</BookingStyleTitle>
              <BookingStyleNumber>{ticket?.accessCode}</BookingStyleNumber>
            </BookingStyle>
            <Link
              to={routes.ticketPreview.url({
                ticketId: ticket?.id,
              })}
              target="_blank"
            >
              <TicketButton color="secondary">{t("tickets")}</TicketButton>
            </Link>
          </div>
        )}
        {/* {secondTicket && secondTicket.ticket && (
          <div className="ticket-wrapper">
            <BookingStyle>
              <BookingStyleTitle>{t("title")}</BookingStyleTitle>
              <BookingStyleNumber>
                {secondTicket?.ticket?.accessCode}
              </BookingStyleNumber>
            </BookingStyle>
            <Link
              to={routes.ticketPreview.url({
                ticketId: secondTicket.ticket.id,
              })}
              target="_blank"
            >
              <TicketButton color="primary">{t("tickets")}</TicketButton>
            </Link>
          </div>
        )} */}
      </ButtonsContainer>
    </>
  );
};
