import React from "react";
import { useTranslation } from "react-i18next";
// import clsx from "clsx";
import styled from "styled-components";
import { useMonth } from "@datepicker-react/hooks";
import { Day } from "../Day/Day";
// import classes from "./Month.module.css";]

const MonthWrapper = styled.div`
  &.monthFirst {
    padding: 13px 40px 40px 87px;
    background: ${(props) => props.theme.custom["white-color"]};
    @media (max-width: ${(props) =>
        props.theme.custom.breakpoints["lg-breakpoint"]}) {
      padding: 13px 40px 40px 40px;
    }
    @media (max-width: ${(props) =>
        props.theme.custom.breakpoints["md-breakpoint"]}) {
      padding: 13px 20px 20px 20px;
      width: 100%;
    }
    @media (max-width: ${(props) =>
        props.theme.custom.breakpoints["xs-breakpoint"]}) {
      width: 100%;
    }
  }

  &.monthSecond {
    padding: 13px 87px 40px 40px;
    background: ${(props) => props.theme.custom["white-color"]};
  }
`;

const MonthLabel = styled.div`
  text-align: center;
  margin: 0 0 35px;
  font-family: ${(props) => props.theme.custom["roboto-font"]};
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 18px;
  color: ${(props) => props.theme.custom["mine-shaft-color"]};
`;

const DayLabel = styled.div`
  text-align: center;
  font-family: ${(props) => props.theme.custom["roboto-font"]};
  font-style: normal;
  font-size: 14px;
  line-height: 13px;
  text-transform: uppercase;
  color: ${(props) => props.theme.custom["dove-gray-color"]};
`;

export function Month({ year, month, firstDayOfWeek, index }: any) {
  const { days, weekdayLabels, monthLabel } = useMonth({
    year,
    month,
    firstDayOfWeek,
  });
  const { t } = useTranslation("datepicker");
  const [monthValue, yearValue] = monthLabel.split(" ");

  return (
    <MonthWrapper className={!index ? "monthFirst" : "monthSecond"}>
      <MonthLabel>
        <strong>
          {t(`months.${monthValue}`)} {yearValue}
        </strong>
      </MonthLabel>
      <div
        style={{
          display: "grid",
          gridGap: 4,
          gridTemplateColumns: "repeat(7, 1fr)",
          justifyContent: "center",
          marginBottom: "13px",
          fontSize: "10px",
        }}
      >
        {weekdayLabels.map((dayLabel) => (
          <DayLabel key={dayLabel}>{t(`days.${dayLabel}`)}</DayLabel>
        ))}
      </div>
      <div
        style={{
          display: "grid",
          gridGap: 4,
          gridTemplateColumns: "repeat(7, 1fr)",
          justifyContent: "center",
        }}
      >
        {days.map((day, index) => {
          if (typeof day === "object") {
            return (
              <Day
                date={day.date}
                key={day.date.toString()}
                dayLabel={day.dayLabel}
              />
            );
          }

          return <div key={index} />;
        })}
      </div>
    </MonthWrapper>
  );
}
