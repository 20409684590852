import baseAxios from "axios";
import { Notifications } from "modules/notifications";
import { getOperatorCode } from "modules/utils/getOperatorCode";

const SERVER_URL = process.env.REACT_APP_SERVER_PATH;

// const API_URL = "https://purple-sheep-85.loca.lt" + "/api";
const API_URL = SERVER_URL + "/api";
const ONBOARDING_API_URL = SERVER_URL + "/onboarding/api";
const PAYMENT_COLLECTION_API_URL = SERVER_URL + "/paymentcollection/api";
// const NGROCK_URL =
//   "https://b08a-2401-4900-4639-53de-3488-7d47-63d2-c743.ngrok.io/paymentcollection/api";

export const axios = baseAxios.create({
  baseURL: API_URL,
});

export const onboardingAxios = baseAxios.create({
  baseURL: ONBOARDING_API_URL,
});

export const paymentCollectionAxios = baseAxios.create({
  baseURL: PAYMENT_COLLECTION_API_URL,
});

export const axiosWithoutAuth = baseAxios.create({
  baseURL: API_URL,
});

export const campaignAxios = baseAxios.create({
  baseURL: API_URL,
	headers: {
		operatorcode: getOperatorCode()
	}
});

// export const paymentCollectionAxiosNgrock = baseAxios.create({
//   baseURL: NGROCK_URL,
// });

const isNetworkError = (error: any) => {
  return error.isAxiosError && error.response === undefined;
};

axios.interceptors.response.use(undefined, (error) => {
  if (isNetworkError(error)) {
    Notifications.enqueueSnackbar("Network error", {
      preventDuplicate: true,
    });
  }

  return Promise.reject(error);
});

export const updateClientToken = (token: string | null) => {
  if (token === null) {
    delete axios.defaults.headers.common["Authorization"];
  } else {
    axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  }
};

export const updateOperatorCode = (operatorCode: string | null) => {
  if (operatorCode === null) {
    delete axios.defaults.headers.operatorcode;
    delete axiosWithoutAuth.defaults.headers.operatorcode;
  } else {
    axios.defaults.headers.operatorcode = operatorCode;
    axiosWithoutAuth.defaults.headers.operatorcode = operatorCode;
  }
}; 

if (process.env.NODE_ENV !== "production") {
  // @ts-ignore
  window.axios = axios;
}
