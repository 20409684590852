import useSWR from "swr";
import { ApplyVoucherResult } from "modules/entities";
import { ENDPOINTS } from "../endpoints";
import { axios } from "../client";

const fetcher = (
  url: string,
  rideId: string,
  voucherCode: string | null,
  countPassengers: number
) => {
  return axios
    .post(url, {
      rideId,
      voucherCode,
      countPassengers,
    })
    .then((res) => res.data)
    .catch((err) => {
      throw err.response.data;
    });
};

export const useApplyVocher = (
  rideId: string,
  voucher: string | null,
  passengersCount: number
) => {
  return useSWR<ApplyVoucherResult>(
    voucher
      ? [ENDPOINTS.VERIFY_VOUCHER, rideId, voucher, passengersCount]
      : null,
    fetcher,
    {
      revalidateOnFocus: false,
      shouldRetryOnError: false,
    }
  );
};
