import * as React from "react";

const BACKSPACE_KEY = 8;

type OtpInputValues = {
  length: number;
  onChange: (value: string) => void;
  value: string;
};

export const useOtpInput = ({
  length,
  onChange: handleChange,
  value,
}: OtpInputValues) => {
  const [values, setValues] = React.useState<Array<string>>(() =>
    Array(length)
      .fill("")
      .map((v, index) => value.charAt(index))
  );

  const refs = React.useRef<Array<HTMLInputElement>>([]);

  const focusPrevious = React.useCallback((index: number) => {
    const prev = Math.max(index - 1, 0);
    if (refs.current[prev]) {
      refs.current[prev].focus();
    }
  }, []);

  const focusNext = React.useCallback(
    (index: number, charsAdded: number) => {
      const next = Math.min(index + charsAdded, length - 1);
      if (refs.current[next]) {
        refs.current[next].focus();
      }
    },
    [length]
  );

  const onChange = (cursor: number) => (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const str = event.target.value;

    if (str) {
      const nextValue = [
        ...values.slice(0, cursor),
        ...values.slice(cursor, values.length).map((_, index) => {
          return str[index] || values[index + cursor];
        }),
      ];
      setValues(nextValue);
      focusNext(cursor, str.length);
      handleChange(nextValue.join(""));
    } else {
      const nextValue = [...values];
      nextValue[cursor] = "";
      setValues(nextValue);
      handleChange(nextValue.join(""));
    }
  };

  const register = (index: number) => (ref: HTMLInputElement) => {
    refs.current[index] = ref;
  };

  const onKeyUp = (cursor: number) => (
    event: React.KeyboardEvent<HTMLInputElement>
  ) => {
    if (event.keyCode === BACKSPACE_KEY) {
      focusPrevious(cursor);
    }
  };

  const onFocus = React.useCallback(
    (event: React.FocusEvent<HTMLInputElement>) => {
      event.target.select();
    },
    []
  );
  return {
    onFocus,
    onChange,
    onKeyUp,
    register,
    values,
  };
};
