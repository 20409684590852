import { Price } from "modules/entities";
import { axios, axiosWithoutAuth } from "../client";
import { ENDPOINTS } from "../endpoints";

type BookingParams = {
  rentalServiceId: string;
  name: string;
  phone: string;
  email: string;
  departureDate: string;
  returnDate: string;
  address: string;
  price: Price;
  status: string;
  discount: {
    amount: number;
    value: number;
    type: string;
    currency: string;
  } | null;
};

export async function getRentalServices() {
  return axios
    .get<any>(`${ENDPOINTS.RENTAL_SERVICE}/services/active`)
    .then((res) => {
      return res.data;
    })
    .catch((error) => error);
}

export async function getRentalServiceCategories() {
  return axios
    .get<any>(`${ENDPOINTS.RENTAL_CATEGORIES}/active`)
    .then((res) => {
      return res.data;
    })
    .catch((error) => error);
}

export async function postRentalQuery(data: any) {
  return axios
    .post<any>(`${ENDPOINTS.RENTAL_SERVICE}/enquiries`, { ...data })
    .then((res) => {
      return res.data;
    })
    .catch((error) => error);
}

export async function getRentalCategoryServices(id: string) {
  return axiosWithoutAuth
    .get<any>(`${ENDPOINTS.RENTAL_CATEGORIES}/${id}/services`)
    .then((res) => {
      return res.data;
    })
    .catch((error) => error);
}

export async function getRentalService(id: string) {
  return axiosWithoutAuth
    .get<any>(`${ENDPOINTS.RENTAL_SERVICE}/services/${id}`)
    .then((res) => {
      return res.data;
    })
    .catch((error) => error);
}

export const createRentalBooking = ({
  rentalServiceId,
  name,
  phone,
  email,
  departureDate,
  returnDate,
  address,
  price,
  status,
  discount,
}: BookingParams) => {
  const body = {
    rentalServiceId,
    name,
    phone,
    email,
    departureDate,
    returnDate,
    address,
    price,
    status,
    discount,
  };
  return axios.post<any>(ENDPOINTS.RENTAL_BOOKING, body).then((r) => r.data);
};
