import useSWR, { ConfigInterface } from "swr";
import { City } from "modules/entities";
import { ENDPOINTS } from "../endpoints";

export const useCity = (id: string, config?: ConfigInterface<City, any>) => {
  const { data, error, isValidating } = useSWR<City>(
    `${ENDPOINTS.CITIES}${id}`,
    config
  );
  return { data, error, isValidating };
};
