import React from "react";
import { useTranslation } from "react-i18next";
// import clsx from "clsx";
import styled from "styled-components";
import { Divider } from "@material-ui/core";
import { Price, ApplyVoucherResult } from "modules/entities";
import { useSelector, RootStateOrAny } from "react-redux";
// import { ReactComponent as Loyalty } from "../../icons/loyalty.svg";
// import RightArrow from "../../icons/rightArrow.svg";
// import classes from "./PriceBlock.module.css";

const PriceWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  &.marginTop {
    margin-top: 20px;
    @media (max-width: ${(props) =>
        props.theme.custom.breakpoints["xs-breakpoint"]}) {
      margin-top: -12px;
    }
  }
  &.totalWrapper {
    margin-top: 15px;
    margin-bottom: 13px;
  }
`;

const PriceTitle = styled.p`
  margin: 0;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: ${(props) => props.theme.custom["emperor-color"]};
`;

const PriceValue = styled.p`
  margin: 0;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  color: ${(props) => props.theme.custom["emperor-color"]};
`;

const SimpleTitle = styled.p`
  margin: 0;
  font-size: 18px;
  color: ${(props) => props.theme.custom["emperor-color"]};
`;

const TotalTitle = styled.span`
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.2em;
  text-transform: uppercase;
  color: ${(props) => props.theme.custom["emperor-color"]};
`;

const BreakUpSec = styled.div`
  margin-top: 20px;

  .fare-head {
    font-weight: 600;
    font-size: 16px;
    line-height: 32px;
    color: #4f4f4f;
    margin-bottom: 20px;
    text-transform: uppercase;
  }
`;

const OnwardSec = styled.div`
  display: flex;
  justify-content: space-between;

  .onward-head {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #4a4b57;
  }

  .onward-value {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #4a4b57;
  }
`;

const DiscountSec = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 10px;

  .discount-head {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #43a047;
  }

  .discount-value {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #43a047;
  }
`;

const TotalSec = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 10px 0px;

  .total-head {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #4a4b57;
  }

  .total-value {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #4a4b57;
  }
`;

// const PointsWrapper = styled.div`
//   display: flex;
//   justify-content: space-between;
//   width: 86%;
//   align-items: center;
//   margin: 10px 0;
//   input[type="checkbox"] {
//     width: 18px;
//     height: 18px;
//   }
//   p {
//     font-weight: normal;
//     font-size: 16px;
//     line-height: 19px;
//     color: #37474f;
//   }
// `;

// const Voucher = styled.div`
//   display: flex;
//   align-items: center;
//   margin-top: 20px;
//   p {
//     display: inline-block;
//     font-weight: 500;
//     font-size: 14px;
//     line-height: 16px;
//     color: ${(props) => props.theme.custom.primary.main};
//     margin-right: 15px;
//   }
// `;
type Props = {
  price: Price;
  returnPrice?: Price | null;
  voucherData?: ApplyVoucherResult;
};

export const PriceBlock: React.FC<Props> = ({
  price,
  returnPrice,
  voucherData,
}) => {
  const { t } = useTranslation("reservation");
  const campaign = useSelector(
    (state: RootStateOrAny) => state?.campaign?.data,
  );
  const diff = voucherData
    ? Math.max(0, voucherData.oldPriceValue - voucherData.newPriceValue)
    : 0;
  const priceVoucher = {
    value: diff,
    currency: voucherData?.currency ?? "",
  };
  const priceTotal = {
    value: price.value,
    currency: price.currency,
  };
  if (returnPrice) {
    priceTotal.value += returnPrice.value;
  }

  let priceWithDiscount;
  if (campaign?.discount) {
    priceWithDiscount = {
      value: price.value - campaign?.details,
      currency: price.currency,
    };
    if (returnPrice) {
      priceWithDiscount.value += returnPrice.value;
    }
  } else {
    priceWithDiscount = {
      value: price.value,
      currency: price.currency,
    };
    if (returnPrice) {
      priceWithDiscount.value += returnPrice.value;
    }
  }

  const discountAmt = {
    value: campaign?.details,
    currency: price.currency,
  };

  return (
    <>
      {/* <PriceWrapper className="marginTop">
        <PriceTitle></PriceTitle>
        <PriceValue>{t("price", { price })}</PriceValue>
      </PriceWrapper> */}
      {voucherData && (
        <PriceWrapper>
          <PriceTitle>{t("voucher")}</PriceTitle>
          <PriceValue>- {t("price", { price: priceVoucher })}</PriceValue>
        </PriceWrapper>
      )}
      <Divider />
      <PriceWrapper className="totalWrapper">
        <SimpleTitle>
          <TotalTitle>{t("totalTitle")}</TotalTitle> {t("taxIncluded")}
        </SimpleTitle>
        <PriceValue>{t("price", { price: priceTotal })}</PriceValue>
      </PriceWrapper>

      <BreakUpSec>
        <p className="fare-head">{t("fareBreakup")}</p>
        <OnwardSec>
          <p className="onward-head">{t("onwardFare")}</p>
          <p className="onward-value">{t("price", { price: priceTotal })}</p>
        </OnwardSec>
        {campaign?.code ? (
          <DiscountSec>
            <p className="discount-head">
              {`'${campaign?.code}'`} {t("offerDiscount")}
            </p>
            <p className="discount-value">
              - {t("price", { price: discountAmt })}
            </p>
          </DiscountSec>
        ) : null}

        <Divider />
        <TotalSec>
          <p className="total-head">{t("totalPayable")}</p>
          <p className="total-value">
            {t("price", { price: priceWithDiscount })}
          </p>
        </TotalSec>
      </BreakUpSec>

      {/* <PointsWrapper>
        <input type="checkbox" />
        <Loyalty />
        <p>
          {t("redeem")} <b>10.00</b> {t("bussrPoints")}
        </p>
      </PointsWrapper> */}
      <Divider />
      {/* <Voucher>
        <p>{t("addVoucher")}</p>
        <img src={RightArrow} alt="" />
      </Voucher> */}
    </>
  );
};
