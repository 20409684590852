import React from 'react';
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import Carousel from 'react-elastic-carousel';
import { Notifications } from "modules/notifications";
import offerBanner1 from 'assets/images/offerBanner1.png';
import offerBanner2 from 'assets/images/offerBanner2.png';
// import offerBanner3 from 'assets/images/offerBanner3.png';
import useWindowDimensions from '../utils/hooks/windowDimensions';
import { useHistory } from 'react-router-dom';
import { CopyIcon } from "assets/icons";
import ViewDetailsPopup from './ViewDetails';
import { campaignList } from 'modules/api/methods/campaign';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';

const Container = styled.div`
  margin: 50px 120px;

  @media (max-width: ${(props) =>
    props.theme.custom.breakpoints["md-breakpoint"]}) {
    margin: 20px;
  }
`;

const HeadSection = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Headings = styled.p`
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  color: #000;
`;

const ViewAll = styled.p`
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  color: #2979FF;
  cursor: pointer
`;

const SwiperSec = styled.div`
  margin: -5%;
  margin-top: 10px;

  .carousel {
    .sc-hjriPb {
      box-shadow: none;
      font-size: 1em;
      width: 40px;
      min-width: 40px;
      height: 40px;
      line-height: 0px;
    }

    .sc-hjriPb:hover {
      background-color: rgba(103,58,183,0.1);
    }
    .sc-hjriPb {
      font-size: 1em;
      box-shadow: none;
      width: 40px;
      min-width: 40px;
      height: 40px;
      line-height: 0px;
    }

    .sc-hjriPb:hover {
      background-color: rgba(103,58,183,0.1);
    }
  }
`;

const Slider = styled.div`
  box-shadow: 0px 12px 24px rgba(58, 58, 68, 0.08), 0px 24px 48px rgba(90, 91, 106, 0.08);
  border-radius: 8px;
  margin: 30px 15px;
  cursor: pointer;
  .slider__sec {
    padding: 20px;
  }

  .slider__title {
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    color: 292A31;
  }

  .slider__subTitle {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #292A31;
    padding: 5px 0px;
  }

  .border {
    border-top: 1px solid #E1E1E3;
  }

  .slider__codeSec {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;

    .slider__code {
      display: flex;
      align-items: center;
      background: #787885;
      border-radius: 4px;
      padding: 5px 8px;
      color: #fff;
      font-weight: 400;
      font-size: 14px;
      line-height: 18px;

      span {
        margin-right: 10px;
      }
    }

    .slider__details {
      font-weight: 600;
      font-size: 14px;
      line-height: 16px;
      color: #2979FF;
      text-transform: uppercase;
      text-align: center;
    }
  }

`;

const breakPoints = [
  {
    width: 1, itemsToShow: 1
  },
  {
    width: 550, itemsToShow: 2
  },
  {
    width: 768, itemsToShow: 3
  },
];

const OffersSliders = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation("offer");
  const currency = useSelector<any>(val => val?.region?.currency);
  const { width } = useWindowDimensions();
  const [swipeArrow, setSwipeArrow] = React.useState(width && width > 1024 ? true : false);
  const [pagination, setPagination] = React.useState(width && width < 1024 ? true : false);
  const [showDetails, setShowDetails] = React.useState(false);
  const [campaignData, setCampaignData] = React.useState([]);
  const [viewInfoData, setViewInfoData] = React.useState([]);
  const [currentIndex, setCurrentIndex] = React.useState(0);
  const [showViewAll, setShowViewAll] = React.useState(false);

  React.useEffect(() => {
    if (width && width > 1024) {
      setSwipeArrow(true);
      setPagination(false);
    }
    if (width && width < 1024) {
      setPagination(true);
      setSwipeArrow(false);
    }

    if (campaignData.length <= 3) {
        setSwipeArrow(false);
      }

      if (campaignData.length >= 3) {
        setShowViewAll(true);
      }
  }, [width, campaignData])

  React.useEffect(() => {
    const getCampaign = async () => {
      const res = await campaignList();
      const tempArray: any = [];
      const popupData: any = [];
      res?.map((data: any, index: number) => {
        if (data?.status === 'Active') {
          const travelFrom = moment(data?.travelDates?.startDate).format('MMM DD');
          const travelTo = moment(data?.travelDates?.endDate).format('MMM DD');
          const couponValidFrom = moment(data?.couponValidity?.startDate).format('MMM DD');
          const couponValidTo = moment(data?.couponValidity?.endDate).format('MMM DD');
          const validTill = moment(data?.couponValidity?.endDate).format('DD MMM YYYY');
          const tempData = {
            banner: index % 2 === 0 ? offerBanner1 : offerBanner2,
            title: `${t('flat')} ${currency} ${data?.couponData?.maxDiscount} ${t('off')}`,
            subText: `${t('ifTravelling')} ${travelFrom} ${t('to')} ${travelTo}`,
            bookingText: `${t('makeBooking')} ${couponValidFrom} ${t('to')} ${couponValidTo}`,
            code: data?.coupon,
          }
          tempArray.push(tempData);

          const infoData = {
            title: `${t('flat')} ${currency} ${data?.couponData?.maxDiscount} ${t('off')}`,
            amount: `${currency} ${data?.couponData?.maxDiscount}`,
            code: data?.coupon,
            valid: validTill,
            discount: data?.couponData?.type === 'percentage' ? `${data?.couponData?.discountValue}%` : `${currency} ${data?.couponData?.discountValue}`
          }
          popupData.push(infoData);
        }
      });
      setCampaignData(tempArray);
      setViewInfoData(popupData);
      const campaignValues = {
        listData: tempArray,
        popupData: popupData
      }
      dispatch({ type: 'CAMPAIGN_LIST', payload: campaignValues })
    }
    getCampaign();
  }, [currency, t, dispatch])

  const toggleModal = (index: number) => {
    setShowDetails(!showDetails);
    setCurrentIndex(index);
  }

  const copyCouponCode = (code: string) => {
    navigator.clipboard.writeText(code);
    Notifications.enqueueSnackbar(`${t('coupon')} '${code}' ${t('copied')}`, {
      preventDuplicate: true,
    });
  }

  return (
    <>
      {campaignData?.length > 0 ? (
        <Container>
          <HeadSection>
            <Headings>
              {t("offers")}
            </Headings>
            {showViewAll ? (
              <ViewAll onClick={() => history.push('/en/offers')}>
                {t("viewAll")}
              </ViewAll>
            ) : null}
          </HeadSection>

          <SwiperSec>
            <Carousel itemPosition={'CENTER'} showArrows={swipeArrow} pagination={pagination} className="carousel" isRTL={false} breakPoints={breakPoints} showEmptySlots={false}>
              {campaignData?.map((item: any, index) => (
                <Slider key={index}>
                  <img src={item.banner} alt="" />
                  <div className="slider__sec">
                    <p className="slider__title">{item.title}</p>
                    <p className="slider__subTitle">{item.subText}</p>
                    <p className="slider__subTitle border">{item.bookingText}</p>

                    <div className="slider__codeSec">
                      <p className="slider__code" onClick={() => copyCouponCode(item.code)}>
                        <span>{t("useCode")} '{item.code}'</span><CopyIcon />
                      </p>
                      <p className="slider__details" onClick={() => toggleModal(index)}>{t("viewDetails")}</p>
                    </div>
                  </div>
                </Slider>
              ))}
            </Carousel>
          </SwiperSec>
          <ViewDetailsPopup onCloseModal={() => toggleModal(currentIndex)} isOpen={showDetails} viewInfoData={viewInfoData[currentIndex]} />
        </Container>
      ) : null}
    </>
  )
}

export default OffersSliders;
