import * as React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import {
  Dialog,
  DialogContent,
  useMediaQuery,
  useTheme,
  withStyles,
} from "@material-ui/core";
import { Link } from "modules/routing";
import { Button } from "modules/ui";
import { ReactComponent as NoSeatsIcon } from "./no-seats.svg";
// import classes from "./GetCheckoutFailed.module.css";

type GetCheckoutFailedDialogProps = {
  open: boolean;
  onClose: () => void;
  backTo: string;
};

const LinkWrapper = styled(({ ...rest }) => <Link {...rest} />)`
  text-decoration: none;
  display: block;
  width: 100%;
`;

const Title = styled.p`
  font-weight: 900;
  font-size: 24px;
  line-height: 28px;
  margin: 0;
  text-align: center;
  color: ${(props) => props.theme.custom["emperor-color"]};
`;

const Description = styled.h1`
  line-height: 21px;
  font-size: 18px;
  margin: 0;
  text-align: center;
  color: ${(props) => props.theme.custom["emperor-color"]};
`;

const StyledContent = withStyles((theme) => ({
  root: {
    padding: "20px 14px 10px 14px !important",
    display: "grid",
    placeItems: "center",
    rowGap: "40px",
    gridTemplateRows: "repeat(auto-fit, minmax(0, max-content))",
    [theme.breakpoints.up("sm")]: {
      padding: "30px 55px 55px 55px !important",
      maxWidth: 530,
      boxSizing: "border-box",
    },
  },
}))(DialogContent);

export const GetCheckoutFailedDialog: React.FC<GetCheckoutFailedDialogProps> = ({
  open,
  onClose,
  backTo,
}) => {
  const theme = useTheme();
  const { push } = useHistory();
  const fullScreen = useMediaQuery(theme.breakpoints.down("xs"));
  const { t } = useTranslation("selectPayment");

  return (
    <Dialog fullScreen={fullScreen} open={open} onClose={() => push(backTo)}>
      <StyledContent>
        <NoSeatsIcon />
        <Title>{t("errors.noSeats.title")}</Title>
        <Description>{t("errors.noSeats.description")}</Description>
        <LinkWrapper to={backTo}>
          <Button fullWidth color="secondary">
            {t("errors.noSeats.continue")}
          </Button>
        </LinkWrapper>
      </StyledContent>
    </Dialog>
  );
};
