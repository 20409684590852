import React from "react";

interface Icon {
  color?: string;
  style?: any;
}

export const Revisit = (props: Icon) => {
  return (
    <svg
      {...props}
      width="21"
      height="15"
      viewBox="0 0 21 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.5659 10.8682L3.13085 10.8682L5.675 13.4124C6.03314 13.7705 6.03314 14.3511 5.675 14.7092C5.31676 15.0674 4.73631 15.0673 4.37807 14.7092L0.268603 10.5995C-0.0895348 10.2414 -0.0895349 9.66084 0.268603 9.3027L4.37807 5.19324C4.55718 5.01412 4.79183 4.92466 5.02648 4.92466C5.26113 4.92466 5.49578 5.01422 5.6749 5.19324C6.03304 5.55138 6.03304 6.13194 5.6749 6.49007L3.13075 9.03423L15.5658 9.03423C17.5509 9.03423 19.1659 7.41919 19.1659 5.4341C19.1659 3.44901 17.5509 1.83398 15.5658 1.83398L8.00142 1.83398C7.49493 1.83398 7.08442 1.42347 7.08442 0.916987C7.08442 0.4105 7.49493 -8.28462e-06 8.00142 -8.3289e-06L15.5658 -8.9902e-06C18.5621 -9.25215e-06 20.9999 2.43777 20.9999 5.4341C20.9999 8.43044 18.5622 10.8682 15.5659 10.8682Z"
        fill={props.color || "#03AA00"}
      />
    </svg>
  );
};
