import * as React from "react";
import styled from "styled-components";
// import classes from "./Row.module.css";

export const Row: React.FC<{ as?: React.ComponentType<any> | string }> = ({
  children,
  as: Component = "p",
}) => {
  const Wrapper = styled(({ ...rest }) => <Component {...rest} />)`
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
  `;
  return <Wrapper>{children}</Wrapper>;
};
