import React from "react";
import styled from "styled-components";
import { Dialog } from "@material-ui/core";
import { OtpVerification } from "modules/auth";
// import classes from "./OtpModal.module.css";

const DialogContainer = styled(Dialog)`
  @media (max-width: ${(props) =>
      props.theme.custom.breakpoints["xs-breakpoint"]}) {
    margin: 0 15px;
    .MuiDialog-paper {
      width: 100%;
      max-width: 100%;
      margin: 0;
    }
  }
`;

const Wrapper = styled.div`
  padding: 50px;
  @media (max-width: ${(props) =>
      props.theme.custom.breakpoints["xs-breakpoint"]}) {
    padding: 30px 27px;
  }
`;

type OtpModalProps = {
  open: boolean;
  onClose: () => void;
  phone: string;
  verificationId: string;
};

export const OtpModal: React.FC<OtpModalProps> = ({
  open,
  onClose,
  phone,
  verificationId,
}) => {
  // const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <DialogContainer
      // fullScreen={fullScreen}
      open={open}
      onClose={onClose}
      scroll="body"
    >
      <Wrapper>
        <OtpVerification
          onContinue={onClose}
          verificationId={verificationId}
          phone={phone}
        />
      </Wrapper>
    </DialogContainer>
  );
};
