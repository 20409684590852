export enum PaymentTypes {
  dana = "dana",
  ovo = "e_wallet",
  va = "xendit_va",
  retail = "xendit_retail_outlet",
  rapyd = "rapyd_checkout",
  none = "null",
  card = "credit_card",
  cash = "cash"
}

export enum PaymentMethodCodes {
  ovo = "OVO",
  dana = "DANA",
  mandiri = "MANDIRI",
  bni = "BNI",
  bri = "BRI",
  permata = "PERMATA",
  bca = "BCA",
  alfamart = "ALFAMART",
  other = "OTHER",
  ewallet = "E WALLET",
  cash = "CASH",
  bank_redirect = "NET BANKING",
  card = "DEBIT/CREDIT CARD",
  none = "null",
}

export interface PaymentMethod {
  name: string;
  code: string;
  type: PaymentTypes;
  minAmount: number;
  icon?: string;
}

export const RapydMethods = [
  {
    name: "E WALLET",
    code: "ewallet",
    type: PaymentTypes.rapyd,
    minAmount: 0,
    icon: "",
  },
  {
    name: "CASH",
    code: "cash",
    type: PaymentTypes.rapyd,
    minAmount: 0,
    icon: "",
  },
  {
    name: "NET BANKING",
    code: "bank_redirect",
    type: PaymentTypes.rapyd,
    minAmount: 0,
    icon: "",
  },
  {
    name: "DEBIT/CREDIT CARD",
    code: "card",
    type: PaymentTypes.rapyd,
    minAmount: 0,
    icon: "",
  },
];
