import { ConfigInterface } from "swr";
import { AxiosError } from "axios";
import { axios } from "./client";

const fetcher = (url: string) => {
  return axios
    .get(url)
    .then((res) => res.data)
    .catch((error: AxiosError) => {
      return Promise.reject(error.response);
    });
};

export const config: ConfigInterface = {
  fetcher,
};
