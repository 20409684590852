import React from "react";
import { useTranslation } from "react-i18next";
import { FormControlLabel, Typography } from "@material-ui/core";
import { Link, routes } from "modules/routing";
import { Checkbox } from "modules/ui";
import styled from "styled-components";

type TermsConditionsCheckboxProps = {
  confirmed: boolean;
  setConfirmed: (value: boolean) => void;
};

const TermsWrapper = styled.div`
  @media (max-width: 1024px) {
    margin-left:10px;
  }
`;

export const TermsConditionsCheckbox: React.FC<TermsConditionsCheckboxProps> =
  ({ confirmed, setConfirmed }) => {
    const { t } = useTranslation("selectPayment");

    return (
      <TermsWrapper>
        <FormControlLabel
          control={
            <Checkbox
              checked={confirmed}
              onChange={(e, v) => setConfirmed(v)}
              name="termsConfirmed"
            />
          }
          label={
            <Typography variant="body2" color="textPrimary">
              {t("terms.iAgreeTo")}{" "}
              <Link
                to={routes.termsNConditions.url()}
                target={"_blank"}
                rel="noopener noreferrer"
                onClick={() => localStorage.setItem('USE_TERMS_OPERATOR','true') }
              >
                {t("terms.tc")}
              </Link>{" "}
              {t("terms.and")}{" "}
              <Link to={routes.privacypolicy.url()} target={"_blank"}>
                {t("terms.privacyPolicy")} 
              </Link>
            </Typography>
          }
        />
      </TermsWrapper>
      
    );
  };
