import React from "react";
import styled from "styled-components";
import { Link as BaseLink, LinkProps } from "react-router-dom";
import { useLanguage } from "modules/localization";
import { trimSlash } from "../utils/trimSlash";

const StyledLink = styled(BaseLink)`
  text-decoration: none;

  &:focus,
  &:hover,
  &:visited,
  &:link,
  &:active {
    text-decoration: none;
  }
`;

export const Link: React.FC<LinkProps & { to: string }> = (props) => {
  const language = useLanguage();
  const to = `/${language}/${trimSlash(props.to)}`;
  return <StyledLink {...props} to={to} />;
};
