import { combineReducers } from "redux";
import { userReducer } from "./userReducer";
import { regionReducer } from "./regionReducer";
import { bookingReducer } from "./bookingReducer";
import { rentalBookingReducer } from "./rentalReducer";
import { RemoteConfigReducer } from "./featureTogglingReducer";
import { campaignReducer } from "./campaignReducer";

export default combineReducers({
  user: userReducer,
  region: regionReducer,
  booking: bookingReducer,
  rental: rentalBookingReducer,
  remoteConfig: RemoteConfigReducer,
  campaign: campaignReducer,
});
