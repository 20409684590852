import React from "react";
import styled from "styled-components";
// import classes from "./Label.module.css";

interface Props {
  number: number;
  label: string;
}

const LabelBlock = styled.div`
  display: flex;
  align-items: center;
`;

const LabelNumber = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  color: ${(props) => props.theme.custom.primary.contrastText};
  width: 30px;
  height: 30px;
  background: ${(props) => props.theme.custom["gray-color"]};
  border-radius: 5px;
`;

const LabelText = styled.div`
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 23px;
  padding-left: 10px;
  color: ${(props) => props.theme.custom["emperor-color"]};
`;

export const Label = (props: Props) => {
  const { number, label } = props;
  return (
    <LabelBlock>
      <LabelNumber>{number}</LabelNumber>
      <LabelText>{label}</LabelText>
    </LabelBlock>
  );
};
