import React from "react";
import useSWR from "swr";
import { stringify } from "query-string";
import { SearchResult } from "modules/entities";
import { SearchParams } from "modules/routing/query-types";
import { ENDPOINTS } from "../endpoints";
import { axios } from "modules/api/client";
import {getDefaultOperatorCode} from 'modules/utils/getDefaultOperator'
// import { TabContext } from "modules/search-result-list/pages/SearchResultPage";


type SearchResultResponse = {
  results: Array<SearchResult>;
  status: string; 
}; 

const createUseSearch = (searchUrl: string) => (params: SearchParams) => {
  
  const urlParams = new URLSearchParams(window.location.search);
  const tabIndex = urlParams.get("tabIndex");
  let queryString;

  if (tabIndex === "1") {
    queryString = stringify({
      fromId: params.arrivalId,
      toId: params.departureId,
      fromType: params.arrivalType,
      toType: params.departureType,
      countPassengers: params.passengers,
      date: params.returnDate ? new Date(params.returnDate).toISOString() : "",
      from:params.departureName,
      to:params.arrivalName,
    });
  } else {
    queryString = stringify({
      fromId: params.departureId,
      toId: params.arrivalId,
      fromType: params.departureType,
      toType: params.arrivalType,
      countPassengers: params.passengers,
      date: params.date.toISOString(),
      from:params.arrivalName,
      to:params.departureName, 
    });
  }
  // const queryString = stringify(queryObj);
  const url = `${searchUrl}?${queryString}`;
  const fetcher = (url:any) => {
    return axios
        .get(url, { 
            headers: { 
              operatorcode: getDefaultOperatorCode()
            }
        })
        .then((res) => res.data);
}
  let { data, error, isValidating } = useSWR<SearchResultResponse>(url,fetcher);
  return { data, error, isValidating };
}; 

const useSearchRides = createUseSearch(ENDPOINTS.SEARCH_RIDES);

export const useSearch = (params: SearchParams) => {
  const {
    data: searchResult,
    error: searchError,
    isValidating: isSearchValidating, 
  } = useSearchRides(params);

  const [results, setResults] = React.useState<Array<SearchResult>>(
    () => searchResult?.results ?? []
  );
  const [errors, setErrors] = React.useState<Array<any>>(() =>
    [searchError].filter(Boolean)
  );
  React.useEffect(() => {
    const nextResults = searchResult?.results ?? [];
    const nextErrors = [searchError].filter(Boolean);
    setResults(nextResults);
    setErrors(nextErrors);
  }, [searchResult, searchError]);

  const isValidating = isSearchValidating;
  const isLoading = isValidating && !searchResult;

  return {
    results,
    errors,
    isLoading,
  };
}; 
