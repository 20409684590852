import React from "react";
import MuiButton, { ButtonProps } from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core/styles";

const CustomButton: React.FC<ButtonProps> = (props) => {
  const {
    children,
    variant = "contained",
    color = "primary",
    ...other
  } = props;

  return (
    <MuiButton disableElevation variant={variant} color={color} {...other}>
      {children}
    </MuiButton>
  );
};

export const Button = withStyles((theme) => ({
  outlinedPrimary: {
    color: "#4F4F4F",
    border: "1px solid #C4C4C4",
  },
  root: {
    borderRadius: "3px",
  },
  label: {
    textTransform: "capitalize",
  },
  contained: {
    "&:hover": {
      backgroundColor: theme.custom.primary.main,
    },
  },
}))(CustomButton);
