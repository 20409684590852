import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Form,
  FormLabel,
  FormControl,
  Button,
} from "react-bootstrap";
import styled from "styled-components";
import { CircularProgress } from "@material-ui/core";

const PAYU_URL = "https://test.payu.in/_payment";

const LoaderWrapper = styled.div`
  display: flex;
  position: fixed;
  top: 0;
  flex-direction: column;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9;
  align-items: center;
  justify-content: center;
  background: rgba(255, 255, 255, 0.5);
`;

function PayUForm({ data }) {
  const [baseURL] = useState(PAYU_URL);
  const [key, setKey] = useState("");
  const [txnid, setTxnId] = useState("");
  const [amount, setAmount] = useState("");
  const [firstname, setFirstName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [productinfo, setProductInfo] = useState("");
  const [surl, setSurl] = useState("");
  const [furl, setFurl] = useState("");
  const [hash, setHash] = useState("");
  const [udf1, setUdf1] = useState("");
  const [udf2, setUdf2] = useState("");

  const calcHash = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    if (name === "key") {
      setKey(value);
    }
    if (name === "txnid") {
      setTxnId(value);
    }
    if (name === "amount") {
      setAmount(value);
    }
    if (name === "firstname") {
      setFirstName(value);
    }
    if (name === "email") {
      setEmail(value);
    }
    if (name === "phone") {
      setPhone(value);
    }
    if (name === "productinfo") {
      setProductInfo(value);
    }
    if (name === "udf1") {
      setUdf1(value);
    }
    if (name === "udf2") {
      setUdf2(value);
    }
  };

  useEffect(() => {
    if (txnid !== "") {
      document.getElementById("submit").submit();
    }
  }, [data, txnid]);

  useEffect(() => {
    if (data) {
      const {
        key,
        txnid,
        amount,
        firstname,
        email,
        phone,
        productinfo,
        hash,
        surl,
        udf1,
        udf2,
      } = data;
      setKey(key);
      setTxnId(txnid);
      setAmount(amount);
      setFirstName(firstname);
      setEmail(email);
      setPhone(phone);
      setProductInfo(productinfo);
      setHash(hash);
      setSurl(surl);
      setFurl(surl);
      setUdf1(udf1);
      setUdf2(udf2);
    }
  }, [data]);

  return (
    <Container>
      <Form id="submit" action={baseURL} method="post">
        <LoaderWrapper>
          <CircularProgress />
        </LoaderWrapper>
        <div style={{ opacity: 0 }}>
          <Row>
            <Col sm>{/* <h4>{title}</h4> */}</Col>
          </Row>
          <Row className="pt-sm-2">
            <Col sm>
              <Form.Label>Key</Form.Label>
            </Col>
            <Col sm>
              <Form.Control
                type="text"
                name="key"
                value={key}
                onChange={calcHash}
              ></Form.Control>
            </Col>
          </Row>
          <Row className="pt-sm-2">
            <Col sm>
              <FormLabel>Txn Id</FormLabel>
            </Col>
            <Col sm>
              <FormControl
                type=""
                name="txnid"
                value={txnid}
                onChange={calcHash}
              ></FormControl>
            </Col>
          </Row>
          <Row className="pt-sm-2">
            <Col sm>
              <FormLabel>Amount</FormLabel>
            </Col>
            <Col sm>
              <FormControl
                type="text"
                name="amount"
                value={amount}
                onChange={calcHash}
              ></FormControl>
            </Col>
          </Row>
          <Row className="pt-sm-2">
            <Col sm>
              <FormLabel>First name</FormLabel>
            </Col>
            <Col sm>
              <FormControl
                type="text"
                name="firstname"
                value={firstname}
                onChange={calcHash}
              ></FormControl>
            </Col>
          </Row>
          <Row className="pt-sm-2">
            <Col sm>
              <FormLabel>Email</FormLabel>
            </Col>
            <Col sm>
              <FormControl
                type="text"
                name="email"
                value={email}
                onChange={calcHash}
              ></FormControl>
            </Col>
          </Row>
          <Row className="pt-sm-2">
            <Col sm>
              <FormLabel>Phone</FormLabel>
            </Col>
            <Col sm>
              <FormControl
                type="text"
                name="phone"
                value={phone}
                onChange={calcHash}
              ></FormControl>
            </Col>
          </Row>
          <Row className="pt-sm-2">
            <Col sm>
              <FormLabel>Product Info</FormLabel>
            </Col>
            <Col sm>
              <FormControl
                type="text"
                name="productinfo"
                value={productinfo}
                onChange={calcHash}
              ></FormControl>
            </Col>
          </Row>
          <Row className="pt-sm-2">
            <Col sm>
              <FormLabel>SURL</FormLabel>
            </Col>
            <Col sm>
              <FormControl
                type="text"
                name="surl"
                value={surl}
                readOnly
              ></FormControl>
            </Col>
          </Row>
          <Row className="pt-sm-2">
            <Col sm>
              <FormLabel>FURL</FormLabel>
            </Col>
            <Col sm>
              <FormControl
                type="text"
                name="furl"
                value={furl}
                readOnly
              ></FormControl>
            </Col>
          </Row>
          <Row className="pt-sm-2">
            <Col sm>
              <FormLabel>UDF1</FormLabel>
            </Col>
            <Col sm>
              <FormControl
                type="text"
                name="udf1"
                value={udf1}
                readOnly
              ></FormControl>
            </Col>
          </Row>
          <Row className="pt-sm-2">
            <Col sm>
              <FormLabel>UDF2</FormLabel>
            </Col>
            <Col sm>
              <FormControl
                type="text"
                name="udf2"
                value={udf2}
                readOnly
              ></FormControl>
            </Col>
          </Row>
          <Row className="pt-sm-2">
            <Col sm>
              <FormLabel>Hash</FormLabel>
            </Col>
            <Col sm>
              <FormControl
                type="text"
                name="hash"
                value={hash}
                readOnly
              ></FormControl>
            </Col>
          </Row>
          <Row className="pt-sm-2">
            <Col sm>
              <Button type="submit">Pay</Button>
            </Col>
          </Row>
        </div>
      </Form>
    </Container>
  );
}

export default PayUForm;
