import * as React from "react";
import { Prompt } from "react-router-dom";
import styled from "styled-components";
import { parse } from "query-string";
import { useTranslation } from "react-i18next";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import Grid from "@material-ui/core/Grid";
import CircularProgress from "@material-ui/core/CircularProgress";
import { usePaymentMethods, useRide, useTickets } from "modules/api";
import { createBooking } from "modules/api/methods/booking";
import { PaymentTypes, Ticket, TicketStatus } from "modules/entities";
import { useUserData } from "modules/auth";
import { NewReservationParams, routes, Redirect } from "modules/routing";
import { Button, TextField } from "modules/ui";
import { getOrderId, getBookingValues } from "modules/booking";
import { GetCheckoutFailedDialog } from "modules/select-payment/molecules/GetCheckoutFailedDialog/GetCheckoutFailed";
import { Notifications } from "modules/notifications";
import { CancellationDialog } from "../../checkout-va/organisms/CancellationDialog/CancellationDialog";
import { useHandleLeave } from "../../checkout-va/hooks/useHandleLeave";
import { PoweredByIcon } from "../PoweredByIcon";

// function getColor(color: string, amount: number) {
//   return (
//     "#" +
//     color
//       .replace(/^#/, "")
//       .replace(/../g, (color) =>
//         (
//           "0" +
//           Math.min(255, Math.max(0, parseInt(color, 16) + amount)).toString(16)
//         ).substr(-2)
//       )
//   );
// }

const Page = styled.div`
  background: ${(props) => props.theme.custom["concrete-color"]};
  display: flex;
  justify-content: center;
  flex: 1;
`;

const PageContainer = styled.div`
  width: 100%;
  max-width: 1100px;
  @media (max-width: ${(props) =>
      props.theme.custom.breakpoints["xs-breakpoint"]}) {
    padding: 20px 12px;
    width: 100%;
  }
  @media (min-width: ${(props) =>
      props.theme.custom.breakpoints["xs-breakpoint"]}) {
    padding: 30px 40px 100px 40px;
  }
`;

const Content = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(4, min-content);
  grid-gap: 20px;
  @media (max-width: ${(props) =>
      props.theme.custom.breakpoints["xs-breakpoint"]}) {
    grid-template-columns: 1fr;
    row-gap: 20px;
  }
  @media (min-width: ${(props) =>
      props.theme.custom.breakpoints["xs-breakpoint"]}) {
    grid-template-columns: 6fr 4fr;
    column-gap: 40px;
  }
`;

const CardContainer = styled.div`
  background-color: #f6f6f6;
  box-shadow: 0px 30px 50px rgba(0, 0, 0, 0.25);
  border-radius: 3px;
  overflow: hidden;
`;

const Header = styled.div`
  height: 62px;
  background-color: ${(props) => props.theme.custom.primary.main};
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
`;

const CardContent = styled.div`
  padding: 12px;
`;

const PayList = styled.ol`
  padding-left: 16px;
  font-size: 14px;
  line-height: 2;
  color: ${(props) => props.theme.palette.text.primary};
`;

const Footer = styled.div`
  height: 62px;
  background-color: ${(props) => props.theme.custom.primary.main};
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
`;

const BtnWrapper = styled.div`
  margin: ${(props) => props.theme.spacing(1)}px;
  position: relative;
`;

const Progress = styled(CircularProgress)`
  color: ${(props) => props.theme.custom.primary.main};
  position: absolute;
  top: 50%;
  left: 50%;
  margintop: -12px;
  marginleft: -12px;
`;

const PayBtn = styled(Button)`
  margin-top: 16px;
  background-color: ${(props) => props.theme.custom.secondary.main};
  height: 48px;
  color: #ffffff;

  &:disabled {
    background-color: ${(props) => props.theme.custom.secondaryInactive};
  }
`;

const useSavedValues = () => {
  const rideDetails = parse(
    window.location.search,
  ) as unknown as NewReservationParams;

  const userDetails = React.useMemo(() => {
    const id = getOrderId(rideDetails);

    return getBookingValues(id);
  }, [rideDetails]);

  return {
    rideDetails,
    userDetails,
  };
};

type State = "idle" | "failed" | "pending" | "success";
const TICKET_REFRESH_INTERVAL = 5000;
let interval: any;

export const CheckoutOVO: React.FC = () => {
  const { t } = useTranslation("checkoutOVO");
  const { value: userData } = useUserData();
  const phone = `0` + userData?.phoneNumber?.split("+")[1];
  const [phoneNumber, setPhoneNumber] = React.useState<
    string | null | undefined
  >(phone.length < 13 ? phone : null);
  const [state, setState] = React.useState<State>("idle");
  const [ticket, setTicket] = React.useState<Ticket | null>(null);
  const { rideDetails, userDetails } = useSavedValues();
  const { data } = usePaymentMethods();
  const selectedMethod = data?.find((method) => method.code === "OVO") ?? null;
  const [errorDialogOpen, setErrorOpen] = React.useState(false);
  const [counter, setCounter] = React.useState<number>(30);
  const { data: ride } = useRide(rideDetails?.rideId);
  const { data: returnRide } = useRide(rideDetails?.returnRideId ?? "");

  const {
    handleLeave,
    closeDialog,
    confirmationOpen,
    isBlockingNavigation,
    unblockNavigation,
  } = useHandleLeave();

  const { data: ticketData, error } = useTickets(ticket?.id ?? "", {
    refreshInterval: TICKET_REFRESH_INTERVAL,
  });

  React.useEffect(() => {
    interval = setInterval(() => {
      setCounter((prevCount) => (prevCount === 0 ? 0 : prevCount - 1));
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPhoneNumber(e.currentTarget.value);
  };

  const handlePay = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (phoneNumber && /^08\d/g.test(phoneNumber)) {
      setState("pending");
      if (selectedMethod && userDetails.valid) {
        clearInterval(interval);
        // Two way booking
        const rides = [
          {
            rideId: rideDetails.rideId,
            pickUpStopId: rideDetails.pickUpStopId,
            dropOutStopId: rideDetails.dropOutStopId,
            passengers: userDetails.params.passengers,
          },
        ];
        if (rideDetails.returnRideId) {
          rides.push({
            rideId: rideDetails.returnRideId,
            pickUpStopId: rideDetails.dropOutStopId,
            dropOutStopId: rideDetails.pickUpStopId,
            passengers: userDetails.params.passengers,
          });
        }
        createBooking({
          rides,
          paymentMethod: {
            code: "OVO",
            icon: "/assets/OVO.png",
            minAmount: 1,
            name: "OVO",
            type: PaymentTypes.ovo,
          },
          voucherCode: userDetails.params.voucher,
          email: userDetails.params.email,
          eWalletPhoneNumber: phoneNumber,
        })
          .then((res) => {
            setState("success");
            setTicket(res.ticket);
            if (res.ticket.status === TicketStatus.paid) {
              Notifications.enqueueSnackbar(t("success"), {
                preventDuplicate: true,
              });
            }
          })
          .catch((error: any) => {
            setState("failed");
            Notifications.enqueueSnackbar(error?.response?.data?.message, {
              preventDuplicate: true,
            });
            if (error.response?.status === 409) {
              setErrorOpen(true);
            }
          });
      }
    } else {
      Notifications.enqueueSnackbar(t("phoneError"), {
        preventDuplicate: true,
      });
    }
  };

  const isPending = state === "pending";
  const redirectUrl = routes.home.url();
  const ticketGroup = ticketData?.ticket;
  const firstTicket = ticketData?.tickets?.[0];
  const methodCode = firstTicket?.paymentMethodCode ?? null;

  const backUrl = firstTicket
    ? routes.selectPayment.url({
        dropOutStopId: firstTicket.dropOutStopId,
        pickUpStopId: firstTicket.pickUpStopId,
        passengers: 1,
        rideId: firstTicket.rideId,
        paymentCode: methodCode || undefined,
      })
    : null;

  const isTicketCancelled =
    ticketGroup?.status === TicketStatus.payment_cancelled ||
    ticketGroup?.status === TicketStatus.cancelled;

  if (isTicketCancelled && backUrl) {
    return <Redirect to={backUrl} />;
  } else if ([404, 403].includes(error?.status as number) || counter === 0) {
    return <Redirect to={redirectUrl} />;
  } else if (firstTicket?.status === "paid") {
    return (
      <Redirect
        to={{
          pathname: routes.checkoutSuccess.url({ ticketId: firstTicket.id }),
          state: {
            ticketId: firstTicket.id,
          },
        }}
      />
    );
  }

  const getRidePriceValue = () => {
    if (rideDetails.returnRideId) {
      return (
        ride?.price &&
        returnRide?.price &&
        (ride?.price.value + returnRide?.price.value) * rideDetails.passengers
      );
    } else {
      return ride?.price && ride?.price.value * rideDetails.passengers;
    }
  };

  return (
    <Page>
      <PageContainer>
        <Prompt when={isBlockingNavigation} message={handleLeave} />
        <CancellationDialog
          ticketId={ticket?.id ?? ""}
          open={confirmationOpen}
          onClose={closeDialog}
          backUrl={redirectUrl}
          unblockNavigation={unblockNavigation}
        />
        <GetCheckoutFailedDialog
          open={errorDialogOpen}
          backTo={redirectUrl}
          onClose={() => setErrorOpen(false)}
        />
        <Content>
          <CardContainer>
            <Header>
              <Typography variant="h6" color="inherit">
                {t("title")}
              </Typography>
            </Header>
            <CardContent>
              <Card
                variant="outlined"
                style={{ padding: 16, marginBottom: 16 }}
              >
                <Grid
                  container
                  justifyContent="space-between"
                  alignItems="flex-start"
                  style={{ marginBottom: 16 }}
                >
                  <Grid item>
                    <Typography variant="caption">
                      {t("customerName")}
                    </Typography>
                    <Typography variant="subtitle2">
                      {userData?.displayName}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <img
                      src={`${process.env.REACT_APP_SERVER_PATH}/assets/OVO.png`}
                      alt="OVO"
                      style={{ height: 18 }}
                    />
                  </Grid>
                </Grid>
                <Grid
                  container
                  justifyContent="space-between"
                  alignItems="flex-end"
                >
                  <Grid item>
                    <Typography variant="caption">{t("amount")}</Typography>
                    {ride && (
                      <Typography variant="subtitle2">
                        {t("price", {
                          price: {
                            ...ride?.price,
                            value: getRidePriceValue(),
                          },
                        })}
                      </Typography>
                    )}
                  </Grid>
                  {/* <Grid item>
                    <Typography variant="caption">Invoice</Typography>
                    <Typography variant="subtitle2">
                      {userData?.displayName}
                    </Typography>
                  </Grid> */}
                </Grid>
              </Card>
              <Card
                variant="outlined"
                style={{ padding: 16, marginBottom: 16 }}
              >
                <Typography variant="subtitle2">{t("howToPay")}</Typography>
                <PayList>
                  <li>{t("lineOne")}</li>
                  <li>{t("lineTwo")}</li>
                  <li>{t("lineThree")}</li>
                </PayList>
              </Card>
              <Typography
                variant="subtitle2"
                style={{ textAlign: "center", marginBottom: 10 }}
              >
                {t("phone")}
              </Typography>
              <form onSubmit={handlePay} style={{ textAlign: "center" }}>
                <div>
                  <TextField
                    id="phoneNumber"
                    type="tel"
                    variant="outlined"
                    style={{
                      marginBottom: 6,
                      backgroundColor: "#ffffff",
                      width: "64%",
                    }}
                    InputProps={{
                      inputProps: {
                        style: { textAlign: "center" },
                        maxLength: 12,
                      },
                    }}
                    value={phoneNumber}
                    onChange={handleChange}
                  />
                </div>
                <Typography variant="caption">081xxxxxxxxx</Typography>
                <BtnWrapper>
                  <PayBtn
                    disabled={
                      phoneNumber && !isPending
                        ? phoneNumber?.length < 11
                          ? true
                          : state === "pending" || state === "success"
                          ? true
                          : false
                        : true
                    }
                    type="submit"
                    fullWidth
                  >
                    {t("pay")}
                  </PayBtn>
                  {isPending && <Progress size={24} />}
                </BtnWrapper>
                <Typography variant="caption">
                  {t("expire_1")}
                  {counter}
                  {t("expire_2")}
                </Typography>
              </form>
            </CardContent>
            <Footer>
              <PoweredByIcon />
            </Footer>
          </CardContainer>
        </Content>
      </PageContainer>
    </Page>
  );
};
