import * as React from "react";
import {
  ProviderContext,
  SnackbarProvider,
  SnackbarProviderProps,
} from "notistack";

let snackbarContext: ProviderContext | null = null;

export const Notifications: ProviderContext = {
  enqueueSnackbar(...args) {
    if (!snackbarContext) {
      throw new Error("snackbarContext is empty");
    }
    return snackbarContext.enqueueSnackbar(...args);
  },
  closeSnackbar(...args) {
    if (!snackbarContext) {
      throw new Error("snackbarContext is empty");
    }
    return snackbarContext.closeSnackbar(...args);
  },
};

export const NotificationsProvider: React.FC<SnackbarProviderProps> = (
  props
) => {
  const ref = React.useRef<ProviderContext | null>(null);
  React.useEffect(() => {
    if (ref.current) {
      snackbarContext = ref.current;
    }
  }, []);
  return <SnackbarProvider ref={ref} {...props} />;
};
