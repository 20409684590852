import React from "react";
import { useTranslation } from "react-i18next";
import { Title } from "modules/legal/atoms/Title";
import { TextContainer } from "modules/legal/atoms/TextContainer";
import { Subtitle } from "modules/legal/atoms/Subtitle";
import { List } from "modules/legal/molecules/List";
import { TermsGroup } from "../types";
import GoogleAnalyticsService from "../../../modules/utils/GoogleAnalyticsService"
import {LOG_EVENTS} from "../../../modules/utils/LogEvents"
import {getOperatorCode}  from "../../../modules/utils/getOperatorCode";

export const TermsAndConditions = () => {
  React.useEffect(() => {
    GoogleAnalyticsService.createEvent(LOG_EVENTS.LOG_EVENTS_NAME.SCREEN_VIEW, {
      firebase_screen: LOG_EVENTS.SCREEN_NAME.TERMS_AND_CONDITION_SCREEN, 
      firebase_screen_class: LOG_EVENTS.SCREEN_NAME.TERMS_AND_CONDITION_SCREEN,
      operator_code: getOperatorCode(),
      source:"web"
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const { t } = useTranslation("terms");
  const items = t("groups", { returnObjects: true }) as Array<TermsGroup>;
  return (
    <TextContainer>
      <Title>{t("title")}</Title>
      {items.map((item) => {
        return (
          <div id={item.id}>
            <Subtitle>{item.subtitle}</Subtitle>
            <List items={item.items} />
          </div>
        );
      })}
    </TextContainer>
  );
};
