import * as React from "react";

type fn = () => void;

const PhoneEmailVerifyContext = React.createContext<React.MutableRefObject<fn | null> | null>(
  null
);

export const PhoneEmailVerifyProvider: React.FC = ({ children }) => {
  const ref = React.useRef<fn | null>(null);
  return (
    <PhoneEmailVerifyContext.Provider value={ref}>
      {children}
    </PhoneEmailVerifyContext.Provider>
  );
};

export const usePhoneVerify = () => React.useContext(PhoneEmailVerifyContext);
