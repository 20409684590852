import React from "react";
import styled from "styled-components";
import { Typography } from "@material-ui/core";
import { Button } from "modules/ui";
import { VerificationSuccessIcon } from "../atoms/VerificationSuccessIcon";

const Verfied = styled(Typography)`
  font-size: 18px;
  font-weight: 700;
  margin-top: -15px;
  color: ${(props) => props.theme.custom.primary.main};
`;

const StyledButton = styled(Button)`
  background-color: ${(props) => props.theme.custom.secondary.main};
`;

export const OtpVerificationSuccess: React.FC<{ onContinue: () => void }> = ({
  onContinue,
}) => {
  return (
    <>
      <VerificationSuccessIcon />
      <Verfied variant="overline">Verified</Verfied>
      <StyledButton
        onClick={onContinue}
        type="submit"
        fullWidth
        color="primary"
        size="large"
      >
        Continue
      </StyledButton>
    </>
  );
};
