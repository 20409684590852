import { useRouteMatch } from "react-router-dom";
import { routes } from "modules/routing";

const FALLBACK_LANGUAGE = "en";

export const useLanguage = (): string => {
  const res = useRouteMatch<{ language?: string }>(
    routes.languagePrefix.pattern
  );
  return res?.params?.language ?? FALLBACK_LANGUAGE;
};
