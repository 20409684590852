import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
// import i18next from "i18next";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Drawer from "@material-ui/core/Drawer";
import {
  useTheme, // withStyles,
} from "@material-ui/core";
import { useUserData } from "modules/auth";
import headerBurger from "./header-burger.svg";
import loginIcon from "./login-icon.svg";
import Navigation from "./Navigation";
import {
  ProfileIcon,
  RidesIcon,
  LogoutIcon,
  SearchIcon,
  AboutUsIcon,
  HelpIcon
  // LoyalityIcon,
} from "./icon";
import { useSelector } from "react-redux";
import { MY_RIDE, PROFILE } from "modules/auth/constants";
import { featureToggle } from "modules/utils/featureToggle";

// const options = [
//   { value: 1, label: "about" },
//   { value: 2, label: "rental" },
//   { value: 3, label: "routes" },
//   { value: 4, label: "help" },
// ];

const BurgerWrapper = styled.div`
  display: none;
  @media (max-width: ${(props) =>
    props.theme.custom.breakpoints["lg-breakpoint"]}) {
    display: block;
    z-index: 2;
  }
`;
// const SelectWrapper = styled.div`
//   min-width: 60px;
//   margin-top: 0px;
//   background: ${(props) => props.theme.custom["white-color"]};
//   border: none;
//   box-sizing: border-box;
//   box-shadow: none;
//   border-radius: 0px;
//   height: 100%;
//   width: 295px;
// `;
// const Select = styled(Link)`
//   min-width: 80px;
//   padding: 20px;
//   font-family: ${(props) => props.theme.custom["roboto-font"]};
//   font-style: normal;
//   font-weight: 500;
//   font-size: 18px;
//   line-height: 21px;
//   color: ${(props) => props.theme.custom["emperor-color"]};
//   display: block;
//   text-decoration: none;
//   &.rental {
//     background: #ededf0;
//     color: #19191d;
//   }
// `;

const Login = styled.div`
  background: ${(props) => props.theme.custom.primary.main};
  color: ${(props) => props.theme.custom["white-color"]};
  display: flex;
  padding: 25px 20px;
  align-items: center;
`;

const LoginText = styled.h2`
  font-size: 20px;
  font-weight: 500;
  padding: 0 15px;
  line-height: 24px;
`;

interface Props {
  onClick?: (type: string) => void;
}

const fetchItems = (
  theme: any,
  selectedItem: any,
  t: any,
  userData: any,
  showProfile: any,
  showRides: any,
) => {
  if (userData && showProfile && showRides) {
    return [
      {
        title: t("search"),
        itemId: "/",
        elemBefore: () => (
          <SearchIcon
            color={
              selectedItem === "/"
                ? theme.custom.primary.main
                : theme.custom["limed-spruce"]
            }
          />
        ),
      },
      {
        title: t("myRides"),
        itemId: "/rides",
        elemBefore: () => (
          <RidesIcon
            color={
              selectedItem === "/rides"
                ? theme.custom.primary.main
                : theme.custom["limed-spruce"]
            }
          />
        ),
      },
      {
        title: t("profile"),
        itemId: "/profile",
        elemBefore: () => (
          <ProfileIcon
            color={
              selectedItem === "/profile"
                ? theme.custom.primary.main
                : theme.custom["limed-spruce"]
            }
          />
        ),
      },
      {
        title: t("help"),
        itemId: "/help",
        elemBefore: () => (
          <HelpIcon
            color={
              selectedItem === "/help"
                ? theme.custom.primary.main
                : theme.custom["limed-spruce"]
            }
          />
        ),
      },
      {
        title: t("aboutUs"),
        itemId: "/about-us",
        elemBefore: () => (
          <AboutUsIcon
            color={
              selectedItem === "/about-us"
                ? theme.custom.primary.main
                : theme.custom["limed-spruce"]
            }
          />
        ),
      },
      {
        title: t("logout"),
        itemId: "/logout",
        elemBefore: () => (
          <LogoutIcon
            color={
              selectedItem === "/logout"
                ? theme.custom.primary.main
                : theme.custom["limed-spruce"]
            }
          />
        ),
      },
    ];
  } else if (userData && showProfile) {
    return [
      {
        title: t("search"),
        itemId: "/",
        elemBefore: () => (
          <SearchIcon
            color={
              selectedItem === "/"
                ? theme.custom.primary.main
                : theme.custom["limed-spruce"]
            }
          />
        ),
      },
      {
        title: t("profile"),
        itemId: "/profile",
        elemBefore: () => (
          <ProfileIcon
            color={
              selectedItem === "/profile"
                ? theme.custom.primary.main
                : theme.custom["limed-spruce"]
            }
          />
        ),
      },
      {
        title: t("logout"),
        itemId: "/logout",
        elemBefore: () => (
          <LogoutIcon
            color={
              selectedItem === "/logout"
                ? theme.custom.primary.main
                : theme.custom["limed-spruce"]
            }
          />
        ),
      },
    ];
  } else if (userData && showRides) {
    return [
      {
        title: t("search"),
        itemId: "/",
        elemBefore: () => (
          <SearchIcon
            color={
              selectedItem === "/"
                ? theme.custom.primary.main
                : theme.custom["limed-spruce"]
            }
          />
        ),
      },
      {
        title: t("myRides"),
        itemId: "/rides",
        elemBefore: () => (
          <RidesIcon
            color={
              selectedItem === "/rides"
                ? theme.custom.primary.main
                : theme.custom["limed-spruce"]
            }
          />
        ),
      },
      {
        title: t("logout"),
        itemId: "/logout",
        elemBefore: () => (
          <LogoutIcon
            color={
              selectedItem === "/logout"
                ? theme.custom.primary.main
                : theme.custom["limed-spruce"]
            }
          />
        ),
      },
    ];
  } else {
    return [
      {
        title: t("search"),
        itemId: "/",
        elemBefore: () => (
          <SearchIcon
            color={
              selectedItem === "/"
                ? theme.custom.primary.main
                : theme.custom["limed-spruce"]
            }
          />
        ),
      },
      {
        title: t("help"),
        itemId: "/help",
        elemBefore: () => (
          <HelpIcon
            color={
              selectedItem === "/help"
                ? theme.custom.primary.main
                : theme.custom["limed-spruce"]
            }
          />
        ),
      },
      {
        title: t("aboutUs"),
        itemId: "/about-us",
        elemBefore: () => (
          <AboutUsIcon
            color={
              selectedItem === "/about-us"
                ? theme.custom.primary.main
                : theme.custom["limed-spruce"]
            }
          />
        ),
      },
    ];
  }
};

export function BurgerMenu(props: Props) {
  const { onClick }: any = props;
  const history = useHistory();
  const { location: currentLocation } = history;
  const { pathname } = currentLocation;
  const { t } = useTranslation("header");
  const { value: userData } = useUserData();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [open, setOpen] = React.useState<boolean>(false);
  const theme: any = useTheme();
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });
  const [selectedItem, setSelectedItem] = React.useState("");
  const [showProfile, setShowProfile] = React.useState<boolean>(true);
  const [showRides, setShowRides] = React.useState<boolean>(true);

  const firebase_app = useSelector(
    (state: any) => state.remoteConfig.firebaseApp,
  );
  const criteria = {
    country: useSelector((state: any) => state.remoteConfig.countryName),
    operator: useSelector((state: any) => state.remoteConfig.operatorId),
  };

  React.useEffect(() => {
    async function getResult() {
      const toggleProfile = await featureToggle(
        PROFILE,
        firebase_app,
        criteria,
      );
      const toggleRides = await featureToggle(MY_RIDE, firebase_app, criteria);
      setShowProfile(toggleProfile);
      setShowRides(toggleRides);
    }

    getResult();
  }, [criteria, firebase_app]);

  const toggleDrawer = (anchor: any, open: any) => (event: any) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
    setOpen((prev) => !prev);
  };

  const handleClickAway = () => {
    setOpen(false);
    setAnchorEl(null);
  };

  // const handleClickSelect = (value: string) => {
  //   handleClickAway();
  //   setSelectedItem("/rental");
  // };
  const items = fetchItems(
    theme,
    selectedItem,
    t,
    userData,
    showProfile,
    showRides,
  );
  return (
    <BurgerWrapper>
      <ClickAwayListener onClickAway={handleClickAway}>
        <div>
          <div onClick={handleClick}>
            <img src={headerBurger} alt="arrow" data-testid="menu-button" />
          </div>
          <Drawer
            variant="persistent"
            anchor={"left"}
            open={open}
            onClose={toggleDrawer("left", true)}
          >
            <Login>
              <img src={loginIcon} alt="" />
              <LoginText>
                {(userData && userData?.displayName) ||
                  (userData && userData?.phoneNumber) ||
                  (userData && userData?.email) ? (
                  `${t("salute")} ${userData?.displayName ||
                  userData?.phoneNumber ||
                  userData?.email}`
                ) : (
                  <div
                    data-testid="login-text"
                    onClick={() => {
                      setOpen(false);
                      onClick("login");
                    }}
                  >
                    Login or <br />
                    Create Account
                  </div>
                )}
              </LoginText>
            </Login>
            {/* {userData && ( */}
            <Navigation
              // you can use your own router's api to get pathname
              activeItemId={selectedItem}
              onSelect={({ itemId }) => {
                // maybe push to the route
                setSelectedItem(itemId);
                if (itemId === "/logout") {
                  onClick("logout");
                } else {
                  const next = `${pathname}${itemId}`;
                  history.push(next);
                }
                setOpen(false);
              }}
              items={items}
            />
            {/* )} */}

            {/* <SelectWrapper>
              {options.map((option) => (
                <Select
                  to={`/${i18next.language}/${option.label}`}
                  key={option.value}
                  className={selectedItem === "/rental" ? "rental" : ""}
                  onClick={handleClickSelect.bind(null, option.label)}
                >
                  {t(option.label)}
                </Select>
              ))}
            </SelectWrapper> */}
          </Drawer>
        </div>
      </ClickAwayListener>
    </BurgerWrapper>
  );
}
