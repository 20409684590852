import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { TextFieldProps } from "@material-ui/core";
import { TextField } from "modules/ui";
interface Props {
  name?: string;
  label?: string;
  requiredErrorMessage?: string;
  validationPattern?: RegExp;
  onChange?: (e: any) => void;
  validationRules?: {
    root: string;
    index: number;
    key: string;
  };
  value?: string;
  smallText?: string;
  multiline?: boolean;
  required?: boolean;
  inputProps?: any;
}

interface Validation {
  required?: string | boolean;
  pattern?: { value: RegExp; message: string };
}

const useClasses = makeStyles({
  root: {
    width: "100%",
    minHeight: 40,
    height: "auto",
    zIndex: 0,

    "& textarea": {
      minHeight: 40,
      lineHeight: "18px",
    },
    
    "& label.MuiInputLabel-root.MuiInputLabel-formControl > span.MuiFormLabel-asterisk.MuiInputLabel-asterisk": {
      color:"red"
    }
  },
});

export const FormInput = React.forwardRef(
  (props: Props | TextFieldProps, ref) => {
    const classes = useClasses();

    return (
      <div className="form-outer">
        <TextField
          inputRef={ref}
          variant="outlined"
          color="secondary"
          classes={classes}
          {...props}
        />
      </div>
    );
  }
);
