import { User as FirebaseUser } from "firebase";

type AuthState = "idle" | "pending" | "resolved";

export interface UserState {
  state: AuthState | string;
  hasToken: boolean;
  value: FirebaseUser | null;
}

const initialState = {
  state: "pending",
  hasToken: false,
  value: null,
};

export type UserGotAction = {
  type: "GOT_USER";
  payload: { state: string; hasToken: boolean; value: FirebaseUser | null };
};

export type UserRemoveAction = {
  type: "REMOVE_USER";
  payload: { state: string; hasToken: boolean; value: null };
};

export const userReducer = (
  state: UserState = initialState,
  action: UserGotAction | UserRemoveAction,
) => {
  switch (action.type) {
    case "GOT_USER": {
      return { ...action.payload };
    }

    case "REMOVE_USER": {
      return { ...action.payload };
    }

    default:
      return {
        ...state,
      };
  }
};
