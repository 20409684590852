import React, { useEffect, useState } from "react";
import parse from "autosuggest-highlight/parse";
import match from "autosuggest-highlight/match";
import clsx from "clsx";
import styled from "styled-components";
import { makeStyles } from "@material-ui/core/styles";
import Autocomplete, {
  AutocompleteInputChangeReason,
  RenderOptionState,
} from "@material-ui/lab/Autocomplete";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useSearchSuggest } from "modules/api";
import { BusStop, City } from "modules/entities";
import { TextField } from "modules/ui";
import { SearchFormConstants } from "modules/ui/constants";
import { useDebounce } from "modules/utils/hooks";
import { SearchItem } from "../../atoms/SearchItem";
import { ReactComponent as MapIcon } from "./MapIcon.svg";
import { tranformResults } from "./transformResults";

const TextBox = styled(TextField)`
  & .MuiOutlinedInput-root {
    &.Mui-focused fieldset {
      border-color: ${(props) => props.theme.custom.primary.main};
    }
  }
`;

const IconWrapper = styled.div`
  display: flex;
  padding: 10px 8px;
  padding-right: 2px;
  &.toIconWrapper {
    padding-left: 20px;
    @media (max-width: ${(props) =>
        props.theme.custom.breakpoints["xs-breakpoint"]}) {
      padding-left: 8px;
    }
  }
`;

const Container = styled.div`
  font-size: 12px;
  @media (min-width: ${(props) =>
      props.theme.custom.breakpoints["lg-breakpoint"]}) {
    width: 180px;
    &.containerTo {
      width: 185px;
    }
  }
  @media (max-width: ${(props) =>
      props.theme.custom.breakpoints["md-breakpoint"]}) {
    width: 50%;
  }
  @media (max-width: ${(props) =>
      props.theme.custom.breakpoints["xs-breakpoint"]}) {
    width: 100%;
    margin-bottom: 15px;
    &.containerTo {
      width: 100%;
      margin-bottom: 15px;
    }
  }
`;

const Label = styled.p`
  margin: 0px 0px 5px 5px;
  font-family: ${(props) => props.theme.custom["roboto-font"]};
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: ${(props) => props.theme.custom["gray-color"]};
  @media (max-width: ${(props) =>
      props.theme.custom.breakpoints["xs-breakpoint"]}) {
    display: none;
  }
`;

interface SearchInputProps {
  defaultValue?: string;
  value?: City | BusStop | null;
  onChange?: (value: City | BusStop | null) => void;
  name: string;
  label: string;
  outlineClassName?: string;
  iconClassName?: string;
  placeholder?: string;
  testId?: string;
  inputRef?: React.MutableRefObject<HTMLInputElement | undefined>;
}

const renderOption = (
  option: City | BusStop,
  { inputValue }: RenderOptionState
) => {
  const matches = match(option.name, inputValue);
  const parts = parse(option.name, matches);

  return (
    <div title={option.name}>
      {parts.map((part, index) => (
        <SearchItem key={index} part={part} />
      ))}
    </div>
  );
};

const useStyles = makeStyles({
  inputRoot: {
    height: 40,
    padding: "3px !important",
    fontWeight: 300,
  },
  autocompleteInput: {
    padding: "7.5px 4px!important",
    lineHeight: "unset",
  },
  poperWrapper: {
    minWidth: "300px",
  },
});

export const SearchInput: React.FC<SearchInputProps> = ({
  defaultValue = "",
  name,
  label,
  value,
  onChange,
  outlineClassName,
  iconClassName,
  placeholder,
  testId,
  inputRef,
}) => {
  const [text, setText] = useState(value?.name ?? defaultValue);

  useEffect(() => {
    setText(value?.name ?? defaultValue);
  }, [value, defaultValue]);
  const muiClasses = useStyles();

  const [open, setOpen] = useState<boolean>(false);
  const debouncedSearchValue = useDebounce(text, 300);

  const { data: searchResult, isValidating } =
    useSearchSuggest(debouncedSearchValue);
  const loading = Boolean(isValidating && !searchResult && text);

  const suggestList = tranformResults(searchResult);

  const changeHandler = (
    e: React.ChangeEvent<{}>,
    value: string,
    reason: AutocompleteInputChangeReason
  ) => {
    if (reason === "reset") {
      return;
    }
    setText(value);
  };

  const changeValueHandler = (
    e: React.ChangeEvent<{}>,
    value: City | BusStop | null
  ) => {
    setText(value?.name ?? "");
    onChange && onChange(value);
  };

  return (
    <Container className={name === SearchFormConstants.TO ? "containerTo" : ""}>
      <Label>{label}</Label>
      <Autocomplete
        classes={{
          popper: muiClasses.poperWrapper,
          input: muiClasses.autocompleteInput,
        }}
        onInputChange={changeHandler}
        onChange={changeValueHandler}
        inputValue={text}
        options={suggestList}
        getOptionLabel={(option) => option.name}
        renderInput={(params) => (
          <TextBox
            {...params}
            name={name}
            inputRef={inputRef}
            variant="outlined"
            value={text}
            color="primary"
            placeholder={placeholder}
            InputProps={
              {
                ...params.InputProps,
                "data-testid": testId,
                classes: {
                  notchedOutline: outlineClassName,
                },
                className: clsx(
                  muiClasses.inputRoot,
                  params.InputProps.className
                ),
                startAdornment: (
                  <IconWrapper className={iconClassName}>
                    <MapIcon role="img" />
                  </IconWrapper>
                ),
                endAdornment: (
                  <React.Fragment>
                    {loading ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : null}
                    {params.InputProps.endAdornment}
                  </React.Fragment>
                ),
              } as any
              // any because of data-testid
            }
            onClick={() => setOpen(true)}
          />
        )}
        renderOption={renderOption}
        popupIcon={null}
        closeIcon={null}
        open={Boolean(open && text)}
        loading={open && loading}
        onOpen={() => {
          setOpen(true);
        }}
        onClose={() => {
          setOpen(false);
        }}
      />
    </Container>
  );
};
