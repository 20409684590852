import * as React from "react";
import Qr from "qrcode.react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
// import ZoomInIcon from "@material-ui/icons/ZoomIn";
import {
  Dialog,
  DialogContent,
  useMediaQuery,
  useTheme,
  withStyles,
} from "@material-ui/core";
import { Ticket } from "modules/entities";
import { Button } from "modules/ui";
import { ReactComponent as ZoomInIcon } from "./zoom.svg";
// import classes from "./QrCode.module.css";

const Container = styled.button`
  position: relative;
  cursor: pointer;
  border: none;
  background: transparent;
  margin: 0;
  padding: 0;
`;

const FullQr = styled(({ ...rest }) => <Qr {...rest} />)`
  @media (max-width: ${(props) =>
      props.theme.custom.breakpoints["xs-breakpoint"]}) {
    width: 100%;
  }
`;

const ZoomContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, calc(-50% - 16px));
  width: 39px;
  height: 39px;
  font-size: 24px;
  background: white;
  border-radius: 50%;
  display: grid;
  place-items: center;
  color: ${(props) => props.theme.custom.primary.main};
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
`;

const QrTicketNumberRow = styled.p`
  font-size: 12px;
  line-height: 20px;
  text-align: center;
  color: ${(props) => props.theme.custom["emperor-color"]};
  margin: 0;
  padding: 0;
`;

const TicketNumberText = styled.p`
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: ${(props) => props.theme.custom["white-color"]};
  margin: 0;
  padding: 0;
`;

type QrCodeProps = {
  ticket: Ticket;
};

const Content = withStyles({
  root: {
    padding: 24,
  },
})(DialogContent);

const StyledDialog = withStyles({
  paper: {
    borderRadius: 4,
  },
  paperFullScreen: {
    borderRadius: 0,
  },
})(Dialog);

const qrPreviewSize = 130;
const qrPreviewMobileSize = 100;
const qrCodeSize = 350;
const backgroundColor = "#fff";
const qrColor = "#000";

export const QrCode: React.FC<QrCodeProps> = ({ ticket }) => {
  const [dialogOpen, setOpen] = React.useState(false);
  const qrCodeValue = ticket.qrCode || ticket.bookCode || "";
  const theme = useTheme();
  const open = () => setOpen(true);
  const close = () => setOpen(false);
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"));
  const { t } = useTranslation("ticket");

  const size = isMobile ? qrPreviewMobileSize : qrPreviewSize;

  return (
    <>
      <div>
        <Container type="button" onClick={open}>
          <Qr
            value={qrCodeValue}
            size={size}
            bgColor={backgroundColor}
            fgColor={qrColor}
            renderAs="svg"
          />
          <ZoomContainer>
            <ZoomInIcon />
          </ZoomContainer>
          <div>
            <QrTicketNumberRow>{t("ticketNumber")}</QrTicketNumberRow>
            <QrTicketNumberRow>
              <b>{ticket.bookCode}</b>
            </QrTicketNumberRow>
          </div>
        </Container>
      </div>
      <StyledDialog fullScreen={isMobile} open={dialogOpen} onClose={close}>
        <Content>
          <div>
            <FullQr value={qrCodeValue} size={qrCodeSize} renderAs="svg" />
            <TicketNumberText>
              {t("yourTicketNum")}
              <br />
              {ticket.bookCode}
            </TicketNumberText>
          </div>
          <Button fullWidth onClick={close}>
            {t("close")}
          </Button>
        </Content>
      </StyledDialog>
    </>
  );
};
