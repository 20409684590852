import React from "react";
import { useTranslation } from "react-i18next";
import { enUS, id } from "date-fns/locale";
import { format } from "date-fns";
import clsx from "clsx";
import styled from "styled-components";
import { IconButton, withStyles } from "@material-ui/core";
import CancelIcon from "@material-ui/icons/Cancel";
import { Price, Ride, Timezone } from "modules/entities";
import { getBusStop, getBusStopName } from "modules/entities/helpers";
import { ApplyVoucherResult } from "modules/entities";
import { useLanguage } from "modules/localization";
import { breakpoints } from "modules/ui/variables";
import { Duration } from "modules/ui";
import { useWindowResizeListener } from "modules/utils/hooks/useWindowResizeListener";
import { getDuration } from "modules/utils/getDuration";
import { getTimeValue } from "modules/utils/rideUtils";
import { ReactComponent as PassengerIcon } from "../../icons/passenger.svg";
import { ReactComponent as DurationIcon } from "../../icons/clock.svg";
import { TripInfo } from "../TripInfo/TripInfo";
import { PriceBlock } from "../PriceBlock/PriceBlock";
import classes from "../../organisms/ShoppingCart/ShoppingCart.module.css";

const CartContent = styled.div`
  font-size: 12px;
  @media (max-width: ${(props) =>
      props.theme.custom.breakpoints["xs-breakpoint"]}) {
    margin-top: 0px;
    padding: 0 0px;
  }
`;
const TripInfoMobileWrapper = styled.div`
  display: flex;
  margin-top: 0px;
  .route {
    width: 1px;
    background: ${(props) => props.theme.custom["gray-color"]};
    position: relative;
    &::before,
    &::after {
      position: absolute;
      top: 0px;
      border: 1px solid #bdbdbd;
      background: ${(props) => props.theme.custom["alabaster-color"]};
      width: 6px;
      height: 6px;
      border-radius: 100px;
      content: "";
      left: -3px;
    }
    &::after {
      bottom: 0;
      top: unset;
    }
  }
  .route-name {
    margin-left: 18px;
    p {
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      color: ${(props) => props.theme.custom["emperor-color"]};
      &:nth-child(1) {
        margin-bottom: 15px;
      }
    }
  }
`;
const CartPasseneger = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
  margin-top: 20px;
  align-items: end;
  p {
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    text-align: right;
    color: #4f4f4f;
  }
  .cartPassengerOuter {
    display: flex;
    flex-direction: column;
    small {
      font-weight: normal;
      font-size: 12px;
      line-height: 17px;
      color: #353f47;
      display: flex;
      svg {
        margin-right: 10px;
        width: 15px;
        margin-bottom: 15px;
      }
    }
  }
`;
const ReturnSepartor = styled.div`
  border: 1px solid #e0e0e0;
  margin-bottom: 20px;
`;

const RideTime = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: ${(props) => props.theme.custom["emperor-color"]};
  margin-bottom: 20px;
`;
const PasDurWarpper = styled.div`
  flex-direction: row;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  .pasDurText {
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    color: ${(props) => props.theme.custom["gray-color"]};
  }
  @media (max-width: ${(props) =>
      props.theme.custom.breakpoints["xs-breakpoint"]}) {
    flex-direction: column;
    .ShoppingCart_contentFlex__w4xjr {
      margin-top: 15px;
    }
  }
`;

const RemoveIcon = withStyles({
  root: {
    marginRight: 0,
  },
})(IconButton);

export interface CartContentTicketProps {
  ride: Ride;
  pickUpStopId: string;
  dropOutStopId: string;
  passengersCount: number;
  onRemove?: () => any;
  voucherData?: ApplyVoucherResult;
  onShoppingCart?: boolean;
  price: Price | null | undefined;
  timezone?: Timezone;

  onReturnRemove?: () => any;
  returnTimezone?: Timezone;
  returnRide?: Ride | null;
  returnDropOutStopId?: string;
  returnPickUpStopId?: string;
  returnPrice?: Price | null;
}

export const CartContentTicket: React.FC<CartContentTicketProps> = ({
  passengersCount,
  pickUpStopId,
  dropOutStopId,
  ride,
  onRemove,
  onReturnRemove,
  voucherData,
  onShoppingCart,
  price,
  timezone,
  returnRide,
  returnDropOutStopId,
  returnPickUpStopId,
  returnPrice,
}) => {
  const { t } = useTranslation("searchResult");
  const urlParams = new URLSearchParams(window.location.search);
  const timezoneFrom = urlParams.get("timezoneFrom") || timezone?.from;
  const timezoneTo = urlParams.get("timezoneTo") || timezone?.to;
  const language = useLanguage();
  const currentLocale = language === "en" ? enUS : id;
  const { windowWidth } = useWindowResizeListener();
  // prettier-ignore
  const pickUpStop = React.useMemo(() => getBusStop(ride, pickUpStopId), [ride, pickUpStopId]);
  // prettier-ignore
  const dropOutStop = React.useMemo(() => getBusStop(ride, dropOutStopId), [ride, dropOutStopId]);

  const returnPickUpStop = React.useMemo(
    () =>
      returnRide &&
      returnPickUpStopId &&
      getBusStop(returnRide, returnPickUpStopId),
    [returnRide, returnPickUpStopId],
  );
  // prettier-ignore
  const returnDropOutStop = React.useMemo(() => returnRide && returnDropOutStopId && getBusStop(returnRide, returnDropOutStopId), [returnRide, returnDropOutStopId]);

  const duration = React.useMemo(() => {
    const from = new Date(pickUpStop?.timeOfArrival ?? "");
    const to = new Date(dropOutStop?.timeOfArrival ?? "");
    return getDuration(from, to);
  }, [dropOutStop, pickUpStop]);

  const returnDuration = React.useMemo(() => {
    const from =
      returnPickUpStop && new Date(returnPickUpStop?.timeOfArrival ?? "");
    const to =
      returnDropOutStop && new Date(returnDropOutStop?.timeOfArrival ?? "");
    if (from && to) {
      return getDuration(from, to);
    } else {
      return {
        d: 0,
        m: 0,
        h: 0,
      };
    }
  }, [returnPickUpStop, returnDropOutStop]);

  const { vendor } = ride || {};

  if (!pickUpStop || !dropOutStop) {
    return null;
  }

  const { timeOfArrival } = pickUpStop;
  
  return (
    <CartContent>
      {vendor && <div>Operated by {vendor}</div>}
      <div className={classes.infoContainer}>
        {/* WEB VIEW */}
        {windowWidth > breakpoints.md ? (
          <TripInfoMobileWrapper>
            <TripInfo
              pickUpStop={pickUpStop}
              dropOutStop={dropOutStop}
              smallFont
              timezone={timezone}
              duration={duration}
              showDuration={false}
              wasManuallyEdited={ride?.wasManuallyEdited}
              disableConversion={ride?.disableConversion}
              ridePatternId={ride?.ridePatternId}
            />
            {returnPickUpStop && returnDropOutStop && (
              <TripInfo
                pickUpStop={returnPickUpStop}
                dropOutStop={returnDropOutStop}
                smallFont
                timezone={timezone}
                duration={duration}
                showDuration={false}
                wasManuallyEdited={ride?.wasManuallyEdited}
                disableConversion={ride?.disableConversion}
                ridePatternId={ride?.ridePatternId}
              />
            )}
          </TripInfoMobileWrapper>
        ) : (
          <>
            {/* MOBILE VIEW */}
            {/* single trip */}
            <div className={clsx(classes.contentFlex, classes.dateWrap)}>
              <RideTime>
                Outbound :{" "}
                {format(new Date(timeOfArrival), "EEE, d MMM,", {
                  locale: currentLocale,
                })}{" "}
                |{" "}
                {getTimeValue(
                  pickUpStop?.timeOfArrival,
                  timezoneFrom,
                  ride?.wasManuallyEdited,
                  ride?.disableConversion,
                  ride?.ridePatternId,
                )}{" "}
                -{" "}
                {dropOutStop &&
                  getTimeValue(
                    dropOutStop?.timeOfArrival,
                    timezoneFrom,
                    ride?.wasManuallyEdited,
                    ride?.disableConversion,
                    ride?.ridePatternId,
                  )}
              </RideTime>
              {onRemove && (
                <RemoveIcon
                  aria-label="Cancel"
                  onClick={onRemove}
                  size="medium"
                >
                  <CancelIcon />
                </RemoveIcon>
              )}
            </div>
            <TripInfoMobileWrapper>
              <div className="route"></div>
              <div className="route-name">
                <p className={classes.tripInfoMobileCity}>
                  {getBusStopName(pickUpStop.stop)}
                </p>
                {/* <ArrowRightAltIcon
                className={classes.tripInfoMobileArrow}
                fontSize="small"
              /> */}
                <p className={classes.tripInfoMobileCity}>
                  {getBusStopName(dropOutStop.stop)}
                </p>
              </div>
            </TripInfoMobileWrapper>
            <CartPasseneger>
              <div className="cartPassengerOuter">
                <small>
                  <PassengerIcon role="img" />
                  {passengersCount} {t("passengers")}
                </small>
                <small>
                  <DurationIcon role="img" />
                  {t("duration")}:
                  <Duration value={duration} />
                </small>
              </div>

              <p>{t("price", { price })}</p>
            </CartPasseneger>

            {/* return trip */}
            {returnRide && <ReturnSepartor />}
            {returnRide && (
              <div className={clsx(classes.contentFlex, classes.dateWrap)}>
                <RideTime>
                  Inbound :{" "}
                  {returnPickUpStop &&
                    format(
                      new Date(returnPickUpStop.timeOfArrival),
                      "EEE, d MMM,",
                      {
                        locale: currentLocale,
                      },
                    )}{" "}
                  |{" "}
                  {returnPickUpStop &&
                    getTimeValue(
                      returnPickUpStop?.timeOfArrival,
                      timezoneFrom,
                      ride?.wasManuallyEdited,
                      ride?.disableConversion,
                      ride?.ridePatternId,
                    )}{" "}
                  -{" "}
                  {returnDropOutStop &&
                    getTimeValue(
                      returnDropOutStop?.timeOfArrival,
                      timezoneTo,
                      ride?.wasManuallyEdited,
                      ride?.disableConversion,
                      ride?.ridePatternId,
                    )}
                  {/* <div className="pasDurText">
              {t("duration")}: <Duration value={returnDuration} />
            </div> */}
                </RideTime>
                {onReturnRemove && (
                  <RemoveIcon
                    aria-label="Cancel"
                    onClick={onReturnRemove}
                    size="medium"
                  >
                    <CancelIcon />
                  </RemoveIcon>
                )}
              </div>
            )}
            {returnRide && (
              <>
                <TripInfoMobileWrapper>
                  <div className="route"></div>
                  <div className="route-name">
                    <p className={classes.tripInfoMobileCity}>
                      {getBusStopName(dropOutStop.stop)}
                    </p>
                    {/* <ArrowRightAltIcon
                className={classes.tripInfoMobileArrow}
                fontSize="small"
              /> */}
                    <p className={classes.tripInfoMobileCity}>
                      {getBusStopName(pickUpStop.stop)}
                    </p>
                  </div>
                </TripInfoMobileWrapper>
                <CartPasseneger>
                  <div className="cartPassengerOuter">
                    <small>
                      <PassengerIcon role="img" />
                      {passengersCount} {t("passengers")}
                    </small>
                    <small>
                      <DurationIcon role="img" />
                      {t("duration")}:
                      <Duration value={returnDuration} />
                    </small>
                  </div>

                  <p>{returnPrice && t("returnPrice", { returnPrice })}</p>
                </CartPasseneger>
              </>
            )}
          </>
        )}
      </div>
      {windowWidth > breakpoints.md ? (
        <PasDurWarpper>
          <div className={clsx(classes.contentFlex, classes.marginRight)}>
            <div className={classes.iconWrapper}>
              <PassengerIcon role="img" />
            </div>
            <div className="pasDurText">
              {passengersCount} {t("passengers")}
            </div>
          </div>
          <div className={classes.contentFlex}>
            <div className={classes.iconWrapper}>
              <DurationIcon role="img" />
            </div>
            <div className="pasDurText">
              {t("duration")}: <Duration value={duration} />
            </div>
            {/* <>
              {onShoppingCart && (
                <div className={clsx(classes.fontNormal)}>
                  {t("price", { price })}
                </div>
              )}
            </> */}
          </div>
        </PasDurWarpper>
      ) : (
        <></>
      )}

      {windowWidth > breakpoints.md && onShoppingCart && (
        <div className={clsx(classes.price, classes.fontNormal)}>
          {t("price", { price })}
        </div>
      )}

      {!onShoppingCart && price && (
        <PriceBlock
          price={price}
          returnPrice={returnPrice}
          voucherData={voucherData}
        />
      )}
    </CartContent>
  );
};
