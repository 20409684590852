import React from 'react'
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { Link, routes } from "modules/routing";
import {isEmpty} from 'lodash'
import {
    FAQIcon,
    ContactUsIcon,
  } from "./icon";
  import Back from './back'

const Help = () => {
    const { t } = useTranslation("footer");
    let help_options = [
        {
          link:routes.contactUs.url(),
          value:t("contactus"),
          icon:<ContactUsIcon />
        },
        {
          link:routes.faq.url(),
          value:t("faq"),
          icon:<FAQIcon />
        }
      ]
    
    const HelpWrapper = styled.div`
    background: #FFFFFF
    `;

    const LIWrapper = styled.li`
        padding: 17px 25px;
        font-size: 14px;
        line-height: 18px;
        color: #202427;
        border-bottom: 1px solid #f0f4f7;

        a{
            padding-left: 18px;
            font-size: 14px;
            color: #353F47;
            position: absolute;
            padding-top: 6px;
        }
    `;

return(
    <HelpWrapper>
        <Back label={t("help")}>
        </Back>
        <ul>
            {!isEmpty(help_options) ?
            help_options.map((val,i) => {
              return(
                  <LIWrapper className="help__points" key={i}>
                    {val.icon}
                    <Link to={val.link}>
                        {val.value}
                    </Link>
                  </LIWrapper>
              )
            })
            
          : ''}
        </ul>
    </HelpWrapper>
)
}
export default Help