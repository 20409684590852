import * as React from "react";
import { Prompt, useRouteMatch } from "react-router-dom";
import styled from "styled-components";
import { useRide, useTickets } from "modules/api";
import { axios } from "modules/api/client";
import { ENDPOINTS } from "modules/api/endpoints";
import { PaymentResponse, TicketStatus } from "modules/entities";
import { Redirect, routes } from "modules/routing";
import { Placeholder } from "modules/ui";
import { safeParse } from "modules/utils/safePars";
import { multiplyPrice } from "modules/utils/price";
import { CartContentTicket } from "modules/search-result-list";
import { PaymentsGuide } from "../organisms/PaymentsGuide/PaymentsGuide";
import { InvoiceDetails } from "../organisms/InvoiceDetails/InvoiceDetails";
import { TimeLeft } from "../molecules/TimeLeft/TimeLeft";
import { CancellationDialog } from "../organisms/CancellationDialog/CancellationDialog";
import { useAccountNumber } from "../hooks/useAccountNumber";
import { useHandleLeave } from "../hooks/useHandleLeave";

const Page = styled.div`
  background: ${(props) => props.theme.custom["concrete-color"]};
  display: flex;
  justify-content: center;
  flex: 1;
`;

const PageContainer = styled.div`
  width: 100%;
  max-width: 1100px;
  @media (max-width: ${(props) =>
      props.theme.custom.breakpoints["xs-breakpoint"]}) {
    padding: 20px 12px;
    width: 100%;
  }
  @media (min-width: ${(props) =>
      props.theme.custom.breakpoints["xs-breakpoint"]}) {
    padding: 30px 40px 100px 40px;
  }
`;

const Content = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(4, min-content);
  grid-gap: 20px;
  @media (max-width: ${(props) =>
      props.theme.custom.breakpoints["xs-breakpoint"]}) {
    grid-template-columns: 1fr;
    row-gap: 20px;
  }
  @media (min-width: ${(props) =>
      props.theme.custom.breakpoints["xs-breakpoint"]}) {
    grid-template-columns: 6fr 4fr;
    column-gap: 40px;
  }
`;

const LeftContent = styled.div`
  display: grid;
  row-gap: 22px;
  grid-template-rows: repeat(auto-fit, minmax(0, max-content));
`;

const TicketDetails = styled.div`
  background: ${(props) => props.theme.custom["white-color"]};
  border: 1px solid ${(props) => props.theme.custom["alto-color"]};
  border-radius: 3px;
  padding: 20px;
`;

const ShoppingCartTitle = styled.p`
  display: none;
  @media (max-width: ${(props) =>
      props.theme.custom.breakpoints["xs-breakpoint"]}) {
    display: block;
    font-weight: bold;
    font-size: 18px;
    line-height: 21px;
    color: ${(props) => props.theme.custom["emperor-color"]};
    margin: 0 0 14px;
  }
`;

const TICKET_REFRESH_INTERVAL = 5000;

export const CheckoutVA: React.FC = () => {
  const match = useRouteMatch<{ language: string; ticketId: string }>(
    `${routes.languagePrefix.pattern}${routes.checkoutVA.pattern}`,
  );
  const urlParams = new URLSearchParams(window.location.search);
  const returnRideId = urlParams.get("returnRideId");
  console.log("match", urlParams);
  const { data: ticketData, error } = useTickets(
    match?.params?.ticketId ?? "",
    {
      refreshInterval: TICKET_REFRESH_INTERVAL,
    },
  );
  const passengers = ticketData?.tickets?.length ?? 1;
  const ticketIds = ticketData?.ticketGroup?.ticketsId;
  const [ticketOne, setTicketOne] = React.useState<any>(null);
  const [ticketTwo, setTicketTwo] = React.useState<any>(null);
  React.useEffect(() => {
    const fetchTickets = async () => {
      if (ticketIds && ticketIds[0]) {
        const response = await axios.get(
          `${ENDPOINTS.TICKETS}/${ticketIds[0]}`,
        );
        setTicketOne(response.data);
      }
      if (ticketIds && ticketIds[1]) {
        const response = await axios.get(
          `${ENDPOINTS.TICKETS}/${ticketIds[1]}`,
        );
        setTicketTwo(response.data);
      }
    };
    fetchTickets();
  }, [ticketIds]);

  const firstTicket = ticketOne?.tickets?.[0];
  const firstTicketGroup = ticketOne?.ticket;

  // const secondTicket = ticketTwo?.tickets?.[0];
  const secondTicketGroup = ticketTwo?.ticket;

  const { data: ride } = useRide(firstTicket?.rideId ?? null);
  const { data: returnRide } = useRide(returnRideId ?? null);

  const paymentResponse: PaymentResponse | null = React.useMemo(() => {
    if (firstTicket && firstTicket.paymentResponse) {
      return safeParse(firstTicket.paymentResponse);
    }
    return null;
  }, [firstTicket]);

  const date = paymentResponse ? new Date(paymentResponse.expired) : null;
  const methodCode = firstTicket?.paymentMethodCode ?? null;
  const accountNumber = useAccountNumber(paymentResponse, methodCode);
  const {
    handleLeave,
    closeDialog,
    confirmationOpen,
    isBlockingNavigation,
    unblockNavigation,
  } = useHandleLeave();

  const backUrl = firstTicket
    ? routes.selectPayment.url({
        dropOutStopId: firstTicket.dropOutStopId,
        pickUpStopId: firstTicket.pickUpStopId,
        passengers: 1,
        rideId: firstTicket.rideId,
        paymentCode: methodCode || undefined,
      })
    : null;

  const redirectUrl = backUrl || routes.home.url();
  const isTicketCancelled =
    firstTicketGroup?.status === TicketStatus.payment_cancelled ||
    firstTicketGroup?.status === TicketStatus.cancelled;

  if (isTicketCancelled && backUrl) {
    return <Redirect to={backUrl} />;
  } else if ([404, 403].includes(error?.status as number)) {
    return <Redirect to={redirectUrl} />;
  } else if (firstTicket?.status === "paid") {
    return (
      <Redirect
        to={{
          pathname: routes.checkoutSuccess.url({ ticketId: firstTicket.id }),
          state: {
            ticketId: firstTicket.id,
          },
        }}
      />
    );
  }

  return (
    <Page>
      <PageContainer>
        <Prompt when={isBlockingNavigation} message={handleLeave} />
        <CancellationDialog
          ticketId={match?.params?.ticketId ?? ""}
          open={confirmationOpen}
          onClose={closeDialog}
          backUrl={redirectUrl}
          unblockNavigation={unblockNavigation}
        />
        {/* <BackButtonWrapper to={backUrl || undefined} /> */}
        <Content>
          <LeftContent>
            {date ? (
              <TimeLeft expiryTimestamp={date} />
            ) : (
              <Placeholder variant="rect" height={116} />
            )}
            {paymentResponse ? (
              <InvoiceDetails
                account={accountNumber}
                amount={paymentResponse.invoice.amount}
                methodCode={methodCode}
              />
            ) : (
              <Placeholder variant="rect" height={200} />
            )}
            {methodCode ? (
              <PaymentsGuide method={methodCode} />
            ) : (
              <Placeholder variant="rect" height={400} />
            )}
          </LeftContent>
          <div>
            {ride && firstTicketGroup ? (
              <>
                <ShoppingCartTitle>Shopping Cart</ShoppingCartTitle>
                <TicketDetails>
                  <CartContentTicket
                    dropOutStopId={firstTicketGroup.dropOutStopId}
                    pickUpStopId={firstTicketGroup.pickUpStopId}
                    returnDropOutStopId={secondTicketGroup?.pickUpStopId}
                    returnPickUpStopId={secondTicketGroup?.dropOutStopId}
                    returnRide={returnRideId ? returnRide : null}
                    returnPrice={
                      returnRideId
                        ? multiplyPrice(firstTicketGroup.price, passengers)
                        : null
                    }
                    ride={ride}
                    voucherData={undefined}
                    price={multiplyPrice(firstTicketGroup.price, passengers)}
                    timezone={firstTicket.timezone}
                    passengersCount={passengers}
                  />
                </TicketDetails>
              </>
            ) : (
              <Placeholder variant="rect" height={300} />
            )}
          </div>
        </Content>
      </PageContainer>
    </Page>
  );
};
