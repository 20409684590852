export const LOG_EVENTS = {
  LOG_EVENTS_NAME: {
    SCREEN_VIEW: "screen_view",
    LOGIN: "web_login",
    GUEST_ID: "guest_id",
    SIGN_UP: "web_sign_up",
    SELECT_PHONE_NUMBER: "web_select_phone_number",
    PRESS_BUTTON_BACK: "web_press_button_back",
    ADD_PHONE_NUMBER: "web_add_phone_number",
    PRESS_BUTTON_CONTINUE: "web_press_button_continue",
    ADD_OTP: "web_add_otp",
    RECEIVE_OTP_ERROR: "web_receive_otp_error",
    GET_OTP_SUCCESS: "web_get_otp_success",
    SELECT_GOOGLE_SIGNUP: "web_select_google_signup",
    SELECT_FACEBOOK_SIGNUP: "web_select_facebook_signup",
    ADD_FULL_NAME: "web_add_full_name",
    ADD_EMAIL: "web_add_email",
    SELECT_SEARCH_FROM: "web_select_search_from",
    SELECT_SEARCH_TO: "web_select_search_to",
    SELECT_DATE_TO: "web_select_date_to",
    SELECT_DATE_FROM: "web_select_date_from",
    SELECT_SEATS: "web_select_seats",
    PRESS_BUTTON_SEARCH: "web_press_button_search",
    SEARCH_SUGGEST_FROM: "web_search_suggest_from",
    SEARCH_SUGGEST_TO: "web_search_suggest_to",
    SEARCH_RESULT_EMPTY: "web_search_result_empty",
    PRESS_BUTTON_MODIFY_SEARCH: "web_press_button_modify_search",
    PRESS_BACK_BUTTON: "web_press_back_button",
    SELECT_RIDE_FROM: "web_select_ride_from",
    PRESS_BUTTON_SELECT_RIDE_FROM: "web_press_button_select_ride_from",
    PRESS_BUTTON_REMOVE_RIDE_FROM: "web_press_button_remove_ride_from",
    PRESS_BUTTON_BOOK_RIDE_FROM: "web_press_button_book_ride_from",
    SELECT_RIDE_TO: "web_select_ride_to",
    PRESS_BUTTON_SELECT_RIDE_TO: "web_press_button_select_ride_to",
    PRESS_BUTTON_REMOVE_RIDE_TO: "web_press_button_remove_ride_to",
    PRESS_BUTTON_PROCEED_RIDE_TO: "web_press_button_proceed_ride_to",
    ADD_NAME: "web_add_name",
    ADD_PAYMENT_METHOD: "web_add_payment_method",
    PRESS_BUTTON_CONFIRM: "web_press_button_confirm",
    PRESS_BUTTON_BUY_TICKET: "web_press_button_buy_ticket",
    GET_PAYMENT_SUCCESS: "web_get_payment_success",
    GET_PAYMENT_ERROR: "web_get_payment_error",
    GET_PAYMENT_FAILURE: "web_get_payment_failure",
    CANCEL_PAYMENT_SUCCESS: "web_cancel_payment_success",
    CANCEL_PAYMENT_CANCEL: "web_cancel_payment_cancel",
    SHOWN_CANCEL_POP_UP: "web_shown_cancel_pop_up",
    PLANNED_RIDE: "web_planned_ride",
    PRESS_BUTTON_PAID: "web_press_button_paid",
    PRESS_BUTTON_QR: "web_press_button_qr",
    SHOWN_QR: "web_shown_qr",
    PRESS_BUTTON_QR_CLOSE: "web_press_button_qr_close",
    PRESS_BUTTON_VIEW_TRIP_HISTORY: "web_press_button_view_trip_history",
  },
  LOG_METHOD_KEY: {
    LOGIN: "login",
    REGISTRATION: "registration",
    HOME: "home",
    SEARCH_SUGGESTIONS: "search_suggestions",
    SEARCH_RESULT_EMPTY: "search_result_empty",
    SEARCH_RESULT_FROM: "search_result_from",
    SEARCH_RESULT_TO: "search_result_to",
    PASSENGER_DETAILS: "passenger_details",
    PAYMENT_METHOD: "payment_method",
    PAYMENT_PROCESS: "payment_process",
    RIDES: "rides",
    METHOD: "method",
  },
  LOG_METHOD_VALUE: {
    LOGIN_CLICKED: "login_clicked",
    GUEST_USER: "web_guest_user_added",
    SIGNUP_CLICKED: "sign_up_clicked",
    PHONE_NUMBER: "phone_number",
    APPLE_LOGIN: "apple_login",
    GOOGLE_LOGIN: "google_login",
    FACEBOOK_LOGIN: "facebook_login",
    PHONE_NUMBER_SELECTED: "phone_number_selected",
    BACK_BUTTON_PRESSED: "back_button_pressed",
    PHONE_NUMBER_ADDED: "phone_number_added",
    CONTINUE_BUTTON_PRESSED: "continue_button_pressed",
    OTP_ADDED: "otp_added",
    OTP_ERROR_RECEIVED: "otp_error_received",
    OTP_SUCCESS: "otp_success",
    GOOGLE_SIGNUP_SELECTED: "google_signup_selected",
    FACEBOOK_SIGNUP_SELECTED: "facebook_signup_selected",
    FULL_NAME_ADDED: "full_name_added",
    EMAIL_ADDED: "email_added",
    SEARCH_FROM_SELECTED: "search_from_selected",
    SEARCH_TO_SELECTED: "search_to_selected",
    DATE_TO_SELECTED: "date_to_selected",
    DATE_FROM_SELECTED: "date_from_selected",
    SEATS_SELECTED: "seats_selected",
    SEARCH_BUTTON_PRESSED: "search_button_pressed",
    SEARCH_SUGGESTION_FROM_FILLED: "search_suggestion_from_filled",
    SEARCH_SUGGESTION_TO_FILLED: "search_suggestion_to_filled",
    EMPTY_SEARCH_RESULT: "empty_search_result",
    MODIFY_SEARCH_PRESSED: "modify_search_pressed",
    BACK_BUTTON_CLICKED: "back_button_clicked",
    CLICKED_FROM_BUTTON_ON_SEARCH_RESULT_PAGE:
      "clicked_from_button_on_search_result_page",
    CLICKED_BOOK_BUTTON_ON_RIDE_ITEM: "clicked_book_button_on_ride_item",
    CLICKED_TO_BUTTON_ON_SEARCH_RESULT_PAGE:
      "clicked_to_button_on_search_result_page",
    CLICKED_SELECT_BUTTON_ON_RIDE_ITEM: "clicked_select_button_on_ride_item",
    CLICKED_REMOVE_BUTTON_ON_RIDE_ITEM: "clicked_remove_button_on_ride_item",
    CLICKED_PROCEED_BUTTON_ON_RIDE_ITEM: "clicked_proceed_button_on_ride_item",
    USER_ENTERED_FIRST_NAME: "user_entered_first_name",
    PAYMENT_METHOD_ADDED: "payment_method_added",
    CLICKED_CONFIRM_BUTTON: "clicked_confirm_button",
    CLICKED_BUY_TICKET_BUTTON: "clicked_buy_ticket_button",
    PAYMENT_SUCCESSFUL: "payment_successful",
    ERROR_ON_PAYMENT: "error_on_payment",
    FAIL_ON_PAYMENT: "fail_on_payment",
    PAYMENT_SUCCESS_CANCELED: "payment_success_canceled",
    USER_CANCEL_PAYMENT: "user_cancel_payment",
    CANCEL_POP_UP_SHOWN: "cancel_pop_up_shown",
    RIDES_APPEARS: "rides_appears",
    CLICKED_GET_TICKET_DETAILS: "clicked_get_ticket_details",
    CLICKED_BUTTON_QR: "clicked_button_qr",
    QR_POP_UP_SHOWN: "qr_pop_up_shown",
    CLICKED_CLOSE_BUTTON: "clicked_close_button",
    CLICKED_BACK_BUTTON: "clicked_back_button",
    CLICKED_TRIP_HISTORY_BUTTON: "clicked_trip_history_button",
  },
  SCREEN_NAME: {
    PROFILE_SCREEN: "PROFILE_SCREEN",
    SEARCH_RIDE_SCREEN: "SEARCH_RIDE_SCREEN",
    CREATE_RESERVATION_SCREEN: "BOOK_RIDE_SCREEN",
    SELECT_PAYMENT_SCREEN: "SELECT_PAYMENT_SCREEN",
    WELCOME_SCREEN: "WELCOME_SCREEN",
    COUNTRY_PICKER_SCREEN: "COUNTRY_PICKER_SCREEN",
    FACEBOOK_LOGIN_SCREEN: "FACEBOOK_LOGIN_SCREEN",
    GOOGLE_LOGIN_SCREEN: "GOOGLE_LOGIN_SCREEN",
    OTP_VERIFICATION_SCREEN: "OTP_VERIFICATION_SCREEN",
    PHONE_LOGIN_SCREEN: "PHONE_LOGIN_SCREEN",
    REGISTRATION_SCREEN: "REGISTRATION_SCREEN",
    BANK_TRANSFER_SCREEN: "BANK_TRANSFER_SCREEN",
    TICKET_DETAILS_SCREEN: "TICKET_DETAILS_SCREEN",
    CANCEL_TRIP_SCREEN: "CANCEL_TRIP_SCREEN",
    CONTACT_SUPPORT_PAGE: "CONTACT_SUPPORT_PAGE",
    MY_RIDES_PAGE: "MY_RIDES_PAGE",
    TERMS_AND_CONDITION_SCREEN: "TERMS_AND_CONDITION_SCREEN",
    //   HELP_SCREEN: "HELP_SCREEN",
    //   HISTORY_RIDE_SCREEN: "HISTORY_RIDE_SCREEN",
    //   UPCOMING_RIDE_SCREEN: "UPCOMING_RIDE_SCREEN",
    //   REFERRAL_SCREEN: "REFERRAL_SCREEN",
    //   RESERVATION_SCREEN: "RESERVATION_SCREEN",
    //   RULES_SCREEN: "RULES_SCREEN",
    //   SETTING_PHONE_SCREEN: "SETTING_PHONE_SCREEN",
    //   SETTING_NAME_SCREEN: "SETTING_NAME_SCREEN",
    //   SETTING_SCREEN: "SETTING_SCREEN",
    //   SETTING_LANGUAGE_SCREEN: "SETTING_LANGUAGE_SCREEN",
    //   SETTING_EMAIL_SCREEN: "SETTING_EMAIL_SCREEN",
    //   TUTORIAL_SCREEN: "TUTORIAL_SCREEN",
  },
};
