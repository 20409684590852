import React from "react";
import { Row } from "modules/legal/atoms/Row";
import { GroupItem } from "modules/legal/types";

export const List: React.FC<{ items: Array<GroupItem>; level?: number }> = ({
  items,
  level = 1,
}) => {
  const listStyle = level === 1 ? "decimal" : "lower-latin";

  return (
    <ol style={{ listStyle }}>
      {items.map((node, index) => {
        if (typeof node === "string") {
          return (
            <Row as={"li"} key={index}>
              {node}
            </Row>
          );
        }
        return (
          <>
            <Row as={"li"} key={index}>
              {node.subtitle}
            </Row>
            <List level={level + 1} items={node.items} />
          </>
        );
      })}
    </ol>
  );
};
