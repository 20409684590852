import useSWR, { ConfigInterface } from "swr";
import { BusStop, City } from "modules/entities";
import { ENDPOINTS } from "../endpoints";

export const useCityOrBusStop = (
  id: string,
  type: string,
  config?: ConfigInterface<BusStop | City, any>
) => {
  const endPoint = type === "City" ? ENDPOINTS.CITIES : ENDPOINTS.BUS_STOPS;
  const { isValidating, data, error } = useSWR<BusStop | City>(
    `${endPoint}${id}`,
    config
  );
  return {
    isValidating,
    data,
    error,
  };
};
