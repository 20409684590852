import React from 'react'
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { ENDPOINTS } from "modules/api/endpoints";
import { onboardingAxios } from "modules/api/client";
import GoogleAnalyticsService from "../../../modules/utils/GoogleAnalyticsService";
import { LOG_EVENTS } from "../../../modules/utils/LogEvents";
import { getDefaultOperatorCode } from "../../../modules/utils/getDefaultOperator";
import Back from '../../help/back'

const TitleWrapper = styled.h3`
  margin: 35px 35px;
  margin-bottom: 18px;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 18px;
  font-feature-settings: "cpsp" on;
  color: #4f4f4f;
  align-items: center;
`;

const ContentWrapper = styled.div`
  margin-top: 0px;
  margin-bottom: 25px;
  padding: 32px;
  text-align: "justify";
  lineheight: 1.5;
`;
const ContainerWrapper = styled.div`
  margin-left: 13%;
  @media (max-width: ${(props) =>
      props.theme.custom.breakpoints["md-breakpoint"]}) {
    margin-left: 10px;
  }
`;

const Title = styled.p`
    font-size: 16px;
    color: #5A5B6A;
    padding-bottom: 25px;
`;

const ValueWrapper = styled.span`
  padding-left:5px;
`;

export const ContactUs = () => {
    const { t } = useTranslation("contactUs");
    const [data,setData] = React.useState<any>([]);
  
    React.useEffect( () => {
        GoogleAnalyticsService.createEvent(LOG_EVENTS.LOG_EVENTS_NAME.SCREEN_VIEW, {
            firebase_screen: LOG_EVENTS.SCREEN_NAME.TERMS_AND_CONDITION_SCREEN,
            firebase_screen_class: LOG_EVENTS.SCREEN_NAME.TERMS_AND_CONDITION_SCREEN,
            operator_code: getDefaultOperatorCode(),
        });
        const getContactUs = async () => {
            try{
                const res = await onboardingAxios.get(
                    ENDPOINTS.ONBOARDING.CONTACTUS.replace('{operatorCode}', getDefaultOperatorCode())
                )
                setData(res.data)
            }catch(e){
    
            }
        }; 
        getContactUs();
    },[])

    return(
        <>
            <Back label={t("contactus")}>
            </Back>
        <ContainerWrapper>
            
            <TitleWrapper>{t("contactus")}</TitleWrapper>
            <ContentWrapper >
                <div>
                    <Title>
                        <span>{t("support_email")}:</span>
                        <ValueWrapper>
                            {data && data.email ? data.email : '-'}
                        </ValueWrapper>
                    </Title>
                    <Title>
                        <span>{t("support_number")}:</span>
                        <ValueWrapper>
                            {data && data.phoneNumber ? data.phoneNumber : '-'}
                        </ValueWrapper>
                    </Title>
                </div>
            </ContentWrapper>
        </ContainerWrapper>
        </>
    )
}