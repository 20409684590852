import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { Typography, makeStyles } from "@material-ui/core";
import { Ride } from "modules/entities";
import { Button } from "modules/ui";
import { useBooking } from "modules/utils/BookingContext";
import { CartContentTotal } from "../atoms/CartContentTotal/CartContentTotal";
import { ColoredSvg } from "../icons/arrow-double.js";

const CustomButton = styled(({ ...rest }) => <Button {...rest} />)`
  background-color: ${(props) => props.theme.custom.secondary};
  &:hover {
    background-color: ${(props) => props.theme.custom.primary};
    color: #fff;
  }
  &:disabled {
    background-color: ${(props) => props.theme.custom.secondaryInactive};
    color: #fff;
  }
`;

const useStyles = makeStyles({
  root: {
    minHeight: "57px",
    minWidth: "130px",
    borderRadius: "5px",
    maxHeight: "57px",
    lineHeight: "0px",
    paddingTop: "18px",
    display: "block",
    textAlign: "center",
  },
  disable: {
    lineHeight: "auto",
    paddingTop: "10px",
    minHeight: "57px",
    minWidth: "130px",
    borderRadius: "5px",
    maxHeight: "57px",
  },
  book: {
    width: "auto",
  },
  bookText: {
    marginRight: 6,
    fontSize: '0.875rem',
  },
  bookActive: {
    marginRight: 6,
    fontSize: 16,
  },
  price: {
    // padding: "5px 15px 5px 5px",
  },
});

const ButtonText = styled.p`
  display: block;
  text-align: center;
  margin: -12px 0 0;
  .sc-GTVdH.gpVxSx {
    display: none;
  }
  .eowspc {
    text-align: center;
  }
  @media (max-width: ${(props) =>
    props.theme.custom.breakpoints["xs-breakpoint"]}) {
    display: block;
    text-align: center;
    margin: 0;
  }
`;

const BookText = styled.div`
  display: inline-block;
  text-align: center;
  margin: 0;
  p {
    display: inline-block;
    margin: 0;
    text-align: center;
  }
`;

type BookButtonProps = React.ComponentProps<typeof Button> & {
  ride: Ride | null;
  returnRide?: Ride | null;
  passengersCount?: number | null;
};

export const BookButton: React.FC<BookButtonProps> = ({
  ride,
  returnRide,
  passengersCount,
  ...muiProps
}) => {
  const classes = useStyles();
  const { t } = useTranslation("searchResult");
  const { totalPrice } = useBooking();

  return (
    <CustomButton
      {...muiProps}
      color="secondary"
      disableElevation={false}
      classes={
        ride || returnRide ? { root: classes.root } : { root: classes.disable }
      }
    >
      <ButtonText>
        {ride && totalPrice && <CartContentTotal price={totalPrice} />}
      </ButtonText>
      <BookText>
        <p>
          <Typography
            className={ride ? classes.bookActive : classes.bookText}
            variant="overline"
            display="inline"
            data-testid="book-button"
          >
            {t("book")}
          </Typography>
        </p>
        <ColoredSvg color={"#FFF"} />
      </BookText>
    </CustomButton>
  );
};
