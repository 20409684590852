import { useTranslation } from "react-i18next";
import { VERIFY_PHONE_ERRORS } from "../constants";

const HANDLED_ERROR_TYPES = Object.values(VERIFY_PHONE_ERRORS);

export const usePhoneAuthErrorMessage = (
  errorCode: string | undefined | null
) => {
  const { t } = useTranslation("otp");
  if (!errorCode) {
    return undefined;
  }
  const isHandled = HANDLED_ERROR_TYPES.includes(errorCode);
  const type = isHandled ? errorCode : VERIFY_PHONE_ERRORS.UNKNOWN;
  return t(`errors.${type}`);
};
