import minutesDiff from "date-fns/differenceInMinutes";

export type Duration = {
  d: number;
  h: number;
  m: number;
};

export const getDuration = (from: Date, to: Date): Duration => {
  const allMinutes = Math.abs(minutesDiff(to, from));
  if (Number.isFinite(allMinutes)) {
    const hoursWithDays = Math.floor(allMinutes / 60);
    const minutes = allMinutes % 60;
    const hours = hoursWithDays % 24;
    const days = Math.floor(hoursWithDays / 24);
    return { d: days, h: hours, m: minutes };
  }
  return {
    d: 0,
    m: 0,
    h: 0,
  };
};
