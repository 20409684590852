import React from "react";
import styled from "styled-components";
// import classes from "./Subtitle.module.css";

const Heading2 = styled.h2`
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 20px;
  font-feature-settings: "cpsp" on;
  color: #4f4f4f;
`;

export const Subtitle: React.FC = ({ children }) => {
  return <Heading2>{children}</Heading2>;
};
