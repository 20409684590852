import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

const Section = styled.div`
  display: flex;
  justify-content: space-between;
  background: #f7f7fa;
  border-radius: 6px;
  margin-bottom: 10px;
  padding: 15px 10px;
  cursor: pointer;
  align-items: center;
`;

const OfferCode = styled.p`
  font-weight: 500;
  font-size: 12px;
  color: #19191d;
  flex: 0.2;
`;

const OfferDetails = styled.p`
  font-weight: 400;
  font-size: 10px;
  color: #19191d;
  flex: 0.5;
`;

const Applied = styled.p`
  font-weight: 400;
  font-size: 10px;
  color: #43a047;
  flex: 0.23;
`;

const Exhausted = styled.p`
  font-weight: 400;
  font-size: 10px;
  color: #f44336;
  flex: 0.23;
`;

const RadioBtn = styled.input`
  width: 15px;
  height: 15px;
  flex: 0.07;
`;

const RadioField = ({
  index,
  data,
  selectCoupon,
  coupon,
  ErrorCoupon,
  autoAppliedCoupon,
  appliedCoupon
}: any) => {
  const { t } = useTranslation("reservation");
  const [selected, setSelected] = React.useState(data.autoApply);
  const [disabled] = React.useState(data.limitExhaust === data.code);

  const handleRadio = (e: any, i: any) => {
    const { name } = e.target;
    setSelected(!selected);
    selectCoupon(name, i);
  };

  return (
    <Section
      style={{
        pointerEvents: disabled ? "none" : "visible",
        opacity: disabled ? 0.6 : 1,
      }}
    >
      <RadioBtn
        id={data.code}
        type="radio"
        checked={data.code === coupon}
        name={data.code}
        onChange={(e) => handleRadio(e, index)}
      />

      <OfferCode>
        {t("useCode")}: {data.code}
      </OfferCode>
      <OfferDetails>{data.details}</OfferDetails>
      {coupon && appliedCoupon === data.code ? <Applied>{t("applied")}</Applied> : disabled ? (
        <Exhausted>{t("usageLimit")}</Exhausted>
      ) : coupon === data.code && !appliedCoupon && autoAppliedCoupon === data.code ? (
        <Applied>{t("autoApplied")}</Applied>
      ) : (
        <Applied></Applied>
      )}
    </Section>
  );
};

export default RadioField;
