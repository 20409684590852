import React from "react";
import { parseISO } from "date-fns";
import { utcToZonedTime } from "date-fns-tz";
import format from "date-fns/format";
import { Redirect, routes } from "modules/routing";
import { refundCategoryType } from "./constants";
import convertToUtc from "./convertUtc";

export const getRefundCategoryText = (refundCategory, t) => {
  if (
    refundCategory &&
    refundCategory.type === refundCategoryType.nonRefundable
  ) {
    return t("non_refundable");
  } else if (
    refundCategory &&
    refundCategory.type === refundCategoryType.refundable
  ) {
    return t("fully_refundable");
  } else if (
    refundCategory &&
    refundCategory.type === refundCategoryType.partialRefundable
  ) {
    return t("partially_refundable");
  }
};

export const getTimeValue = (
  time,
  timezone,
  wasManuallyEdited,
  disableConversion,
  ridePatternId,
) => {
  try {
    return getRideTime(
      time,
      timezone,
      wasManuallyEdited,
      disableConversion,
      ridePatternId,
    );
  } catch (error) {
    console.error(error);
    return <Redirect to={routes.home.url()} />;
  }
};

export const getRideTime = (
  time,
  timezone,
  wasManuallyEdited,
  disableConversion,
  ridePatternId,
) => {
  if (!timezone) {
    return null;
  }
  // let newTime = convertToUtc(time).toISOString();
  // if (
  //   wasManuallyEdited === true ||
  //   disableConversion === true ||
  //   ridePatternId === null
  // ) {
  //   newTime = time;
  // }
  return format(utcToZonedTime(parseISO(time), timezone), "HH:mm");
};
