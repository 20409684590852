import React from "react";
import Img from "react-image";
// import clsx from "clsx";
import styled from "styled-components";
import { Skeleton } from "@material-ui/lab";
import { PaymentMethod } from "modules/entities";
// import classes from "../organisms/PaymentMethods.module.css";

function getColor(color: string, amount: number) {
  return (
    "#" +
    color
      .replace(/^#/, "")
      .replace(/../g, (color) =>
        (
          "0" +
          Math.min(255, Math.max(0, parseInt(color, 16) + amount)).toString(16)
        ).substr(-2)
      )
  );
}

const MethodWrapper = styled.button`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 80px;
  min-width: 150px;
  max-height: 80px;
  width: 100%;
  background: ${(props) => props.theme.custom["white-color"]};
  border: 1px solid ${(props) => props.theme.custom["alto-color"]};
  box-sizing: border-box;
  border-radius: 3px;
  transition: all 150ms ease;
  &:focus {
    outline: none;
    border: 3px solid ${(props) => props.theme.custom.secondary.main};
  }
  &:hover {
    cursor: pointer;
  }
  &.methodActive {
    outline: none;
    // background: ${(props) => props.theme.custom["snow-flurry-color"]};
    background-color: ${(props) =>
      getColor(props.theme.custom.secondary.main, 140)};
    border: 3px solid ${(props) => props.theme.custom.secondary.main};
    box-sizing: border-box;
    border-radius: 3px;
  }
  @media (max-width: ${(props) =>
      props.theme.custom.breakpoints["xs-breakpoint"]}) {
    min-width: 140px;
  }
`;

const MethodImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 13px;
  height: 26px;
  img {
    max-height: 26px;
  }
`;

const MethodName = styled.p`
  margin: 0;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 12px;
  text-align: center;
  color: ${(props) => props.theme.custom["emperor-color"]};
  &.hugeMethodName {
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    color: ${(props) => props.theme.custom["limed-spruce-color"]};
    @media (max-width: ${(props) =>
        props.theme.custom.breakpoints["xs-breakpoint"]}) {
      display: block;
    }
  }
`;

interface Props {
  value: PaymentMethod;
  selectedItem: PaymentMethod | null;
  onChange: (value: PaymentMethod) => void;
}

const areMethodsEqual = (a: PaymentMethod | null, b: PaymentMethod | null) => {
  return Boolean(a && b && a.type === b.type && a.code === b.code);
};

export function Method({ value, selectedItem, onChange }: Props) {
  const selected = areMethodsEqual(selectedItem, value);
  const id = `payment-method-${value.code}`;
  return (
    <MethodWrapper
      id={id}
      role="radio"
      aria-checked={selected}
      aria-label={value.name}
      className={selected ? "methodActive" : ""}
      onClick={() => onChange(value)}
    >
      {value.icon && (
        <MethodImageWrapper>
          <Img
            src={`${process.env.REACT_APP_SERVER_PATH}${value.icon}`}
            loader={<Skeleton height={26} width={100} />}
            alt={value.name}
          />
        </MethodImageWrapper>
      )}
      <MethodName className={!value.icon ? "hugeMethodName" : ""}>
        {value.name}
      </MethodName>
    </MethodWrapper>
  );
}
