import React from "react";
import { useRide } from "modules/api";
import { Price } from "modules/entities";

interface BookingInterface {
  ride: {};
  returnRide: {};
  fromDate: Date | null;
  toDate: Date | null;
  passengerCount: number;
  totalPrice: Price;
  fromRide: {
    price: Price;
  };
  toRide: {
    price: Price;
  };
}

const defaultValue = {
  ride: {},
  returnRide: {},
  fromDate: null,
  toDate: null,
  passengerCount: 0,
  totalPrice: {
    value: 0,
    currency: "IDR",
  },
  fromRide: {
    price: {
      value: 0,
      currency: "IDR",
    },
  },
  toRide: {
    price: {
      value: 0,
      currency: "IDR",
    },
  },
};

const BookingContext = React.createContext<BookingInterface>(defaultValue);

export const BookingProvider: React.FC = ({ children }) => {
  const searchParams = window.location.search.substring(1);
  let params: any = {};

  if (searchParams) {
    params = JSON.parse(
      '{"' +
        decodeURI(searchParams)
          .replace(/"/g, '\\"')
          .replace(/&/g, '","')
          .replace(/=/g, '":"') +
        '"}',
    );
  }

  const { data: fromRideIDResponse } = useRide(params?.fromRideId, params.passengers);
  const { data: toRideIDResponse } = useRide(params.toRideId, params.passengers);
  let totalPrice: Price = { value: 0, currency: "IDR" };
  if (fromRideIDResponse) {
    params.fromRide = fromRideIDResponse;
    if (fromRideIDResponse?.bestCampaignOffer?.discountDetails) {
      totalPrice.value =
        fromRideIDResponse.bestCampaignOffer.discountDetails.discountedPrice;
    } else {
      totalPrice.value = fromRideIDResponse.price.value * params.passengers;
    }
  }

  if (toRideIDResponse) {
    params.toRide = toRideIDResponse;
    if (toRideIDResponse?.bestCampaignOffer?.discountDetails) {
      totalPrice.value +=
        toRideIDResponse.bestCampaignOffer.discountDetails.discountedPrice;
    } else {
      totalPrice.value += toRideIDResponse.price.value * params.passengers;
    }
  }

  params.totalPrice = totalPrice;

  return (
    <BookingContext.Provider value={params}>{children}</BookingContext.Provider>
  );
};

export const useBooking = () => React.useContext(BookingContext);
