import { getOperatorCode } from "modules/utils/getOperatorCode";
import {
  paymentCollectionAxios,
  // paymentCollectionAxiosNgrock,
} from "../client";

export const getPaymentCollections = () => {
  return paymentCollectionAxios
    .get<any>(`/v1/${getOperatorCode()}/gateway`)
    .then((r) => r.data);
};

export const transactionInitiate = (data: any) => {
  return paymentCollectionAxios
    .post<any>(`/v1/transaction/initiate`, data)
    .then((r) => r.data);
};

export const createHash = (data: any) => {
  return paymentCollectionAxios
    .post<any>(`/v1/transaction/createhash`, data)
    .then((r) => r.data);
};
