export const Colors = {
	sideNavBlue: '#2979FF',
	sideNavGray: '#19191D',
	sideNavHeadGray: '#787885',
	white: '#ffffff',
	headerGray: '#4A4B57',
	breadCrumbGray: '#3A3A44',
	breadCrumbActiveGray: '#19191D',
	indicatorBlue: '#1B4EA3',
	greyBackground: '#F6F6F6',
	signUpBackground : '#292A31',
	loadingGreen: '#2B662E'
};
