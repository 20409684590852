import { SearchSuggestResult } from "modules/entities";

export const tranformResults = (
  searchResult: SearchSuggestResult | undefined
) => {
  if (!searchResult) {
    return [];
  }
  return [
    ...searchResult.cities.map((el) => ({ ...el, type: "City" })),
    ...searchResult.busStops.map((el) => ({ ...el, type: "BusStop" })),
  ];
};
