import React from 'react'
import styled from 'styled-components';
import { Typography } from '@material-ui/core';
import { useTranslation } from "react-i18next";
import EcoIcon from '../../icons/ecoSave.svg';
import InfoIcon from '../../icons/infoOutlined.svg';
import CloseIcon from '../../icons/closeIcon.svg';

const EmissionsContainer = styled.div`
  margin-bottom: 10px;
  position: relative;
  width: 100px;
`;

const Header = styled(Typography)`
  color: ${(props) => props.theme.custom.primary.main};
  font-weight: 400;
  font-size: 12px;
  line-height: 22px;
  display: inline;
`;

const EmissionsContainerHeader = styled.div`
  color:#828282;
  font-weight: 400;
  font-size: 12px;
  line-height: 22px;
  margin-left: 2px;
  span {
    font-size: 9px;
  }
  `;

const EmissionsContainerInfo = styled.div`
  display:flex;
  align-items: center;
  cursor: pointer;
  
`;

const Emissions = styled.div`
  background : #DCEDDD;
  border-radius: 2px;
  color: #292A31;
  font-weight: 700;
  font-size: 10px;
  line-height: 16px;
  padding: 3px 5px 2px 4px;

  img{
    vertical-align: top;
    margin-right:2px;
  }
  span{
    font-size:8px;
  }
`;

const EmissionsPopup = styled.div`
  background: #fff;
  box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.3);
  border-radius: 8px;
  padding: 12px 16px;
  width: 260px;
  position: absolute;
  top: -70px;
  left: 105px;
  z-index:10;

  h3 {
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  margin-bottom: 10px;

    span {
    float: right;
  }
}

  .popup--info {
  background: #F7F7FA;
  padding: 8px
}

  .popup--value {
  font-weight: 400;
  font-size: 10px;
  line-height: 24px;
  color: #4A4B57;
  display: flex;
  align-item: center;
  justify-content: space-between
}

  .popup--total {
  color: #19191D;
  font-weight: 600;
  border-top: 1px solid #E1E1E3;
  padding-top: 2px;
}

  .popup--message {
  margin-top: 10px;
  line-height: 12px;
}

  .subText {
  font-size: 8px;
}

@media (max-width: ${(props) =>
    props.theme.custom.breakpoints["xs-breakpoint"]}) {
top:-110px;
left:20px;
}
`;



const IconContainer = styled.div`
cursor: pointer;
margin-left: 3px;
`

type CarbonEmissionCounterProps = {
  emission: {
    cabEmissionPerPassenger: number,
    rideEmissionPerPerson: number,
    differencePercentage: number,
  },

};
const roundToTwo = (num: number) => {
  return Math.round((num + Number.EPSILON) * 100) / 100
};

export const CarbonEmissionCounter: React.FC<CarbonEmissionCounterProps> = ({ emission }) => {

  const { t } = useTranslation("searchResult");

  const emissionContainerRef = React.useRef<any>(null);

  const [isHoverActive, setIsHoverActive] = React.useState(false);

  React.useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('scroll', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('scroll', handleClickOutside);
    };
  }, [isHoverActive]);

  const handleClickOutside = (event: { target: any; }) => {
    if (emissionContainerRef.current && !emissionContainerRef.current.contains(event.target)) {
      setIsHoverActive(false);
    }
  };

  const handleHover = (bool: boolean) => {
    setIsHoverActive(bool);
  };

  return (
    <EmissionsContainer ref={emissionContainerRef}>
      <EmissionsContainerHeader>CO<span className='subtext'>2</span> {t("savings")}</EmissionsContainerHeader>
      <EmissionsContainerInfo>
        <Emissions onClick={() => handleHover(true)}>
          <img src={EcoIcon} alt='savings icon' />
          {roundToTwo(emission.differencePercentage)}% {t("less")} CO<span>2</span>
        </Emissions>
        <IconContainer onClick={() => handleHover(true)}><img src={InfoIcon} alt='info icon' /></IconContainer>
        {isHoverActive === true &&
          <EmissionsPopup>
            <h3>
              <Header>{t("lower")}</Header> {t("emissions")}
              <span onClick={() => handleHover(false)}><img src={CloseIcon} alt='close icon' /></span>
            </h3>
            <div className='popup--info'>
              <div className='popup--value'>
                <div>{t("thisRide")}</div>
                <div>{roundToTwo(emission.rideEmissionPerPerson)} kg CO<span className='subText'>2</span></div>
              </div>
              <div className='popup--value'>
                <div>{t("typicalCarRide")}</div>
                <div>{roundToTwo(emission.cabEmissionPerPassenger)} kg CO<span className='subText'>2</span></div>
              </div>
              <div className='popup--value popup--total'>
                <div style={{ textTransform: 'lowercase' }}>{roundToTwo(emission.differencePercentage)}% {t("lower")}</div>
                <div> - {roundToTwo(roundToTwo(emission.cabEmissionPerPassenger) - roundToTwo(emission.rideEmissionPerPerson))} kg CO<span className='subText'>2</span></div>
              </div>
            </div>
            <div className='popup--value popup--message'>{t("emissionMessage")}</div>
          </EmissionsPopup>
        }
      </EmissionsContainerInfo>
    </EmissionsContainer >
  )
}
