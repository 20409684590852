import React from "react";
import { useTranslation } from "react-i18next";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import { ButtonProps } from "@material-ui/core";
import { Button } from "modules/ui";

type Props = ButtonProps & {
  to?: string;
};

export const BackButton: React.FC<Props> = (props) => {
  const { t } = useTranslation("routing");
  const { to, ...buttonProps } = props;

  const onClick = () => {
    const { href } = window.location;
    window.history.back();
    if (to) {
      setTimeout(() => {
        if (window.location.href === href) {
          window.history.replaceState(null, "", to);
        }
      }, 0);
    }
  };

  return (
    <div>
      <Button
        startIcon={<ArrowBackIosIcon role="img" />}
        onClick={onClick}
        variant="text"
        color="default"
        {...buttonProps}
      >
        {t("back")}
      </Button>
    </div>
  );
};
