import React from 'react';
import { useTranslation } from "react-i18next";
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';
import styled from "styled-components";
import { CloseIcon } from 'assets/icons';

const Section = styled.div`
  padding: 10px;
  min-width: 450px;
  border-radius: 12px;

  @media (max-width: ${(props) =>
      props.theme.custom.breakpoints["md-breakpoint"]}) {
    min-width: 200px;
  }
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #D2D2D6;
  padding-bottom: 10px;

  p {
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: #292A31;
  }

  .closeIcon {
    cursor: pointer;
  }
`;

const Content = styled.div`
  padding: 20px;

  ul {
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #4A4B57;
    list-style-type: disc;
  }
`;

const ViewDetails = ({ isOpen, onCloseModal, viewInfoData }: any) => {
  const { t } = useTranslation("offer");
  
  return (
    <Modal open={isOpen} onClose={onCloseModal} center showCloseIcon={false}>
      <Section>
        <Header>
          <p>{viewInfoData?.title}</p>
          <div className="closeIcon" onClick={onCloseModal}>
            <CloseIcon />
          </div>
          
        </Header>

        <Content>
            <ul>
              <li>{`${t('applyCode')} ${viewInfoData?.code} ${t('busCheckout')} ${viewInfoData?.amount} ${t('promoCashback')}`}</li>
              <li>{t('noMin')}</li>
              <li>{t('offerValid')}</li>
              <li>{`${t('allCust')} ${viewInfoData?.code} ${t('successfully')}`}</li>
              <li>{`${t('userRedeem')} ${viewInfoData?.discount} ${t('upto')} ${viewInfoData?.amount} ${t('futureBooking')}`}</li>
              <li>{t('userLogged')}</li>
              <li>{`${t('offerTill')} ${viewInfoData?.valid}`}</li>
              <li>{t('offerValidForBussr')}</li>
              <li>{t('offerCombine')}</li>
              <li>{t('bussrReceives')}</li>
            </ul>
        </Content>
      </Section>
    </Modal>
  )
}

export default ViewDetails;
