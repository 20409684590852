import React from "react";
import classes from "./AppContainer.module.css";

export const AppContainer: React.FC = ({ children }) => {
  return (
    <main id="main-container" className={classes.app}>
      {children}
    </main>
  );
};
