import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import { PaymentMethod, PaymentTypes } from "modules/entities";
import { Method } from "../molecules/Method";
import { getOperatorCode } from "../../../modules/utils/getOperatorCode";
import GoogleAnalyticsService from "../../../modules/utils/GoogleAnalyticsService";
import { LOG_EVENTS } from "../../../modules/utils/LogEvents";

interface Props {
  data: Array<PaymentMethod>;
  selectedItem: PaymentMethod | null;
  onChange: (value: PaymentMethod) => void;
}

const PageWrapper = styled.div`
  padding: 20px;
  outline: none;
`;

const PageTitle = styled.p`
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 23px;
  color: ${(props) => props.theme.custom["emperor-color"]};
  margin-top: 0px;
  margin-bottom: 40px;
`;
const PaymentBlockTitle = styled.p`
  margin: 0 0 15px 0;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  text-transform: uppercase;
  margin-bottom: 10px;
  color: ${(props) => props.theme.custom["emperor-color"]};
  @media (max-width: ${(props) =>
      props.theme.custom.breakpoints["xs-breakpoint"]}) {
    margin: 0;
    margin-bottom: 10px;
  }
`;

const MethodsList = styled.ul`
  margin: 0px;
  display: grid;
  grid-template-columns: repeat(auto-fit, 150px);
  grid-gap: 20px;
  list-style: none;
  padding-left: 0px;
  @media (max-width: ${(props) =>
      props.theme.custom.breakpoints["xs-breakpoint"]}) {
    grid-template-columns: repeat(auto-fit, 100%);
    display: flex;
    flex-wrap: wrap;
    li {
      width: calc(100% / 2 - 15px);
    }
  }
`;

const MethodsWrapper = styled.div`
  margin-bottom: 30px;
`;

const Disclaimer = styled.p`
  background-color: #ffefd1;
  padding: 8px 10px;
  margin-top: 16px;
  border-radius: 4px;
  color: #8f6512;
  font-size: 14px;
  line-height: 18px;
  display: flex;
  align-items: center;
`;

const methodTitles: { [key: string]: string } = {
  e_wallet: "E-WALLET",
  dana: "DANA",
  xendit_va: "INSTANT PAYMENT",
  xendit_retail_outlet: "RETAIL",
  credit_card: "CREDIT/DEBIT CARD",
};

interface TypesValues {
  [key: string]: Array<PaymentMethod>;
}

const getMethodsData = (data: Array<PaymentMethod>) => {
  const typesData: TypesValues = {};
  if (data) {
    data.forEach((el) => {
      if (typesData[el.type]) {
        typesData[el.type].push(el);
      } else {
        typesData[el.type] = [el];
      }
    });
  }
  return typesData;
};

export function PaymentMethods({ data, selectedItem, onChange }: Props) {
  const { t } = useTranslation("paymentMethods");
  const types: TypesValues = React.useMemo(() => getMethodsData(data), [data]);
  const isDisclaimerVisible = selectedItem?.type === PaymentTypes.va;
  GoogleAnalyticsService.createEvent(
    LOG_EVENTS.LOG_EVENTS_NAME.ADD_PAYMENT_METHOD,
    {
      payment_method: LOG_EVENTS.LOG_METHOD_VALUE.PAYMENT_METHOD_ADDED,
      payment_name: selectedItem?.name,
      operator_code: getOperatorCode(),
      source: "web",
    }
  );

  return (
    <PageWrapper
      role="radiogroup"
      tabIndex={0}
      aria-labelledby="select-payment-methods-title"
      aria-activedescendant={
        selectedItem ? `payment-method-${selectedItem.code}` : ""
      }
    >
      <PageTitle id="select-payment-method">{t("title")}</PageTitle>

      {data &&
        Object.keys(types).map((type) => (
          <MethodsWrapper key={type}>
            <PaymentBlockTitle>{methodTitles[type]}</PaymentBlockTitle>
            <MethodsList>
              {types[type].map((el) => {
                return (
                  <li key={el.code}>
                    <Method
                      value={el}
                      selectedItem={selectedItem}
                      onChange={onChange}
                    />
                  </li>
                );
              })}
            </MethodsList>
            {type === "xendit_va" && isDisclaimerVisible && (
              <Disclaimer>
                <span>
                  <ErrorOutlineIcon style={{ color: "#8F6512" }} />
                </span>
                <span style={{ flex: 1, marginLeft: 10 }}>
                  {t("disclaimer")}
                </span>
              </Disclaimer>
            )}
          </MethodsWrapper>
        ))}
    </PageWrapper>
  );
}
