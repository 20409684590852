import * as React from "react";
import { enUS, id } from "date-fns/locale";
import { format } from "date-fns";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { useMediaQuery, useTheme } from "@material-ui/core";
import { Ride, Ticket } from "modules/entities";
import { getBusStop } from "modules/entities/helpers";
import { useLanguage } from "modules/localization";
// import classes from "modules/ticket/organisms/TicketDetails.module.css";
import { RideDetails } from "modules/ticket/organisms/RideDetails";

const TopContainer = styled.div`
  display: flex;
  justify-content: space-between;
  @media (max-width: ${(props) =>
      props.theme.custom.breakpoints["xs-breakpoint"]}) {
    flex-direction: column;
  }
`;

const PassangerTitle = styled.p`
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.25px;
  color: ${(props) => props.theme.custom["limed-spruce"]};
  margin-bottom: 5px;
`;

const Name = styled.p`
  font-weight: 500;
  font-size: 20px;
  line-height: 132%;
  letter-spacing: 0.0015em;
  color: ${(props) => props.theme.custom["limed-spruce"]};
  border-radius: 2px;
  margin: 0;
`;

const DateWrapper = styled.p`
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: ${(props) => props.theme.custom["emperor-color"]};
  margin: 0;
  @media (max-width: ${(props) =>
      props.theme.custom.breakpoints["xs-breakpoint"]}) {
    margin-top: 20px;
  }
`;

// const AddCalender = styled.p`
//   display: none;
//   @media (max-width: ${(props) =>
//       props.theme.custom.breakpoints["xs-breakpoint"]}) {
//     font-weight: 700;
//     font-size: 14px;
//     line-height: 16px;
//     color: ${(props) => props.theme.custom.primary.main};
//     margin: 5px 0 20px;
//     display: block;
//   }
// `;

const BookingCodeLabel = styled.p`
  // display: none;
  @media (max-width: ${(props) =>
      props.theme.custom.breakpoints["xs-breakpoint"]}) {
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    color: ${(props) => props.theme.custom["emperor-color"]};
    margin: 0 0 5px;
  }
`;

const BookingCode = styled.p`
  @media (max-width: ${(props) =>
      props.theme.custom.breakpoints["xs-breakpoint"]}) {
    display: block;
    font-weight: 700;
    font-size: 16px;
    line-height: 16px;
    color: ${(props) => props.theme.custom["emperor-color"]};
    margin: 0;
  }
`;

type RideDetailsProps = {
  ticket: Ticket;
  ride: Ride;
};

export const TicketDetails: React.FC<RideDetailsProps> = ({ ride, ticket }) => {
  const language = useLanguage();
  const { t } = useTranslation("ticket");
  const { dropOutStopId, pickUpStopId } = ticket;
  const pickUpStop = getBusStop(ride, pickUpStopId);
  const dropOutStop = getBusStop(ride, dropOutStopId);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const startTime = ticket.ride ? ticket.ride.startTime : "";

  if (!pickUpStop || !dropOutStop) {
    return <div>Error</div>;
  }

  const locale = language === "en" ? enUS : id;
  const departDate = format(new Date(startTime), "EEE, d MMM, yyyy", {
    locale: locale,
  });

  // const departDate = format(
  //   new Date(pickUpStop.timeOfArrival),
  //   "EEE, d MMM, yyyy",
  //   {
  //     locale: locale,
  //   },
  // );

  return (
    <div style={{ marginLeft: 16 }}>
      <TopContainer>
        <div>
          <PassangerTitle>{t("passengerName")}</PassangerTitle>
          <Name>
            {ticket.passenger.firstName} {ticket.passenger.lastName}
            <DateWrapper>{departDate}</DateWrapper>
          </Name>
        </div>
        {/* <AddCalender>ADD TO CALENDAR</AddCalender> */}
        <div>
          <BookingCodeLabel>{t("bookingCode")}</BookingCodeLabel>
          <BookingCode>{ticket?.bookCode}</BookingCode>
        </div>
      </TopContainer>
      {!isMobile && <RideDetails ticket={ticket} ride={ride} />}
    </div>
  );
};
