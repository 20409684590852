import React from "react";
// import clsx from "clsx";
// import classes from "./SearchItem.module.css";
import styled from "styled-components";

interface Props {
  part: {
    text: string;
    highlight: boolean;
  };
}

const Wrapper = styled.span`
  font-family: Roboto;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #4f4f4f;
  &.searchItemBold {
    font-weight: 700;
  }
`;

export function SearchItem(props: Props) {
  const { part } = props;
  return (
    <Wrapper className={part.highlight ? "searchItemBold" : ""}>
      {part.text}
    </Wrapper>
  );
}
