import React from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import { Authorized, AuthProvider } from "modules/auth";
import { LanguageRedirect } from "modules/localization";
import { BookingProvider } from "modules/utils/BookingContext";
import { SearchMainPage } from "modules/search";
import { SearchResultPage } from "modules/search-result-list";
import { CreateReservationPage } from "modules/booking";
import { SelectPaymentPage } from "modules/select-payment";
import { RapydPaymentPage, PaymentStatus } from "modules/rapyd";
import { CheckoutVA } from "modules/checkout-va";
import { CheckoutOVO } from "modules/checkut-ovo";
import { CheckoutSuccess } from "modules/checkout-success";
import { TicketPage } from "modules/ticket/pages/TicketPreview";
import { PrivacyPolicy, TermsAndConditions } from "modules/legal";
import { Profile } from "modules/profile/pages/Profile";
import Rental from "modules/rental/pages/rental";
import { MyRides } from "modules/myRides/pages/MyRides";
import Vehicle from "modules/rental/pages/Vehicle";
import RentalForm from "modules/rental/pages/RentalForm";
import { TermsNConditions } from "modules/terms/pages/TermsNConditions";
import XenditCheckout from "modules/xendit/pages/xenditCheckout";
import { ThankYouPage } from "modules/rental/pages/ThankYou";
import { routes } from "../routes";
import PaymentCheckout from "modules/xendit/pages/paymentCheckout";
import { ContactUs } from "modules/contact-us/pages/contactUs";
import PrivacyPolicyNew from "modules/privacy-policy/pages/privacyPolicy";
import { AboutUs } from "modules/about-us/about-us";
import { FAQ } from "modules/faq/faq";
import Help from "modules/help/help";
import AllOffers from "modules/offersAndBenefits/AllOffers";

const MainRouting: React.FC = () => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route path={`${path}${routes.rental.pattern}`}>
        <Rental serviceData={[]} showTitle />
      </Route>
      <Route path={`${path}${routes.profile.pattern}`}>
        <Authorized>
          <Profile />
        </Authorized>
      </Route>
      <Route path={`${path}${routes.search.pattern}`}>
        <BookingProvider>
          <SearchResultPage />
        </BookingProvider>
      </Route>
      <Route path={`${path}${routes.xendit.pattern}`}>
        <XenditCheckout />
      </Route>
      <Route path={`${path}${routes.paymentGateway.pattern}`}>
        <PaymentCheckout />
      </Route>
      <Route path={`${path}${routes.rentalPaymentStatus.pattern}`}>
        <ThankYouPage />
      </Route>
      <Route path={`${path}${routes.newReservation.pattern}`}>
        <CreateReservationPage />
      </Route>
      <Route path={`${path}${routes.selectPayment.pattern}`}>
        <Authorized>
          <SelectPaymentPage />
        </Authorized>
      </Route>
      <Route path={`${path}${routes.rapyd.pattern}`}>
        <Authorized>
          <RapydPaymentPage />
        </Authorized>
      </Route>
      <Route path={`${path}${routes.checkoutVA.pattern}`}>
        <Authorized>
          <CheckoutVA />
        </Authorized>
      </Route>
      <Route path={`${path}${routes.checkoutOVO.pattern}`}>
        <Authorized>
          <CheckoutOVO />
        </Authorized>
      </Route>
      <Route path={`${path}${routes.checkoutSuccess.pattern}`}>
        <Authorized>
          <CheckoutSuccess />
        </Authorized>
      </Route>
      <Route path={`${path}${routes.paymentStatus.pattern}`}>
        <Authorized>
          <PaymentStatus />
        </Authorized>
      </Route>
      <Route path={`${path}${routes.rentalService.pattern}`}>
        <RentalForm serviceData={[]} showTitle />
      </Route>
      <Route path={`${path}${routes.rentalServiceListing.pattern}`}>
        <Vehicle />
      </Route>
      <Route path={`${path}${routes.ticketPreview.pattern}`}>
        <Authorized>
          <TicketPage />
        </Authorized>
      </Route>
      <Route path={`${path}${routes.termsAndConditions.pattern}`}>
        <TermsAndConditions />
      </Route>
      <Route path={`${path}${routes.termsNConditions.pattern}`}>
        <TermsNConditions />
      </Route>

      <Route path={`${path}${routes.privacyPolicy.pattern}`}>
        <PrivacyPolicy />
      </Route>
      <Route path={`${path}${routes.rides.pattern}`}>
        <Authorized>
          <MyRides />
        </Authorized>
      </Route>
      <Route path={`${path}${routes.contactUs.pattern}`}>
        <ContactUs />
      </Route>
      <Route path={`${path}${routes.aboutUs.pattern}`}>
        <AboutUs />
      </Route>
      <Route path={`${path}${routes.faq.pattern}`}>
        <FAQ />
      </Route>
      <Route path={`${path}${routes.help.pattern}`}>
        <Help />
      </Route>
      <Route path={`${path}${routes.privacypolicy.pattern}`}>
        <PrivacyPolicyNew />
      </Route>
      <Route path={`${path}${routes.offers.pattern}`}>
        <AllOffers />
      </Route>
      <Route path={`${path}${routes.home.pattern}`}>
        <SearchMainPage />
      </Route>
    </Switch>
  );
};

export const AppRouting = () => {
  return (
    <>
      <LanguageRedirect />
      <AuthProvider>
        <Route path={routes.languagePrefix.pattern}>
          <MainRouting />
        </Route>
      </AuthProvider>
    </>
  );
};
