import React from 'react'
import styled from "styled-components";
import { Link } from "modules/routing";
import {
    BackArrowIcon,
  } from "./icon";

  type BackProps = {
    label: '';
  };

const Back: React.FC<BackProps> =
({ label }) => {
    const BackWrapper = styled.div`
    background-color: ${(props) => props.theme.custom.primary.main};
    margin-top: 15px;
    height: 78px;
    display: flex;
    padding: 34px 0px 16px 28px;
    @media (min-width: 1024px) {
        display:none;
      }

    .back{
        padding-left: 18px;
        color: #FFFFFF;
        font-size: 16px;
    }
    .back_link{
        display:flex;
    }
    `;
return(
    <BackWrapper>
        <Link className="back_link" to="/">
            <BackArrowIcon />
            <p className="back">{label}</p>
        </Link>
    </BackWrapper>
)
}
export default Back