// import ReactGA from 'react-ga';

// // export default class GoogleAnalyticsService {
// //   static setUserId = async userId => {
// //     ReactGA.initialize(userId);
// //     await analytics().setUserProperty("user_id", userId);
// //   };
// //   static createEvent = async (eventName, eventMethod) => {
// //     await analytics().logEvent(eventName, eventMethod);
// //   };
// //   static trackScreen = async screenName => {
// //     await analytics().logScreenView({
// //       screen_name: screenName,
// //       screen_class: screenName
// //     });
// //   };
// // }

// export const trackAnalytics = (custId: any) => {
//     ReactGA.initialize(custId);
//    // ReactGA.pageview(window.location.pathname + window.location.search);
//     // ReactGA.set({"dimension3": custId});
//     // ReactGA('set', 'dimension3', custId.toString());
//     // ReactGA('send', 'pageview');
//     // ga('m2igtm.send', 'pageview', '/VPV/M2I/currency-converter/usd-to-inr');
//   };
//   export const eventTrack = (category: any, action: any, label: any) => {
//     console.log("GA event:", category, ":", action, ":", label);
//     ReactGA.event({
//       category: category,
//       action: action,
//       label: label,
//     })
//   };
import firebase from "firebase/app";
import "firebase/auth";
import 'firebase/analytics';
export default class GoogleAnalyticsService {
  static analytics = firebase.analytics();
  static createEvent = async (eventName :any, eventMethod:any) => {
    firebase.analytics().logEvent(eventName, eventMethod);
  };

  static trackScreen = async (screenName:string) => {
    firebase.analytics().setCurrentScreen(
      screenName
    );
  };

  static setUserId = async (phoneNo:any) => {
    firebase.analytics().setUserId(phoneNo);
    firebase.analytics().setUserProperties({"user_id": phoneNo});
  };
}


