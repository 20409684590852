import * as React from "react";
import { User as FirebaseUser } from "firebase";
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/analytics";
import { store } from "store";
import { updateClientToken } from "modules/api/client";
import { getToken } from "modules/api/methods";
import { auth } from "../firebaseAuth";
import GoogleAnalyticsService from "../../../modules/utils/GoogleAnalyticsService";
import { LOG_EVENTS } from "../../../modules/utils/LogEvents";
import { getOperatorCode } from "../../../modules/utils/getOperatorCode";
const CACHE_KEY = "phone-guest_id";
type AuthState = "idle" | "pending" | "resolved";

type AuthContextValue = {
  value: FirebaseUser | null;
  state: AuthState;
};

const AuthContext = React.createContext<AuthContextValue | undefined>(
  undefined
);

export const useUserData = () => {
  const contextValue = React.useContext(AuthContext);
  if (!contextValue) {
    throw new Error("You should use AuthProvider in your component tree.");
  }
  return contextValue;
};

export const AuthProvider: React.FC = ({ children }) => {
  const [value, setValue] = React.useState<FirebaseUser | null>(null);
  const [hasToken, setHasToken] = React.useState(false);
  const [state, setState] = React.useState<AuthState>("idle");
  const str = localStorage.getItem(CACHE_KEY);
  if (!str && !value?.phoneNumber) {
    const id = new Date().getUTCMilliseconds();
    localStorage.setItem(CACHE_KEY, String(id));
    GoogleAnalyticsService.setUserId("guest" + id);
    firebase.analytics().setUserProperties({ guest_id: "guest" + id });
    GoogleAnalyticsService.createEvent(LOG_EVENTS.LOG_EVENTS_NAME.GUEST_ID, {
      web_guest_user: LOG_EVENTS.LOG_METHOD_VALUE.GUEST_USER,
      phone_no: "guest" + id,
      guest_id: "guest" + id,
      operator_code: getOperatorCode(),
      source: "web",
    });
  } else {
  }
  React.useEffect(() => {
    setState("pending");
    const unsubscribe = auth.onAuthStateChanged(async (value) => {
      setValue(value);
      GoogleAnalyticsService.setUserId(value?.phoneNumber);
      firebase.analytics().setUserProperties({ guest_id: str });
      GoogleAnalyticsService.createEvent(LOG_EVENTS.LOG_EVENTS_NAME.GUEST_ID, {
        web_guest_user: LOG_EVENTS.LOG_METHOD_VALUE.GUEST_USER,
        phone_no: value?.phoneNumber,
        guest_id: str,
        operator_code: getOperatorCode(),
        source: "web",
      });
      if (value) {
        const firebaseToken = await value.getIdToken();
        const { token: apiToken } = await getToken(firebaseToken);
        updateClientToken(apiToken);
        setHasToken(true);
        setState("resolved");
        store.dispatch({
          type: "GOT_USER",
          payload: { state: "resolved", hasToken: true, value },
        });
      } else {
        updateClientToken(null);
        setHasToken(false);
        setState("resolved");
        store.dispatch({
          type: "REMOVE_USER",
          payload: { state: "resolved", hasToken: false, value: null },
        });
      }
    });
    return unsubscribe;
  }, [str]);

  const contextValue = React.useMemo(
    () => ({ value, state, hasToken }),
    [value, state, hasToken]
  );
  return (
    <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
  );
};

export type User = FirebaseUser;
