import React from "react";
import { useRifm } from "rifm";
import { AsYouType } from "libphonenumber-js";
import { TextFieldProps } from "@material-ui/core/TextField";
import KeyboardArrowDown from "@material-ui/icons/KeyboardArrowDown";
import { Button as DefaultButton } from "@material-ui/core";
import { TextField } from "modules/ui/atoms/TextField";
import countryFlags from "modules/ui/molecules/PhoneInput/flags.json";
import { TEST_ID } from "modules/ui";

export const normalizePhone = (value: string) =>
  (value.match(/\d+/g) || []).join("");

const toValue = (val: unknown): string => {
  if (typeof val === "string" || typeof val === "number") {
    return String(val);
  }
  return "";
};

type Code = {
  dial_code: string;
  flag: string;
  name: string;
  code: string;
};

type PhoneInputProps = Omit<TextFieldProps, "onChange"> & {
  onChange: (str: any) => any;
  handleOpenCountrySearchBox: (str: any) => any;
  codeValue: Code | null;
};

const formatPhone = (value: string) => {
  const digits = normalizePhone(value).substr(0, 15);
  return new AsYouType().input(digits);
};

export const PhoneInputV2 = (props: PhoneInputProps) => {
  const {
    value,
    onChange,
    InputProps,
    codeValue,
    handleOpenCountrySearchBox,
    ...rest
  } = props;
  const correctValue = toValue(value);
  const rifm = useRifm({
    accept: /\d+/g,
    value: correctValue,
    onChange: onChange,
    format: formatPhone,
  });

  return (
    <TextField
      type="text"
      onChange={rifm.onChange}
      value={rifm.value}
      {...(rest as any)}
      InputProps={{
        style: {
          height: "3.5rem",
          padding: "0 12px",
        },
        startAdornment: (
          <DefaultButton
            data-testid={TEST_ID.COUNTRY_SELECT_BUTTON}
            disableRipple
            style={{ padding: 0 }}
            onClick={handleOpenCountrySearchBox}
          >
            {
              //@ts-ignore
              `${codeValue?.flag || countryFlags[97].flag} ${
                codeValue?.dial_code || countryFlags[97].dial_code
              }`
            }
            <KeyboardArrowDown />
          </DefaultButton>
        ),
      }}
    />
  );
};
