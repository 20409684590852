import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { Advantage } from "../../molecules/Advantage/Advantage";
import advantageIcon1 from "./advantage-1.svg";
import advantageIcon2 from "./advantage-2.svg";
import advantageIcon3 from "./advantage-3.svg";
import advantageIcon4 from "./advantage-4.svg";
// import classes from "./AdvantageList.module.css";

const advantages = [
  {
    title: "bookingTitle",
    content: "bookingContent",
    icon: advantageIcon1,
  },
  {
    title: "promotionTitle",
    content: "promotionContent",
    icon: advantageIcon2,
  },
  {
    title: "paymentTitle",
    content: "paymentContent",
    icon: advantageIcon3,
  },
  {
    title: "routesTitle",
    content: "routesContent",
    icon: advantageIcon4,
  },
];

const AdvantageBlock = styled(({ ...rest }) => <div {...rest} />)`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  padding: 70px 50px 75px 50px;
  @media (max-width: 1200px) {
    padding: 45px 50px 75px 50px;
  }
  @media (max-width: 768px) {
    padding: 50px 0;
  }
  @media (max-width: 575px) {
    padding: 20px;
    padding-top: 0px;
  }
`;

export function AdvantageList() {
  const { t } = useTranslation("advantage");
  return (
    <AdvantageBlock>
      {advantages.map((advantage) => (
        <Advantage
          key={advantage.title}
          title={t(advantage.title)}
          content={t(advantage.content)}
          icon={advantage.icon}
        />
      ))}
    </AdvantageBlock>
  );
}
