import { onboardingAxios } from "../client";
import { getDefaultOperatorCode } from "../../utils/getDefaultOperator";

export async function operatorConfig() {
  return onboardingAxios
    .get<any>(`/v1/operators/${getDefaultOperatorCode()}/config`)
    .then((res) => {
      return res.data;
    })
    .catch((error) => error);
}
