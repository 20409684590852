import React from "react";
import { useTranslation } from "react-i18next";
// import clsx from "clsx";
import styled from "styled-components";
import Hidden from "@material-ui/core/Hidden";
import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/core";
import { Price } from "modules/entities";
import { Button } from "modules/ui";
import { getOperatorCode } from "../../../utils/getOperatorCode";
import GoogleAnalyticsService from "../../../utils/GoogleAnalyticsService";
import { LOG_EVENTS } from "../../../utils/LogEvents";
import { VerifiedIcon } from "assets/icons";
// import classes from "modules/search-result-list/organisms/TripCard/TripCard.module.css";

const TripReserveWrapper = styled(Grid)`
  margin-top: 35px !important;
  @media (max-width: ${(props) =>
    props.theme.custom.breakpoints["xs-breakpoint"]}) {
    margin-top: 10px !important;
    max-width: 100% !important;
    flex-basis: 100% !important;
  }
`;

const TextCommon = styled(({ ...rest }) => <Grid {...rest} />)`
  font-family: ${(props) => props.theme.custom["roboto-font"]};
  font-style: normal;
  color: ${(props) => props.theme.custom["emperor-color"]};
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 5px;
  &.textLarge {
    font-size: 18px;
    span {
      @media (max-width: ${(props) =>
    props.theme.custom.breakpoints["xs-breakpoint"]}) {
        display: block;
    }
  }
  }
`;

const TextCommonDiv = styled.div`
  font-family: ${(props) => props.theme.custom["roboto-font"]};
  font-style: normal;
  color: ${(props) => props.theme.custom["emperor-color"]};
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 5px;
  &.textWarning {
    color: #eb5757;
  }
`;

const TextBold = styled.span`
  font-weight: bold;
`;

const GridWrapper = styled(({ ...rest }) => <Grid {...rest} />)`
  padding-bottom: 15px;
  padding-top: 5px;
`;
export interface TripReserveProps {
  price: Price;
  onSelect: () => void;
  seatsLeft: number;
  selected: boolean;
  selectedTab: string;
  discountDetails: any;
}

const ReserveButton = withStyles((theme) => ({
  root: {
    minWidth: "135px",
    minHeight: "40px",
    "&:hover": {
      background: theme.custom.secondary.main,
    },
    backgroundColor: theme.custom.secondary.main,
  },
  primary: {
    backgroundColor: theme.custom.secondary.main,
  },
}))(Button);

export const TripReserve: React.FC<TripReserveProps> = ({
  price,
  selected,
  onSelect,
  seatsLeft,
  selectedTab,
  discountDetails,
}) => {
  const [discountedValue, setDiscountedValue] = React.useState<any>();
  const [discountData, setDiscountData] = React.useState<any>();
  const [priceValue, setPriceValue] = React.useState<any>();

  React.useEffect(() => {
    if (discountDetails) {
      const tampData1 = {
        value: discountDetails.discountedPrice,
        currency: price.currency,
      };

      const originalPrice = {
        value: discountDetails.discountedPrice + discountDetails.discount,
        currency: price.currency,
      }

      const tampData2 = {
        value: discountDetails.discount,
        currency: price.currency,
      };
      setDiscountedValue(tampData1);
      setDiscountData(tampData2);
      setPriceValue(originalPrice);
    }
  }, [discountDetails, price.currency]);

  const btnOnSelect = () => {
    onSelect();
    if (selected) {
      if (selectedTab === "0") {
        GoogleAnalyticsService.createEvent(
          LOG_EVENTS.LOG_EVENTS_NAME.PRESS_BUTTON_SELECT_RIDE_FROM,
          {
            search_result_from:
              LOG_EVENTS.LOG_METHOD_VALUE.CLICKED_SELECT_BUTTON_ON_RIDE_ITEM,
            operator_code: getOperatorCode(),
            source: "web",
          },
        );
      } else {
        GoogleAnalyticsService.createEvent(
          LOG_EVENTS.LOG_EVENTS_NAME.PRESS_BUTTON_SELECT_RIDE_TO,
          {
            search_result_to:
              LOG_EVENTS.LOG_METHOD_VALUE.CLICKED_SELECT_BUTTON_ON_RIDE_ITEM,
            operator_code: getOperatorCode(),
            source: "web",
          },
        );
      }
    } else {
      if (selectedTab === "0") {
        GoogleAnalyticsService.createEvent(
          LOG_EVENTS.LOG_EVENTS_NAME.PRESS_BUTTON_REMOVE_RIDE_FROM,
          {
            search_result_from:
              LOG_EVENTS.LOG_METHOD_VALUE.CLICKED_REMOVE_BUTTON_ON_RIDE_ITEM,
            operator_code: getOperatorCode(),
            source: "web",
          },
        );
      } else {
        GoogleAnalyticsService.createEvent(
          LOG_EVENTS.LOG_EVENTS_NAME.PRESS_BUTTON_REMOVE_RIDE_TO,
          {
            search_result_to:
              LOG_EVENTS.LOG_METHOD_VALUE.CLICKED_REMOVE_BUTTON_ON_RIDE_ITEM,
            operator_code: getOperatorCode(),
            source: "web",
          },
        );
      }
    }
  };

  const { t } = useTranslation("searchResult");
  return (
    <TripReserveWrapper
      item
      sm={5}
      xs={12}
      container
      direction="column"
      alignItems="flex-end"
    // className={classes.tripReserveWrapper}
    >
      {discountDetails ? (
        <>
          <TextCommon className="textLarge">
            <span
              style={{
                textDecoration: "line-through",
                fontSize: 12,
                paddingRight: 4,
              }}
            >
              {t("priceValue", { priceValue })}
            </span>
            <span>{t("discountedValue", { discountedValue })}</span>
          </TextCommon>
          <p
            style={{
              color: "#03AA00",
              fontSize: 12,
              fontWeight: 400,
              display: "flex",
            }}
          >
            <VerifiedIcon />{" "}
            <span style={{ marginLeft: 5 }}>
              {t("save")} {t("discountData", { discountData })}/-
            </span>
          </p>
        </>
      ) : (
        <TextCommon className="textLarge">{t("price", { price })}</TextCommon>
      )}

      <GridWrapper>
        <ReserveButton
          color={selected ? "primary" : "secondary"}
          onClick={btnOnSelect}
          variant="contained"
          data-testid="reserve-button"
          className={selected ? "primary" : "secondary"}
        >
          {selected ? t("reserveButtonRemove") : t("reserveButtonReserve")}
        </ReserveButton>
      </GridWrapper>
      <Hidden xsDown>
        <Grid item>
          {seatsLeft < 6 && (
            <TextCommonDiv className="textWarning">
              <TextBold>
                {seatsLeft} {seatsLeft > 1 ? t("seats") : t("seat")}
              </TextBold>
            </TextCommonDiv>
          )}
        </Grid>
      </Hidden>
    </TripReserveWrapper>
  );
};
