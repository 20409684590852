import * as React from "react";
import { useTranslation } from "react-i18next";
// import clsx from "clsx";
import styled from "styled-components";
import { useRide } from "modules/api";
// import { StopElement } from "modules/entities";
import { getRideTime } from "modules/utils/rideUtils";
// import classes from "./StopsDetails.module.css";

const TimeContainerWrapper = styled.div`
  width: 60px;
  text-align: right;
`;

const PointLabel = styled.div`
  font-weight: bold;
  font-size: 10px;
  line-height: 16px;
  font-feature-settings: "pnum" on, "lnum" on;
  color: ${(props) => props.theme.custom.primary.main};
  text-transform: uppercase;
`;

const TimeLabel = styled.div`
  font-size: 16px;
  line-height: 16px;
  color: ${(props) => props.theme.custom["limed-spruce"]};
`;

const StopNameWrapper = styled.p`
  font-size: 16px;
  line-height: 16px;
  text-transform: capitalize;
  color: ${(props) => props.theme.custom["limed-spruce"]};
  margin: 0;
`;

const StepRow = styled.div`
  position: relative;
  display: grid;
  grid-auto-flow: column;
  grid-auto-rows: 100%;
  justify-content: start;
  align-items: flex-start;
  column-gap: 12px;
  &.arrived {
    align-items: flex-end;
  }
`;

const Connector = styled.div`
  position: absolute;
  width: 1px;
  top: 8px;
  left: 76px;
  z-index: 0;
  background: ${(props) => props.theme.custom["gray-color"]};
  height: 130%;
`;

const CircleWrapper = styled.div`
  width: 7px;
  height: 7px;
  border-radius: 50%;
  z-index: 1;
  &.circleFilled {
    background: ${(props) => props.theme.custom.main};
    width: 9px;
    background: ${(props) => props.theme.custom["gray-color"]};
    height: 9px;
  }

  &.circleOutlined {
    border: ${(props) => props.theme.custom.main} 1px solid;
    background: white;
  }
`;

type StopsDetailsProps = {
  pickUpStop: any;
  dropUpStop: any;
  rideId: string;
  timezoneFrom: string;
  timezoneTo: string;
};

const Circle: React.FC<{ filled: boolean }> = ({ filled }) => {
  return (
    <CircleWrapper className={filled ? "circleFilled" : "circleOutlined"} />
  );
};

const TimeContainer: React.FC<{
  label: string;
  time: string;
}> = ({ label, time }) => {
  const formatted = time;
  return (
    <TimeContainerWrapper>
      <PointLabel>{label}</PointLabel>
      <TimeLabel>{formatted}</TimeLabel>
    </TimeContainerWrapper>
  );
};

const StopName: React.FC = ({ children }) => (
  <StopNameWrapper>{children}</StopNameWrapper>
);

export const StopsDetails: React.FC<StopsDetailsProps> = ({
  pickUpStop,
  dropUpStop,
  rideId,
  timezoneFrom,
  timezoneTo,
}) => {
  const { t } = useTranslation("ticket");
  const { data: ride } = useRide(rideId);

  return (
    <div>
      <StepRow style={{ paddingBottom: 14 }}>
        <Connector />
        <TimeContainer
          label={t("departure")}
          time={
            getRideTime(
              pickUpStop.timeOfArrival,
              timezoneFrom,
              ride?.wasManuallyEdited,
              ride?.disableConversion,
              ride?.ridePatternId,
            ) || ""
          }
        />
        <Circle filled={false} />
        <StopName>{pickUpStop.stopId.name}</StopName>
      </StepRow>
      <StepRow className="arrived">
        <TimeContainer
          label={t("arrival")}
          time={
            getRideTime(
              dropUpStop.timeOfArrival,
              timezoneTo,
              ride?.wasManuallyEdited,
              ride?.disableConversion,
              ride?.ridePatternId,
            ) || ""
          }
        />
        <Circle filled />
        <StopName>{dropUpStop.stopId.name}</StopName>
      </StepRow>
    </div>
  );
};
