import React from "react";
import { parse } from "query-string";
import { useHistory } from "react-router-dom";
import { useTheme } from "@material-ui/core";
import { createReservation } from "modules/api/methods";
import { createBookingWithRapyd } from "modules/api/methods/booking";
import { PaymentTypes, Ticket } from "modules/entities";
import {
  NewReservationParams,
  Redirect,
  routes,
  useQueryParamState,
} from "modules/routing";
import { useLanguage } from "modules/localization";
import { Loading } from "modules/ui/atoms/Loading";
import { getBookingValues, getOrderId } from "modules/booking";
import { Notifications } from "modules/notifications";
import GoogleAnalyticsService from "../../../modules/utils/GoogleAnalyticsService";
import { LOG_EVENTS } from "../../../modules/utils/LogEvents";
import { getOperatorCode } from "../../../modules/utils/getOperatorCode";

declare global {
  interface Window {
    RapydCheckoutToolkit: any;
  }
}

type CheckoutResponse = {
  checkoutId: string;
  redirectUrl: string;
  ticketGroupId: string;
};

const useSavedValues = () => {
  const rideDetails = parse(
    window.location.search,
  ) as unknown as NewReservationParams;

  const userDetails = React.useMemo(() => {
    const id = getOrderId(rideDetails);
    return getBookingValues(id);
  }, [rideDetails]);

  return {
    rideDetails,
    userDetails,
  };
};

export function RapydPaymentPage() {
  const history = useHistory();
  const theme = useTheme();
  const language = useLanguage();
  const [loading, setLoading] = React.useState(true);
  const { rideDetails, userDetails } = useSavedValues();
  const [scriptLoaded, setScriptLoaded] = React.useState(false);
  const [ticket, setTicket] = React.useState<Ticket | null>(null);
  const [checkout, setCheckout] = React.useState<CheckoutResponse>();
  const [paymentMethod] = useQueryParamState("paymentMethod");
  const baseUrl = `${window.location.origin}/${
    window.location.pathname.split("/")[1]
  }/${language}`;
  const [paid, setPaid] = React.useState<Boolean | null>(null);

  React.useEffect(() => {
    GoogleAnalyticsService.createEvent(
      LOG_EVENTS.LOG_EVENTS_NAME.PRESS_BUTTON_BUY_TICKET,
      {
        payment_method: LOG_EVENTS.LOG_METHOD_VALUE.CLICKED_BUY_TICKET_BUTTON,
        operator_code: getOperatorCode(),
        source: "web",
      },
    );
    if (userDetails.valid) {
      const rides = [
        {
          rideId: rideDetails.rideId,
          pickUpStopId: rideDetails.pickUpStopId,
          dropOutStopId: rideDetails.dropOutStopId,
          passengers: userDetails.params.passengers,
        },
      ];

      createReservation({
        rides,
        paymentMethod: {
          code:
            paymentMethod && !Array.isArray(paymentMethod)
              ? paymentMethod
              : "null",
          icon: "",
          minAmount: 0,
          name: "none",
          type: PaymentTypes.none,
        },
        voucherCode: userDetails.params.voucher,
        email: userDetails.params.email,
        couponCode: "",
      })
        .then((r) => {
          setTicket(r.ticket);
        })
        .catch((e) => {
          Notifications.enqueueSnackbar(e?.response?.data?.message, {
            preventDuplicate: true,
          });
          setLoading(false);
        });
    } else {
      Notifications.enqueueSnackbar("User details are not valid!", {
        preventDuplicate: true,
      });
      setLoading(false);
      history.push(routes.newReservation.url(rideDetails));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (ticket?.id) {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      const thankYouPage = `${baseUrl}${routes.paymentStatus.url({
        ticketId: ticket.id,
      })}`;

      const params = {
        id: ticket.id,
        completePaymentUrl: thankYouPage,
        errorPaymentUrl: thankYouPage,
        type: "booking",
        category: paymentMethod,
      };

      createBookingWithRapyd(params)
        .then((r) => {
          setCheckout(r);
        })
        .catch((e) => {
          Notifications.enqueueSnackbar(e?.response?.data?.message, {
            preventDuplicate: true,
          });
          setLoading(false);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ticket]);

  React.useEffect(() => {
    if (checkout?.checkoutId) {
      setLoading(false);
      const handleScriptLoad = () => {
        let method = new window.RapydCheckoutToolkit({
          pay_button_text: "Pay Now",
          pay_button_color: theme?.custom?.secondary?.main,
          id: checkout?.checkoutId, // @todo checkout page id goes here
          close_on_complete: true,
          style: {
            submit: {
              base: {
                color: "white",
              },
            },
          },
        });
        method.displayCheckout();
      };

      ((callback: any) => {
        if (!document.getElementById("rapydScript")) {
          const rapydScript = document.createElement("script");
          rapydScript.src =
            process.env.REACT_APP_RAPYD_CHECKOUT_SCRIPT_URL || "";
          rapydScript.async = true;
          rapydScript.id = "rapydScript";
          rapydScript.onload = () => {
            setScriptLoaded(true);
            callback.call();
          };
          document.body.appendChild(rapydScript);
        } else if (scriptLoaded) {
          callback.call();
        }
      })(() => handleScriptLoad());

      window.addEventListener("onCheckoutPaymentSuccess", rapydSuccessFeedback);
      window.addEventListener("onCheckoutFailure", rapydErrorFeedback);
    }

    return () => {
      document.getElementById("rapydScript")?.remove();
      window.removeEventListener(
        "onCheckoutPaymentSuccess",
        rapydSuccessFeedback,
      );
      window.removeEventListener("onCheckoutFailure", rapydErrorFeedback);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkout]);

  const getPaymentStatus = (status: string) => {
    let result = {
      paid: false,
      message: "",
    };

    switch (status) {
      case "ACT":
        result = {
          paid: false,
          message: "Waiting for payment!",
        };
        break;

      case "CAN":
        result = {
          paid: false,
          message: "Payment cancelled!",
        };
        break;

      case "CLO":
        result = {
          paid: true,
          message: "Paid successffully!",
        };
        break;

      case "ERR":
        result = {
          paid: false,
          message: "An error occured while payment!",
        };
        break;

      case "EXP":
        result = {
          paid: false,
          message: "Payment link expired!",
        };
        break;

      default:
        result = {
          paid: false,
          message: "Payment initiated!",
        };
        break;
    }

    return result;
  };

  const rapydSuccessFeedback = (e: any) => {
    const result = getPaymentStatus(e?.detail?.status);

    if (e?.detail?.redirect_url) {
      window.location.href = e?.detail?.redirect_url;
    } else {
      Notifications.enqueueSnackbar(result.message, {
        preventDuplicate: true,
      });
      setPaid(true);
    }
  };

  const rapydErrorFeedback = (e: any) => {
    Notifications.enqueueSnackbar("Payment Failed", {
      preventDuplicate: true,
    });
    setPaid(false);
  };

  return loading ? (
    <Loading title="" />
  ) : paid === null ? (
    <div id="rapyd-checkout" />
  ) : paid ? (
    <Redirect to={routes.paymentStatus.url({ ticketId: ticket?.id || "" })} />
  ) : (
    <Redirect to={routes.paymentStatus.url({ ticketId: ticket?.id || "" })} />
  );
}
