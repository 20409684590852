import * as React from "react";
import { LinearProgress } from "@material-ui/core";
import { Redirect } from "modules/routing";
import { useUserData } from "./AuthProvider";

export const Authorized: React.FC = ({ children }) => {
  const { state, value } = useUserData();
  switch (state) {
    case "resolved": {
      if (value) {
        return <>{children}</>;
      }
      return <Redirect to="/" />;
    }
    case "idle":
    case "pending":
      return <LinearProgress />;
  }
};
