export const OTP_LENGTH = 6;

export const VERIFY_PHONE_ERRORS = {
  INVALID_PHONE_NUMBER: "auth/invalid-phone-number",
  INVALID_VERIFICATION_CODE: "auth/invalid-verification-code",
  ALREADY_IN_USE: "auth/credential-already-in-use",
  TOO_MANY_REQUESTS: "auth/too-many-requests",
  CODE_EXPIRED: "auth/code-expired",
  UNKNOWN: "auth/unknown",
};

export const OTP_VERIFICATION_SECS_TO_WAIT = 60;

export const EMAIL_PATTERN =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const INITIALIZE_APP = "INITIALIZE_APP";
export const UPDATE_OPERATOR = "UPDATE_OPERATOR";
export const UPDATE_COUNTRY = "UPDATE_COUNTRY";
export const SET_OPERATOR_CODE = "SET_OPERATOR_CODE";

// Feature Toggle Constants
export const RENTAL_CATALOG = "RentalCatalog";
export const RENTAL_ENQUIRY = "RentalEnquiry";
export const TWO_WAY_BOOKING = "TwoWayBooking";
export const CANCELLATION = "Cancellation";
export const MY_RIDE = "MyRide";
export const PROFILE = "Profile";
export const ONE_WAY_BOOKING = "OneWayBooking";
export const LANGUAGE = "Language";
