import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
// import classes from "./CartContentEmpty.module.css";

const CartWrapper = styled.div`
  font-family: Roboto;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  color: #4f4f4f;
`;

export const EmptyContent: React.FC = () => {
  const { t } = useTranslation("searchResult");
  return <CartWrapper>{t("emptyCart")}</CartWrapper>;
};
