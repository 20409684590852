import { parse, stringify } from "query-string";
import { format } from "date-fns";
import {
  parseNewReservation,
  parseRentalService,
  ParseResult,
  parseSearch,
  parseSelectPayment,
} from "./parsers";
import {
  CheckoutParams,
  NewReservationParams,
  RentalParams,
  RentalServiceParams,
  SearchParams,
  SelectPaymentParams,
  TicketPreviewParams,
} from "./query-types";

export const routes = {
  languagePrefix: {
    pattern: "/:language",
  },
  home: {
    pattern: "/",
    url: () => "/",
  },
  search: {
    pattern: "/search",
    url: (params: SearchParams) => {
      const date = format(params.date, "yyyy-MM-dd");
      const returnDate =
        params.returnDate && format(params.returnDate, "yyyy-MM-dd");
      const queryObj: any = { ...params, date };
      if (returnDate) {
        queryObj["returnDate"] = returnDate;
      }
      const query = stringify(queryObj);
      return "/search?" + query;
    },
    parse: (query: string): ParseResult<SearchParams> => {
      const values = parse(query);
      return parseSearch(values);
    },
  },
  newReservation: {
    pattern: "/new-reservation",
    url: (params: NewReservationParams) => {
      const query = stringify(params);
      return `/new-reservation?${query}`;
    },
    parse: (query: string): ParseResult<NewReservationParams> => {
      const values = parse(query);
      return parseNewReservation(values);
    },
  },
  selectPayment: {
    pattern: "/select-payment",
    url: (params: SelectPaymentParams) => {
      const query = stringify(params);
      return `/select-payment?${query}`;
    },
    parse: (query: string): ParseResult<SelectPaymentParams> => {
      const values = parse(query);
      return parseSelectPayment(values);
    },
  },
  checkoutVA: {
    pattern: "/checkout/va/:ticketId",
    url: (params: CheckoutParams) => {
      const query = stringify(params);
      return `/checkout/va/${params.ticketId}?${query}`;
    },
    parse: (query: string): ParseResult<SelectPaymentParams> => {
      const values = parse(query);
      return parseSelectPayment(values);
    },
  }, 
  checkoutSuccess: {
    pattern: "/checkout/thankyou/:ticketId",
    url: (params: CheckoutParams) => {
      return `/checkout/thankyou/${params.ticketId}`;
    },
  },
  ticketPreview: {
    pattern: "/tickets/:ticketId",
    url: (params: TicketPreviewParams) => {
      return `/tickets/${params.ticketId}`;
    },
  },
  termsAndConditions: {
    pattern: "/terms-and-conditions",
    url: () => "/terms-and-conditions",
  },
  termsNConditions: {
    pattern: "/terms-n-conditions",
    url: () => "/terms-n-conditions",
  },
  privacyPolicy: {
    pattern: "/privacy-policy",
    url: () => "/privacy-policy",
  },
  profile: {
    pattern: "/profile",
    url: () => "/profile",
  },
  rental: {
    pattern: "/rental",
    url: () => "/rental",
  },
  checkoutOVO: {
    pattern: "/checkout/ovo",
    url: (params: any) => {
      return `/checkout/ovo${params}`;
    },
  },
  rides: {
    pattern: "/rides",
    url: () => "/rides",
  },
  rapyd: {
    pattern: "/payment-gateway",
    url: (params: string) => {
      return `/payment-gateway${params}`;
    },
  },
  paymentStatus: {
    pattern: "/payment-checkout-status/:ticketId",
    url: (params: TicketPreviewParams) => {
      return `/payment-checkout-status/${params.ticketId}`;
    },
  },
  xendit: {
    pattern: "/payment-checkout",
    url: (params: string) => {
      return `/payment-checkout${params}`;
    },
  },
  paymentGateway: {
    pattern: "/payment-gate",
    url: (params: string) => {
      return `/payment-gate${params}`;
    },
  },
  rentalServiceListing: {
    pattern: "/rental-service-listing",
    url: (params: RentalServiceParams) => {
      const query = stringify(params);
      return `/rental-service-listing?${query}`;
    },
    parse: (query: string): ParseResult<RentalServiceParams> => {
      const values = parse(query);
      return parseRentalService(values);
    },
  },
  rentalService: {
    pattern: "/rental-service-listing/:serviceId",
    url: (params: RentalServiceParams) => {
      const query = stringify(params);
      return `/rental-service-listing/${params.serviceId}?${query}`;
    },
  },
  rentalPaymentStatus: {
    pattern: "/payment-rental-status/:id",
    url: (params: RentalParams) => {
      return `/payment-rental-status/${params.id}`;
    },
  },
  contactUs: {
    pattern: "/contact-us",
    url: () => "/contact-us",
  },
  privacypolicy: {
    pattern: "/privacy_policy",
    url: () => "/privacy_policy",
  },
  aboutUs: {
    pattern: "/about-us",
    url: () => "/about-us",
  },
  faq: {
    pattern: "/faq",
    url: () => "/faq",
  },
  help: {
    pattern: "/help",
    url: () => "/help",
  },
  offers: {
    pattern: "/offers",
    url: () => "/offers",
  },
};
