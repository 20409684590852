import React from "react";
import { useTranslation } from "react-i18next";
import { useFieldArray, useFormContext } from "react-hook-form";
import clsx from "clsx";
import styled from "styled-components";
import { BookingFormFieldsName } from "modules/ui/variables";
import { Label } from "../atoms/Label/Label";
import { FormInput } from "../molecules/FormInput/FormInput";
import Info from "../icons/info.svg";
// import classes from "./FirstBlock.module.css";

type Props = {
  passengers: number;
};

const Wrapper = styled.div`
  background: ${(props) => props.theme.custom["white-color"]};
  border: 1px solid ${(props) => props.theme.custom["alto-color"]};
  box-sizing: border-box;
  border-radius: 3px;
  padding: 15px 30px;
  max-width: 680px;
  @media (max-width: ${(props) =>
    props.theme.custom.breakpoints["xs-breakpoint"]}) {
    padding: 15px 16px;
    border: none;
  }
`;

const InfoWrapper = styled.div`
  .infoIcon__wrapper {
    display: flex;
    justify-content: space-between;
    img {
      width: 22px;
      height: 22px;
    }
  }
`;

const PassangerTitle = styled.p`
  margin-top: 15px;
  &.firstLable {
    margin: 24px 0 0 0;
  }
  &.nextLable {
    margin: 20px 0 0 0;
  }
  &.labelInput {
    font-weight: 500;
    font-size: 14px;
    line-height: 10px;
    color: ${(props) => props.theme.custom["emperor-color"]};
  }
`;

const InputsWrapper = styled.div`
  display: flex;
  margin: 14px 0 0 0;
  justify-content: space-between;
  &.inputsError {
    margin-bottom: 22px;
  }
  @media (max-width: ${(props) =>
    props.theme.custom.breakpoints["xs-breakpoint"]}) {
    flex-direction: column;
  }
`;

const InfoDescriptionText = styled.div`
  font-family: ${(props) => props.theme.custom["roboto-font"]};
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 18px;
  color: ${(props) => props.theme.custom["gray-color"]};
  margin: 6px 0 0 0;
`;

const InputWrapper = styled.div`
  width: 100%;
  .MuiFormHelperText-contained {
    margin: 4px 0;
  }
  &.marginBottom {
    margin-bottom: 0px;
  }
  &.paddingLeft {
    padding-left: 25px;
    @media (max-width: ${(props) =>
    props.theme.custom.breakpoints["xs-breakpoint"]}) {
      padding-left: 0px;
      margin-top: 25px;
    }
  }
`;

export const FirstBlock: React.FC<Props> = ({ passengers }) => {
  const { t } = useTranslation("reservation");
  const { errors } = useFormContext();
  const { fields } = useFieldArray({ name: "passengers" });

  return (
    <Wrapper>
      <InfoWrapper>
        <div className="infoIcon__wrapper">
          <Label number={1} label={t("passengerTitle")} />
          {/* <img src={Info} alt="" /> */}
        </div>
        {fields.map((item, index) => {
          return (
            <React.Fragment key={index}>
              {fields.length > 1 && (
                <PassangerTitle
                  className={clsx(
                    index === 0 ? "firstLable" : "nextLabel",
                    "labelInput"
                  )}
                >
                  {t("passengerTitle")} {index + 1}
                </PassangerTitle>
              )}
              <InputsWrapper
                className={clsx(
                  errors["passengers"] &&
                  errors["passengers"][index] &&
                  "inputsError"
                )}
              >
                <InputWrapper
                  className="marginBottom"
                  data-testid="reservation-name-input"
                >
                  <FormInput
                    name={`passengers[${index}].${BookingFormFieldsName.firstName}`}
                    label={t("firstNameLabel")}
                    requiredErrorMessage={t("required")}
                    validationRules={{
                      root: "passengers",
                      index,
                      key: BookingFormFieldsName.firstName,
                    }}
                  />
                </InputWrapper>
                <InputWrapper className="paddingLeft">
                  <FormInput
                    name={`passengers[${index}].${BookingFormFieldsName.lastName}`}
                    label={t("lastNameLabel")}
                    requiredErrorMessage={t("required")}
                    validationRules={{
                      root: "passengers",
                      index,
                      key: BookingFormFieldsName.lastName,
                    }}
                  />
                </InputWrapper>
              </InputsWrapper>
              {index === 0 && (
                <InfoDescriptionText>
                  {t("passengerDescription")}
                </InfoDescriptionText>
              )}
            </React.Fragment>
          );
        })}
      </InfoWrapper>
    </Wrapper>
  );
};
