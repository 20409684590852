export const ENDPOINTS = {
  BUS_STOPS: "/bus-stops/",
  CITIES: "/cities/",
  // SEARCH_BUSSR: "/search/bussr",
  // SEARCH_SQIVA: "/search/sqiva",
  SEARCH_RIDES: "/v2/search/rides",
  SEARCH_SUGGESTS: "/location/search",
  TICKETS: "/tickets",
  CREATE_RESERVATION: "/v2/tickets/reserve",
  CREATE_BOOKING: "/v2/tickets",
  RIDES: "/v2/rides",
  ME: "/me",
  UPDATE_ME: "/v2/me",
  VERIFY_VOUCHER: "/voucher/verify",
  PAYMENT_METHODS: "/payment-methods",
  RENTAL_SERVICE: "/v2/rental",
  MY_RIDES: "/me/active-tickets",
  PAYMENT_CHECKOUT: "/v2/payment-checkout",
  TERMS_CONDITIONS: "/terms-conditions",
  RENTAL_CATEGORIES: "v2/rental/categories",
  RENTAL_BOOKING: "v2/rental-catalog/booking",
  GET_RENTAL_BOOKING: "v2/rental-catalog/bookings",
  ONBOARDING: {
    TERMS_CONDITIONS: "/v1/{operatorCode}/customer-policies/find-one",
    SUBSCRIPTION: "/v1/operators",
    CONTACTUS: "/v1/operators/{operatorCode}/customer-support",
    TERMS_SERVICE: "/v1/{operatorCode}/customer-policies",
    PRIVACY_POLICY: "v1/operators/{operatorCode}/bussr-to-passengers-policy",
    ABOUT_US: "v1/operators/{operatorCode}/html-pages",
    REGION: "v1/regions",
  },
  CAMPAIGNS: "campaigns",
  VALIDATE_COUPON: "campaigns/validate",
};
