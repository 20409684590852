import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";

interface Props {
  title?: string;
}

const LoadingContainer = styled.div`
  display: flex;
  min-height: 100vh;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: ${(props) => props.theme.custom["gray-text-color"]};
`;

export function Loading(props: Props) {
  const { title } = props;
  const { t } = useTranslation("loading");

  return (
    <LoadingContainer>
      <div>
        <CircularProgress color="inherit" style={{ marginBottom: 20 }} />
      </div>
      <Typography component="h1">{t(`${title}`) || ""}</Typography>
    </LoadingContainer>
  );
}
