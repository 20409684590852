import useSWR from "swr";
import { Ride } from "modules/entities";
import { ENDPOINTS } from "../endpoints";
import { updateOperatorCode } from "modules/api/client";

export const useRide = (rideId: string | null, passengers?: number) => {
  updateOperatorCode(localStorage.getItem("operatorCode"));
  return useSWR<Ride>(
    rideId && `${ENDPOINTS.RIDES}/${rideId}?countOfPassengers=${passengers ? passengers : 1}`,
  );
};
