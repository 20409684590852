import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

const Section = styled.div`
  border: 1px solid #d2d2d6;
  box-sizing: border-box;
  border-radius: 6px;
  padding: 6px 10px;
  display: flex;
  align-items: center;
`;

const TextInput = styled.input`
  width: 100%;
  border: none;
  outline: none;
  font-weight: 500;
  font-size: 16px;
  color: #19191d;
  flex: 0.8;
  background: #fff;
`;

const ApplyBtn = styled.button`
  background: #2979ff;
  border-radius: 4px;
  flex: 0.2;
  color: white;
  padding: 10px;
  outline: none;
  font-weight: 700;
  border: none;
  cursor: pointer;

  &:hover {
    background: #2264d1;
  }

  &:active {
    background: #1b4ea3;
  }
`;

const RemoveBtn = styled.button`
  background: #f44336;
  border-radius: 4px;
  flex: 0.2;
  color: white;
  padding: 10px;
  outline: none;
  font-weight: 700;
  border: none;
  cursor: pointer;

  &:hover {
    background: #c8372d;
  }

  &:active {
    background: #9c2b23;
  }
`;

const ErrorMsg = styled.p`
  color: red;
  font-size: 10px;
  padding-top: 5px;
`;

const TextField = ({
  onRemoveCoupon,
  onApplyCoupon,
  offerCode,
  handleChange,
  removeCoupon,
  errorMsg,
}: any) => {
  const { t } = useTranslation("reservation");
  return (
    <>
      <Section>
        <TextInput
          placeholder="Coupon code"
          value={offerCode}
          onChange={handleChange}
          disabled={removeCoupon}
          maxLength={20}
        />
        {removeCoupon ? (
          <RemoveBtn type="button" onClick={onRemoveCoupon}>
            {t("removeCaps")}
          </RemoveBtn>
        ) : (
          <ApplyBtn type="button" onClick={onApplyCoupon}>
            {t("apply")}
          </ApplyBtn>
        )}
      </Section>
      {errorMsg ? <ErrorMsg>{errorMsg}</ErrorMsg> : null}
    </>
  );
};

export default TextField;
