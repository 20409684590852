import React from "react";
import { useLocation } from "react-router-dom";
import { parse } from "query-string";
import { addDays } from "date-fns";
import styled from "styled-components";
import { Link, routes } from "modules/routing";
import { SearchParams } from "modules/routing/query-types";
// import classes from "./DateLink.module.css";

const LinkWrapper = styled(({ ...rest }) => <Link {...rest} />)`
  text-decoration: none;
`;

type DateLinkProps = {
  date: Date;
  daysToAdd: number;
};

export const DateLink: React.FC<DateLinkProps> = ({
  date,
  daysToAdd,
  children,
}) => {
  const { search } = useLocation();
  const params: any = parse(search);
  let nextDate = date;
  const urlParams = new URLSearchParams(window.location.search);
  const tabIndex = urlParams.get("tabIndex");
  let fromDate = new Date(params.date);
  let toDate = params.returnDate && new Date(params.returnDate);
  if (toDate) {
    if (tabIndex === "0") {
      fromDate = addDays(new Date(params.date), daysToAdd);
    }
    if (tabIndex === "1") {
      toDate = addDays(new Date(params.returnDate), daysToAdd);
    }
  } else {
    nextDate = addDays(date, daysToAdd);
  }

  const to = React.useMemo(() => {
    // query params at this url are considered to be valid search parameters
    const nextQueryParams = {
      ...params,
      date: !params.returnDate ? nextDate : fromDate,
    } as SearchParams;
    if (params.returnDate) {
      nextQueryParams.returnDate = toDate;
    }
    return routes.search.url(nextQueryParams);
  }, [fromDate, toDate, params, nextDate]);
  return <LinkWrapper to={to}>{children}</LinkWrapper>;
};
